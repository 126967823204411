import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TrainingService } from '../../training.service';
import { ReplaySubject } from 'rxjs';
import { orderBy } from 'natural-orderby';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class VolumesService {

  private observables = {};
  private groupsObservables = {};

  constructor(
    private httpClient: HttpClient,
    private trainingService: TrainingService
  ) {
  }

  async load(courseId: string): Promise<any> {

    if (typeof this.observables[courseId] === 'undefined') {
      this.observables[courseId] = new ReplaySubject(1);
      const api = await this.trainingService.loadByCourse(courseId);
      this.httpClient.get(api.getRoute('volumesUrl')).subscribe((result) => {
        this.observables[courseId].next(result);
        this.observables[courseId].complete();
      });
    }
    return this.observables[courseId].toPromise();
  }

  async loadVolume(courseId: string, uuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const api = this.trainingService.loadByCourse(courseId).then((api) => {
        const url = api.getRoute('volumeUrl').replace('{webAppId}', courseId).replace('{uuid}', uuid);
        this.httpClient.get(url).subscribe((result) => {
          resolve(result);
        });
      });
    });
  }

  async loadGroups(courseId: string): Promise<any> {
    if (typeof this.groupsObservables[courseId] === 'undefined') {
      this.groupsObservables[courseId] = new ReplaySubject(1);
      const api = await this.trainingService.loadByCourse(courseId);
      console.log(api);
      console.log(api.getRoute('volumeGroupsUrl'));
      this.httpClient.get(api.getRoute('volumeGroupsUrl')).subscribe((result) => {
        this.groupsObservables[courseId].next(result);
        this.groupsObservables[courseId].complete();
      });
    }
    return this.groupsObservables[courseId].toPromise();
  }

  public filter(value: any, volumeGroupId: string) {
    return orderBy(
      _.filter(value, element => {
        if (!volumeGroupId) {
          return true;
        } else if (volumeGroupId === 'VIDEO_TRAINING' && element.volumeGroup === null) {
          return true;
        } else if (element.volumeGroup === null) {
          return true;
        } else {
          return element.volumeGroup.code === volumeGroupId;
        }
      }),
      [v => v['sectionID']],
      ['asc']
    );
  }
}
