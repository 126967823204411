<form [formGroup]="form" (ngSubmit)="onSubmit()" class="stylized cfi-login-form">
  <div class="row">
    <div class="col-xl-9 col">
      <ng-container>
        <div class="form-row">
          <div class="form-group col-12 col-md-6">
            <input autocomplete="off" type="text" class="form-control" formControlName="firstName" id="firstName"
                   placeholder="First Name" required>
            <ng-container
              *ngIf="form.get('firstName').invalid && (form.get('firstName').dirty || form.get('firstName').touched)">
              <div class="text-danger" *ngIf="form.get('firstName').errors?.required">
                First name is required.
              </div>
            </ng-container>
          </div>
          <div class="form-group col-12 col-md-6">
            <input autocomplete="off" type="text" class="form-control" formControlName="lastName" id="lastName"
                   placeholder="Last Name" required>
            <ng-container
              *ngIf="form.get('lastName').invalid && (form.get('lastName').dirty || form.get('lastName').touched)">
              <div class="text-danger" *ngIf="form.get('lastName').errors?.required">
                Last name is required.
              </div>
            </ng-container>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 col-md-6">
            <input autocomplete="off" type="text" class="form-control" formControlName="email" type="email"
                   placeholder="Email Address" required>
            <ng-container *ngIf="form.get('email').invalid && (form.get('email').dirty || form.get('email').touched)">
              <div class="text-danger" *ngIf="form.get('email').errors?.required">
                Email is required.
              </div>
              <div class="text-danger" *ngIf="form.get('email').errors?.email">
                Email is invalid.
              </div>
            </ng-container>
          </div>
          <div class="form-group col-12 col-md-6">
            <input autocomplete="off" type="email" class="form-control" formControlName="confirm_email"
                   id="confirm_email" required placeholder="Confirm Email Address">
            <ng-container
              *ngIf="form.get('confirm_email').invalid && (form.get('confirm_email').dirty || form.get('confirm_email').touched)">
              <div class="text-danger" *ngIf="form.get('confirm_email').errors?.required">
                Confirm email is required.
              </div>
            </ng-container>
            <div class="text-danger" *ngIf="form.errors?.mismatchedEmail && (form.touched || form.dirty)">
              Emails must match.
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 col-md-6">
            <input autocomplete="off" type="password" class="form-control" formControlName="password" id="password"
                   placeholder="Password" required>
            <ng-container
              *ngIf="form.get('password').invalid && (form.get('password').dirty || form.get('password').touched)">
              <div class="text-danger" *ngIf="form.get('password').errors?.required">
                Password is required.
              </div>
              <div class="text-danger" *ngIf="form.get('password').errors?.minlength">
                Password length is too short.
              </div>
              <div class="text-danger" *ngIf="form.get('password').errors?.strong">
                The password must contain one upper case letter and one number
              </div>
            </ng-container>
            <div class="form-text text-muted">Must contain one upper case letter and one number</div>
            <div class="form-text text-muted">Minimum length of 8 characters</div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="showCfiCredentials">
        <div class="form">
          <h2>Add Your CFI Credentials (Optional)</h2>
          <div>This information is necessary to verify your CFI status and will
            provide complimentary access
            to Sporty's FIRC, Commercial, Instrument Rating and Learn to Fly Courses.
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-12 col-md-6">
            <input autocomplete="off" type="text" class="form-control" formControlName="cfi_number" id="cfiNumber"
                   [required]="false"
                   placeholder="CFI Certification #" (keyup)="cfiExpirationDateType='date'">
            <label>CFI Certification #</label>
          </div>
          <div class="form-group col-12 col-md-6">
            <div class="d-flex flex-wrap flex-row">
              <div class="w-50">
                <select formControlName="cfi_expiration_month"
                        class="form-control"
                        [required]="false">
                  <option value="">Month</option>
                  <option *ngFor="let month of months" [value]="month">{{month}}</option>
                </select>

              </div>
              <div class="w-50">
                <select formControlName="cfi_expiration_year"
                        class="form-control"
                        [required]="false">
                  <option value="">Year</option>
                  <option *ngFor="let year of years" [value]="year">{{year}}</option>
                </select>

              </div>
              <div class="w-100">CFI Recent Experience End Date (formerly your CFI Expiration Date)</div>
              <div *ngIf="!isValidExpirationDate()" class="w-100 text-danger">CFI Recent Experience End Date is expired</div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="d-flex align-items-center text-danger" *ngIf="errorMessage">
        <app-icon glyph name="info-red" [scale]="1" class="mr-1"></app-icon>
        {{ errorMessage }}
      </div>
    </div>
    <div class="col-xl-3 col-12 d-flex d-flex-row align-items-end justify-content-end pb-3">
      <button id="cancelAndLogin" type="button" class="btn btn-sm btn-white btn-round btn-shadow mr-3"
              (click)="cancelAndLogin()" *ngIf="showCancelAndLoginButton">Login
      </button>
      <button id="cancel" type="button" class="btn" (click)="cancel()" *ngIf="showCancelButton">Cancel
      </button>
      <button id="createAccount" [disabled]="form.invalid || isLoading" type="submit"
              class="btn btn-sm btn-primary btn-round btn-shadow ml-3" [ngClass]="{'btn-loading': isLoading === true}">
        {{submitButtonLabel }}
      </button>
    </div>

    <div class="text-size-12 px-5">
      By creating an account, you agree to the <a class="external-link" target="_blank" href="https://www.sportys.com/terms-of-use">Terms of Use</a> and <a
      class="external-link" target="_blank" href="https://www.sportys.com/privacy/">Privacy Policy</a>
    </div>

  </div>
</form>
