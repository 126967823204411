import { Component, Input, OnInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SignupModalComponent } from '../signup-modal/signup-modal.component';

@Component({
  selector: 'app-marketing-plate',
  templateUrl: './marketing-plate.component.html',
  styleUrls: ['./marketing-plate.component.scss']
})
export class MarketingPlateComponent implements OnInit {

  @Input()
  course: any;

  @Input()
  autoOpenCreateAccountModal = false;

  constructor(private modalService: BsModalService) {
  }

  ngOnInit(): void {
    if (this.autoOpenCreateAccountModal){
      this.signup();
    }
  }

  signup() {
    this.modalService.show(SignupModalComponent, {
      initialState: {
        course: this.course
      }, class: 'modal-lg rounded-modal'
    });
  }
}
