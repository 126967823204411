import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'underscore';
import Fuse from 'fuse.js';

@Pipe({
  name: 'questionFilter'
})
export class QuestionFilterPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    console.log('QuestionFilterPipe', args);

    const fuse = args[0] as Fuse<any>;
    const pattern = args[1];
    const marked = args[2];
    const questionAnswer = args[3];



    let results = value;
    if (pattern && fuse) {
      results = _.map(fuse.search(pattern), (question) => {
        return question.item;
      });
    }
    if (marked) {
      results = _.filter(results, (question) => {
        return question.marked === marked;
      });
    }
    if (questionAnswer === 'CORRECT') {
      results = _.filter(results, (question) => {
        return question.correct;
      });
    }
    if (questionAnswer === 'INCORRECT') {
      results = _.filter(results, (question) => {
        return !question.correct;
      });
    }
    return results;
  }

}
