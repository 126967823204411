import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ProgressService } from '../../progress.service';
import { CoursesService } from '../../courses.service';
import { TrainingService } from '../../training.service';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { ColorSchemeService } from 'src/app/services/color-scheme.service';

@Component({
  selector: 'app-old-help',
  templateUrl: './old-help.component.html',
  styleUrls: ['./old-help.component.scss']
})
export class OldHelpComponent implements OnInit {

  title: string;
  course: any;
  userCourse: any;
  closeBtnName: string;
  purchased: boolean;
  confirm = false;
  confirmed = false;
  api: any;
  user: any;
  homePage: boolean;
  callback: () => void;
  tab = 'GETTING_STARTED';

  constructor(
    public bsModalRef: BsModalRef,
    private coursesService: CoursesService,
    private progressService: ProgressService,
    private trainingService: TrainingService,
    public sanitizer: DomSanitizer,
    private modalService: BsModalService,
    public colorSchemeService: ColorSchemeService
  ) {}

  ngOnInit() {
    this.trainingService.load().subscribe(value => {
      this.api = value;
    });

    if (this.userCourse) {
      this.homePage = this.userCourse.homePage === 'volumes';
    }
    this.modalService.onHide.pipe(take(1)).subscribe(value => {
      console.log('hide');
      if (this.callback) {
        this.callback();
      }
    });
  }

  setTab($event, tab: string) {
    $event.preventDefault();
    $event.stopPropagation();

    console.log('setTab', tab);
    this.tab = null;
    window.setTimeout(() => {
      this.tab = tab;
    }, 500);
  }

  confirmResetCourseProgress() {
    this.confirm = true;
  }

  resetCourseProgress() {
    this.coursesService.resetVideoProgress(this.course.webAppId).then(value => {
      this.progressService.refreshProgress(this.course.webAppId);
      this.confirm = false;
      this.confirmed = true;
    });
  }

  selectHomePage($event) {
    this.coursesService.homePage(this.course, $event ? 'volumes' : 'home').then(value => {
      this.progressService.refreshProgress(this.course.webAppId);
    });
  }

}

