<div class="modal-header w-100 p-0">
  <div
    class="w-100 plate plate-dark_blue swoosh swoosh-cfi-home px-3 py-4 border-bottom-left-radius-0 border-bottom-right-radius-0 border-top-left-radius-modal border-top-right-radius-modal">
    <div class="d-flex justify-content-start align-items-center w-100 plate-header">
      <img class="w-100 modal-header-image d-none d-md-block"
        src="https://dl.videos.sportys.com/onlinecourse/images/sportys-logo-block-airplane.svg" />
      <div class="tagline text-size-18 ml-2">Share Course Progress</div>
      <div class="d-flex align-items-start pt-0 text-size-14 ml-5 pl-5 mr-5 cursor-pointer"
        (click)="this.cancel();">
        <span aria-hidden="true" class="text-white modal-close-btn text-size-20">×</span>
      </div>
    </div>
  </div>
</div>
<div class="modal-body px-4 mx-3 mb-0">
  <form class="stylized">
    <div>
      <p>
        Enter the email address of the person with which you’d like to share your course progress. They will receive an
        email with a special link to use, which will take them to a progress page that displays the video progress and
        test prep scores for all of your active Sporty’s training courses.
      </p>
      <div class="form-group my-3 py-3">
        <input type="email" [(ngModel)]="email" [ngModelOptions]="{standalone:true}" (ngModelChange)="evalEmail($event)"
          required class="form-control" id="exampleInputEmail1" placeholder="Email Address">
      </div>
      <div class="mb-2 mt-4">
        <button class="btn btn-round btn-primary" (click)="ok()" [disabled]="disabled">Send</button>
      </div>
    </div>
  </form>
  &nbsp;
  <div class="alert alert-warning" *ngIf="message && message !== ''">
    {{message}}
  </div>
</div>
