<div class="notes-container" (click)="$event.stopPropagation()">
  <mat-drawer-container [hasBackdrop]="true">
    <mat-drawer #drawer [mode]="'over'">
      <div class="left">
        <div class="actions">
          <a class="btn float-left" (click)="drawer.close()" title="Toggle drawer">
            <app-icon glyph name="left-arrow" />
          </a>
        </div>
        <ul class="notes-list">
          @for (parsedNote of parsedNotes(); track parsedNotes; let isLast = $last; let i = $index) {
            <li #notesListRef
                class="note-link"
                [class.active]="selectedNoteIndex() === i"
                (click)="selectNote(i); drawer.close()">
              <div class="text" [innerHTML]="parsedNote || ''"></div>
              @if (!isLast) {
                <div class="horizontal-divider"></div>
              }
              <a class="btn delete" (click)="deleteNote(i)">
                <app-icon glyph name="trash"></app-icon>
              </a>
            </li>
          }
        </ul>
      </div>
    </mat-drawer>
    <mat-drawer-content>
      <div class="right">
        <div class="notes-menu">
          <a class="btn float-left" (click)="openNotesListDrawer(drawer)" title="Show Notes">
            <app-icon glyph name="notes-list" />
          </a>
        </div>
        <div class="notes-content">
          <div class="note-editor-container">
            <form [formGroup]="noteForm" class="quill-form">
              <quill-editor
                #editor
                [theme]="'snow'"
                class="quill-editor"
                formControlName="note"
                [placeholder]="
                  notes()?.length ? '' : 'Add a note by clicking on the Add Note button to start typing...'
                "
                [customToolbarPosition]="'bottom'"
                [sanitize]="false"
                [readOnly]="notes()?.length < 1"
                [modules]="{
                  toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ header: 1 }, { header: 2 }],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link']
                  ]
                }"
              />
            </form>
          </div>
        </div>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
