import {Injectable} from '@angular/core';

import {AuthService} from '../../services/auth.service';
import {InstructorService} from '../../cfi/students/instructor.service';

@Injectable({providedIn: 'root'})
export class InstructorResolver  {
  constructor(private instructorService: InstructorService, private authService: AuthService) {
  }

  resolve(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.authService.renew().then(value => {
        this.instructorService.getInstructor().then(value => {
          resolve(value);
        });
      }, (error) => {
        this.instructorService.getInstructor().then(value => {
          resolve(value);
        });
      });
    });
  }
}
