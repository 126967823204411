import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './notes.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { ComponentModule } from '../component.module';
import { QuillModule } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';

@NgModule({
  declarations: [NotesComponent],
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    ComponentModule,
    ReactiveFormsModule,
    QuillModule,
    MatSidenavModule
  ],
  exports: [NotesComponent]
})
export class NotesModule {}
