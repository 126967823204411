@if (showMenu()) {
  @if (isPrivateCourse() || isInstrumentCourse()) {
    <div class="block">
      <h2>RESOURCES</h2>
      <div class="menu-card-list">
        <div class="menu-card" (click)="openQuickStartGuide()">
          <app-icon glyph name="resource-file"></app-icon>
          <span class="label">Quick Start Guide</span>
        </div>
        <div class="menu-card" (click)="selectQuickStartGuide()">
          <app-icon glyph name="help-center-hangar"></app-icon>
          <span class="label">Help Center</span>
        </div>
        <div class="menu-card" (click)="openKnowledgeBase()">
          <app-icon glyph name="resource-file"></app-icon>
          <span class="label">Knowledge Base</span>
        </div>
      </div>
    </div>
  }

  <div class="block">
    <h2>ACCOUNT & TECHNICAL QUESTIONS</h2>
    Contact Sporty's experienced customer service team with questions related to your account or course features.
    <a href="mailto:coursesupport@sportys.com" class="email">
      <app-icon glyph name="envelope" scale="0.6" class="envelope"></app-icon>
      {{ 'coursesupport@sportys.com' }}
    </a>
  </div>

  @if (isAskCfiVisible()) {
    <div class="block">
      <h2>ASK A CFI</h2>
      Looking for help understanding a particular aviation training topic, or have a question about the FAA written test? Send us an email and one of Sporty's experienced CFIs will get back to you within 24 hours.
      <a href="mailto:cfi@sportys.com" class="email">
        <app-icon glyph name="envelope" scale="0.6" class="envelope"></app-icon>
        {{ 'cfi@sportys.com' }}
      </a>
    </div>
  }

  <div class="block help-card">
    <h2>INSTACFI</h2>
    Connect with a certified flight instructor through a live video meeting.
    <div class="actions">
      <button type="button" class="primary" (click)="onScheduleNow()">Schedule Now</button>
    </div>
  </div>
} @else {
  <div class="summary-card">
    @if (summaryTitles()?.length > 0) {
      <span class="summary-title">Jump to Section</span>
    } @else {
      <span class="summary-title">No titles available at the moment</span>
    }

    @for (title of summaryTitles(); track title) {
      <a class="title-link" (click)="onTitleClick($event, title)" [href]="title.link">{{title.text}}</a>
    }
  </div>
  @if (quickStartGuideParsed()?.outerHtml) {
    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(quickStartGuideParsed()?.outerHtml)" class="help-center-content"></div>
  }
  <button class="btn btn-round btn-primary scroll-to-top" [class.show]="isScrolled()" (click)="onScrollToTop()">
    <app-icon glyph name="up-white"></app-icon>
  </button>
}
