import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {ResourcesService} from '../services/resources.service';
import * as _ from 'underscore';
import {VolumesService} from '../services/volumes.service';
import {AuthService} from '../../services/auth.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserResolver  {
  constructor(private authService: AuthService) {
  }

  resolve(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.authService.renew().then(value => {
        resolve(value);
      }, reason => {
        resolve(null);
      }).catch(reason => {
        resolve(null);
      });
    });
  }
}
