import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {AuthService} from 'src/app/services/auth.service';
import {CoursesService} from 'src/app/courses.service';
import {ProgressService} from 'src/app/progress.service';
import {ColorSchemeService} from 'src/app/services/color-scheme.service';
import {UserService} from 'src/app/user.service';
import {ConfirmationModalComponent} from '../confirmation/confirmation-modal.component';
import {take} from 'rxjs/operators';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AuthContextService} from '../../services/auth-context.service';
import {HelpDataService} from 'src/app/services/help-data.service';
import {QuestionDetailsService} from 'src/app/services/question-details.service';
import {TrainingService} from 'src/app/training.service';
import {ClientConsent} from './oauth2-client-consent.model';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent
  implements OnInit, OnChanges, OnDestroy {
  @Input() user;
  @Input() course;
  @Input() canOpenPanel = true;

  isUserInfoPanelVisible = false;
  isPageOverlayVisible = false;
  defaultThumbnailUrl =
    './assets/images/Person_icon_blank_trans.png';
  newDefaultThumbnailUrl =
    './assets/images/New_Person_icon_blank_trans.png';
  userThumbnailUrl: string;
  scope = 'SCOPE_';
  showPanelOptions = true;
  showPreferences = false;
  showMessages = false;
  showEditProfile = false;
  showHelp = false;
  showAskCfiMenuItem = true;
  showShareProgress = true;
  showGettingStarted = true;
  showAskCfi = false;
  homePage: boolean;
  clients: ClientConsent[] = [];
  errorMessage: any;
  updateProfileForm = new UntypedFormGroup({
    displayName: new UntypedFormControl(null, [
      Validators.required
    ]),
    accountEmail: new UntypedFormControl(null, [
      Validators.required,
      Validators.email
    ]),
    contactPhoneNumber: new UntypedFormControl(null, [
      Validators.required
    ]),
    currentPassword: new UntypedFormControl(null, [
      Validators.required
    ]),
    newPassword: new UntypedFormControl(null, [
      Validators.required
    ]),
    confirmNewPassword: new UntypedFormControl(null, [
      Validators.required
    ])
  });
  userCourse: any;
  popularCourses: any[] = [];
  profilePhotoName: string;
  message: string;
  purchased = false;
  modalOpen = false;
  maxHeight = 0;
  courses: any;
  cheat = false;

  config: any;

  constructor(
    private userService: UserService,
    private authContextService: AuthContextService,
    private authService: AuthService,
    private coursesService: CoursesService,
    private progressService: ProgressService,
    public sanitizer: DomSanitizer,
    public colorSchemeService: ColorSchemeService,
    private modalService: BsModalService,
    private questionDetailsService: QuestionDetailsService,
    private helpDataService: HelpDataService,
    private trainingService: TrainingService
  ) {
    this.cheat = this.trainingService.isTestMode();
  }

  ngOnInit(): void {
    this.trainingService.loadConfig().then(config => {
      this.config = config;

      this.maxHeight = window.innerHeight - 70;
      this.loadUserProfileForm();
      this.userThumbnailUrl =
        this.user?.profile_image_url ?? '';

      this.initShowAskCfiMenuItem();
      this.initShowProgress();
      this.initShowProfile();
      this.initPurchased();
      if (this.authContextService.isLoggedIn()) {
        this.getUserOauth2Consents();
      }

      if (this.course) {
        this.progressService
          .loadOne(this.course?.webAppId)
          .subscribe((progress) => {
            if (progress && progress[this.course?.webAppId]) {
              this.userCourse =
                progress[this.course?.webAppId].userCourse;

              if (this.userCourse) {
                this.homePage =
                  this.userCourse.homePage === 'volumes';
              }
            }
          });
      }

      this.coursesService.loadApi().subscribe(() => {
        this.coursesService.load().subscribe((courses) => {
          this.courses = courses;
        });
        if (this.authContextService.isLoggedIn()) {
          this.coursesService
            .getRecentCourses()
            .subscribe((resp) => {
              this.computePopularCourses(resp);
            });
        }
      });
    });

  }

  loadUserProfileForm() {
    if (this.authContextService.user) {
      this.updateProfileForm.controls.displayName.setValue(
        `${this.authContextService.user.first_name} ${this.authContextService.user.last_name}`
      );
      this.updateProfileForm.controls.accountEmail.setValue(
        `${this.authContextService.user.email}`
      );
      this.updateProfileForm.controls.contactPhoneNumber.setValue(
        `${this.authContextService.user.phone}`
      );
    }
  }

  computePopularCourses(recentCourses) {
    const currentCourseType =
      this.getCurrentCourseFromURL();

    if (currentCourseType !== '') {
      const currentCourseTypeElement = recentCourses.find(
        (x) => x.course_type == currentCourseType
      );

      if (currentCourseTypeElement !== undefined) {
        recentCourses.splice(
          recentCourses.indexOf(currentCourseTypeElement),
          1
        );
      }
    }

    if (recentCourses?.length > 0) {
      if (recentCourses.length < 2) {
        this.popularCourses = recentCourses;
      } else {
        this.popularCourses = recentCourses.slice(0, 2);
      }
    }
  }

  getCurrentCourseFromURL(): string {
    let currentCourseType = '';

    if (this.course !== undefined) {
      currentCourseType = this.course?.webAppId;
    }

    return currentCourseType;
  }

  onPopularCourseClick(course: any): void {
    this.coursesService.openCourse(
      this.courses.allCourseTypes[course.webAppId]
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.user &&
      changes.user.currentValue
    ) {
      this.userThumbnailUrl =
        this.user.profile_image_url ?? '';
    }
  }

  handleFileInput(files: FileList) {
    if (files && files.length) {
      this.profilePhotoName = files.item(0).name;
      this.userService
        .uploadUserThumbnail(files.item(0))
        .then((resp: any) => {
          this.userThumbnailUrl = resp.jpgUrl;
        });
    }
  }

  preferences(event) {
    event.preventDefault();
    event.stopPropagation();

    this.showPanelOptions = false;
    this.showHelp = false;
    this.showMessages = false;
    this.showEditProfile = false;
    this.showPreferences = true;
  }

  messages(event) {
    event.preventDefault();
    event.stopPropagation();

    this.showPanelOptions = false;
    this.showHelp = false;
    this.showMessages = true;
    this.showEditProfile = false;
    this.showPreferences = false;
  }

  help(event) {
    event.preventDefault();
    event.stopPropagation();

    this.showPanelOptions = false;
    this.showHelp = true;
    this.showMessages = false;
    this.showEditProfile = false;
    this.showPreferences = false;
  }

  back(event) {
    event.preventDefault();
    event.stopPropagation();
    this.showPanelOptions = true;
    this.showHelp = false;
    this.showMessages = false;
    this.initShowProfile();
    this.showPreferences = false;
  }

  logout(): void {
    this.authService.logout();
  }

  getCurrentTheme(): string {
    return this.colorSchemeService.colorMode();
  }

  sendResetPasswordEmail(): void {
    this.authService
      .resetPassword(this.authContextService.user.email)
      .then(
        (value) => {
          this.message =
            'You will receive an email with a link to reset your password.';
        },
        (error) => {
          this.message = error.error.message;
        }
      );
  }

  showUserInfoPanel() {
    if (this.canOpenPanel) {
      this.isUserInfoPanelVisible =
        !this.isUserInfoPanelVisible;

      if (this.isUserInfoPanelVisible) {
        this.maxHeight = window.innerHeight - 70;
        this.isPageOverlayVisible =
          this.isUserInfoPanelVisible;
        document.getElementsByTagName(
          'body'
        )[0].style.overflow = 'hidden';
      } else {
        this.closeUserInfo();
      }

      this.helpDataService.helpHidden.set(this.isUserInfoPanelVisible);
      this.helpDataService.helpOpened.set(!this.isUserInfoPanelVisible);
    }
  }

  closeUserInfo() {
    this.isUserInfoPanelVisible = false;
    window.setTimeout(() => {
      this.showPanelOptions = true;
      this.isPageOverlayVisible =
        this.isUserInfoPanelVisible;
      document.getElementsByTagName(
        'body'
      )[0].style.overflow = 'auto';
    }, 500);
    this.helpDataService.helpHidden.set(false);
  }

  selectHomePage($event) {
    this.coursesService
      .homePage(this.course, $event ? 'volumes' : 'home')
      .then((value) => {
        this.progressService.refreshProgress(
          this.course?.webAppId
        );
      });
  }

  getUserOauth2Consents() {
    this.authService.getOath2UserConsets().subscribe((value) => {
      this.clients = value.map(client => {
        return {
          ...client,
          authorities: client.authorities
            .map(auth => auth.replace(this.scope, ''))
            .filter(auth => auth !== 'profile')
        };
      });
    });
  }

  revokeConsent(client: ClientConsent, authority: String) {
    this.authService.revokeConsent(client, authority).subscribe({
      next: (value) => {
        client.authorities = ['revoked'];
      }, error: (error) => {
        this.errorMessage = error.error.message;
      }
    });
  }

  resetVideoProgress() {
    this.modalOpen = true;
    const modal = this.modalService.show(
      ConfirmationModalComponent,
      {
        initialState: {
          message:
            `Use the Reset button below to reset your video progress to 0%.  This will not reset any quizzes or tests you've taken, or remove any completion certificates already earned.`,
          warningMessage:
            'This video progress cannot be recovered once it is reset.',
          yesLabel: 'Yes, reset progress.',
          noLabel: 'No.',
          primaryButton: 'yes',
          title: 'Reset Course Progress'
        },
        ignoreBackdropClick: true,
        class: 'rounded-modal modal-lg'
      }
    );

    const sub = this.modalService.onHide
      .pipe(take(1))
      .subscribe((value) => {
        modal.hide();
        this.modalOpen = false;
        if (modal.content.response) {
          this.coursesService
            .resetVideoProgress(this.course.webAppId)
            .then((value) => {
              this.progressService.refreshProgress(
                this.course.webAppId
              );
            });
        }
      });
  }

  unmarkAll() {
    this.modalOpen = true;
    const modal = this.modalService.show(
      ConfirmationModalComponent,
      {
        initialState: {
          message:
            `Use the Yes, unmark all button below to unmark all your marked questions.`,
          yesLabel: 'Yes, unmark all.',
          noLabel: 'No.',
          primaryButton: 'yes',
          title: 'Unmark all questions'
        },
        ignoreBackdropClick: true,
        class: 'rounded-modal modal-lg'
      }
    );

    const sub = this.modalService.onHide
      .pipe(take(1))
      .subscribe((value) => {
        modal.hide();
        this.modalOpen = false;
        if (modal.content.response) {
          this.questionDetailsService.unmarkQuestions(
            this.course.webAppId
          ).then(
            () => {
              console.log('Questions unmarked successfully');
            },
            (error) => {
              console.error('Error unmarking questions', error);
            }
          );
        }
      });
  }

  setTheme(theme: string): void {
    this.colorSchemeService.update(theme);
    this.colorSchemeService.colorMode.set(theme);
  }

  markAllVideosWatched() {
    this.coursesService.markAllVideosWatched(this.course.webAppId);
  }

  block($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  ngOnDestroy() {
    document.getElementsByTagName(
      'body'
    )[0].style.overflow = 'auto';
  }

  openHelpCenter() {
    this.closeUserInfo();
    this.helpDataService.helpHidden.set(false);
    setTimeout(() => {
      this.helpDataService.helpOpened.set(true);
    }, 100);
  }

  private initShowAskCfiMenuItem() {
    this.showAskCfiMenuItem =
      !!this.course &&
      (this.course?.webAppId === 'PRIVATE' ||
        this.course?.webAppId === 'INSTRUMENT' ||
        this.course?.webAppId === 'COMMERCIAL' ||
        this.course?.webAppId === 'RECREATION' ||
        this.course?.webAppId === 'SPORT');
  }

  private initShowProgress() {
    this.showShareProgress =
      this.config['user.mode'] !== 'YE' &&
      !!this.course &&
      (this.course?.webAppId === 'PRIVATE' ||
        this.course?.webAppId === 'INSTRUMENT' ||
        this.course?.webAppId === 'COMMERCIAL' ||
        this.course?.webAppId === 'RECREATION' ||
        this.course?.webAppId === 'SPORT');
  }

  private initShowProfile() {
    this.showEditProfile =
      !!this.course &&
      (this.course?.webAppId === 'FIRC_2023' ||
        this.course?.webAppId === 'CFI_PORTAL');
  }

  private initPurchased() {
    this.purchased =
      this.authContextService.user &&
      this.course &&
      this.authContextService.user.coursePermissions[
        this.course?.webAppId
        ];
  }
}
