<div class="content">
  <div class="plate plate-light_blue p-0 m-2" style="max-width:900px;">
    <div class="plate plate-dark_blue swoosh swoosh-cfi-home">
      <div>
        <h1>Reset Sporty's Young Eagles Course Password</h1>
      </div>
    </div>
    <div class="plate-header p-4">
      <div *ngIf="error" class="alert alert-warning">
        {{ error.message }}
      </div>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="form-group">
          <label for="password">New Password</label>
          <input type="password" class="form-control" id="password" formControlName="password">
          <div *ngIf="form.get('password').invalid && form.get('password').touched" class="invalid-feedback">
            <div *ngIf="form.get('password').errors.required">Password is required</div>
            <div *ngIf="form.get('password').errors.minlength">Password must be at least 5 characters</div>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Confirm Password</label>
          <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword">
          <div *ngIf="form.get('confirmPassword').invalid && form.get('confirmPassword').touched"
               class="invalid-feedback">
            <div *ngIf="form.get('confirmPassword').errors.required">Password is required</div>
            <div *ngIf="form.get('confirmPassword').errors.minlength">Password must be at least 5 characters</div>
          </div>
        </div>
        <div *ngIf="form?.errors?.mismatch === true" class="alert alert-warning">
          Passwords do not match
        </div>
        <button type="submit" class="btn btn-lg btn-primary" [disabled]="form.invalid">Reset Password</button>
      </form>
    </div>
  </div>
</div>
