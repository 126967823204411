<div class="marketing-plate">
  <div class="area">
    <h1>Create a FREE Sporty's Flight Training Account</h1>
  </div>
  <div class="plate plate-light_blue">
    <div>
      <h2>With a FREE Sporty's Flight Training Account enjoy access to:</h2>
      <div>
        <button class="btn btn-round btn-white" (click)="signup()">
          <app-icon glyph name="login"></app-icon>
          Create a FREE Account
        </button>
      </div>
    </div>
    <div class="marketing-reasons">
      <div>
        <app-icon glyph name="categories"></app-icon>
        Study all question categories
      </div>
      <div>
        <app-icon glyph name="test"></app-icon>
        Review 5 of your previous sessions
      </div>
      <div>
        <app-icon glyph name="category-breakdown"></app-icon>
        View your category performance
      </div>
      <div>
        <app-icon glyph name="marked"></app-icon>
        Take Practice FAA Tests
      </div>
    </div>
  </div>
</div>
