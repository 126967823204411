import { Injectable } from '@angular/core';
import { TrainingService } from '../training.service';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class PingService {
  constructor(
    private httpClient: HttpClient,
    private trainingService: TrainingService) {}

  async ping(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.get(api.getRoute('altitudePingUrl'), {observe: 'response'}).subscribe((result) => {
          resolve(result.status);
        }, (error) => {
          reject(error);
        });
      });
    });
  }
}
