import {Component, OnDestroy, OnInit} from '@angular/core';
import {TrainingService} from './training.service';
import {ActivatedRoute, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, Event} from '@angular/router';
import * as moment from 'moment';
import {ColorSchemeService} from './services/color-scheme.service';
import {CookieService} from 'ngx-cookie-service';
import {Angulartics2Amplitude} from 'angulartics2';
import {HelpDataService} from './services/help-data.service';
import {initializeApp} from 'firebase/app';
import {getAnalytics} from 'firebase/analytics';
import {VersionService} from './services/version.service';
import {Subscription} from 'rxjs';
import {MaintenanceModeService} from './maintenance-mode.service';
import {TestModeService} from './test-mode.service';

import {datadogRum} from '@datadog/browser-rum';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'portal';
  loading = false;
  year = moment().year();
  versionPollingSub: Subscription;

  constructor(
    public helpDataService: HelpDataService,
    private trainingService: TrainingService,
    private router: Router,
    angulartics: Angulartics2Amplitude,
    private colorSchemeService: ColorSchemeService,
    private versionService: VersionService,
  ) {
    //console.log('AppComponent:constructor');
    angulartics.startTracking();
    router.events.subscribe((routerEvent) => {
      //console.log(routerEvent);
      this.checkRouterEvent(routerEvent);
    });

    window['courierAsyncInit'] = () => {
      console.log('Courier is Ready!');
      window['courier'].on('inbox/init', () => {
        console.log(window['courier'].inbox.config);
      });
    };

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before
      vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      if (event.matches) {
        //dark mode
        this.colorSchemeService.update('dark');
      } else {
        //light mode
        this.colorSchemeService.update('light');
      }
    });



    this.versionPollingSub = this.versionService.startPolling().subscribe();

    this.initFirebase();
    this.initGoogleAnalytics();
    this.initTestMode();
    this.initDatadog();
    this.initAmplitude();
  }

  ngOnInit() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        console.log('Navigating to:', event.url);
      }
    });
  }

  ngOnDestroy(): void {
    this.versionPollingSub?.unsubscribe();
  }

  closeHelp() {
    this.helpDataService.helpOpened.set(false);
  }

  private checkRouterEvent(routerEvent): void {
    //.log('AppComponent:routerEvent', routerEvent);
    if (routerEvent instanceof NavigationStart) {
      this.loading = true;
    }

    if (
      routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError
    ) {
      //console.log('AppComponent:navigation end');
      this.loading = false;
    }
  }



  private initFirebase() {
    this.trainingService.loadConfig().then(value => {
      if (value['firebase.enabled'] === true) {
        console.log('firebase init');
        const app = initializeApp(this.trainingService.getFirebaseConfig());
        console.log('firebase app', app);
        const analytics = getAnalytics(app);
        console.log('firebase analytics', analytics);
        window['_firebase'] = app;
        window['_firebase_analytics'] = analytics;
      }
    });
  }


  initGoogleAnalytics() {

    this.trainingService.loadConfig().then(value => {
      const key = value['ga_key'];

      if (key !== '') {
        window['dataLayer'] = window['dataLayer'] || [];

        function gtag() {
          // @ts-ignore
          ['dataLayer'].push(arguments);
        }

        // @ts-ignore
        gtag('js', new Date());
        // @ts-ignore
        gtag('config', key);


        var ga = document.createElement('script');
        ga.type = 'text/javascript';
        ga.async = true;
        ga.src = ('https:' == document.location.protocol ? 'https://www' : 'http://www') + '.googletagmanager.com/gtag/js?id=' + key;
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(ga, s);
      }
    });

  }

  initTestMode() {
    this.trainingService.loadConfig().then(value => {
      const isTestModeEnabled = value['test.mode'];
      const testModeSetting = localStorage.getItem('testMode');

      if (isTestModeEnabled) {
        const originalTitle = document.getElementsByTagName('title')[0].innerHTML;
        document.getElementsByTagName('title')[0].innerHTML = 'TEST MODE ' + originalTitle;

        const div = document.createElement('div');
        div.style.position = 'fixed';
        div.style.top = '0';
        div.style.left = '0';
        div.style.width = '100%';
        div.style.height = '10px';
        div.style.zIndex = '99999';
        div.style.backgroundColor = (testModeSetting === 'true') ? 'red' : 'orange';
        document.body.appendChild(div);
      }
    });
  }

  initAmplitude() {
    this.trainingService.loadConfig().then(value => {
      const key = value['amplitude_key'];
      if (key !== '') {
        (function(e, t) {
          // @ts-ignore
          const n = e.amplitude || {_q: [], _iq: {}};
          const r = t.createElement('script')
          ;r.type = 'text/javascript'
          ;r.integrity = 'sha384-d/yhnowERvm+7eCU79T/bYjOiMmq4F11ElWYLmt0ktvYEVgqLDazh4+gW9CKMpYW'
          ;r.crossOrigin = 'anonymous';
          r.async = true
          ;r.src = 'https://cdn.amplitude.com/libs/amplitude-5.2.2-min.gz.js'
          ;r.onload = function() {
            // @ts-ignore
            if (!e.amplitude.runQueuedFunctions) {
              console.log('[Amplitude] Error: could not load SDK');
            }
          }
          ;var i = t.getElementsByTagName('script')[0];
          i.parentNode.insertBefore(r, i)
          ;

          function s(e, t) {
            e.prototype[t] = function() {
              this._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
              return this;
            };
          }

          var o = function() {
              this._q = [];
              return this;
            }
          ;var a = ['add', 'append', 'clearAll', 'prepend', 'set', 'setOnce', 'unset']
          ;
          for (var u = 0; u < a.length; u++) {
            s(o, a[u]);
          }
          n.Identify = o;
          var c = function() {
              this._q = []
              ;
              return this;
            }
          ;var l = ['setProductId', 'setQuantity', 'setPrice', 'setRevenueType', 'setEventProperties']
          ;
          for (var p = 0; p < l.length; p++) {
            s(c, l[p]);
          }
          n.Revenue = c
          ;var d = ['init', 'logEvent', 'logRevenue', 'setUserId', 'setUserProperties', 'setOptOut', 'setVersionName', 'setDomain', 'setDeviceId', 'setGlobalUserProperties', 'identify', 'clearUserProperties', 'setGroup', 'logRevenueV2', 'regenerateDeviceId', 'groupIdentify', 'onInit', 'logEventWithTimestamp', 'logEventWithGroups', 'setSessionId', 'resetSessionId']
          ;

          function v(e) {
            function t(t) {
              e[t] = function() {
                e._q.push([t].concat(Array.prototype.slice.call(arguments, 0)));
              };
            }

            for (var n = 0; n < d.length; n++) {
              t(d[n]);
            }
          }

          v(n);
          n.getInstance = function(e) {
            e = (!e || e.length === 0 ? '$default_instance' : e).toLowerCase()
            ;
            if (!n._iq.hasOwnProperty(e)) {
              n._iq[e] = {_q: []};
              v(n._iq[e]);
            }
            return n._iq[e];
          }
          // @ts-ignore
          ;e.amplitude = n;
        })(window, document);

        // @ts-ignore
        amplitude.getInstance().init(key, null, {
          includeUtm: true,
          includeReferrer: true
        });
        console.log('amplitide initialized');
      }

    });
  }

  initDatadog() {
    this.trainingService.loadConfig().then(value => {
      const datadogApplicationId = value['datadog_application_id'];
      const datadogClientToken = value['datadog_client_token'];
      const datadogService = value['datadog_service'];
      const datadogEnv = value['datadog_env'];

      datadogRum.init({
        applicationId: datadogApplicationId,
        clientToken: datadogClientToken,

        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/

        site: 'datadoghq.com',
        service: datadogService,
        env: datadogEnv,

        // allowedTracingUrls *.sportys.com and simplysporty.net but not pspdfkit.courses.sportys.com
        allowedTracingUrls: [
          "https://courses.sportys.com",
          "https://staging.courses.sportys.com",
          "https://test.courses.sportys.com",
          "https://touchstone.courses.sportys.com",
          /.*\.simplysporty.net/
        ],


        //allowedTracingUrls: [/.*\.sportys.com/, /.*\.simplysporty.net/],

        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',

        sessionSampleRate: 0,
        sessionReplaySampleRate: 0,
        trackUserInteractions: false,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'allow',
      });
    });
  }

  /*
  initFacebook(){
    (f, b, e, v, n, t, s) => {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ?
          n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s)

    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1677375202485106');
    fbq('track', 'PageView');
  }*/

}
