<div class="doughnut-chart"
     *ngIf="doughnutChartData">
  <div class="doughnut-chart-canvas"
       [ngClass]="{'no-label': (!rightLabel || !innerLabel)}"
       [ngStyle]="{'height': height + 'px', 'width': width + 'px'}">
    <canvas baseChart
            #chart
            [datasets]="doughnutChartData"
            [labels]="doughnutChartLabels"
            [options]="doughnutChartOptions"
            [legend]="doughnutChartLegend"
            [type]="'doughnut'"
    >
    </canvas>

  </div>
  <div *ngIf="rightLabel"
       class="doughnut-chart-right-label">
    {{percent | mathRound}} %
  </div>
  <div *ngIf="innerLabel"
       [ngStyle]="{'color': isDarkModeOn ? '#ffffff' : '#000000',
      'font-size': height < 100 ? '18px' : '26px'}"
       class="doughnut-chart-inner-label">
    {{percent | mathRound}} %
  </div>
</div>
