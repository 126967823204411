import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { EMPTY, interval, map, startWith, switchMap, takeWhile, tap } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { SnackbarService } from './snackbar.service';

const VERSION_URL = '/version.html';
const POLLING_INTERVAL_VALUE = 1000 * 60 * 5; // 5 minutes

@Injectable({ providedIn: 'root' })
export class VersionService {
  private http = inject(HttpClient);
  private snackbarService = inject(SnackbarService);

  private initialVersion = '';
  private snackbarRef;

  startPolling() {
    return interval(POLLING_INTERVAL_VALUE).pipe(
      startWith(0), // start immediately when subscribed
      switchMap(() =>
        this.http.get(VERSION_URL).pipe(
          catchError((error: any) => {
            console.error(
              'An error occurred while polling for the latest version:',
              error
            );

            return EMPTY;
          })
        )
      ),
      map((resp: any) => resp?.message),
      tap((version: string) => {
        if (!this.initialVersion && version) {
          this.initialVersion = version;
        }

        if (version !== this.initialVersion) {
          this.triggerNewVersionSnackbar();
        }
      }),
      takeWhile((version: string) => version === this.initialVersion)
    );
  }

  private triggerNewVersionSnackbar() {
    this.snackbarRef = this.snackbarService.openSnackBar(
      'An updated version of the course is available',
      {
        duration: null,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: 'warning-snackbar-container'
      },
      'Reload'
    );
    this.snackbarRef
      .onAction()
      .pipe(take(1))
      .subscribe(() => {
        window.location.reload();
      });
  }
}
