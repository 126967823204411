<div class="modal-header w-100 p-0">
  <div
    class="w-100 plate plate-dark_blue swoosh swoosh-cfi-home px-3 py-4 border-bottom-left-radius-0 border-bottom-right-radius-0 border-top-left-radius-modal border-top-right-radius-modal">
    <div class="d-flex justify-content-start align-items-center w-100 plate-header">
      <img class="w-100 modal-header-image d-none d-md-block"
           src="https://dl.videos.sportys.com/onlinecourse/images/sportys-logo-block-airplane.svg"/>
      <div class="tagline text-size-18 ml-2">Login</div>
      <div class="d-flex align-items-start pt-0 text-size-14 ml-5 pl-5 mr-5 cursor-pointer"
           (click)="bsModalRef.hide();"
           *ngIf="allowClose">
        <span aria-hidden="true" class="text-white modal-close-btn text-size-20">×</span>
      </div>
    </div>
  </div>
</div>
<div class="modal-body">
  <div>
    <div class="alert alert-warning" *ngIf="message">
      {{message}}
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="stylized">
      <div class="mt-2 font-weight-bold font-size-16" [ngClass]="{'mb-4' : !sessionTimedOut}">Enter your email address
        and
        password to login
      </div>
      @if (!!config && config['user.mode'] !== 'YE') {
        <div class="d-flex d-flex-row align-items-center justify-content-start pb-1">
          <div class="text-color-gray mr-3">New to Sporty's?</div>
          <button type="button" class="btn mr-1" (click)="openSignupForm($event)">Create a free account</button>
        </div>
      }
      <div *ngIf="sessionTimedOut" class="mt-1 mb-4 text-size-12">Your session has timed out. Please log
        in again.
      </div>
      <div class="form-group my-3">
        <input type="text" class="form-control" formControlName="username" id="username" required
               placeholder="Email Address">
      </div>

      <div class="form-group form-group-icon my-3">
        <div (click)="password = !password">
          <i class="fa fa-eye" *ngIf="password"></i>
          <i class="fa fa-eye-slash" *ngIf="!password"></i>
        </div>
        <input type="{{password?'password':'text'}}" class="form-control" formControlName="password" id="password"
               placeholder="Password" required>

      </div>
      <div class="row px-3 d-flex align-items-center">
        <button id="loginButton" type="submit" class="col-2 btn btn-sm btn-primary btn-round"
                [ngClass]="{'btn-loading': working === true}" [disabled]="working || form.status === 'INVALID'">Login
        </button>

        <!-- <a (click)="resetPassword($event)" class="no-decorations ml-2 text-size-12">Forgot your password?</a> -->
        <div class="col-8 ml-2 d-flex align-items-center">
          <div class="accordion_container w-100">
            <input type="checkbox" id="accordion-1-modal" class="accordion-control">
            <label for="accordion-1-modal" class="accordion-label text-size-14" data-label-closed=""
                   data-label-open="">Need
              help?</label>
            <div class="accordion-content">
              <div class="row">
                <a class="external-link text-size-14" (click)="resetPassword($event)">- Forgot Password</a>
              </div>
              <div class="row">
                <a class="external-link text-size-14" (click)="magicLink($event)">- Send me a link to login</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-size-12 px-5">
        By logging in, you agree to the <a class="external-link" target="_blank" href="https://www.sportys.com/terms-of-use">Terms of Use</a> and <a
        class="external-link" target="_blank" href="https://www.sportys.com/privacy/">Privacy Policy</a>
      </div>
    </form>
  </div>
</div>
