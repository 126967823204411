<div class="modal-body">

  <div>

    <div>
      <p>Create a new study session using this graded session.<br>Create a new session based on:</p>
    </div>
    <div class="review-buttons">
      <a class="btn btn-white btn-round btn-shadow" (click)="startQuestionsAnsweredCorrectly(this.test)"
         [attr.disabled]="!user">
        <div class="sportys-correct sportys-correct-icon"></div>
        Correct Questions
      </a>
      <a class="btn btn-white btn-round btn-shadow" (click)="startIncorrectQuestions(this.test)"
         [attr.disabled]="!user">
        <div class="sportys-incorrect sportys-incorrect-icon"></div>
        Incorrect Questions
      </a>
      <a class="btn btn-white btn-round btn-shadow" (click)="startMarkedQuestions(this.test)"
         [attr.disabled]="!user">
        <div class="sportys-marked sportys-marked-icon"></div>
        Marked Questions
      </a>
      <a class="btn btn-white btn-round btn-shadow" (click)="retryTest(this.test)"
         [attr.disabled]="!user">
        <div class="sportys-retry sportys-retry-icon"></div>
        Retry Entire Session
      </a>
    </div>
  </div>
</div>

