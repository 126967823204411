import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Input, signal } from '@angular/core';
import { IconModule } from 'src/app/components/icon/icon.module';

@Component({
  selector: 'app-ground-lesson',
  templateUrl: 'ground-lesson.component.html',
  styleUrls: ['ground-lesson.component.scss'],
  standalone: true,
  imports: [NgIf, NgFor, NgClass, IconModule]
})
export class GroundLessonComponent {
  @Input() lesson;

  isExpanded = signal(false);

  toggleCollapsed() {
    this.isExpanded.update(v => !v);
  }
}
