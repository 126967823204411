<div class="d-flex justify-content-center align-content-center align-items-center">
  <div class="progress">
    <div class="progress-bar" role="progressbar"
         [ngStyle]="{'width': ((100 * correct / attempts) + '%'), 'background': background}"></div>
  </div>
  <div class="progress-percent">
    <span *ngIf="attempts && attempts > 0">{{correct / attempts | percent}}</span>
    <span *ngIf="!attempts">0%</span>
  </div>
</div>
