<ng-container *ngIf="!internalLink">
    <a class="no-decorations" href="{{link}}" target="{{target}}">
        <div class="d-flex justify-content-center">
            <app-icon [glyph]="glyph" [badge]="badge" [classList]="class" [scale]="scale" [radio]="radio"
                [large]="large" [border]="border" [complete]="complete" [alt]="alt" [disabled]="disabled"
                name="{{name}}">
            </app-icon>
        </div>
        <div class="mt-3">{{title}}</div>
    </a>
</ng-container>
<ng-container *ngIf="internalLink">
    <div class="cursor-pointer" (click)="goToInternalLink()">
        <div class="d-flex justify-content-center">
            <app-icon [glyph]="glyph" [badge]="badge" [classList]="class" [scale]="scale" [radio]="radio"
                [large]="large" [border]="border" [complete]="complete" [alt]="alt" [disabled]="disabled"
                name="{{name}}">
            </app-icon>
        </div>
        <div class="mt-3">{{title}}</div>
    </div>
</ng-container>