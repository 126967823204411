import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {HelpDataService} from '../services/help-data.service';
import {TrainingService} from '../training.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private helpDataService: HelpDataService
  ) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 423 && err.error.featureCode === 1) {
          this.router.navigate(['/maintenance'])
        }
        if (err.status === 423 && err.error.featureCode > 1) {
          this.helpDataService.featureCode = err.error.featureCode;
          this.helpDataService.featureErrorMessage = err.error.message;
          this.router.navigate(['/feature-disabled'])
        }
      return throwError(err);
      })
    )

  }
}
