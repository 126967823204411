import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ProgressService } from '../../progress.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CourseService } from '../../course/services/course.service';
import { TrainingService } from '../../training.service';
import * as _ from 'underscore';
import { identityRevealedValidator } from '../../validators/emailvalidator';
import { Observable, Subscription, take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthContextService } from "../../services/auth-context.service";
import { ConfirmationModalComponent } from '../confirmation/confirmation-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TestsService } from 'src/app/course/services/tests.service';
import { TestProgress } from 'src/app/domain/interfaces/test-progress.interface';


@Component({
  selector: 'app-request-endorsement',
  templateUrl: './request-endorsement.component.html',
  styleUrls: ['./request-endorsement.component.scss']
})
export class RequestEndorsementComponent implements OnInit, OnDestroy {

  course: any;
  user: any;
  progress: any;

  allowSubmit = false;
  allowDownload = false;

  downloadUrl: string;

  loading: boolean;

  formDisabled = true;

  progressServiceSubscription: Subscription;

  result: any;
  testProgress: TestProgress = new TestProgress();

  form = new UntypedFormGroup({
    name: new UntypedFormControl(),
    address: new UntypedFormControl(),
    city: new UntypedFormControl(),
    state: new UntypedFormControl(),
    zip: new UntypedFormControl(),
    phone: new UntypedFormControl(),
    email: new UntypedFormControl(),
    confirmEmail: new UntypedFormControl()
  }, { validators: identityRevealedValidator });

  constructor(
    private authService: AuthService,
    private progressService: ProgressService,
    private courseService: CourseService,
    private trainingService: TrainingService,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private router: Router,
    private authContext: AuthContextService,
    private modalService: BsModalService,
    private testsService: TestsService
  ) {
  }

  ngOnInit() {

    this.course = this.route.snapshot.data.course;
    this.getTestProgress();
    console.log('RequestEndorsementComponent:init', this.course.webAppId);
    this.progressService.refreshProgress(this.course.webAppId).then(progress => {
      console.log('RequestEndorsementComponent:init:on progress', this.course.webAppId);
      if (progress && progress[this.course.webAppId]) {
        console.log('RequestEndorsementComponent:init:on progress', this.course.webAppId);
        this.progress = progress[this.course.webAppId];

        if (this.progress.writtenExamRequested) {
          this.allowDownload = true;
          this.downloadUrl = this.progress.certificationRequests.EXAM_ENDORSEMENT.downloadUrl;
        } else {
          this.formDisabled = false;
          this.allowSubmit = true;
        }


      } else {
        this.progress = null;
      }
    });

  }

  ngOnDestroy() {
    if (this.progressServiceSubscription) {
      this.progressServiceSubscription.unsubscribe();
    }
  }


  onSubmit() {
    this.loading = true;

    const value = _.clone(this.form.value);
    value.modeType = 'COURSE';
    value.webAppId = this.course.webAppId;
    value.certificationArtifactType = "pdf";

    this.courseService.submitExamEndorsementRequest(this.course.webAppId, value, this.course.isCourseMixed, this.course.defaultCertificationType).then(async (response) => { // to be updated with course.webAppId
      this.downloadUrl = await this.courseService.getCertificateDownloadUrl(response);
      this.result = response;
      if (response.success) {
        this.allowSubmit = false;
        this.allowDownload = true;
      } else {
        this.allowSubmit = true;
      }
      this.loading = false;
      this.formDisabled = true;
      this.progressService.refreshProgress(this.course.webAppId);
    });
  }

  getTestProgress(): any {
    this.courseService.getTestProgress(this.course.webAppId).subscribe((testProgress: TestProgress) => {
      this.testProgress = testProgress;
    });
  }

}
