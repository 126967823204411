import {AfterContentInit, AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {TrainingService} from '../training.service';
import {Subscription} from 'rxjs';
import {AuthContextService} from '../services/auth-context.service';
import {CourierService} from "../services/courier.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy, AfterViewInit {
    collapsed = true;
    routerSubscription: Subscription;
    config: any;
    user: any;


    constructor(
        private router: Router,
        public authContextService: AuthContextService,
        private trainingService: TrainingService,
        private courierService: CourierService
    ) {
        this.routeEvent(this.router);
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.trainingService.loadConfig().then((config) => {
            this.config = config;

            this.user = this.authContextService.user?.sportys_id;
            this.courierService.init(this.user, this.config);

        });
    }


    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
    }

    toggleCollapsed(): void {
        this.collapsed = !this.collapsed;
    }

    routeEvent(router: Router) {
        this.routerSubscription = router.events.subscribe(e => {
            if (e instanceof NavigationEnd) {
                this.collapsed = true;
            }
        });
    }
}
