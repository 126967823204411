import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MagicLinkService} from '../../services/magic-link.service';
import {ClaimsService} from '../../services/claims.service';
import {AuthService} from '../../services/auth.service';
import {AuthContextService} from '../../services/auth-context.service';

@Component({
    selector: 'app-magic-link',
    templateUrl: './magic-link.component.html',
})
export class MagicLinkComponent {

    error: any;

    constructor(
        private claimsService: ClaimsService,
        private authContextService: AuthContextService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        console.log('MagicLinkComponent');
    }

    ngOnInit() {
        console.log('MagicLinkComponent');

        this.route.params.subscribe(params => {
            this.claimsService.auth(params.code).then((response) => {
                this.authContextService.setToken(response.id_token);
                this.router.navigate(['/']);
            }, (error) => {
                this.error = error;
            });
        });
    }
}
