import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CourseService } from 'src/app/course/services/course.service';
import { ColorSchemeService } from 'src/app/services/color-scheme.service';

@Component({
  selector: 'app-purchase-course-banner',
  templateUrl: './purchase-course-banner.component.html',
  styleUrls: ['./purchase-course-banner.component.scss']
})
export class PurchaseCourseBannerComponent implements OnInit, OnChanges {
  @Input() course: any;
  @Input() user: any;

  showBanner = true;
  coursePrice = '';

  constructor(
    public colorSchemeService: ColorSchemeService,
    private courseService: CourseService
  ) {}

  ngOnInit(): void {
    this.showBanner =
      !this.user ||
      (this.user.coursePermissions[this.course.webAppId] != true &&
        this.course.webAppId !== 'GETTING_STARTED' &&
        this.course.webAppId !== 'FLIGHTSIM');

    this.getCoursePrice()
      .then((val) => {
        this.coursePrice =
          '$' + (val.discountPrice !== null ? val.discountPrice : val.price);
      })
      .catch((error) => {
        console.log('Error fetching course price:', error);
        this.coursePrice = '';
      });
  }

  ngOnChanges(): void {
    this.showBanner =
      !this.user ||
      (this.user.coursePermissions[this.course.webAppId] != true &&
        this.course.webAppId !== 'GETTING_STARTED' &&
        this.course.webAppId !== 'FLIGHTSIM');
  }

  getSalesLanguage() {
    return this.course.webAppId === 'PRIVATE' ||
      this.course.webAppId === 'SPORT' ||
      this.course.webAppId === 'RECREATIONAL' ||
      this.course.webAppId === 'COMMERCIAL' ||
      this.course.webAppId === 'INSTRUMENT'
      ? 'Lifetime Access'
      : 'Unlock Course';
  }

  getBuyLinkText() {
    if (this.course.buyLinkText && this.course.buyLinkText != '') {
      return this.course.buyLinkText === 'Purchase'
        ? 'Purchase Course'
        : this.course.buyLinkText;
    } else {
      return 'Purchase Course';
    }
  }

  getCoursePrice(): any {
    return this.courseService.getCoursePrice(this.course.webAppId);
  }
}
