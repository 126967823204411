import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Angulartics2} from 'angulartics2';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ResetModalComponent} from '../components/reset-modal/reset-modal.component';
import {TrainingService} from '../training.service';
import {YoungEaglesWarningComponent} from '../components/young-eagles-warning/young-eagles-warning.component';
import {MagicLinkModalComponent} from '../components/magic-link-modal/magic-link-modal.component';
import {ColorSchemeService} from '../services/color-scheme.service';
import {AuthContextService} from '../services/auth-context.service';
import {SchoolUiDetails} from './school.interface';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  form = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl(),
  });

  magicLinkForm = new UntypedFormGroup({
    username: new UntypedFormControl(),
  });

  working = false;
  magicLinkWorking = false;
  message = '';

  school: SchoolUiDetails = {
    code: 'SPORTYS',
    description: '',
    name: '',
    primary_color: '',
    terms_and_conditions: '',
    navBarLogoUrl: '',
    primaryImageUrl: '',
    schoolUrlLink: '',
    secondary_color: '',
  };

  loginSubscription: Subscription;
  resetModal: BsModalRef;
  hidePassword = true;
  config: any;
  userMode: string;

  loginField: string;
  magicLinkResultMessage = '';

  constructor(
    private authContextService: AuthContextService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private angulartics: Angulartics2,
    private modalService: BsModalService,
    private trainingService: TrainingService,
    public colorSchemeService: ColorSchemeService
  ) {
  }

  async ngOnInit() {

    if(this.route.snapshot.queryParamMap.get('token')){
      this.authContextService.setToken(this.route.snapshot.queryParamMap.get('token'));
      this.authService.renew().then(value => {
        console.log('LoginComponent:renew', value);
        if (this.userMode === 'YE') {
          this.router.navigate(["/course/PRIVATE/welcome"], {queryParamsHandling: 'preserve'});
        } else {
          this.router.navigate(["/home/courses"], {queryParamsHandling: 'preserve'});
        }
      })
    }
    if (window['YE_LOGIN_MESSAGE']) {
      this.message = window['YE_LOGIN_MESSAGE'];
    }
    this.route.data.subscribe((data) => {
      if (data.school) {
        this.school = data.school;
      }
    });

    this.config = await this.trainingService.loadConfig();
    this.userMode = this.config['user.mode'];
    if (this.userMode === 'YE') {
      this.loginField = 'Username';
    } else {
      this.loginField = 'Email Address';
    }
  }

  ngOnDestroy() {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  onSubmit() {
    console.log('LoginComponent.onSubmit');
    this.working = true;
    const _this = this;
    this.loginSubscription = this.authService
      .login(this.form.get('username').value, this.form.get('password').value)
      .subscribe(
        (value) => {
          console.log('LoginComponent.onSubmit:login complete', value);
          _this.angulartics.setUsername.next(value.user_name);

          _this.angulartics.setUserProperties.next({
            id: value.id,
            sportysId: value.sportys_id,
            email: value.email,
            lastName: value.last_name,
            firstName: value.first_name,
          });

          if (window['newrelic']) {
            window['newrelic'].setCustomAttribute('username', value['email']);
            window['newrelic'].setCustomAttribute('userId', value['id']);
            window['newrelic'].setCustomAttribute(
              'sportysId',
              value['sportys_id']
            );
          }

          this.working = false;

          if(this.authService.deepLinkRoute){
            this.router.navigate([this.authService.deepLinkRoute]);
            this.authService.deepLinkRoute = null;
          } else if (this.userMode === 'PAID') { // this is for young eagles to go directly to private
            this.router.navigate(['/home/courses'], {queryParamsHandling: 'preserve'});
          } else {
            this.router.navigate(['/course/PRIVATE'], {queryParamsHandling: 'preserve'});
          }
        },
        (error) => {
          console.error(error.error.message);
          this.message = error.error.message;
          this.working = false;

          if (this.userMode === 'YE' && error.status === 403) {
            this.modalService.show(YoungEaglesWarningComponent, {
              initialState: {},
            });
          }
          // sshow ye model
        }
      );
  }

  resetPassword($event) {
    $event.stopPropagation();
    $event.preventDefault();
    //this.authService.resetPassword(this.form.get('username').value).then(value => {
    //  console.log('LoginComponent:resetPassword', value);
    //});

    const initialState = {};

    this.resetModal = this.modalService.show(ResetModalComponent, {
      initialState,
      class: 'modal-lg rounded-modal',
    });
  }

  magicLink($event) {
    $event.stopPropagation();
    $event.preventDefault();
    const initialState = {};
    this.resetModal = this.modalService.show(MagicLinkModalComponent, {
      initialState,
      class: 'modal-lg rounded-modal',
    });
  }

  public enableAllContent(school: any) {
    return school.code === 'SPORTYS' || school.code === 'DEFAULT';
  }
}
