import {Directive, OnDestroy, OnInit, inject} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CoursesService} from '../courses.service';
import {AuthService} from '../services/auth.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {LoginModalComponent} from '../components/login-modal/login-modal.component';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {SignupModalComponent} from '../components/signup-modal/signup-modal.component';
import {AuthContextService} from '../services/auth-context.service';
import { CourseSharedService } from './course-shared.service';

@Directive()
export abstract class CommonCourseComponent implements OnInit, OnDestroy {
  loginModal: BsModalRef;
  signupModal: BsModalRef;
  course: any;
  volumes: any[];
  optionalVolume: any;
  user: any;
  purchased = false;
  cheat = false;

  private loginModalSubscription: Subscription;
  private signupModalSubscription: Subscription;

  private onLoginSubscription: Subscription;
  private onRouteSubscription: Subscription;
  private onLogoutSubscription: Subscription;

  courseSharedService: CourseSharedService = inject(CourseSharedService);

  constructor(
    protected route: ActivatedRoute,
    protected coursesService: CoursesService,
    protected authContextService: AuthContextService,
    protected authService: AuthService,
    protected modalService: BsModalService
  ) {
    this.cheat = localStorage.getItem('testMode') === 'true'
  }

  private commonInit() {
    console.log('CommonCourseComponent:commonInit');
    this.user = this.authContextService.user;
    this.purchased = this.authContextService.user && this.authContextService.user.coursePermissions[this.course.webAppId];

  }

  login(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const initialState = {
        title: 'Modal with component',
        course: this.course,
        courseGroup: this.course.courseGroup
      };

      this.loginModal = this.modalService.show(LoginModalComponent, {initialState, class: 'rounded-modal'});
      this.loginModal.content.closeBtnName = 'Close';

      this.loginModalSubscription = this.modalService.onHide.pipe(take(1)).subscribe(value => {
        console.log('LoginModalComponent:' + value);
        if (this.loginModal.content.user) {
          resolve(this.loginModal.content.user);
        }
        reject();
      });
    });
  }

  signup(): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      const initialState = {
        title: 'Modal with component',
        course: this.course,
        courseGroup: this.course.courseGroup
      };

      this.signupModal = this.modalService.show(SignupModalComponent, {initialState, class: 'modal-lg rounded-modal'});
      this.signupModal.content.closeBtnName = 'Close';

      this.signupModalSubscription = this.modalService.onHide.pipe(take(1)).subscribe(value => {
        console.log('LoginModalComponent:' + value);
        if (this.signupModal.content.user) {
          resolve(this.signupModal.content.user);
        }
        reject();
      });
    });
  }

  ngOnInit() {
    console.log('CommonCourseComponent:ngOnInit');
    this.course = this.route.snapshot.data.course;
    this.volumes = this.route.snapshot.data.volumes;
    this.optionalVolume = this.volumes?.find((v) => this.courseSharedService.isVolumeOptional(v));

    this.authService.renew().then(value => {
      this.commonInit();
    }, error => {
      console.log('CommonCourseComponent.ngOnInit:error');
      this.commonInit();
    });

    if (!this.onRouteSubscription) {
      this.onRouteSubscription = this.route.paramMap.subscribe(params => {
        console.log('CommonCourseComponent.ngOnInit:onRoute', this.route.snapshot.data.course);
        this.course = this.route.snapshot.data.course;

        this.commonInit();
        this.init();
      });
    }

    if (!this.onLoginSubscription) {
      this.onLoginSubscription = this.authContextService.onLogin().subscribe(value => {
        this.user = value;
        this.purchased = this.authContextService.user && this.authContextService.user.coursePermissions[this.course.webAppId];
        console.log('CommonCourseComponent:ngOnInit:onLogin:', this.authContextService.user, this.purchased);
        this.init();
      });
    }

    if (!this.onLogoutSubscription) {
      this.onLogoutSubscription = this.authContextService.onLogout().subscribe(value => {
        this.user = null;
        this.purchased = false;
        console.log('CommonCourseComponent:ngOnInit:onLogout:', this.authContextService.user, this.purchased);
        //don't call init here
      });
    }
    console.log('CommonCourseComponent:ngOnInit:complete', this.course);
  }

  abstract init();

  abstract destroy();

  ngOnDestroy() {
    console.log('CommonCourseComponent:ngOnDestroy');
    if (this.loginModalSubscription) {
      this.loginModalSubscription.unsubscribe();
    }
    if (this.onLoginSubscription) {
      this.onLoginSubscription.unsubscribe();
    }
    if (this.onRouteSubscription) {
      this.onRouteSubscription.unsubscribe();
    }
    this.destroy();

  }

  floor(num: number) {
    return Math.floor(num);
  }
}
