<div class="modal-body">
  <form [formGroup]="form">
    <div class="search-input d-flex flex-row flex-nowrap">
      <div style="width:30px;" class="d-flex flex-column justify-content-center align-content-center">
        <app-icon glyph name="back"></app-icon>
      </div>
      <div class="input-group" style="width: calc(100% - 30px)">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fa fa-search"></i>
          </div>
        </div>
        <input type="text"
               class="form-control"
               formControlName="keyword"
               (ngModelChange)="search($event, page, size)"
               id="keyword"
               formnovalidate
               placeholder="Search for Keyword">
      </div>
    </div>
    <div class="search-results-empty">
      <p>Search this course's questions using keywords.</p>
    </div>
    <div class="search-results">
      <div class="card mb-3" *ngFor="let question of results; trackBy: trackQuestionBy" (click)="navigate(question)"
           [@simpleFadeAnimation]="'in'">
        <div class="row no-gutters">
          <div class="col-md-12">
            {{question.text}}
          </div>
        </div>
      </div>
    </div>
    <div class="search-results-pagination">
      <nav aria-label="Page navigation example" *ngIf="totalResults > 0">
        <ul class="pagination">
          <li class="page-item">
            <button class="page-link" href="#" (click)="prev()">Previous</button>
          </li>
          <li class="page-item" [ngClass]="{'active':page === i}" *ngFor="let p of pages; let i = index">
            <button class="page-link" (click)="load(i)">{{i + 1}}</button>
          </li>
          <li class="page-item">
            <button class="page-link" href="#" (click)="next()">Next</button>
          </li>
        </ul>
      </nav>
    </div>
  </form>

</div>
