export enum EventType {
  DEFAULT,
  CHECKRIDE_SESSION = 'CHECKRIDE_SESSION',
  TEST_PREP_SESSION = 'TEST_PREP_SESSION',
  COURSE = 'COURSE',
  DOCUMENT_VIEWED = 'DOCUMENT_VIEWED',
  RESOURCE_VIEWED = 'RESOURCE_VIEWED',
  USER_DOCUMENT_VIEWED = 'USER_DOCUMENT_VIEWED',
  USER_DOCUMENT_UPLOADED = 'USER_DOCUMENT_UPLOADED',
  REVIEW_QUIZ_SESSION = 'REVIEW_QUIZ_SESSION',
  VIDEO_PLAYED = 'VIDEO_PLAYED',
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  CFI_HOME = 'CFI_HOME',
  CFI_SPECIAL_OFFER = 'CFI_SPECIAL_OFFER',
  CFI_DOCUMENT_FAA_LIBRARY = 'CFI_DOCUMENT_FAA_LIBRARY',
  CFI_DOCUMENT_LESSON_PLAN_GUIDES = 'CFI_DOCUMENT_LESSON_PLAN_GUIDES',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}
