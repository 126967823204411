import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {TrainingService} from "../../training.service";

@Injectable({
  providedIn: 'root'
})
export class SidenavUiService {

  private _isActive = false;

  constructor() {
  }

  get isActive() {
    return this._isActive;
  }

  set isActive(isActive: boolean) {
    setTimeout(() => {
      this._isActive = isActive
    }, 0);
  }
}
