import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { TrainingService } from '../../training.service';
import { InvitationsFilters } from './Invitations-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class InstructorService {
  api = null;
  private dataObs$ = new ReplaySubject(1);

  constructor(private httpClient: HttpClient, private trainingService: TrainingService) {
    this.trainingService.load().subscribe(value => {
      this.api = value;
      this.dataObs$.next(value);
    });
  }

  load(): Observable<any> {
    return this.dataObs$;
  }

  getInstructor() {
    return new Promise((resolve, reject) => {
      this.dataObs$.subscribe(value => {
        this.httpClient.get<any[]>(this.api.getRoute('cfi-get-instructor')).subscribe(value1 => {
          resolve(value1);
        }, error => {
          reject(error);
        });
      });
    });
  }

  getAllClasses(isCfiChief: boolean = false) {
    return new Promise((resolve, reject) => {
      this.dataObs$.subscribe(value => {
        let route = this.api.getRoute('cfi-instructor');
        if (isCfiChief) { route = this.api.getRoute('cfi-chief-getClassrooms');}
        this.httpClient.get<any[]>(route).subscribe(value1 => {
          resolve(value1);
        }, error => {
          reject(error);
        });
      });
    });
  }

  getAllClassesStats() {
    return new Promise((resolve, reject) => {
      this.dataObs$.subscribe(value => {
        this.httpClient.get<any[]>(this.api.getRoute('cfi-classroomsStats')).subscribe(value1 => {
          resolve(value1);
        }, error => {
          reject(error);
        });
      });
    });
  }

  addClass(className: string, classroomType: string, instructorId): Observable<any> {
    const body = {
      'name': className,
      'courseType': classroomType
    };

    if (instructorId === 0){
      return this.httpClient.post(this.api.getRoute('cfi-addClassroom'), body);
    }else{
      return this.httpClient.post(this.api.getRoute('chiefCfi-addClassroom').replace('{instructorId}', instructorId), body);
    }
  }

  deleteClass(classID: number): Observable<any> {
    return this.httpClient.delete(this.api.getRoute('cfi-removeClassroom').replace('{classroomId}', classID));
  }

  updateClass(classID: number, newClassName: string, classroomType: string): Observable<Object> {
    const body = {
      'name': newClassName,
      'courseType': classroomType
    };
    return this.httpClient.put(this.api.getRoute('cfi-updateClassroom').replace('{classroomId}', classID), body);
  }

  bulkAddStudentsToClassroom(classroomId: number, studentIds: number[]): Observable<Object> {
    return this.httpClient.post(this.api.getRoute('cfi-addStudentsToClassroom').replace('{classroomId}', classroomId), studentIds);
  }

  bulkRemoveStudentsFromClassroom(classroomId: number, studentIds: number[]) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: studentIds
    };
    return this.httpClient.delete(this.api.getRoute('cfi-removeStudentsFromClassroom').replace('{classroomId}', classroomId), httpOptions);
  }

  getAllInvitations(forFlightschool: boolean, filters: InvitationsFilters): Observable<any> {
    let route = this.api.getRoute('cfi-getInvitations');

    if (forFlightschool) {
      route = this.api.getRoute('cfi-chief-getInvitations');
    }

    const params = this.getParamsFromInvitationsFilters(filters);

    return this.httpClient.get<any[]>(route,  { params });
  }

  findValidCfi(email: string): Observable<any> {
    let route = this.api.getRoute('cfi-chief-findValidCfi');
    const options = {params: new HttpParams().set('query', encodeURIComponent(email))};
    return this.httpClient.get<any>(route,options);
  }

  getInstructorInvitation(token: string): Observable<any> {
    let route = this.api.getRoute('cfi-getInvitationToSchool');
    return this.httpClient.get<any>(route.replace('{uuid}',token));
  }

  acceptInvitation(token: string): Observable<any> {
    let route = this.api.getRoute('cfi-acceptInvitationToSchool');
    return this.httpClient.put(route.replace('{uuid}',token),{});
  }

  removeInvitation(invitationId: number): Observable<any> {
    return this.httpClient.delete(this.api.getRoute('cfi-deleteInvitation').replace('{invitationId}', invitationId));
  }

  inviteStudent(studentEmail: string, message: string): Observable<Object> {
    const body = {
      'email': studentEmail,
      'message': message
    };
    return this.httpClient.put(this.api.getRoute('cfi-inviteStudent'), body);
  }

  inviteStudents(studentEmails: string[], message: string): Observable<Object> {
    const body = {
      'emails': studentEmails,
      'message': message
    };
    return this.httpClient.post(this.api.getRoute('cfi-inviteStudents'), body);
  }

  inviteInstructors(emails: string[], message: string): Observable<Object> {
    const body = emails.map(email => ({
      email: email,
      message: message
    }));
    return this.httpClient.put(this.api.getRoute('cfi-chief-inviteInstructorToSchool'), body);
  }

  assignStudentToInstructor(studentId: number, instructorId: number): Observable<Object> {
    return this.httpClient.put(this.api.getRoute('cfi-chief-assignStudentToInstructor').replace('{instructorId}', instructorId).replace('{studentId}', studentId), {});
  }

  reinviteStudent(id: number): Observable<Object> {
    return this.httpClient.put(this.api.getRoute('cfi-resendInvitation').replace('{invitationId}', id), {});
  }

  removeStudentFromClassroom(studentID: number, classroomID: number): Observable<Object> {
    return this.httpClient.delete(this.api.getRoute('cfi-removeStudentFromClassroom').replace('{classroomId}', classroomID).replace('{studentId}', studentID));
  }

  removeStudentFromInstructor(studentID: number): Observable<Object> {
    return this.httpClient.delete(this.api.getRoute('cfi-removeStudentFromInstructor').replace('{studentId}', studentID));
  }

  removeInstructorFromSchool(instructorId: number): Observable<Object> {
    return this.httpClient.delete(this.api.getRoute('cfi-chief-removeCfi').replace('{instructorId}', instructorId));
  }

  getAllInstructorsFromSchool(): Observable<Object[]>{
    return this.httpClient.get<any[]>(this.api.getRoute('cfi-chief-getInstructors'));
  }

  getPendingInstructorsInvitations(): Observable<Object[]>{
    return this.httpClient.get<any[]>(this.api.getRoute('cfi-chief-getPendingInvitationsToInstructors'));
  }

  revokeInstructorInvitation(invitationId: string): Observable<Object>{
    return this.httpClient.delete(this.api.getRoute('cfi-chief-revokeInvitationToInstructor').replace('//cfi','/api/cfi').replace('{uuid}',invitationId));
  }

  getAllStudentsByInstructor(instructorId: number): Observable<Object[]>{
    return this.httpClient.get<any[]>(this.api.getRoute('cfi-chief-getInstructorsStudents').replace('{instructorId}', instructorId));
  }

  private getParamsFromInvitationsFilters(filters: InvitationsFilters): HttpParams {
    let params = new HttpParams();
    let filtersDto: any = { ...filters };

    if (filters.sortBy && filters.sortDirection) {
        filtersDto.sort = `${filters.sortBy},${filters.sortDirection}`;
        delete filtersDto.sortBy;
        delete filtersDto.sortDirection;
    }

    if(filters.classroomId){
      params.set('classroomId', filters.classroomId.toString());
    }

    for (let key in filtersDto) {
      if (filtersDto[key] !== null) {
        params = params.set(key, filtersDto[key]);
      }
    }


    return params;
  }
}
