// this.purchased = this.authContextService.user && this.authContextService.user.coursePermissions && this.authContextService.user.coursePermissions[this.course.webAppId];
// if purchased is false redirect the user over to the demo route

import { Injectable } from '@angular/core';
import { AuthContextService } from '../services/auth-context.service';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, GuardResult, MaybeAsync, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ForceDemoGuard implements CanActivate, CanActivateChild {
  constructor(private authContextService: AuthContextService,
    private router: Router,
    private authService: AuthService
  ) {
  }
  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): MaybeAsync<GuardResult> {
    if (!this.router.navigated === false && !this.authService.deepLinkRoute && this.router.url.indexOf("courses") > -1) {
      this.authService.deepLinkRoute = this.router.url;
    }

    const courseId = state.url.substring(state.url.indexOf('course/') + 7, state.url.indexOf('/', state.url.indexOf('course/') + 7));
    console.log('ForcePaidGuard.canActivate');
    if (!this.authContextService.user || !this.authContextService.user.coursePermissions || !this.authContextService.user.coursePermissions[courseId]) {
      this.router.navigate(['/course', courseId, 'volumes']);
      return false;
    } else {
      return true;
    }
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log('ForceDemoGuard.canActivate', state.url);
    if(state.url.indexOf('/course/far') > -1) {
      return true;
    }

    if(!this.router.navigated === false && !this.authService.deepLinkRoute && this.router.url.indexOf("courses") > -1 ) {
      this.authService.deepLinkRoute = this.router.url;
    }

    const courseId = state.url.substring(state.url.indexOf('course/') + 7, state.url.indexOf('/', state.url.indexOf('course/') + 7));
    console.log('ForceDemoGuard.canActivate', courseId);
    if (!this.authContextService.user || !this.authContextService.user.coursePermissions || !this.authContextService.user.coursePermissions[courseId]) {
      this.router.navigateByUrl('demo' + state.url);
      return false;
    } else {
      return true;
    }
  }
}
