import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {SharingService} from '../../course/services/sharing.service';
import * as _ from 'underscore';

@Component({
  selector: 'app-share-invite',
  templateUrl: './share-invite.component.html',
  styleUrls: ['./share-invite.component.scss']
})
export class ShareInviteComponent implements OnInit {

  email: string;
  invitedInstructors = [];
  message: string;
  disabled = true;

  constructor(public bsModalRef: BsModalRef, private sharingService: SharingService) {
  }

  ngOnInit(): void {
  }

  ok() {
    this.bsModalRef.content.approvedEmail = this.email;
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.content.email = null;
    this.bsModalRef.hide();
  }

  evalEmail($event) {
    console.log('disabled:email', this.email);
    this.message = null;
    if (this.email === null || this.email === '') {
      this.disabled = true;
    } else {
      const existingShare = _.find(this.invitedInstructors, (shared) => {
        console.log('disabled:shared', shared);
        return shared.instructorEmail === this.email || (shared.user && shared.user.email === this.email);
      });
      console.log('disabled:existing', existingShare, typeof existingShare !== 'undefined' && existingShare != null);

      const disable = (typeof existingShare !== 'undefined' && existingShare != null);
      if (disable) {
        this.message = 'Your progress is already shared with this email';
      }
      this.disabled = disable;
    }
  }

}
