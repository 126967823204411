<div class="test">
  <!--<div class="test-header">
    <div class="countdown" *ngIf="countdownConfig">
      <countdown #cd [config]="countdownConfig" (event)="handleCountdownEvent($event)"></countdown>
    </div>
  </div>-->
  <div class="test-body">
    <!--<div class="question-number d-flex flex-row flex-nowrap justify-content-between">
      <div>
        <div>
          {{position(currentQuestion)}}/{{questions.length}}</div>
        </div>
      </div>
    </div>-->

    <div class="question-body">

      <div class="question-progress">
        <div>{{correctAnswers }} correct out of {{questions.length}} ({{correctAnswers / questions.length | percent}})
        </div>
        <button (click)="playExplanation(currentQuestion.question.questionVideo)"
                class="btn btn-sm btn-outline-primary">
          Play Explanation&nbsp;<i class="fa fa-arrow-right"></i>
        </button>
      </div>
      <div class="question">
        <div class="question-header">
          <!--<div class="question-text" [p3x-compile]="currentQuestion.question.questionText"
               [p3x-compile-ctx]="this"></div>-->
          <!--<div class="question-text" [innerHTML]="currentQuestion.question.questionText"></div>-->
          <div class="question-text" app-question [question]="currentQuestion.question" [course]="course" [purchased]="purchased"
               [user]="user"></div>
        </div>

        <div class="answers">

          <div *ngFor="let answer of currentQuestion.question.answers"
               class="answer"
               [ngClass]="{'selected': answer.id === currentQuestion.user_answer, 'correct': isGraded() && answer.id ===  currentQuestion.user_answer && answer.correct, 'incorrect': isGraded() && answer.id === currentQuestion.user_answer && !answer.correct}">
            <div class="answer-result">
              <div *ngIf="isGraded() || isLearning()">
                <app-icon glyph name="correct_green"
                     *ngIf="answer.correct && answer.id === currentQuestion.user_answer"></app-icon>
                <app-icon glyph name="incorrect_red"
                     *ngIf="!answer.correct && answer.id === currentQuestion.user_answer"></app-icon>
                <!--<app-icon glyph name="incorrect_red"
                     *ngIf="answer.id !== currentQuestion.user_answer"></app-icon>-->
              </div>
            </div>
            <div class="answer-selection">
              <!--<p-radioButton [name]="currentQuestion.id + '_question'" [value]="answer.id"
                             [disabled]="isGraded()"
                             [(ngModel)]="currentQuestion.user_answer"
                             (ngModelChange)="questionUpdated(currentQuestion)"></p-radioButton>
                             -->
              <!--
              <input type="radio"
                     [name]="currentQuestion.id + '_question'"
                     [value]="answer.id"
                     [disabled]="isGraded()"
                     [(ngModel)]="currentQuestion.user_answer"
                     (ngModelChange)="questionUpdated(currentQuestion)">
  -->
              <div app-custom-radio
                   [(model)]="currentQuestion.user_answer"
                   [value]="answer.id"
                   [name]="currentQuestion.id + '_question'"
                   [disabled]="isGraded()"
                   (doModelChange)="questionUpdated(currentQuestion)"></div>
            </div>
            <div class="answer-text" [innerHTML]="answer.answerText"
                 (click)="currentQuestion.user_answer = answer.id; questionUpdated(currentQuestion)"></div>
          </div>
        </div>
      </div>
      <div class="question-footer">

      </div>


    </div>
    <div class="test-next_prev">
      <button (click)="prev()" class="btn btn-sm btn-white btn-round btn-shadow" [disabled]="!hasPrev()">Previous
      </button>
      <button (click)="next()" class="btn btn-sm btn-white btn-round btn-shadow" [disabled]="!hasNext()">Next</button>
    </div>
  </div>

<!--
  <div (click)="currentQuestion.debug = !currentQuestion.debug">
    DEBUG
    <pre *ngIf="currentQuestion.debug">{{currentQuestion | json}}</pre>
  </div>
-->
</div>
