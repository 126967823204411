import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-with-title-list',
  templateUrl: './icon-with-title-list.component.html',
  styleUrls: ['./icon-with-title-list.component.scss']
})
export class IconWithTitleListComponent implements OnInit, OnChanges {

  @Input() icons: { name: string; title: string; scale:number; link: string }[];

  @Input() glyph: string;

  @Input() badge: string;

  @Input() class: string;

  @Input() radio: string;

  @Input() complete: boolean;

  @Input() disabled: boolean;

  @Input() rotate: string;

  @Input() large: string;

  @Input() border: string;

  @Input() scale = 1;

  @Input() alt: string;

  nameList: string[] = [];
  titleList: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }
  
  ngOnChanges() {
  }


}
