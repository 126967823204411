import {Injectable} from '@angular/core';
import {catchError} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {AuthContextService} from '../services/auth-context.service';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private authContextService: AuthContextService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.authContextService.isLoggedIn() &&
      !request.url.includes('acr.s3.us-east-2.amazonaws.com') &&
      !request.url.includes('hlsvod2.s3.us-east-2.amazonaws.com')) {
      const id_token = this.authContextService.getToken();

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${id_token}`,
        },
      });
    }
    return next.handle(request)
      /*.pipe(
      catchError((err) => {

        if (err.status === 401) {
          //this.authService.logout();
        }
        const error = (err.error && err.error.message) || err.statusText;
        return throwError(error);
      })
    );*/
  }
}
