import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CoursesService } from '../../courses.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-select-course',
  templateUrl: './select-course.component.html',
  styleUrls: ['./select-course.component.scss']
})
export class SelectCourseComponent implements OnInit {

  course: any;
  courseGroup: any;
  userCourse: any;
  purchased: boolean;

  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private coursesService: CoursesService
  ) {
  }

  ngOnInit() {
  }


  /*
  selectCourseForCourseGroup(course: any) {
    //this.router.navigate(['/course', course.webAppId]);
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.bsModalRef.hide();
      this.coursesService.openCourse(course, this.userCourse);
    });

  }*/

  selectCourseForCourseGroup(course: any) {
    if (this.purchased) {
      this.coursesService.selectForCourseGroup(course).subscribe(value => {
        this.courseGroup.primaryCourse = course;
        // window.location.replace(this.courseGroup.primaryCourse.courseUrl);
        this.bsModalRef.hide();
        this.coursesService.openCourse(course, this.userCourse);
        //this.router.navigate(['/course', this.courseGroup.primaryCourse.webAppId]);
      });
    } else {
      this.courseGroup.primaryCourse = course;
      this.bsModalRef.hide();
      this.coursesService.openCourse(course, this.userCourse);
      //this.router.navigate(['/course', this.courseGroup.primaryCourse.webAppId]);
    }
  }
}
