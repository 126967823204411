import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MaintenanceModeService } from '../maintenance-mode.service';

@Injectable({
  providedIn: 'root',
})
export class MaintenanceModeInterceptorService implements HttpInterceptor {

  constructor(private maintenanceModeService: MaintenanceModeService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const excludedUrls = [
      'config/training'
    ];

    const maintenanceMode = this.maintenanceModeService.getMaintenanceMode() != null ? this.maintenanceModeService.getMaintenanceMode() : localStorage.getItem('maintenanceMode');
    if (excludedUrls.some(url => request.url.includes(url)) && maintenanceMode == 'false') {
      const modifiedRequest = request.clone({
        setParams: {
          'maintenance-mode': 'false'
        }
      });
      return next.handle(modifiedRequest);
    }

    if (maintenanceMode != null) {
      const modifiedRequest = request.clone({
        setParams: {
        'maintenance-mode': maintenanceMode.toString()
        }
      });
      return next.handle(modifiedRequest);
    }

    return next.handle(request);
  }
}
