import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {environment} from '../environments/environment';

export class TrainingRouter {
  private api: Map<string, string> = new Map<string, string>();

  constructor(obj: any) {
    Object.keys(obj).forEach(key => {
      this.api.set(key, obj[key]);
    });
  }

  getRoute(key: string): string {
    if (typeof this.api.get(key) === 'undefined') {
      console.error('TrainingRouter:getRoute: key not found: ' + key);
      return 'URL_NOT_FOUND';
    }
    //console.debug('TrainingRouter:getRoute: key found: ' + key);
    return this.api.get(key);
  }
}

@Injectable({
  providedIn: 'root'
})
export class TrainingService {
  private static apiObserver = new ReplaySubject<TrainingRouter>(1);
  private static loading = true;

  //private api: Map<string, string> = new Map<string, string>();
  private api: TrainingRouter = null;
  private configObs$ = null;
  private courseApi: Map<string, TrainingRouter> = new Map<string, TrainingRouter>();

  constructor(
    private httpClient: HttpClient) {

    if (TrainingService.loading) {
      TrainingService.loading = false;
      this.httpClient.get(environment.apiListUrl).subscribe(value => {
        this.api = new TrainingRouter(value);
        TrainingService.apiObserver.next(this.api);
      });
    }
  }

  load(): Observable<TrainingRouter> {
    return TrainingService.apiObserver;
  }

  loadConfig(): Promise<Map<string, string>> {
    if (!this.configObs$) {
      this.configObs$ = new ReplaySubject(1);

      this.load().subscribe(value => {
        this.httpClient.get(this.api.getRoute('configUrl')).subscribe(value => {
          const params: {
            maintenance: boolean
            testMode: boolean
          } = {
            maintenance: value['maintenance.mode'],
            testMode: value['test.mode']
          };
          console.log('MaintenanceComponent', params.maintenance);

          if (!localStorage.getItem('maintenanceMode')) {
            if (params.maintenance == true) {
              localStorage.setItem('maintenanceMode', JSON.stringify(params.maintenance));
            }
          }

          if (!localStorage.getItem('testMode')) {
            if (params.testMode != null) {
              localStorage.setItem('testMode', JSON.stringify(params.testMode));
            }
          }

          this.configObs$.next(value);
          this.configObs$.complete();
        });
      });
    }
    return this.configObs$.toPromise();
  }

  async loadByCourse(courseId: string): Promise<TrainingRouter> {
    return new Promise<any>((resolve, reject) => {
      if (this.courseApi[courseId]) {
        resolve(this.courseApi[courseId]);
      } else {
        this.httpClient.get(environment.apiListUrl, {
          params: {
            'webAppId': courseId
          }
        }).subscribe(value => {
          this.courseApi[courseId] = new TrainingRouter(value);
          resolve(this.courseApi[courseId]);
          console.log(this.courseApi);
        });
      }
    });
  }

  getFirebaseConfig(): any {
    return {
      apiKey: 'AIzaSyB3XNZDXAn3xHRftSe8Rlt0B-ZB-WVprpE',
      authDomain: 'sportys-pilot-training.firebaseapp.com',
      databaseURL: 'https://sportys-pilot-training.firebaseio.com',
      projectId: 'sportys-pilot-training',
      storageBucket: 'sportys-pilot-training.appspot.com',
      messagingSenderId: '591805239998',
      appId: '1:591805239998:web:59b2b37c93ad0a761b04ef',
      measurementId: 'G-F9H1GXLFEH'
    };
  }

  isTestMode(): boolean {
    return localStorage.getItem('testMode') === 'true';
  }

}
