import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TrainingService } from './training.service';
import { Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  constructor(private trainingService: TrainingService, private httpClient: HttpClient) {}

  getNotes(webAppId: string): Observable<any[]> {
    return this.trainingService
      .load()
      .pipe(
        switchMap((api) =>
          this.httpClient.get<any[]>(`${api.getRoute('getCurrentUserNotes').replace('{webAppId}', webAppId)}`)
        )
      );
  }

  saveNote(webAppId: string, noteHtmlContent: string): Observable<any> {
    const body = {
      noteType: 'VOLUME',
      noteUrl: '',
      note: noteHtmlContent
    };

    return this.trainingService
      .load()
      .pipe(
        switchMap((api) =>
          this.httpClient.post<any>(`${api.getRoute('createNoteForCurrentUser').replace('{webAppId}', webAppId)}`, body)
        )
      );
  }

  updateNote(noteId: string, noteHtmlContent: string): Observable<any> {
    const body = {
      noteType: 'VOLUME',
      noteUrl: '',
      note: noteHtmlContent
    };

    return this.trainingService
      .load()
      .pipe(
        switchMap((api) =>
          this.httpClient.put<any>(`${api.getRoute('partialUpdateNote').replace('{id}', noteId)}`, body)
        )
      );
  }

  deleteNote(noteId: string): Observable<void> {
    return this.trainingService
      .load()
      .pipe(
        switchMap((api) =>
          this.httpClient.delete<void>(`${api.getRoute('deleteNote').replace('{id}', noteId)}`)
        )
      );
  }
}
