import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  inject,
  input,
  signal,
  viewChild
} from '@angular/core';
import { IconModule } from '../icon/icon.module';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NotesModule } from '../notes/notes.module';
import { HelpCenterComponent } from './help-center/help-center.component';
import { AuthContextService } from 'src/app/services/auth-context.service';
import { NotesComponent } from '../notes/notes.component';
import { HelpDataService } from 'src/app/services/help-data.service';

@Component({
  selector: 'app-help',
  templateUrl: 'help.component.html',
  styleUrl: 'help.component.scss',
  standalone: true,
  imports: [IconModule, NotesModule, HelpCenterComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideUpDown', [
      state(
        'void',
        style({
          bottom: '1rem',
          opacity: '0'
        })
      ),
      state(
        '*',
        style({
          bottom: '4.75rem',
          opacity: '1'
        })
      ),
      transition('* <=> void', [animate('0.3s cubic-bezier(0.23, 1, 0.32, 1)')])
    ])
  ]
})
export class HelpComponent {
  helpBody = viewChild<ElementRef>('helpBody');
  myNotes = viewChild<NotesComponent>('myNotes');

  courseId = input();
  course = input();
  secondaryBackground = input(false);

  showHelpCenterMenuTitle = signal(true);
  activeOption = signal<'help-center' | 'my-notes'>('help-center');
  isScrolled = signal(false);

  elementRef = inject(ElementRef);
  authContext = inject(AuthContextService);
  helpDataService = inject(HelpDataService);

  @HostListener('window:keydown.esc')
  closeOnEsc() {
    this.onClose();
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event) {
    if (!event.target.contains(this.elementRef.nativeElement)) {
      this.onClose();
    }
  }

  onScroll(event) {
    this.isScrolled.set(event?.currentTarget?.scrollTop > 100);
  }

  onScrollToTop() {
    this.helpBody().nativeElement.scrollTo({ top: 0, behavior: 'smooth' });
    this.isScrolled.set(false);
  }

  onToggle(event) {
    event.preventDefault();
    event.stopPropagation();

    this.helpDataService.helpOpened.update((value) => !value);
    this.resetState();
  }

  onClose() {
    this.helpDataService.helpOpened.set(false);
    this.resetState();
  }

  onHelpCenter() {
    this.activeOption.set('help-center');
  }

  onMyNotes() {
    this.activeOption.set('my-notes');
  }

  onHelpCenterTitleBack() {
    this.showHelpCenterMenuTitle.set(true);
  }

  onHelpCenterSelected() {
    this.showHelpCenterMenuTitle.set(false);
  }

  newNote() {
    this.myNotes().newNote();
  }

  private resetState() {
    this.activeOption.set('help-center');
    this.showHelpCenterMenuTitle.set(true);
    this.isScrolled.set(false);
  }
}
