import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'hoursMinutes'
})
export class HoursMinutesPipe implements PipeTransform {

  transform(milliseconds: number, ...args: unknown[]): unknown {
    return this.getHoursAndMinutesFromSeconds(milliseconds);
  }

  private getHoursAndMinutesFromSeconds(numberOfSeconds: number) {
    if (numberOfSeconds !== null && numberOfSeconds !== undefined) {
      const hours = Math.trunc(numberOfSeconds / 3600);
      const minutes = Math.trunc(numberOfSeconds % 3600 / 60);
      return `${hours} Hours, ${minutes} Minutes`;
    }
    return '';
  }
}
