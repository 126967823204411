import { Injectable, signal } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TrainingService } from '../../training.service';
import * as _ from 'underscore';
import { SessionType } from '../tests/enum/session-type.enum';
import { SessionTypeLabels } from '../tests/enum/session-type-labels.enum'
import { TestType } from '../tests/enum/test-type.enum';
import { CategoryType } from '../tests/enum/category-type.enum';
import moment from 'moment';
import { firstValueFrom, Observable } from "rxjs";
import { StartTestRequest } from '../tests/testprep/test/start-test-request.model';
import { TestAnswer } from '../tests/testprep/test/test-answer.model';
import { CoursesService } from '../../courses.service';

@Injectable({
  providedIn: 'root'
})
export class TestsService {

  correctTestMode = signal(false);

  constructor(private httpClient: HttpClient,
    private trainingService: TrainingService,
    private coursesService: CoursesService) {
  }

  async loadCategories(courseId: string, categoryType: string): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);


    return this.httpClient.get(api.getRoute('categoriesUrl').replace('{categoryType}', categoryType), {}).toPromise();
  }

  async analysisByCategoryGroup(courseId: string, categoryType: string, testTypes: TestType[]): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);

    return this.httpClient.put(api.getRoute('analysisByCategoryGroupUrl').replace('{categoryType}', categoryType), { testTypes }).toPromise();
  }

  async testResultsBySoftwareLicense(licenseId: number) {
    return new Promise((resolve, reject) => {
      const api = this.trainingService.load().subscribe(api => {
        this.httpClient.get(api.getRoute('testResultsBySoftwareLicenseUrl').replace('{softwareLicenseId}', licenseId.toString())).subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
      });
    });

  }


  async allTestResults(courseId: string, page: number, size: number, categoryType: string): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);
    let data = new HttpParams()
      .set('page', page)
      .set('size', size);

    return firstValueFrom(this.httpClient.get((api.getRoute('allTestResultsUrl'))
      .replace('{categoryType}', categoryType), { params: data }));
  }

  async testsTakenCount(courseId: string): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);
    return this.httpClient.get(api.getRoute('testsTakenCountUrl')).toPromise();
  }

  async analyzeTest(courseId: string, testId: number): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);

    return new Promise((resolve, reject) => {
      this.httpClient.put(api.getRoute('testAnalysisUrl').replace('{id}', testId.toString()), {}).subscribe(value => {
        value = _.sortBy(value, element => {
          return element['categoryGroup']['name'];
        });
        resolve(value);
      }, error => {
        reject(error);
      });
    });
  }

  async getTest(testId: number, courseId: string): Promise<any> {

    const api = await this.trainingService.loadByCourse(courseId);

    return new Promise((resolve, reject) => {
      const url = api.getRoute('getTestUrl').replace('{id}', testId.toString());
      this.httpClient.get(url).subscribe(test => {
        if (test['totalQuestions'] > 0) {
          if (test['correct'] == null || test['correct'] == 0) {
            test['grade'] = 0;
          } else {
            test['grade'] = Math.round(100 * test['correct'] / test['totalQuestions']);
          }
        }
        resolve(test);
      }, error => {
        reject(error);
      });
    });
  }

  async loadAllNeverSeenQuestionsCount(courseId: string, courseType: number): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);
    let params = new HttpParams()
      .set('courseType', courseType)

    return new Promise((resolve, reject) => {
      this.httpClient.get(api.getRoute('reviewAllUnseenQuestionsCountUrl'), { params: params })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error);
        });
    });
  }

  async loadQuestionsCount(courseId: string, courseType: number, categoryType: CategoryType): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);

    let params = new HttpParams()
      .set('courseType', courseType)
      .set('categoryType', categoryType);

    return new Promise((resolve, reject) => {
      this.httpClient.get(api.getRoute('reviewQuestionsCountUrl'), { params: params })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error);
        });
    });
  }

  async startTest(courseId: string, totalQuestions: number, testType: TestType, totalTimeMillis: number, sessionType: SessionType, categoryType: CategoryType, selectedCategories: Array<any>, questionUuids?: Array<string>): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);

    let codeLookups = [];

    if (selectedCategories) {
      selectedCategories.forEach(category => {
        codeLookups.push(category.id);
      });
    }

    const startTestRequest = new StartTestRequest();
    startTestRequest.totalQuestions = totalQuestions;
    startTestRequest.testType = testType;
    startTestRequest.sessionType = sessionType;
    startTestRequest.totalTimeMillis = totalTimeMillis;
    startTestRequest.codeLookups = codeLookups;
    startTestRequest.uuids = questionUuids;

    return new Promise((resolve, reject) => {

      if (sessionType === SessionType.DEFAULT) {
        alert('NO SESSION TYPE DEFINED');
        reject();
      } else {

        this.httpClient.post(api.getRoute('startTestUrl').replace('{categoryType}', categoryType), startTestRequest).subscribe(value => {
          resolve(value);
        });
      }
    });
  }

  async saveTest(testResultId: number, courseId: string, testAnswers: Array<TestAnswer>): Promise<any> {

    const api = await this.trainingService.loadByCourse(courseId);

    return new Promise((resolve, reject) => {
      this.httpClient.post(api.getRoute('saveTestUrl').replace('{testResultId}', testResultId.toString()),
        testAnswers
      )
        .subscribe((testResult) => {
          resolve(testResult);
        }, (error) => {
          reject();
        });
    });
  }

  async delete(testId: number, courseId: string): Promise<any> {

    const api = await this.trainingService.loadByCourse(courseId);

    return new Promise((resolve, reject) => {
      this.httpClient.delete(api.getRoute('deleteTestUrl').replace('{id}', testId.toString()), {}).subscribe((questions) => {
        this.modifyQuestions(questions);
        resolve(questions);
      }, (error) => {
        reject(error);
      });
    });
  }

  async loadTestQuestions(testId: number, courseId: string): Promise<any> {

    const api = await this.trainingService.loadByCourse(courseId);

    return new Promise((resolve, reject) => {

      this.httpClient.put(api.getRoute('getTestQuestionsUrl').replace('{id}', testId.toString()), {}).subscribe((result: any) => {
        this.modifyQuestions(result.questions);
        resolve(result.questions);
      }, (error) => {
        reject(error);
      });
    });
  }

  async completeTest(correct: number, courseId: string, testId: number): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);

    return new Promise((resolve, reject) => {
      this.httpClient.put(api.getRoute('completeTestUrl').replace('{id}', testId.toString()), {}, {
        params: { 'numCorrect': correct }
      }
      ).subscribe((response) => {
        resolve(response);
      }, (error) => {
        reject();
      });
    });
  };

  async completeExam(courseId, testResult, results): Promise<any> {

    const api = await this.trainingService.loadByCourse(courseId);

    return new Promise((resolve, reject) => {
      this.httpClient.put(api.getRoute('completeExamUrl').replace('{testResultId}', testResult.id),
        {
          correct: results.correct,
          questions: results.totalQuestions,
          incorrect: results.incorrect,
          answered: results.answered
        })
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error);
        });
    });
  };


  private async loadQuestionsForATest(url, courseId: string, testId: number): Promise<any> {

    const api = await this.trainingService.loadByCourse(courseId);

    return new Promise((resolve, reject) => {
      this.httpClient.put(api.getRoute(url).replace('{id}', testId.toString()), {})
        .subscribe((response) => {
          const questions = response;
          this.modifyQuestions(questions);
          resolve(questions);
        }, (error) => {
          reject(error);
        });
    });
  }

  private async loadQuestionsByCourse(url, courseId: string, categoryType: CategoryType, numberOfQuestions?: number): Promise<any> {

    const api = await this.trainingService.loadByCourse(courseId);

    return new Promise((resolve, reject) => {
      this.httpClient.get(api.getRoute(url).replace('{categoryType}', categoryType), {
        params: {
          totalQuestions: numberOfQuestions
        }
      }).subscribe((response) => {
        const questions = response;
        this.modifyQuestions(questions);
        resolve(questions);
      }, (error) => {
        reject(error);
      });
    })
  }


  async loadQuestionCountByCourse(url, courseId: string, categoryType: CategoryType): Promise<any> {

    const api = await this.trainingService.loadByCourse(courseId);

    return new Promise((resolve, reject) => {
      this.httpClient.get(api.getRoute(url).replace('{categoryType}', categoryType), {})
        .subscribe((response) => {
          resolve(response);
        }, (error) => {
          reject(error);
        });
    });
  }

  async repeatTestQuestions(courseId: string, testId: number): Promise<any> {
    return this.loadQuestionsForATest('repeatTestQuestionsUrl', courseId, testId);
  }

  async loadCorrectQuestions(courseId: string, testId: number): Promise<any> {
    return this.loadQuestionsForATest('reviewCorrectQuestionsUrl', courseId, testId);
  }

  async loadMarkedQuestions(courseId: string, testId: number): Promise<any> {
    return this.loadQuestionsForATest('reviewMarkedQuestionsUrl', courseId, testId);
  }

  async loadIncorrectQuestions(courseId: string, testId: number): Promise<any> {
    return this.loadQuestionsForATest('reviewIncorrectQuestionsUrl', courseId, testId);
  }


  modifyQuestions(questions) {
    _.each(questions, this.modifyQuestion);
  }

  modifyQuestion(question, index) {

    const questionText = question['questionText'] || question['question_text'];

    question['_index'] = index + 1;
    question['legends'] = [];
    question['figures'] = [];
    question['_marked'] = false;
    let split_question = questionText.replace(/^\(Refer.*?\)/, '');//.match(/[^.?!]+[.!?]+[\])'"`’”]*/g);
    if (split_question != null) {
      question['first_sentence'] = split_question;
    } else {
      question['first_sentence'] = questionText + '...';
    }
    question['parsed_questionText'] = questionText.replace(/^\(Refer.*?\)/, (str) => {

      let result = '<a href class="figure" (click)="context.showFigure($event)">' + str + '</a>';

      let first_string;
      first_string = '';
      result = str.replace(/(\w\w\w|,) \d+\w?/g, (sub_str) => {
        let strings;
        strings = sub_str.split(' ');
        if (first_string === '') {
          first_string = strings[0];
        }
        if ((strings[0] !== 'and' && strings[0] !== 'eas' && strings[0] !== 'rea' && strings[0] !== 'ion' && strings[0] !== 'end') && (first_string !== 'and' && first_string !== 'eas' && first_string !== 'rea' && first_string !== 'ion' && first_string !== 'end')) {
          question['figures'].push(strings[1]);
          return strings[0] + ' <a href class="figure" data-figure="' + strings[1] + '" data-type="figure" (click)="context.showFigure($event, ' + strings[1] + ')">' + strings[1] + '</a>';
        } else if (strings[0] === 'end' || first_string === 'end') {
          console.log('question:legend:', question['id'], questionText);
          question['legends'].push(strings[1]);
          return strings[0] + ' <a href class="legend" data-legend="' + strings[1] + '" data-type="legend" (click)="context.showLegend($event, ' + strings[1] + ')">' + strings[1] + '</a>';
        } else {
          return sub_str;
        }
      });
      return result;
    });
  }

  public getTestLabel(course: any, test: any): string {
    if (course.testPrepSessionLabel && course.testPrepSessionLabel !== '' && (test.sessionType === SessionType.PRACTICE_TEST)) {
      return course.testPrepSessionLabel;
    } else {
      return SessionTypeLabels[test.sessionType];
    }
  }

  async uploadTest(file: File): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('analyzeFaaTestResultUrl'), formData).subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
      });
    })
  }

  async getAllFaaTestResults(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.get<any[]>(api.getRoute('getAllFaaTestResultsUrl')).subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject(error);
        })
      })
    });
  }

  async getFaaTestResult(id: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.get<any>(api.getRoute('getFaaTestResultUrl').replace('{id}', id.toString())).subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
      })
    })
  }

  async deleteFaaTestResult(testId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.delete(api.getRoute('deleteFaaTestResultUrl').replace('{id}', testId.toString()), {}).subscribe((questions) => {
          resolve(questions);
        }, (error) => {
          reject(error);
        });
      });
    });
  }

}
