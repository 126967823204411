<div class="nav-item user-thumbnail-area" *ngIf="user">
  <img [src]="!!userThumbnailUrl ? userThumbnailUrl : defaultThumbnailUrl" class="user-info"
       [ngClass]="{ 'default': !userThumbnailUrl }" (click)="showUserInfoPanel()">
  <div id="pageOverlay" *ngIf="isPageOverlayVisible"
       (click)="closeUserInfo()"
       (mousemove)="block($event)"
       (mousedown)="block($event)"
       (touchmove)="block($event)"></div>
  <div class="user-info-panel-container"
       [ngClass]="{'user-info-panel-container-visible':isUserInfoPanelVisible}"
       [ngStyle]="{'max-height': maxHeight + 'px'}">
    <div class="user-info-panel" *ngIf="!showPanelOptions">
      <div class="d-flex align-items-center font-weight-bold">
        <a class="btn float-left" (click)="back($event)">
          <app-icon glyph name="back"></app-icon>
        </a>
        <div class="d-flex align-items-center text-dark" *ngIf="showPreferences">Preferences</div>
        <div class="d-flex align-items-center text-dark" *ngIf="showEditProfile">Profile</div>

        <!-- <div *ngIf="showEditProfile" class="row w-100 d-flex align-items-center justify-content-between text-dark">
          <span>
            Edit Profile
          </span>
          <button class="btn btn-round btn-primary mr-2" (click)="onUpdateProfileFormSubmit()">Save Changes</button>
        </div> -->
        <!-- <div class="d-flex align-items-center" *ngIf="showMessages">Messages</div> -->
        <div class="d-flex align-items-center text-dark" *ngIf="showHelp">Help</div>
      </div>
      <div class="preferences-container" *ngIf="showPreferences">
        <div class="switch primary-switch" *ngIf="!!course && course.enableDashboard">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="customSwitches1" [(ngModel)]="homePage"
                   [ngModelOptions]="{standalone: true}" (ngModelChange)="selectHomePage($event)">
            <label class="custom-control-label text-dark" for="customSwitches1">
              Use Video Training as "Home" page.</label>
          </div>
          <span class="lever"></span>
        </div>

        <hr *ngIf="!!course">

        <div class="theme-selector">
          <div class="text-dark mr-auto">Appearance</div>
          <div class="button-group">
            <button class="btn btn-primary appearance-button btn-round" [ngClass]="{'active': colorSchemeService.colorMode() === 'light'}" (click)="setTheme('light')">
              <span class="appearance-button-text" [ngClass]="{'active': colorSchemeService.colorMode() === 'light'}">Light</span>
            </button>
            <button class="btn btn-primary appearance-button btn-round" [ngClass]="{'active': colorSchemeService.colorMode() === 'dark'}" (click)="setTheme('dark')">
              <span class="appearance-button-text" [ngClass]="{'active': colorSchemeService.colorMode() === 'dark'}">Dark</span>
            </button>
            <button class="btn btn-primary appearance-button btn-round" [ngClass]="{'active': colorSchemeService.colorMode() === 'system'}" (click)="setTheme('system')">
              <span class="appearance-button-text" [ngClass]="{'active': colorSchemeService.colorMode() === 'system'}">System</span>
            </button>
          </div>
        </div>
        <div *ngIf="clients.length > 0">
          <div *ngFor="let client of clients">
            <div *ngIf="client.authorities && client.authorities.length > 0">
              <hr>
              <div *ngFor="let authority of client.authorities" [ngStyle]="{'opacity': authority === 'revoked' ? 0.5 : 1}">
                <i class="fa fa-remove px-2" style="color: red; scale: 1.5" (click)="revokeConsent(client, authority)"
                  [ngStyle]="{'cursor': authority === 'revoked' ? 'not-allowed' : 'pointer' }"></i>
                <span class="text-dark">
                  {{client.clientName}}: Revoke access to {{authority}}
                </span>
                <div class="text-danger" *ngIf="errorMessage">
                  {{ errorMessage }}
                </div>
              </div>
              <hr>
            </div>
          </div>
        </div>

        <div class="switch primary-switch">
        <span class="text-size-12 text-color-gray">
          Use the Reset button below to reset your video progress to 0%. This will not reset any quizzes or tests you've
          taken, or remove any completion certificates already earned.
        </span>
          <button class="btn btn-danger btn-round py-1 my-2" (click)="resetVideoProgress()">Reset My Video
            Progress
          </button>
          <p class="text-size-10 text-danger">This video progress cannot be recovered once it is reset.</p>
          <button class="btn btn-primary btn-round py-1 my-2" (click)="unmarkAll()">
            Unmark all questions
          </button>
          <p class="text-size-10 text-info">Unmark all maked questions for Test Prep.</p>
        </div>


      </div>
      <div class="edit-profile-container" *ngIf="showEditProfile">
        <div class="cert-info" aboveNav
             *ngIf="authService.isLoggedIn() && !authService.isCFIUser() && !authService.accountIsNew && !authService.showSignupForm">
          <div class="plate gray_accent1 px-0 py-3">
            <div class="d-flex flex-row justify-content-between align-items-center">
              <app-icon glyph name="info" class="p-3"></app-icon>
              <div>
                You have no CFI credentials saved. <br/>
                <div (click)="completeProfile()" class="font-weight-bold primary_blue cursor-pointer">
                  Complete Profile
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="personal-details d-flex justify-content-center">
          <div *ngIf="!userThumbnailUrl" class="default-thumbnail">
          </div>
          <img [src]="userThumbnailUrl ? userThumbnailUrl : newDefaultThumbnailUrl" class="thumbnail"
               [ngClass]="{ 'default': !userThumbnailUrl }">
          <input type="file" style="display: none;" id="file" multiple="false" accept="image/jpg, image/jpeg, image/png"
                 (change)="handleFileInput($event.target.files)" #fileUpload>
        </div>
        <div class="d-flex w-100 align-items-center justify-content-center">
          <button class="btn btn-round btn-primary" (click)="fileUpload.click()">Upload Photo</button>
        </div>
        <div *ngIf="!!profilePhotoName" class="row w-100">{{profilePhotoName}} uploaded</div>

        <h2 class="my-2 text-dark">Profile Info</h2>

        <div class="d-flex justify-content-start align-items-center">

          <form [formGroup]="updateProfileForm" class="stylized">
            <div class="form-row">
              <label for="displayName" class="w-100 text-primary text-size-12 pl-2">Display Name</label>
              <div class="form-group col w-100">
                <input type="text" class="form-control" formControlName="displayName" type="text"
                       placeholder="Display Name">
              </div>
            </div>
            <div class="form-row">
              <label for="accountEmail" class="w-100 text-primary text-size-12 pl-2">Account Email</label>
              <div class="form-group col w-100">
                <input type="text" class="form-control" formControlName="accountEmail" type="text"
                       placeholder="Account Email">
              </div>
            </div>
            <div class="form-row">
              <label for="contactPhoneNumber" class="w-100 text-primary text-size-12 pl-2">Contact Phone Number</label>
              <div class="form-group col w-100">
                <input type="text" class="form-control" formControlName="contactPhoneNumber" type="text"
                       placeholder="Contact Phone Number">
              </div>
            </div>

            <button class="btn btn-round btn-primary" (click)="sendResetPasswordEmail()">Send me reset password
              email
            </button>
            <span class="text-info" *ngIf="!!message">{{message}}</span>
          </form>

        </div>
      </div>
      <!-- <div class="messages-container" *ngIf="showMessages">

      </div> -->
      <div class="help-container" *ngIf="showHelp">
        <div class="w-100">
          <div class="row no-gutters w-100 mt-3 text-color-gray text-nowrap text-size-md-14 text-size-sm-12">
            <div class="col-lg-2 col-md-4 col-6 d-flex justify-content-between align-items-center px-md-2">
              <button class="help-menu-item no-decorations text-dark"
                      (click)="showGettingStarted = true; showAskCfi = false;"
                      [ngClass]="{'active': showGettingStarted}">Getting Started
              </button>
              <button class="help-menu-item no-decorations ml-2 text-dark"
                      (click)="showGettingStarted = false; showAskCfi = true;" *ngIf="showAskCfiMenuItem"
                      [ngClass]="{'active': showAskCfi}">Ask a CFI
              </button>
            </div>
          </div>
          <div class="w-100 mt-3 text-dark px-3" style="z-index: 100;">
            <div *ngIf="showGettingStarted" [innerHTML]="course.webHelpHtml">
            </div>
            <!-- <div *ngIf="showGettingStarted">
              <h2>Learn to Fly Course User Guide</h2>
              <div _ngcontent-smg-c71="">
                <p>Welcome to Sporty’s Learn to Fly Course, your complete training companion for the Private, Recreational
                  and Sport pilot certificate.</p>
                <p class="mt-2">This comprehensive training course is designed to prepare you for your flight training as well as the
                  FAA written, oral, and flight tests. The course allows you to access content from the Video Training,
                  Test Prep, Resources, Checkride Prep, and Flight Maneuvers sections. After purchase, you’ll gain access
                  to all training modules and the option to receive your FAA written exam endorsement and FAA WINGS
                  credit.</p>
                <p class="mt-2">Please take a moment to read the information contained in this guide as it is intended to explain the
                  contents of your course and help you get the most out of your flight training experience.</p>
                <h2 class="mt-2">Purchasing access to Sporty’s Learn to Fly Course</h2>
                <p>You can purchase full access to the Learn to Fly Course at anytime by clicking on any of the yellow
                  unlock bottons and checking our via Sporty's web store. Once you have created an account log in to
                  access your content.</p>
                <p class="mt-2">Sporty’s Pilot Training is also compatible with iPhone, iPad, Android, AppleTV, and Roku TV. You're
                  purchased course contente can also be accessed from multiple devices. To use a purchased course from a
                  second device, download Pilot Training from the App Store, Google Play Store, or on a Roku device,
                  select the Learn to Fly Course and use the Sign-In option.</p>
                <h2 class="mt-2">Keeping your course progress in sync</h2>
                <p>In order for your video and test score progress to remain in sync between multiple mobile devices, you
                  must be signed in to Pilot Training with your Sporty’s Account. You can sign into your account using the
                  Sign In button at the top right of the header menu.</p>
                <p class="mt-2">If you do not have a Sporty’s Account, you can create a free account at any time by tapping "Sign In"
                  at the top right of the Learn to Fly Course header menu the select "Create Account" link at the bottom
                  ov the Sign-In form.</p>
                <p class="mt-2">For help with purchasing a course or managing your Sporty’s Account, use the Contact Customer Service
                  link from the very bottom of any view or via the "Help" button in the menu on the left to get in touch
                  with a Sporty’s representative.</p>
                <h2 class="mt-2">Choose your Learn to Fly path: Private, Recreational or Sport Pilot </h2>
                <p>Sporty’s Learn to Fly Course is delivered in a modular format with learning tracks for Private,
                  Recreational, or Sport Pilot. When you first launch the Learn to Fly Course from the course portal,
                  you’ll be prompted to select one of the course options and the course will remember your preference each
                  time you open the app.</p>
                <p class="mt-2">To select an alternate learning track, tap the "Change Track" button at the top of the menu on the left
                  under the course name, and select a training option from the menu (Private, Recreational or Sport).</p>
                <p class="mt-2">NOTE: The content and layout of each course is unique, and your video and test score progress is
                  tracked within each course separately.</p> <br>
                <h1>Course Components</h1><br>
                <h2>Video Training</h2>
                <p>To view a video segment, select the Video Training option from the menu on the left. The videos are
                  organized into chapters, starting with basic pilot training content in Chapter 1, and concludes with
                  advanced aviation topics and checkride preparation in the final chapter.</p>
                <p class="mt-2">Review quizzes - You'll have the option to take a short review quiz after viewing certain video
                  segments. At the end of the video an alert will appear asking if you’d like to take the quiz, or you can
                  press the Review Quiz button below the video at any time to launch the quiz. </p>
                <p class="mt-2">NOTE: results from the quizzes are not saved or recorded in the Private Pilot Progress test history.
                </p>
                <p class="mt-2">Video training progress is tracked with green checkmark indicators in the lower right corner of each
                  video section thumbnail. The thumbnail image will also become faded once the video has been viewed.</p>
                <p class="mt-2">A snapshot of total video progress can be seen in the main menu as a %. To be eligible for the written
                  exam endorsement, all video training segments must be viewed.</p>
                <h2 class="mt-2">Test Prep Help</h2>
                <p>To begin a test prep session, select the Test Prep option from the menu on the left. Sporty's written
                  test prep program features two modes of operation. Use Study Sessions mode to select specific areas of
                  study or study all available questions. Study Sessions will be further divied into six different types.
                  Once the disired type is chosen you can then begin to study in a traditional multiple choice format or
                  as flashcards. Flashcard mode will allow you to answer questions without seeing any answer choices in a
                  self-graded environment.</p>
                <p class="mt-2">Practice Test mode will provide a random mix of question to provide a simulated exam. Two tests with a
                  minimum score of 80% are required for the written exam endorsement.</p>
                <p class="mt-2">At any time while in a session you can select Save and Quit to save your current progress and finish
                  later. To resume a session, Test Prep from the menu on the left and then choose a saved session on the
                  lower half of the Test Prep mode selection screen. You can also review completed sessions here as well -
                  press the blue Review Session button to view all the questions and your answer selections from that
                  session. In this area, you may also start a new test based on the results of the original session.</p>
                <p class="mt-2">The highest two Test Prep scores will automatically be displayed in the menu on the left.</p>
                <h2 class="mt-2">Resources Help</h2>
                <p>Selecting the Resources option from the menu on the left will display a collection of helpful documents
                  during your pilot training</p>
                <p class="mt-2">The Pre-Solo Test is a general representation of what you might expect to recieve prior your instructor
                  issuing a sign-off for a first solo. Your instructor may require certian modifications to this document
                  to accomadate your specific training.</p>
                <p class="mt-2">The Training Course Outline (Syllabus) serves as the bridge between your self-study and the training
                  with your instructor in the aircraft. It allows you to maintain a record of your flight experience. </p>
                <p class="mt-2">Each lesson includes objectives, content, completion standards and an optional required study section
                  for additional information. &nbsp;Utilizing the Training Course Outline will ensure that information is
                  presented in a logical sequence and that you’ve met all of the training requirements.</p>
                <p class="mt-2">The Study Guide includes all the review notes from each individual video training section collected
                  into a single document.</p>
                <h2 class="mt-2">Checkride Prep</h2>
                <p>Checkride Prep Flashcards was designed to help you test your aviation knowledge in preparation for the
                  oral exam segment of your FAA practical test. After selecting a study mode, you'll be presented with a
                  series of questions, simulating what the examiner will ask you during the checkride. </p>
                <p class="mt-2">The FAA Checkride Tips and Tricks is an informative video from a Designated Pilot examiner with an
                  inside perspective on what to expect during your checkride.</p>
                <p class="mt-2">The Interactive Checklist is a helpful form to ensure you have completed and aquired all the neccessary
                  skills and items required to schedule and complete your FAA checkride. Your entries below will
                  automatically be saved, allowing you to return whenever you like to add or update your data. </p>
                <p class="mt-2">The interactive Private Pilot ACS (Airman Certification Standards) and Recreational/Sport PTS
                  (Practical Test Standards) is an FAA outline of all the knowledge and skills areas you need to know for
                  the checkride. </p>
                <p class="mt-2">To make your training and preparation easier, the ACS/PTS is cross-referenced to the appropriate video
                  segment for review. &nbsp;To view the video, click on the video icon of the specific area then use the
                  dropdown menu below the video player to switch between task items. Corresponding video items will be
                  listed to the right of the video player.</p>
                <h2 class="mt-2">Maneuvers Help</h2>
                <p>You’ll use the Flight Maneuvers Guide in the Learn to Fly Course in preparation for your flight
                  lessons, to study a 3D animation and step-by-step instructions on how to complete each procedure. To
                  view the Flight Maneuvers Guide, select the Maneuvers option from the row of blue buttons at the top of
                  the screen.</p>
                <p class="mt-2">Each maneuver includes a narrated animation that provides visual instruction on how to complete the
                  task, and a description describing the steps to fly the maneuver as well as common errors.</p>
                <h2 class="mt-2">Earning your knowledge test endorsement</h2>
                <p>After viewing all the video for a particular course and earning two test scores of 80% or higher, the
                  "Request Test Endorsements" button in the menu on the left will become active, allowing you to request
                  your knowledge test endorsement. After completing the request form, a copy of the endorsement will be
                  emailed to you. It will also be available to download from the menu on the left.</p>
                <h2 class="mt-2">FAA WINGS Credit</h2>
                <p>After viewing all the video for a particular course and earning two test scores of 80% or higher, the
                  "Request Wings Credit" button in the menu on the left will become active, allowing you to request FAA
                  WINGS credit for successfully completing the course. To receive credit, you will first need to register
                  for a free account at <a class="text-primary" href="https://www.faasafety.gov">FAASafety.gov</a>. Complete the request form
                  in the Learn to Fly Course, making sure to enter the same email address as your registered FAA Safety
                  account. </p>
                <h2 class="mt-2">How to Get the Most Out of Your Learn to Fly App</h2>
                <ul class="text-size-14">
                  <li>Customize Your Course. Sporty’s Learn to Fly Course is available as a complete Private pilot
                    training package or as a customizable, modular training system with options that include Sport and
                    Recreational pilot. </li>
                  <li>View the flight training video segments appropriate to your course of training from start to finish.
                    This will provide a complete overview of the course and show you what you can expect during your
                    flight training. </li>
                  <li>Once you’ve found an instructor, show him the applicable Training Course Outline (syllabus) and let
                    him know you’d like to use this during your flight training. This comprehensive syllabus will help you
                    get the most out of Sporty’s Complete Flight Training Course (the syllabus PDF is also available at <a class="text-primary"
                      href="https://www.sportys.com/pilotshop/syllabus">sportys.com/pilotshop/syllabus</a>) </li>
                  <li>Watch the assigned video segments. Real-world footage gives you a "pilot’s-eye-view" of everything
                    you’ll see in the airplane, and state-of-the-art 3D graphics and special effects make each subject or
                    maneuver easy to understand. </li>
                  <li>Review the Interactive Maneuvers when you and your instructor are ready to fly. &nbsp;This animated
                    maneuver with detailed, text description are the same ones you are about to practice in the airplane.
                  </li>
                  <li>After each lesson, use the TCO/syllabus to evaluate the flight. There is plenty of room for your
                    instructor to evaluate your performance and to make comments and suggestions for areas that may need
                    additional study or practice. You and your instructor should also take time to briefly discuss the
                    next lesson so each of you can be completely prepared. </li>
                  <li>Throughout your training, review the Interactive ACS/PTS to measure your current performance against
                    the standards that will be expected by the FAA during your flight test. Remember that you will NOT
                    likely meet these standards at the beginning of your training, so don’t get discouraged. Earning a
                    pilot certificate takes time, and Sporty’s Complete Learn to Fly App (along with an excellent
                    instructor) will be with you every step of the way. </li>
                  <li>Sporty’s written test prep program is an integrated component of the app, so you can study for the
                    written test before you start flight training or as you take lessons. This program allows you to take
                    randomly generated practice tests just like the real exam, and create customized study sessions. Our
                    exclusive flash card function allows you to answer questions without seeing the answers, to truly test
                    your knowledge. </li>
                  <li>About the FAA written exam. Once you have completed the video training and have accomplished two
                    practice tests with a minimum score of 80% using the online test prep program, you are eligible to
                    receive your endorsement from Sporty’s to take your written exam at a FAA testing center. Select
                    "written exam endorsement" from the Certificates menu. </li>
                  <li>Receive FAA WINGS credit. Once you have completed the video training and have accomplished two
                    practice tests with a minimum score of 80% using the online test prep program, you are eligible to
                    receive FAA WINGS credit through FAASafety.gov using your FAA WINGS account. Select "WINGS credit"
                    from the Certificates menu and be sure an account has been established through <a class="text-primary"
                      href="https://www.faasafety.gov/">FAASafety.gov</a>. </li>
                  <li>Use the Checkride Prep flashcards to study and learn potential questions and answer the pilot
                    examiner may ask during the oral and flight portion of your checkride. </li>
                  <li>Finally, your flight test. Only your CFI will know when you are prepared to take your flight test.
                    Once he feels you are ready, he will recommend you for this exam which includes an oral and a flight
                    evaluation. After successful completion of your checkride, you will be an FAA licensed pilot.
                    Congratulations! </li>
                </ul>
              </div>
            </div> -->
            <div *ngIf="!showGettingStarted">
              <h2>Ask A CFI</h2>

              <div class="pt-3 px-3">
                <div class="row col-12 pr-0 text-size-14">
                  Looking for help understanding a particular aviation training topic,
                  or have a question about the FAA written test?
                  Send us an email at <a href="mailto:LearnToFly@sportys.com" class="btn-link">LearnToFly&#64;sportys.com</a>
                  and one of Sporty's experienced CFIs will get back to you within 24 hours.
                </div>
                <div class="row col-12 mt-3">
                  <img src="https://dl.videos.sportys.com/onlinecourse/images/askcfi.jpg" style="width: 250px;"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-info-panel" *ngIf="showPanelOptions">
      <div class="user-info-body">
        <div class="personal-details">
          <div *ngIf="!userThumbnailUrl" class="default-thumbnail" (click)="fileUpload.click()">
          </div>
          <img [src]="userThumbnailUrl ? userThumbnailUrl : newDefaultThumbnailUrl" class="thumbnail"
               [ngClass]="{ 'default': !userThumbnailUrl }" (click)="fileUpload.click()">
          <input type="file" style="display: none;" id="file" multiple="false" accept="image/jpg, image/jpeg, image/png"
                 (change)="handleFileInput($event.target.files)" #fileUpload>
          <div class="username">{{user.firstName}}&nbsp;{{user.lastName}}</div>
        </div>
        <div class="email pl-2">{{user.email}}</div>
        <ul class="nav-user-info flex-column mt-4">
          <!-- TODO: messages will be used in the future-->
          <!-- <li class="nav-item"
              routerLinkActive="active-link"
              (click)="closeUserInfo()">
            <div class="nav-parent">
              <app-icon glyph
                        name="flashcards"
                        class="nav-icon"></app-icon>
              <a class="nav-link"
                 routerLinkActive="active">Messages</a>
            </div>
          </li> -->
          <!-- this is not needed at the moment -->
          <!-- <li class="nav-item" routerLinkActive="active-link" *ngIf="course"
            [routerLink]="['/course', course?.webAppId, 'sharing']" (click)="closeUserInfo()">
            <div class="nav-parent">
              <app-icon glyph name="lock" class="nav-icon"></app-icon>
              <a class="nav-link" routerLinkActive="active">Progress Sharing</a>
            </div>
          </li> -->

          <!-- DARK MODE -->
          <!-- <li class="nav-item">
            <div class="nav-parent mt-2">
              <div class="switch primary-switch">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="customSwitches" (change)="changeTheme()"
                    [(ngModel)]="!isThemeLightSelected">
                  <label class="custom-control-label" for="customSwitches">Dark Mode</label>
                </div>
                <span class="lever"></span>
              </div>
            </div>
          </li> -->

          <!-- we will add this later on -->
          <!-- <li class="nav-item" (click)="editProfile($event)">
            <div class="nav-parent">
              <app-icon glyph name="edit-profile" class="nav-icon"></app-icon>
              <a class="nav-link font-weight-bold text-size-16">Edit Profile</a>
            </div>
          </li> -->


          <!-- <li class="nav-item" (click)="messages($event)">
            <div class="nav-parent">
              <app-icon glyph name="notifications" class="nav-icon"></app-icon>
              <a class="nav-link font-weight-bold text-size-16">Messages</a>
            </div>
          </li> -->
          <li class="nav-item" (click)="preferences($event)">
            <div class="nav-parent">
              <app-icon glyph name="preferences" class="nav-icon"></app-icon>
              <a class="nav-link navigation-link font-weight-bold text-size-16">Preferences</a>
            </div>
          </li>
          <li *ngIf="showEditProfile" class="nav-item">
            <div class="nav-parent">
              <app-icon glyph name="graduation" class="nav-icon"></app-icon>
              <a [routerLink]="['/course', course?.webAppId, 'profile']" (click)="closeUserInfo()"
                 class="nav-link navigation-link font-weight-bold text-size-16">Profile</a>
            </div>
          </li>
          <li *ngIf="showShareProgress" class="nav-item">
            <div class="nav-parent">
              <app-icon glyph name="graduation" class="nav-icon"></app-icon>
              <a [routerLink]="['/course', course?.webAppId, 'sharing']" (click)="closeUserInfo()"
                 class="nav-link navigation-link font-weight-bold text-size-16">Share Progress</a>
            </div>
          </li>
          <li class="nav-item" (click)="help($event)"
              *ngIf="!!course && course.webHelpHtml">
            <div class="nav-parent">
              <app-icon glyph name="info" class="nav-icon"></app-icon>
              <a class="nav-link navigation-link font-weight-bold text-size-16">Help</a>
            </div>
          </li>
        </ul>

        <div class="mt-3 font-weight-bold font-size-16 pl-2 text-dark">
          My Courses
        </div>
        <ul class="nav-user-info flex-column ml-3">
          <li class="nav-item" (click)="closeUserInfo()" *ngFor="let popularCourse of popularCourses">
            <div class="nav-parent">
              <img src="{{popularCourse.thumbnailUrl}}" alt="" width="32">
              <a class="nav-link navigation-link font-weight-normal" routerLinkActive="active"
                 (click)="onPopularCourseClick(popularCourse)">{{popularCourse.shortName}}</a>
            </div>
          </li>
          <li class="nav-item" (click)="closeUserInfo()">
            <div class="nav-parent">
              <app-icon glyph name="see-all-courses" class="nav-icon"></app-icon>
              <a class="nav-link navigation-link font-weight-normal" [routerLink]="['/home', 'courses']"
                 routerLinkActive="active">See All</a>
            </div>
          </li>
        </ul>


        <div *ngIf="user && (course?.enableCloudAhoy || course?.enableForeFlight)">
          @if (!!config && config['user.mode'] !== 'YE') {
            <div class="sidenav-course-info mt-3">
              <div>
                <div class="text-size-16 font-weight-bold pl-2 text-dark">Connect</div>
              </div>
              <div *ngIf="purchased" class="mt-1 px-1 ml-4">
                <div class="nav flex-column sidenav-links">
                  <div class="nav-item" *ngIf="course?.enableCloudAhoy">
                    <a class="nav-link navigation-link font-weight-bold text-size-16 text-dark"
                      [routerLink]="['/course', course?.webAppId, 'cloudahoy']"
                      (click)="closeUserInfo()">
                      Connect to CloudAhoy</a>
                  </div>
                  <div class="nav-item" *ngIf="course?.enableForeFlight && !user.isValidCfi">
                    <a class="nav-link navigation-link font-weight-bold text-size-16 text-dark"
                      [routerLink]="['/course', course?.webAppId, 'foreflight']"
                      (click)="closeUserInfo()">
                      Connect to ForeFlight</a>
                  </div>
                </div>
              </div>
              <div *ngIf="!purchased" class="mt-1 px-1 ml-4">
                <ul class="nav flex-column sidenav-links">
                  <li class="nav-item navigation-link font-weight-bold text-size-16 nav-item-disabled py-2 text-dark"
                      *ngIf="course?.enableCloudAhoy">
                    Connect to CloudAhoy
                  </li>
                  <li class="nav-item navigation-link font-weight-bold text-size-16 nav-item-disabled py-2 text-dark"
                      *ngIf="course?.enableForeFlight">
                    Connect to ForeFlight
                  </li>
                </ul>
              </div>
            </div>
          }
          <hr *ngIf="cheat">
          <div class="sidenav-course-info" *ngIf="cheat">
            <div class="sidenav-help">
              <button (click)="markAllVideosWatched()" class="btn btn-warning">Watch Vids</button>
            </div>
          </div>
        </div>
      </div>

      <div class="user-info-footer">
        <div class="actions bottom">
            <a class="nav-link btn btn-round btn-border-red font-weight-bold" (click)="logout()">Log Out</a>
            @if (course?.webAppId) {
              <button class="btn btn-primary btn-round btn-help-center" (click)="openHelpCenter()">
                <app-icon glyph name="pressure-outlined" class="help-icon"></app-icon>
                Help Center
              </button>
            }
        </div>
      </div>
    </div>
  </div>
</div>
