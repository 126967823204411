<div class="modal-header">
  <h3 class="modal-title pull-left">{{video.description}}</h3>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <app-videojs
    [options]="{ autoplay: true, controls: true, sources: [{ poster:video.thumbnaillUrl, src: video.url }]}">
  </app-videojs>
</div>
