<div class="study-session" [ngClass]="{'marketing':marketing}">
  <div class="study-session-header d-flex justify-content-center flex-wrap" *ngIf="!marketing">
    <div class="session-open-close" (click)="test.open = !test.open"
      *ngIf="enableTestPrepBreakdown && (course.enableTestPrepBreakdown || course.enableTestPrepStudySessions)">
      <div *ngIf="test.endTime">
        <!--<i class="fa" [ngClass]="{'fa-plus-circle':!test.open,'fa-minus-circle':test.open}"></i>-->
        <app-icon glyph [name]="test.open?'minus':'plus'"></app-icon>
      </div>
    </div>
    <div class="session-title">
      <div class="session-title-label">
        <div class="session-title-label-container">
          <span>{{testsService.getTestLabel(course, test)}}</span>
          <span *ngIf="test.testType === 'FLASH'">&nbsp;({{test.testType}})</span>
        </div>
        <div class="alert-in-progress" *ngIf="!test.endTime">
          <div>In Progress</div>
        </div>
      </div>
      <p *ngIf="!test.endTime">Started on {{test.beginTime | date:'mediumDate'}}</p>
      <p *ngIf="test.endTime">Completed on {{test.endTime | date:'mediumDate'}}</p>
      <p>{{test.totalQuestions}} Questions</p>
    </div>
    <div class="session-graph" *ngIf="test.endTime">
      <div class="responsive-content-desktop">
        <app-doughnut-chart [percent]="(100 * test.correct / test.totalQuestions)" [rightLabel]="true" [innerLabel]="false"
          [cutout]="10" [passingGrade]="minimumScore" [height]="45" [width]="60"></app-doughnut-chart>
      </div>
      <div class="responsive-content-mobile">
        <app-progress-bar [attempts]="test.totalQuestions" [correct]="test.correct"></app-progress-bar>
      </div>
    </div>
    <div class="session-command">
      <button *ngIf="options.allowResume && !test.endTime" (click)="resume(test)"
        class=" btn btn-sm btn-primary btn-round btn-shadow btn-w-100">
        Resume
      </button>
      <button *ngIf="options.allowReview && test.endTime" (click)="resume(test)"
        class=" btn btn-sm btn-primary btn-round btn-shadow btn-w-100">
        Review Session
      </button>
      <button *ngIf="options.allowDelete" class="btn btn-sm btn-default session-delete" (click)="remove(test)">
        <app-icon glyph name="trash"></app-icon>
      </button>
    </div>
  </div>
  <div class="study-session-categories" *ngIf="!marketing && test.endTime && test.open">
    <div class="study-session-categories-header">
      <h3>Categories</h3>
    </div>
    <div class="study-session-categories-list" *ngIf="test.analysis"
         [ngStyle]="{height: (ceil(test.analysis.length/2) * 50)+'px'}">
      <div *ngFor="let categoryGroup of test.analysis" class="study-session-category">
        <p>{{categoryGroup.categoryGroup.name}}</p>
        <app-progress-bar [correct]="categoryGroup.correct" [attempts]="categoryGroup.attempts"></app-progress-bar>
      </div>
    </div>
    <!--<pre>{{test.analysis|json}}</pre>-->
  </div>
  <div class="study-session-controls" *ngIf="!marketing && test.endTime && test.open && options.allowCreate">
    <div class="w-100 title">
      <h3>Create a new session based on:</h3>
    </div>
    <div class="study-session-controls-container">
      <div class="study-session-control">
        <div class="type">
          <h3>Questions answered Correctly</h3>
          <p><span>{{test.correct}}</span>/{{test.totalQuestions}}</p>
        </div>
        <div>
          <button (click)="startQuestionsAnsweredCorrectly(test)" class="btn btn-sm btn-white btn-round btn-shadow"
                  [disabled]="test.correct === 0 || !purchased">Start
          </button>
        </div>
      </div>
      <div class="study-session-control">
        <div class="type">
          <h3>Questions Answered Incorrectly</h3>
          <p><span>{{test.answered - test.correct}}</span>/{{test.totalQuestions}}</p>
        </div>
        <div>
          <button (click)="startIncorrectQuestions(test)" class="btn btn-sm btn-white btn-round btn-shadow"
                  [disabled]="(test.answered - test.correct === 0)  || !purchased">Start
          </button>
        </div>
      </div>
      <div class="study-session-control">
        <div class="type">
          <h3>Marked Questions</h3>
          <p><span>{{test.marked}}</span></p>
        </div>
        <div>
          <button (click)="startMarkedQuestions(test)" class="btn btn-sm btn-white btn-round btn-shadow"
                  [disabled]="test.marked === 0 || !purchased">Start
          </button>
        </div>
      </div>
      <div class="study-session-control">
        <div class="type">
          <h3>Retry Entire Session Again</h3>
          <p><span>&nbsp;</span></p>
        </div>
        <div>
          <button (click)="retryTest(test)" [disabled]="!purchased" class="btn btn-sm btn-white btn-round btn-shadow">
            Start
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="study-session-header d-flex justify-content-between flex-wrap" *ngIf="marketing">
    <div class="session-open-close"
         *ngIf="(course.enableTestPrepBreakdown || course.enableTestPrepStudySessions)">
      <div>
        <app-icon glyph name="minus"></app-icon>
      </div>
    </div>
    <div class="session-title">
      <h3 class="dark">{{course.testPrepSessionLabel}}</h3>
      <p>Completed on December 18, 2019</p>
    </div>
    <div class="session-graph">
      <div class="graph">
        <div class="responsive-content-desktop">
          <app-doughnut-chart [percent]="62" [rightLabel]="true" [cutout]="50"
                              [passingGrade]="minimumScore" [height]="45"></app-doughnut-chart>
        </div>
        <div class="responsive-content-mobile">
          <app-progress-bar [attempts]="100" [correct]="62"></app-progress-bar>
        </div>
      </div>
    </div>
    <div class="session-command">
      <button class="btn btn-sm btn-primary btn-round btn-shadow btn-w-100">
        Review Session
      </button>
      <button class="btn btn-sm btn-default session-delete">
        <app-icon glyph name="trash"></app-icon>
      </button>
    </div>
  </div>
  <div class="study-session-categories"
       *ngIf="marketing && (course.enableTestPrepBreakdown || course.enableTestPrepStudySessions)">
    <div>
      <h3>Categories</h3>
    </div>
    <div class="study-session-categories-list" style="height:100px">
      <div class="study-session-category">
        <p>Aerodynamic Forces</p>
        <app-progress-bar [correct]="87" [attempts]="100"></app-progress-bar>
      </div>
      <div class="study-session-category">
        <p>Aircraft Performance</p>
        <app-progress-bar [correct]="55" [attempts]="100"></app-progress-bar>
      </div>
      <div class="study-session-category">
        <p>Aerodynamic Forces</p>
        <app-progress-bar [correct]="87" [attempts]="100"></app-progress-bar>
      </div>
      <div class="study-session-category">
        <p>Aircraft Performance</p>
        <app-progress-bar [correct]="55" [attempts]="100"></app-progress-bar>
      </div>
    </div>
  </div>
  <div class="study-session-controls"
       *ngIf="marketing && (course.enableTestPrepBreakdown || course.enableTestPrepStudySessions)">
    <div class="w-100 title">
      <h3>Create a new session based on:</h3>
    </div>
    <div class="study-session-controls-container">
      <div class="study-session-control">
        <div class="type">
          <h3>Questions answered Correctly</h3>
          <p><span>62</span>/100</p>
        </div>
        <div>
          <button class="btn btn-sm btn-white btn-round btn-shadow">Start</button>
        </div>
      </div>
      <div class="study-session-control">
        <div class="type">
          <h3>Questions Answered Incorrectly</h3>
          <p><span>38</span>/100</p>
        </div>
        <div>
          <button class="btn btn-sm btn-white btn-round btn-shadow">Start</button>
        </div>
      </div>
      <div class="study-session-control">
        <div class="type">
          <h3>Marked Questions</h3>
          <p><span>12</span></p>
        </div>
        <div>
          <button class="btn btn-sm btn-white btn-round btn-shadow">Start</button>
        </div>
      </div>
      <div class="study-session-control">
        <div class="type">
          <h3>Retry Entire Session Again</h3>
          <p><span>&nbsp;</span></p>
        </div>
        <div>
          <button class="btn btn-sm btn-white btn-round btn-shadow">Start</button>
        </div>
      </div>
    </div>
  </div>
</div>
