import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {TrainingService} from '../training.service';
import {AuthContextService} from '../services/auth-context.service';

@Injectable()
//Requires a logged in user to access this resource when in YE mode
export class YeAuthGuard implements CanActivateChild {
  config: any;

  constructor(private authService: AuthService,
              private authContextService: AuthContextService,
              private router: Router,
              private trainingService: TrainingService) {

  }

  async canActivateChild(): Promise<boolean> {
    this.config = await this.trainingService.loadConfig();
    return new Promise((resolve, reject) => {
      console.log('AuthGuard.canActivate');
      if (this.config['user.mode'] !== 'YE') {
        resolve(true);
        return;
      }

      if (this.authContextService.user) {
        resolve(true);
        return;
      }

      this.authService.renew().then((value) => {
          resolve(true);
        },
        (error) => {
          console.log('map error returned from renew');
          if (this.config['user.mode'] === 'YE') {
            this.router.navigate(['/login']);
            resolve(false);
          } else {
            resolve(true);
          }
        }).catch(error => {
        console.log('catchError error returned from renew');
        resolve(false);
      });
    });
  }
}
