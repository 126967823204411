<div class="container">
  <div class="row">
    <div class="col-md-12">
      <!-- ornate html that is aviation themed -->

      <a class="btn mt-3 justify-content-start" (click)="previousState()">
        <app-icon glyph [name]="'back'"></app-icon>
        Back
      </a>
      <h1 class="display-1">Feature Disabled</h1>
      <p class="lead">{{helpDataService.featureErrorMessage}}</p>
      <p class="lead">Thank you.</p>
    </div>
  </div>
</div>
