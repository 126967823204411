import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ProgressService} from '../../progress.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CourseService} from '../../course/services/course.service';
import {TrainingService} from '../../training.service';
import * as _ from 'underscore';
import {identityRevealedValidator} from '../../validators/emailvalidator';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {CoursesService} from "../../courses.service";
import {AuthContextService} from "../../services/auth-context.service";


@Component({
  selector: 'app-request-cfi-certificate',
  templateUrl: './request-cfi-certificate.component.html',
  styleUrls: ['./request-cfi-certificate.component.scss']
})
export class RequestCfiCertificateComponent implements OnInit, OnDestroy {

  course: any;
  user: any;
  progress: any;

  allowSubmit = false;
  allowDownload = false;

  downloadUrl: string;

  loading: boolean;

  formDisabled = true;

  progressServiceSubscription: Subscription;

  result: any;

  private _license: any;

  form: UntypedFormGroup;

  constructor(
    private authService: AuthService,
    private authContextService: AuthContextService,
    private progressService: ProgressService,
    private courseService: CourseService,
    private trainingService: TrainingService,
    private route: ActivatedRoute,
    private router: Router,
    private coursesService: CoursesService,
  ) {
  }

  ngOnInit() {


    this.course = this.route.snapshot.data.course;
    console.log('RequestEndorsementComponent:init', this.course.webAppId);

    this.coursesService.loadCourseLicense(this.course.webAppId).then((license) => {
      this._license = license;
    });

    this.authContextService.onRenew().subscribe((user) => {
      this.user = user;
      this.createForm();
    });

    this.progressService.refreshProgress(this.course.webAppId).then(progress => {
      console.log('RequestEndorsementComponent:init:on progress', this.course.webAppId);
      if (progress && progress[this.course.webAppId]) {
        console.log('RequestEndorsementComponent:init:on progress', this.course.webAppId);
        this.progress = progress[this.course.webAppId];

        if (this.progress.writtenExamRequested) {
          this.allowDownload = true;
          //calc download url
        } else {
          this.formDisabled = false;
          this.allowSubmit = true;
        }

        /*
        if (_.keys(this.progress.certificationRequests).length > 0) {
          this.certificationRequests = this.progress.certificationRequests;
          if (this.certificationRequests['EXAM_ENDORSEMENT']) {
            this.courseService.getCertificateDownloadUrl(this.certificationRequests['EXAM_ENDORSEMENT']).then(value => {
              this.certificationRequests['EXAM_ENDORSEMENT'].downloadUrl = value;
            });
          }
        }*/

      } else {
        this.progress = null;
      }
    });

    /*
    if (this.progress.writtenExamRequested) {
      this.allowDownload = true;
      //calc download url
    } else {
      this.formDisabled = false;
      this.allowSubmit = true;


    }*/


    /*
      this.form.get('name').setValue('Mike');
      this.form.get('address').setValue('1194');
      this.form.get('city').setValue('Maineville');
      this.form.get('state').setValue('OH');
      this.form.get('zip').setValue('45039');
      this.form.get('phone').setValue('5135555555');
      this.form.get('email').setValue('mwball@gmail.com');
      this.form.get('confirm_email').setValue('mwball@gmail.com');
*/
  }

  createForm() {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(),
      address: new UntypedFormControl(),
      city: new UntypedFormControl(),
      state: new UntypedFormControl(),
      zip: new UntypedFormControl(),
      phone: new UntypedFormControl(),
      email: new UntypedFormControl(),
      confirmEmail: new UntypedFormControl()
    }, {validators: identityRevealedValidator});

    this.form.setValue({
      name: this.user.firstName + " " + this.user.lastName,
      address: this.user.address,
      city: this.user.city,
      state: this.user.state,
      zip: this.user.zip,
      phone: this.user.phone,
      email: this.user.email,
      confirmEmail: this.user.email,
    }, {emitEvent: true});
  }

  ngOnDestroy() {
  }

  redirectToAddress(): void {
    this.router.navigate(['/course', 'FIRC_2023', 'acr']);
  }

  onSubmit() {
    this.loading = true;
    const value = _.clone(this.form.value);
    value.modeType = 'COURSE';
    value.webAppId = this.course.webAppId; // to be updated with webAppId
    value.certificationArtifactType = "pdf";

    const callback = (response) => {

      this.courseService.getCertificateDownloadUrl(response).then(downloadUrl => {
        this.progressService.refreshProgress(this.course.webAppId).then(progress => {
          this.downloadUrl = downloadUrl;
          this.result = response;
          if (response.success) {
            this.allowSubmit = false;
            this.allowDownload = true;
          } else {
            this.allowSubmit = true;
          }
          this.loading = false;
          this.formDisabled = true;
        });
      });
    };

    const onError = (error) => {
      this.progressService.refreshProgress(this.course.webAppId).then(progress => {
        this.allowSubmit = false;
        this.allowDownload = false;
        this.loading = false;
        this.formDisabled = false;
        this.result = error;
      });
    };


    if (this.course.isCourseMixed) {
      this.courseService.submitCourseCompletionRequestMixed(this.course.webAppId, value).then(callback, onError); // to be updated with course.webAppId
    } else {
      this.courseService.submitCourseCompletionRequest(this.course.webAppId, value).then(callback, onError); // to be updated with course.webAppId
    }
  }

  get license() {
    return this._license;
  }
}
