<div class="modal-header w-100 p-0">
  <div
    class="w-100 plate plate-dark_blue swoosh swoosh-cfi-home px-3 py-4 border-bottom-left-radius-0 border-bottom-right-radius-0 border-top-left-radius-modal border-top-right-radius-modal">
    <div class="d-flex justify-content-start align-items-center w-100 plate-header">
      <img class="w-100 modal-header-image d-none d-md-block"
           src="https://dl.videos.sportys.com/onlinecourse/images/sportys-logo-block-airplane.svg"/>
      <div class="tagline text-size-18 ml-2" *ngIf="title">{{title}}</div>
      <div class="d-flex align-items-start pt-0 text-size-14 ml-5 pl-5 mr-5 cursor-pointer"
           (click)="bsModalRef.hide();">
        <span aria-hidden="true" class="text-white modal-close-btn text-size-20">×</span>
      </div>
    </div>
  </div>
</div>
<div class="modal-body">
  <div class="messages">
    <div [innerHTML]="message"></div>
    <div class="warning-message" *ngIf="warningMessage">{{warningMessage}}</div>
  </div>

  <div class="d-flex" *ngIf="showDoughnutChart">
    <app-doughnut-chart [cutout]="80" [rightLabel]="false" [innerLabel]="true" [gradient]="false" [height]="60"
            [passingGrade]="0" [percent]="doughnutChartCompletnessPercentage"></app-doughnut-chart>
  </div>

  <div class="button-controls" [ngStyle]="{'flex-direction': primaryButton==='no'?'yes':'row-reverse'}">
    <button (click)="yes()" class="btn btn-sm btn-round btn-shadow"
            *ngIf="yesLabel"
            [ngClass]="{'btn-primary':primaryButton==='yes' && !yesLabelClass, 'btn-white': primaryButton ==='no' && !yesLabelClass, yesLabelClass: !!yesLabelClass}">{{yesLabel}}</button>
    <button href (click)="no()" class="btn btn-sm btn-round btn-shadow"
            *ngIf="noLabel"
            [ngClass]="{'btn-primary':primaryButton==='no' && !noLabelClass, 'btn-white': primaryButton==='yes' && !noLabelClass, noLabelClass: !!noLabelClass }">{{noLabel}}</button>
  </div>

</div>
