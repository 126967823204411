import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalQueryParamsResolverService implements Resolve<any> {
  constructor() {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const queryParams = route.queryParams;

    if (queryParams['maintenance-mode'] === 'true' || queryParams['maintenance-mode'] === 'false') {
      localStorage.setItem('maintenanceMode', queryParams['maintenance-mode'])
     }

    if (queryParams['test-mode'] === 'true' || queryParams['test-mode'] === 'false') {
      localStorage.setItem('testMode', queryParams['test-mode']);
    }

    return of(queryParams);
  }
}
