import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ProgressService} from '../../progress.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import * as _ from 'underscore';
import {CourseService} from '../../course/services/course.service';
import {identityRevealedValidator} from '../../validators/emailvalidator';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-request-wings',
  templateUrl: './request-wings.component.html',
  styleUrls: ['./request-wings.component.scss']
})
export class RequestWingsComponent implements OnInit, OnDestroy {
  course: any;
  user: any;
  progress: any;


  allowSubmit = false;
  allowDownload = false;

  downloadUrl: string;

  loading: boolean;

  formDisabled = true;

  error: string = null;
  result: any;

  form = new UntypedFormGroup({
    name: new UntypedFormControl(),
    address: new UntypedFormControl(),
    city: new UntypedFormControl(),
    state: new UntypedFormControl(),
    zip: new UntypedFormControl(),
    phone: new UntypedFormControl(),
    email: new UntypedFormControl(),
    confirmEmail: new UntypedFormControl()
  }, {validators: identityRevealedValidator});

  progressServiceSubscription: Subscription;


  constructor(
    private authService: AuthService,
    private progressService: ProgressService,
    private courseService: CourseService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.course = this.route.snapshot.data.course;
    console.log('RequestWingsComponent:init', this.course.webAppId);
    this.progressService.refreshProgress(this.course.webAppId).then(progress => {
      console.log('RequestWingsComponent:init:on progress', this.course.webAppId);
      if (progress && progress[this.course.webAppId]) {
        console.log('RequestWingsComponent:init:on progress', this.course.webAppId);
        this.progress = progress[this.course.webAppId];

        if (this.progress.faaWingsRequested) {
          this.allowDownload = true;
          //calc download url
        } else {
          this.formDisabled = false;
          this.allowSubmit = true;
        }

        /*
        if (_.keys(this.progress.certificationRequests).length > 0) {
          this.certificationRequests = this.progress.certificationRequests;
          if (this.certificationRequests['EXAM_ENDORSEMENT']) {
            this.courseService.getCertificateDownloadUrl(this.certificationRequests['EXAM_ENDORSEMENT']).then(value => {
              this.certificationRequests['EXAM_ENDORSEMENT'].downloadUrl = value;
            });
          }
        }*/

      } else {
        this.progress = null;
      }
    });

    /*
    if (this.progress.faaWingsRequested) {
      this.allowDownload = true;
      //calc download url
    } else {
      this.formDisabled = false;
      this.allowSubmit = true;
      }
     */
    /*
          this.form.get('name').setValue('Mike');
          this.form.get('address').setValue('1194');
          this.form.get('city').setValue('Maineville');
          this.form.get('state').setValue('OH');
          this.form.get('zip').setValue('45039');
          this.form.get('phone').setValue('5135555555');
          this.form.get('email').setValue('mwball@gmail.com');
          this.form.get('confirm_email').setValue('mwball@gmail.com');
    */
  }

  ngOnDestroy() {
    if (this.progressServiceSubscription) {
      this.progressServiceSubscription.unsubscribe();
    }
  }

  onSubmit() {
    this.loading = true;
    const value = _.clone(this.form.value);
    value.modeType = 'COURSE';
    value.webAppId = this.course.webAppId; // to be updated with webAppId
    value.certificationArtifactType = "pdf";
    this.formDisabled = true;

    //if this is a licensed course we have to add the licenseId as a param
    let licenseId = null;
    if (this.course.courseLicensed) {
      licenseId = this.progress.displayLicense ? this.progress.displayLicense.id : null;
    }
    this.courseService.submitFaaWingsRequest(this.course.webAppId, value).then(async (response) => { // to be updated with course.webAppId
      this.allowSubmit = false;
      this.allowDownload = true;
      this.formDisabled = true;
      this.progressService.refreshProgress(this.course.webAppId);
      this.loading = false;
      this.result = response;
    }, reason => {
      this.formDisabled = false;
      this.allowSubmit = true;
      if (reason.data) {
        this.error = reason.data['message'];
      } else {
        this.error = 'There was a problem submitting your FAA Wings request.';
      }
    });

  }
}
