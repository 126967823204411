import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Injectable } from '@angular/core';
import { TrainingService } from '../training.service';

@Injectable()
export class YeGuard  {
    config: any;
    constructor(
      private authService: AuthService,
      private router: Router,
      private trainingService: TrainingService) {
    }

    async canActivate(): Promise<boolean> {
      this.config = await this.trainingService.loadConfig();
        return new Promise(async (resolve, reject) => {
          console.log('YeGuard.canActivate');
            if (this.config['user.mode'] === 'YE') {
                this.router.navigate(['/login']);
                resolve(false);
            }
            resolve(true);
        });
    }
}
