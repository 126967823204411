import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { TestModeService } from '../test-mode.service';

@Injectable({
  providedIn: 'root',
})
export class TestModeInterceptorService implements HttpInterceptor {

  constructor(private testModeService: TestModeService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const testMode = this.testModeService.getTestMode();

    if (testMode != null) {
      const modifiedRequest = request.clone({
        setParams: {
          'test-mode': testMode.toString()
        }
      });
      return next.handle(modifiedRequest);
    }

    return next.handle(request);

  }

}
