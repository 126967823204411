import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-flippy',
  templateUrl: './flippy.component.html',
  styleUrls: ['./flippy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlippyComponent implements OnInit {

  @Input()
  flipText = 'Click Card to Show Question';

  @Input() context: any;

  constructor() {
  }

  ngOnInit() {

  }


}
