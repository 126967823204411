import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  inject
} from '@angular/core';
import {NavItemComponent, SidenavItem} from '../nav-item/nav-item.component';
import {TestBuilderService} from '../../services/test-builder.service';
import {CategoryType} from '../../tests/enum/category-type.enum';
import {TestType} from '../../tests/enum/test-type.enum';
import {SessionType} from '../../tests/enum/session-type.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {StudentService} from '../../../cfi/students/student.service';
import {firstValueFrom, pipe} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {TrainingService} from '../../../training.service';


@Component({
  selector: 'app-accordion-menu',
  standalone: true,
  imports: [CommonModule, NavItemComponent],
  template: `
    <app-nav-item
      [hasTopBorder]="false"
      [mainItem]="{
        label: 'Home',
        iconName: 'home',
        routeParts: ['/course', course.webAppId, course.homePageType.route]
      }"
    >
    </app-nav-item>
    <app-nav-item
      *ngFor="let volumeGroup of volumeGroups; trackBy: volumeGroupIdentifier"
      [isExpanded]="!!expandState?.['videoTraining']"
      (contentToggle)="onContentToggle($event, 'videoTraining')"
      [mainItem]="{
        label:
          course.webAppId.indexOf('FIRC') !== -1 && volumeGroup.code === 'VIDEO_TRAINING'
            ? 'Lessons'
            : volumeGroup.name,
        iconName: volumeGroup.svgIconName,
        routeParts: ['/course', course.webAppId, 'volumes', volumeGroup.code]
      }"
      [secondaryItems]="[
        {
          enabled: !!this.firstUnwatchedVideoVolumeUuid && !!this.firstUnwatchedVideoUuid,
          label: 'Start Next Lesson',
          routeParts: [
            '/course',
            course.webAppId,
            'volumes',
            volumeGroup.code,
            this.firstUnwatchedVideoVolumeUuid,
            this.firstUnwatchedVideoUuid
          ]
        },
        {
          enabled: purchased && !!optionalVolume?.uuid,
          label: 'Optional Video Training',
          routeParts: [
            '/course',
            course.webAppId,
            'volumes',
            volumeGroup.code,
            optionalVolume?.uuid
          ]
        },
        {
          enabled: course.enableFlightManeuvers,
          label: 'Flight Maneuvers Guide',
          routeParts: ['/course', course.webAppId, 'maneuvers']
        }
      ]"
    >
    </app-nav-item>
    <app-nav-item
      [isExpanded]="!!expandState?.['testPrep']"
      (contentToggle)="onContentToggle($event, 'testPrep')"
      [mainItem]="{
        label: course.testPrepLabel,
        iconName: 'grade',
        routeParts: ['/course', course.webAppId, 'testprep']
      }"
      [secondaryItems]="[
        {
          label: 'Start new study session',
          routeParts: ['/course', course.webAppId, 'testprep', 'select']
        },
        {
          enabled: purchased && course.enableTestPrepPracticeTest,
          label: 'Take a practice test',
          onClick: this.startPracticeTest.bind(this)
        },
        {
          enabled: purchased && course?.enableTestPrep,
          label: 'Study History',
          routeParts: ['/course', course.webAppId, 'testprep', 'sessions']
        }
      ].concat(this.cheat ? [{
        enabled: cheat,
        label: 'Upload a test',
        routeParts: ['/course', course.webAppId, 'testprep', 'upload-test']
      }] : [])"
    >
    </app-nav-item>

    <app-nav-item [isExpanded]="!!expandState?.['faaHandbooks']"
                  (contentToggle)="onContentToggle($event, 'faaHandbooks')"
                  [mainItem]="{
        label: 'FAA Handbooks',
        iconName: 'test',
        routeParts: ['/course', course.webAppId, 'faalibrary']
      }"
                  [secondaryItems]="[
      {
        visible: isAiVisible,
        enabled: isChatFarAssistantAvailable,
        label: 'ChatFAR<sup>SM</sup>',
        onClick: this.openChatFAR
      },
      {
        label: 'Federal Aviation Regulations (FAR)',
        routeParts: ['/course', this.course.webAppId, 'far']
      },
      {
        label: 'Aeronautical Information Manual (AIM)',
        routeParts: [
          '/course',
          this.course.webAppId,
          'faalibrary',
          'resource',
          'AERONAUTICAL_INFORMATION_MANUAL_PDF'
        ]
      },
      {
        enabled: this.purchased,
        label: 'Airman Certificate Standards (ACS)',
        routeParts: ['/course', this.course.webAppId, 'checkride', 'acs']
      },
      {
        label: 'View All',
        routeParts: ['/course', this.course.webAppId, 'faalibrary'],
        skipActiveLinkHighlight: true
      }
    ]"
    >
    </app-nav-item>

    <app-nav-item
      [isExpanded]="!!expandState?.['trainingResources']"
      (contentToggle)="onContentToggle($event, 'trainingResources')"
      [mainItem]="{
        label: 'Training Resources',
        iconName: 'maneuvers',
        routeParts: ['/course', course.webAppId, 'resources']
      }"
      [secondaryItems]="[
        {
          enabled: course.enableFaaHandbooks,
          label: 'Training Course Outline (TCO)',
          routeParts: [
            '/course',
            course.webAppId,
            'resources',
            'resource',
            'PRIVATE_TRAINING_COURSE_OUTLINE'
          ]
        },
        {
          enabled: purchased,
          label: 'Pre-Solo Exam',
          routeParts: ['/course', course.webAppId, 'resources', 'resource', 'PRESOLOTEST']
        },
        {
          enabled: purchased,
          label: 'Flight Sim Training Guide',
          routeParts: [
            '/course',
            course.webAppId,
            'resources',
            'resource',
            'FLIGHTSIMGUIDEPRIVATE'
          ]
        },
        {
          label: 'View All',
          routeParts: ['/course', course.webAppId, 'resources'],
          skipActiveLinkHighlight: true
        }
      ]"
    >
    </app-nav-item>
    <app-nav-item
      [isExpanded]="!!expandState?.['checkridePrep']"
      (contentToggle)="onContentToggle($event, 'checkridePrep')"
      [mainItem]="{
          label: 'Checkride Prep',
          iconName: 'checkride_prep',
          routeParts: ['/course', course.webAppId, 'checkride']}"
      [secondaryItems]="[
      {
        visible: isAiVisible,
        enabled: isChatDpeAssistantAvailable,
        label: 'ChatDPE<sup>SM</sup> Checkride Prep',
        onClick: this.openChatDPE
      },
      {
        enabled: this.purchased,
        label: 'Checkride Flashcards',
        routeParts: ['/course', this.course.webAppId, 'checkride', 'prep']
      },
      {
        enabled: this.purchased,
        label: 'Checkride Checklist',
        routeParts: ['/course', this.course.webAppId, 'checkride', 'checklist', 'ltf']
      }
    ]"
    >
    </app-nav-item>
  `,
  styleUrls: ['./accordion-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionMenuComponent {
  @Input() course: any;
  @Input() purchased: any;
  @Input() volumeGroups: any[];
  @Input() optionalVolume: any;
  @Input() firstUnwatchedVideoVolumeUuid: string;
  @Input() firstUnwatchedVideoUuid: string;
  @Input() expandState: { [key: string]: boolean } = {};

  @Output() expandStateChange = new EventEmitter();

  testBuilderService = inject(TestBuilderService);
  studentService = inject(StudentService);
  trainingService = inject(TrainingService);
  router = inject(Router);
  route = inject(ActivatedRoute);
  cheat = localStorage.getItem('testMode') === 'true';

  isAiVisible: boolean = false;
  isChatFarAssistantAvailable: boolean = false;
  isChatDpeAssistantAvailable: boolean = false;

  volumeGroupIdentifier(index, volumeGroup) {
    return volumeGroup.code;
  }

  async ngOnInit() {
    const config = await this.trainingService.loadConfig();

    this.isAiVisible = config['user.mode'] !== 'YE';

    this.chatDpeAssistantEnabled().then((enabled) => {
      this.isChatDpeAssistantAvailable = enabled && this.purchased;
    });

    this.farAssistantEnabled().then((enabled) => {
      this.isChatFarAssistantAvailable = enabled && this.purchased;
    });

  }

  startPracticeTest(event) {
    event.preventDefault();
    event.stopPropagation();
    this.testBuilderService.begin(this.course, CategoryType.EXAM);
    this.testBuilderService.testType = TestType.TEST;
    this.testBuilderService.sessionType = SessionType.PRACTICE_TEST;

    this.testBuilderService.startTest().then((test) => {
      this.router.navigate([
        '/',
        'course',
        this.course.webAppId,
        'testprep',
        test['testResult']['testType'].toLowerCase()
      ]);
    });
  }

  openChatFAR(event) {
    event.preventDefault();
    event.stopPropagation();

    window.open('/course/far?assistant=true', '_blank');
  }

  openChatDPE(event) {
    event.preventDefault();
    event.stopPropagation();

    window.open('/course/PRIVATE/checkride/prep/chat-dpe', '_blank');
  }

  onContentToggle(value, key) {
    this.expandState[key] = value;
    this.expandStateChange.emit(this.expandState);
  }

  private async chatDpeAssistantEnabled(): Promise<boolean> {
    const assistants = await firstValueFrom(this.studentService.getAvailableAiAssistants());
    return assistants.some(assistant => assistant.name === 'DPE_ASSISTANT');
  }

  private async farAssistantEnabled(): Promise<boolean> {
    const assistants = await firstValueFrom(this.studentService.getAvailableAiAssistants());
    return assistants.some(assistant => assistant.name === 'FAR_ASSISTANT');
  }
}
