import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { EventType } from './enum/event-type.enum';
import { AuthService } from '../services/auth.service';
import * as _ from 'underscore';
import { getAnalyticsName } from '../name.annotation';
import {AuthContextService} from '../services/auth-context.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  /*
  components = {
    ShareProgressComponent: 'share_progress',
    CloudAhoyComponent: 'cloud_ahoy',
    ForeFlightComponent: 'foreflight',
    WelcomeComponent: 'home',
    VolumesComponent: 'volumes',
    VolumeComponent: 'volume',
    VideoComponent: 'video',
    SegmentsComponent: false,
    TestprepComponent: 'test_prep',
    BreakdownComponent: 'breakdown',
    SelectComponent: false,
    TestComponent: false,
    GradeComponent: false,
    CategoriesComponent: false,
    QuestionsComponent: false,
    QuestionSearchComponent: 'question_search',
    SessionsComponent: false,
    ResourcesModuleComponent: false,
    ResourceComponent: 'resource',
    UserDocumentComponent: 'user_document',
    CheckrideComponent: false,
    FaaCheckrideTipsAndTricksComponent: 'checkride_tips_tricks',
    ChecklistComponent: 'checkride_checklist',
    AcsComponent: 'acs',
    StandardComponent: 'acs_standard',
    CheckrideprepComponent: 'checkride_prep',
    FlashcardComponent: false,
    LearnComponent: false,
    ManeuversComponent: 'maneuvers',
    ManeuverComponent: 'maneuver'
  };*/

  constructor(private angulartics: Angulartics2, private authContextService: AuthContextService) {
  }

  /*DONE*/
  videoPlayed(course, video, area, videoProps: any) {
    if (!_.isString(course)) {
      course = course.webAppId;
    }
    const props = {
      COURSE_TYPE: course,
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      AREA: area,
      DEMO: this.authContextService.user == null || !this.authContextService.user.coursePermissions[course] ? 'TRUE' : 'FALSE'
    };

    _.extend({}, props, videoProps);

    this.angulartics.eventTrack.next({
      action: EventType.VIDEO_PLAYED.toString(),
      properties: props
    });
  }


  /*DONE*/
  accessedCfiHomePage() {

    const props = {
      GUEST: this.authContextService.user === null ? 'TRUE' : 'FALSE',
      DEMO: this.authContextService.user !== null ? 'TRUE' : 'FALSE'
    };
    this.angulartics.eventTrack.next({
      action: EventType.CFI_HOME.toString(),
      properties: props
    });
  }

  /*DONE*/
  accessedCfiSpecialOfferPage(offer: string) {

    const props = {
      GUEST: this.authContextService.user === null ? 'TRUE' : 'FALSE',
      DEMO: this.authContextService.user !== null ? 'TRUE' : 'FALSE',
      OFFER: offer
    };

    this.angulartics.eventTrack.next({
      action: EventType.CFI_SPECIAL_OFFER.toString(),
      properties: props
    });
  }

  /*DONE*/
  accessedCourse(course, component) {

    if (getAnalyticsName(component)) {
      if (!_.isString(course)) {
        course = course.webAppId;
      }
      const props = {
        COURSE_TYPE: course,
        GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
        AREA: getAnalyticsName(component),
        DEMO: this.authContextService.user == null || !this.authContextService.user.coursePermissions[course] ? 'TRUE' : 'FALSE'
      };

      this.angulartics.eventTrack.next({
        action: EventType.COURSE.toString(),
        properties: props
      });

      if (window['ineum']) {
        window['ineum']('page', getAnalyticsName(component));
      }
    }
  }

  /*DONE*/
  startCheckrideSession(course, sessionType, totalQuestions) {
    if (!_.isString(course)) {
      course = course.webAppId;
    }
    const props = {
      COURSE_TYPE: course,
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      AREA: 'checkride_flashcards',
      DEMO: this.authContextService.user == null || !this.authContextService.user.coursePermissions[course] ? 'TRUE' : 'FALSE',
      SESSION_TYPE: sessionType,
      TOTAL_QUESTIONS: totalQuestions
    };

    this.angulartics.eventTrack.next({
      action: EventType.CHECKRIDE_SESSION.toString(),
      properties: props
    });
  }

  /*DONE*/
  startTestprepSession(course, sessionType, totalQuestions) {
    if (!_.isString(course)) {
      course = course.webAppId;
    }
    const props = {
      COURSE_TYPE: course,
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      AREA: 'testprep',
      DEMO: this.authContextService.user == null || !this.authContextService.user.coursePermissions[course] ? 'TRUE' : 'FALSE',
      SESSION_TYPE: sessionType,
      TOTAL_QUESTIONS: totalQuestions
    };

    this.angulartics.eventTrack.next({
      action: EventType.TEST_PREP_SESSION.toString(),
      properties: props
    });
  }

  /*DONE*/
  startReviewSession(course, sessionType, totalQuestions) {
    if (!_.isString(course)) {
      course = course.webAppId;
    }
    const props = {
      COURSE_TYPE: course,
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      AREA: 'testprep',
      DEMO: this.authContextService.user == null || !this.authContextService.user.coursePermissions[course] ? 'TRUE' : 'FALSE',
      SESSION_TYPE: sessionType,
      TOTAL_QUESTIONS: totalQuestions
    };

    this.angulartics.eventTrack.next({
      action: EventType.REVIEW_QUIZ_SESSION.toString(),
      properties: props
    });
  }


  documentViewed(documentId) {

    const props = {
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      AREA: 'resource',
      DEMO: this.authContextService.user == null? 'TRUE' : 'FALSE',
      DOCUMENT_ID: documentId
    };

    this.angulartics.eventTrack.next({
      action: EventType.DOCUMENT_VIEWED.toString(),
      properties: props
    });
  }

  documentCourseViewed(course, documentId) {
    if (!_.isString(course)) {
      course = course.webAppId;
    }
    const props = {
      COURSE_TYPE: course,
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      AREA: 'resource',
      DEMO: this.authContextService.user == null || !this.authContextService.user.coursePermissions[course] ? 'TRUE' : 'FALSE',
      DOCUMENT_ID: documentId
    };

    this.angulartics.eventTrack.next({
      action: EventType.DOCUMENT_VIEWED.toString(),
      properties: props
    });
  }

  resourceViewed(source, documentId, group, subgroup) {
    const props = {
      SOURCE: source,
      GROUP: group,
      SUBGROUP: subgroup,
      AREA: 'resource',
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      DEMO: this.authContextService.user == null || !this.authContextService.user.coursePermissions[source] ? 'TRUE' : 'FALSE',
      DOCUMENT_ID: documentId
    };

    this.angulartics.eventTrack.next({
      action: EventType.RESOURCE_VIEWED.toString(),
      properties: props
    });
  }

  userDocumentViewedByCourse(course, documentId) {
    if (!_.isString(course)) {
      course = course.webAppId;
    }
    const props = {
      COURSE_TYPE: course,
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      AREA: 'user_document',
      DEMO: this.authContextService.user == null || !this.authContextService.user.coursePermissions[course] ? 'TRUE' : 'FALSE',
      DOCUMENT_ID: documentId
    };

    this.angulartics.eventTrack.next({
      action: EventType.USER_DOCUMENT_VIEWED.toString(),
      properties: props
    });
  }

  userDocumentViewed(documentId) {
    const props = {
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      AREA: 'user_document',
      DEMO: this.authContextService.user == null,
      DOCUMENT_ID: documentId
    };

    this.angulartics.eventTrack.next({
      action: EventType.USER_DOCUMENT_VIEWED.toString(),
      properties: props
    });
  }

  userDocumentUploaded(course, documentId) {

    if (!_.isString(course)) {
      course = course.webAppId;
    }
    const props = {
      COURSE_TYPE: course,
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      AREA: 'user_document',
      DEMO: this.authContextService.user == null || !this.authContextService.user.coursePermissions[course] ? 'TRUE' : 'FALSE',
      DOCUMENT_ID: documentId
    };

    this.angulartics.eventTrack.next({
      action: EventType.USER_DOCUMENT_UPLOADED.toString(),
      properties: props
    });
  }

  /*DONE*/
  login(course) {
    if (!_.isString(course)) {
      course = course.webAppId;
    }
    const props = {
      COURSE_TYPE: course,
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      DEMO: this.authContextService.user == null || !this.authContextService.user.coursePermissions[course] ? 'TRUE' : 'FALSE',
    };

    this.angulartics.eventTrack.next({
      action: EventType.LOGIN.toString(),
      properties: props
    });
  }

  /*DONE*/
  signup(course) {
    if (!_.isString(course)) {
      course = course.webAppId;
    }
    const props = {
      COURSE_TYPE: course,
      GUEST: this.authContextService.user == null ? 'TRUE' : 'FALSE',
      DEMO: this.authContextService.user == null || !this.authContextService.user.coursePermissions[course] ? 'TRUE' : 'FALSE',
    };

    this.angulartics.eventTrack.next({
      action: EventType.SIGNUP.toString(),
      properties: props
    });
  }
}
