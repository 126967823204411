import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { GenericModalButtonModel } from '../models/generic-modal-button-model';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent implements OnInit {

  @Input() modalTitle: string;
  @Input() modalBodyMessage: string;
  @Input() modalButtons: GenericModalButtonModel[];

  @Output() buttonClicked = new EventEmitter<string>();
  result: Subject<string> = new Subject<string>();
  onCancel: Subject<boolean> = new Subject<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  btnClicked(id: string): void {
    this.result.next(id);
  }

  closeModal(): void {
    this.onCancel.next(true);
  }

}
