import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TestModeService {
  testMode: boolean = null;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const params = this.activatedRoute.snapshot.queryParams;
      if (params['test-mode'] !== undefined) {
        this.testMode = params['test-mode'];
      } else {
        this.testMode = null;
      }
    });
  }

  getTestMode(): string {
    return this.testMode != null ? this.testMode.toString(): null;
  }
}
