<small>
  Copyright hint: the PDF file has been published by <a href="https://de.linkedin.com/in/stephan-rauh-a6a398aa">Stephan
    Rauh</a> on
  <a href="https://www.beyondjava.net/page/beyondjava-conference">BeyondJava.net</a> under a
  <a target="#" rel="license" href="https://creativecommons.org/licenses/by-nc-nd/3.0/de/">Creative Commons
    Attribution-NonCommercial-NoDerivs 3.0 Germany License.<br /></a> </small>
<ngx-extended-pdf-viewer
  [src]="'/assets/pdfs/Bootstrap-vs-Material-Design-vs-Prime-vs-Tailwind.pdf'"
  [useBrowserLocale]="true">
</ngx-extended-pdf-viewer>
