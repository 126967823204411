import {Component, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {SharingService} from '../../course/services/sharing.service';
import {SelectCourseComponent} from '../select-course/select-course.component';
import {ShareInviteComponent} from '../share-invite/share-invite.component';
import {take} from 'rxjs/operators';
import {CommonCourseComponent} from '../../course/common.course.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CoursesService} from '../../courses.service';
import {AuthService} from '../../services/auth.service';
import {ProgressService} from '../../progress.service';
import {ResourcesService} from '../../course/services/resources.service';
import {HttpClient} from '@angular/common/http';
import {TrainingService} from '../../training.service';
import {UserDocumentService} from '../../course/services/user-document.service';
import {Analytics} from '../../name.annotation';
import {ConnectionService} from '../../course/services/connection.service';
import {ConfirmationModalComponent} from '../confirmation/confirmation-modal.component';
import {AuthContextService} from '../../services/auth-context.service';
import _ from 'underscore';
import {LoginModalComponent} from "../login-modal/login-modal.component";

@Component({
  selector: 'app-share-progress',
  templateUrl: './share-progress.component.html',
  styleUrls: ['./share-progress.component.scss']
})
@Analytics('share_progress')
export class ShareProgressComponent extends CommonCourseComponent {

  title: string;
  closeBtnName: string;

  invitedInstructors = []; //these are connections from the students
  invitedStudents = []; //these are the connections from the instructors

  loading = false;
  isDemoUser = false;

  inviteModalRef: BsModalRef;

  sharedLink = null;

  constructor(route: ActivatedRoute,
              private router: Router,
              coursesService: CoursesService,
              authContextService: AuthContextService,
              authService: AuthService,
              progressService: ProgressService,
              modalService: BsModalService,
              private resourcesService: ResourcesService,
              private httpClient: HttpClient,
              private trainingService: TrainingService,
              private userDocumentService: UserDocumentService,
              private sharingService: SharingService,
              private connectionService: ConnectionService) {
    super(route, coursesService, authContextService, authService, modalService);
  }

  init() {
    this.authService.renew().then(() => {
      if (this.authContextService.user) {
        this.initUser();
      }
    }, () => {
      const initialState = {
        title: 'Login',
        course: this.course,
        courseGroup: this.course.courseGroup,
        allowClose: false,
      };
      this.modalService.show(LoginModalComponent, {
        ignoreBackdropClick: true,
        initialState: initialState,
        class: 'modal-lg rounded-modal'
      });

      const loginModalSubscription = this.modalService.onHide.pipe(take(1)).subscribe(value => {
        if (this.loginModal.content.user) {
          this.initUser();
        }
      });
    });

  }

  initUser() {
    this.isDemoUser = !(this.authContextService.user && this.authContextService.user.coursePermissions[this.course.webAppId]);

    this.trainingService.load().subscribe(api => {
      this.sharedLink = api.getRoute('progressUrl');
    });

    this.refresh();
  }

  destroy() {

  }

  refresh() {
    const promises = [];
    promises.push(this.connectionService.getInvitationRequests()); // instructor to student
    promises.push(this.connectionService.getConnectionRequests()); // student to instructor

    Promise.all(promises).then((result) => {



      const invitedStudents = result[0];
      const invitedInstructors = result[1];

      const instructors = _.map(invitedStudents, (invitedStudent) => {
        return invitedStudent.instructor ? invitedStudent.instructor.id : -1;
      });

      this.invitedInstructors = _.filter(invitedInstructors, (invitedInstructor) => {
        return !invitedInstructor.instructor || instructors.indexOf(invitedInstructor.instructor.id) < 0;
      });

      this.invitedStudents = invitedStudents;
    });

  }

  revoke($event, invitation, type: string) {
    $event.preventDefault();
    $event.stopPropagation();

    const modal = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        message: 'Are you sure you want to remove your connection to this instructor?',
        warningMessage: null,
        yesLabel: 'Yes',
        noLabel: 'No',
        primaryButton: 'yes'
      }, class: 'rounded-modal'
    });

    const sub = this.modalService.onHide.pipe(take(1)).subscribe(value => {
      console.log('ResourcesComponent:', value);
      if (modal.content.response) {
        this.doRemove(invitation, type);
      }
      modal.hide();
    });
  }

  doRemove(invitation, type) {
    if (type === 'student') {
      this.connectionService.revokeConnection(invitation, true).then(value => {
        this.refresh();
      });
    } else {
      this.connectionService.revokeInvitation(invitation, true).then(value => {
        this.refresh();
      });
    }

  }

  //
  // share($event) {
  //   $event.stopPropagation();
  //   $event.preventDefault();
  //
  //   const initialState = {
  //     sharedByMe: this.sharedByMe
  //   };
  //
  //   this.inviteModalRef = this.modalService.show(ShareInviteComponent, {initialState, class: 'modal-lg'});
  //   const sub = this.modalService.onHide.pipe(take(1)).subscribe(value => {
  //     console.log('ShareProgressComponent:', value);
  //
  //     if (this.inviteModalRef.content.email) {
  //       this.sharingService.shareWithEmail(this.inviteModalRef.content.email).then(() => {
  //         this.sharingService.sharedByMe().then((result) => {
  //           this.sharedByMe = result;
  //
  //         });
  //       });
  //     }
  //
  //   });
  //
  //
  //   //share with an email
  //   /*
  //   var instanceController = function($scope, $modalInstance) {
  //     this.ok = function() {
  //       SharingService.shareWithEmail(this.email).then(function() {
  //         return $modalInstance.close();
  //       });
  //     };
  //     this.cancel = function() {
  //       $modalInstance.dismiss();
  //     };
  //   };
  //
  //   var modalInstance = $modal.open({
  //     templateUrl: window.templateRoot + '/course/components/progress-sharing-invite.html',
  //     controller: instanceController
  //   }).result.then(function() {
  //     this.load();
  //   }, function() {
  //
  //   });
  //
  //
  //    */
  // };

  connect($event) {
    $event.stopPropagation();
    $event.preventDefault();

    const initialState = {
      invitedInstructors: this.invitedInstructors
    };

    this.inviteModalRef = this.modalService.show(ShareInviteComponent, {initialState, class: 'modal-lg rounded-modal'});
    const sub = this.modalService.onHide.pipe(take(1)).subscribe(value => {
      console.log('ShareProgressComponent:', value);

      if (value != 'backdrop-click' && this.inviteModalRef.content.approvedEmail) {
        this.connectionService.sendConnectionRequest(this.inviteModalRef.content.approvedEmail).then(() => {
          this.refresh();
        });
      }

    });
  }
}
