import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {
  pad(num: number) {
    if (num < 10) {
      return '0' + num;
    } else {
      return num;
    }
  };

  transform(milliseconds: any, ...args: any[]): any {
    milliseconds = Math.abs(milliseconds);
    let seconds = milliseconds / 1000;
    let minutes = (seconds / 60) % 60;
    minutes = Math.floor(minutes);
    let hours = seconds / 3600;
    hours = Math.floor(hours);
    seconds = seconds - (minutes * 60) - (hours * 60 * 60);
    const time = '' + (hours.toFixed(0)) + ':' + (this.pad(Math.floor(minutes))) + ':' + (this.pad(Math.floor(seconds)));
    return time;
  }

}
