import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthContextService {
  private _user: any = null;
  private tokenPayload: any;
  private _anonymous: boolean = false;
  private _loginSubject = new Subject<any>();
  private _logoutSubject = new Subject<void>();
  private _renewSubject = new Subject<any>();
  private _accountIsNew = false;
  private _showSignupForm = false;

  /**
   * TODO: refactor this to use the signals after update to Angular v16
   */
  private isValidCfiUser = new BehaviorSubject<boolean>(false);
  isValidCfiUser$ = this.isValidCfiUser.asObservable();

  public constructor(private cookieService: CookieService) {
  }

  onLogin(): Observable<any> {
    return this._loginSubject;
  }

  public get loginSubject() {
    return this._loginSubject;
  }

  onLogout(): Observable<any> {
    return this._logoutSubject;
  }

  public get logoutSubject() {
    return this._logoutSubject;
  }

  onRenew(): Observable<any> {
    return this._renewSubject;
  }

  public get renewSubject() {
    return this._renewSubject;
  }

  public get user() {
    return this._user;
  }

  public set user(user) {
    if (user) {
      this.anonymous = false;
    }
    this._user = user;
    this.isValidCfiUser.next(user?.isValidCfi);
  }

  public getToken() {
    return window.localStorage.getItem('token');
  }

  public setToken(token: string) {
    window.localStorage.setItem('token', token);
    this.parseToken(token);
  }

  private parseToken(token: string) {
    const payload = token.split('.')[1];
    const decoded = window.atob(payload);
    const json = JSON.parse(decoded);
    this.tokenPayload = json;
  }

  public get anonymous() {
    return this._anonymous;
  }

  public set anonymous(anonymous) {
    this._anonymous = anonymous;
  }

  public get accountIsNew() {
    return this._accountIsNew;
  }

  public set accountIsNew(accountIsNew) {
    this._accountIsNew = accountIsNew;
  }

  public get showSignupForm() {
    return this._showSignupForm;
  }

  public set showSignupForm(showSignupForm) {
    this._showSignupForm = showSignupForm;
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }

  isCFIUser(): boolean {
    return this.isLoggedIn() && !!this.user?.cfiNumber;
  }

  isValidCFIUser(): boolean {
    return this.isCFIUser() && this.user?.isValidCfi;
  }

  isCoursePurchasedByUser(courseId: string): boolean {
    return this.isLoggedIn() && !!this.user.coursePermissions[courseId];
  }

  public get orgType() {
    if (this.user && this.user.orgType) {
      return this.user.orgType;
    } else if (this.cookieService.check('orgType')) {
      return this.cookieService.get('orgType');
    } else if (this.anonymous) {
      return 'SPORTYS';
    } else {
      return 'loading';
    }
  }
}
