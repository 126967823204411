<div class="route-container">
  <courier-toast></courier-toast>
  <router-outlet></router-outlet>
  <router-outlet name="modal-outlet-2"></router-outlet>
</div>

@if (helpDataService.helpOpened()) {
  <div class="help-backdrop" (click)="closeHelp()"></div>
}

<!--
<footer>
  <a href="https://www.sportys.com/customer-service/">Customer Service</a>
  <div class="copyright">&copy;{{year}} Sporty's Pilot Shop</div>
</footer>
-->
