<div class="study-session">
  <div class="study-session-header d-flex justify-content-center flex-wrap" >
    <div class="session-open-close" (click)="getDetails()">
      <div>
        <app-icon glyph [name]="test.open?'minus':'plus'"></app-icon>
      </div>
    </div>
    <div class="session-title">
      <div class="session-title-label">
        <div class="session-title-label-container">
          <span>{{test.name}}</span>
        </div>
      </div>
      <p>Completed on {{test.date}}</p>
    </div>
    <div class="session-graph" >
      <div class="responsive-content-desktop">
        <app-doughnut-chart [percent]="test.score" [rightLabel]="true" [innerLabel]="false"
          [cutout]="10" [passed]="test.passed" [height]="45" [width]="60"></app-doughnut-chart>
      </div>
      <div class="responsive-content-mobile">
        <app-progress-bar [attempts]="100" [correct]="test.score"></app-progress-bar>
      </div>
    </div>
    <div *ngIf="user" class="session-command">
      <button class="btn btn-sm btn-default session-delete" (click)="remove()">
        <app-icon glyph name="trash"></app-icon>
      </button>
    </div>
  </div>
  <div class="study-session-categories">
    <div class="study-session-categories-header">
      @if (test.open) {
        <a *ngIf="user" class="btn justify-content-start my-2" (click)="viewTest(test.fileUrl)">View Test</a>
        <div *ngFor="let acsCodeDetail of test.acsCodeDetails" class="mb-4">
          <span class="text-size-16 weight-bold" >{{ acsCodeDetail.acsCode }}</span>
          <p>Area of Operations {{ acsCodeDetail.acsCode.split('.')[1] }}: {{acsCodeDetail.areaOfOperations}}</p>
          <p>Task {{ acsCodeDetail.acsCode.split('.')[2] }}: {{acsCodeDetail.task}}</p>
          <p>Task Element {{ acsCodeDetail.acsCode.split('.')[3] }}: {{acsCodeDetail.taskElement}}</p>
        </div>
      }
    </div>
  </div>
</div>
