<div class="sidenav">

    <div class="sidenav-controls">

        <div class="sidenav-back">
            <div *ngIf="backLink">
                <a class='btn' [routerLink]="backLink.routerLink">
                    <app-icon glyph [name]="backLink.glyph"></app-icon>
                    {{ backLink.label }}
                </a>
            </div>
        </div>

        <ng-content select="[aboveNav]"></ng-content>

        <div class="sidenav-nav">
            <ul class="nav flex-column">
                <li tooltip data-popover="{{tooltip(link)}}" [popover]="tooltip(link)" triggers="hover"
                    containerClass="custom-popover" class="nav-item" *ngFor="let link of links"
                    routerLink="{{link.routerLink}}"
                    [routerLinkActive]="link.isDisabled ? 'disabled-active-link' : 'active-link'"
                    [routerLinkActiveOptions]="{exact: false}" [routerLink]="link.isDisabled ? null : link.routerLink"
                    (click)="sidenavLinkClicked()">
                    <div class="nav-parent">
                        <app-icon glyph [name]="link.glyph"></app-icon>
                        <a class="nav-link">{{ link.label }}</a>
                    </div>
                    <app-icon *ngIf="link.isDisabled && !colorSchemeService.isDarkMode()" [disabled]="true" glyph name="lock"></app-icon>
                    <app-icon *ngIf="link.isDisabled && colorSchemeService.isDarkMode()" [disabled]="true" glyph name="lock-white"></app-icon>
                </li>
            </ul>
        </div>
    </div>
</div>
