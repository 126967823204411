import {Component, OnInit} from "@angular/core";
import { HelpDataService } from "../services/help-data.service";

@Component({
  selector: 'app-feature-disabled',
  templateUrl: './feature-disabled.component.html'
})
export class FeatureDisabledComponent implements OnInit {

  constructor(public helpDataService: HelpDataService) {
  }

  ngOnInit() {
    console.log('FeatureModeComponent');
  }

  previousState(): void {
    window.history.back();
  }

}
