import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IconModule } from '../../icon/icon.module';
import { Router } from '@angular/router';

const QUICK_START_GUIDE_PRIVATE_ID = 'LTF_GETTING_STARTED';
const QUICK_START_GUIDE_INSTRUMENT_ID = 'INT_GETTING_STARTED';

@Component({
  selector: 'app-help-center',
  templateUrl: 'help-center.component.html',
  styleUrl: 'help-center.component.scss',
  standalone: true,
  imports: [IconModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpCenterComponent {
  course = input<any>();
  showMenu = input<boolean>();
  isScrolled = input<boolean>();

  helpCenterSelected = output();
  quickStartGuideOpened = output();
  scrollToTop = output();

  sanitizer = inject(DomSanitizer);
  router = inject(Router);

  isPrivateCourse = computed(() => this.course()?.webAppId === 'PRIVATE');
  isInstrumentCourse = computed(() => this.course()?.webAppId === 'INSTRUMENT');
  quickStartGuideParsed = computed(() =>
    this.processResponseHtml(this.course()?.webHelpHtml)
  );
  isAskCfiVisible = computed(
    () =>
      this.course()?.webAppId === 'PRIVATE' ||
      this.course()?.webAppId === 'INSTRUMENT' ||
      this.course()?.webAppId === 'COMMERCIAL'
  );
  summaryTitles = computed(() =>
    this.createSummaryTiles(this.quickStartGuideParsed()?.h2Elements)
  );

  openQuickStartGuide() {
    const quickStartGuideId = this.isPrivateCourse()
      ? QUICK_START_GUIDE_PRIVATE_ID
      : this.isInstrumentCourse()
      ? QUICK_START_GUIDE_INSTRUMENT_ID
      : null;

    if (!quickStartGuideId) {
      return;
    }

    this.router.navigate([
      `/course/${this.course().webAppId}/resources/resource/${quickStartGuideId}`
    ]);
    this.quickStartGuideOpened.emit();
  }

  selectQuickStartGuide() {
    this.helpCenterSelected.emit();
  }

  openKnowledgeBase() {
    window.open(
      'https://support.courses.sportys.com/en-US/articles/pilot-training-56017',
      '_blank'
    );
  }

  onTitleClick(event, title: any) {
    event.preventDefault();

    const element = document.getElementById(title.link.substring(1)); // Get element without leading #

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  onScheduleNow() {
    window.open('https://instacfi.com/ref/11', '_blank');
  }

  onScrollToTop() {
    this.scrollToTop.emit();
  }

  private processResponseHtml(html: string): {
    outerHtml: string;
    h2Elements: NodeListOf<HTMLHeadingElement>;
  } {
    if (!html) {
      return null;
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const h2Elements = doc.querySelectorAll('h2');

    h2Elements.forEach((h2) => {
      const id = `h2-${h2.textContent.replace(/\s+/g, '-')}`; // Generate unique ID
      h2.setAttribute('id', id);
    });

    return {
      outerHtml: doc.body.outerHTML,
      h2Elements
    };
  }

  private createSummaryTiles(h2Elements: NodeListOf<HTMLHeadingElement>) {
    const newSummaryTiles = [];

    if (!h2Elements) {
      return newSummaryTiles;
    }

    h2Elements.forEach((h2) => {
      newSummaryTiles.push({
        text: h2.textContent,
        link: `#${h2.id}`
      });
    });

    return newSummaryTiles;
  }
}
