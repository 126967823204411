<div class="w-100 bg-dark-blue purchase-course-banner" *ngIf="showBanner">
    <div class="banner-content py-2">
      <div class="title">
        <span class="text-white text-size-20 ml-3 row">{{course.shortName | uppercase}} COURSE</span>
      </div>
      <div class="d-flex justify-content-md-start justify-content-end align-items-center">
        <div class="row pl-2 py-0 m-0">
          <app-icon *ngIf="!colorSchemeService.isDarkMode()" glyph name="lock-orange-rounded"></app-icon>
          <app-icon *ngIf="colorSchemeService.isDarkMode()" glyph name="lock-white-orange-rounded"></app-icon>
            <span class="text-white text-size-14 mx-2 align-items-center d-flex" [innerText]="getSalesLanguage()">

            </span>
        </div>
        <div class="row pr-2 py-0 m-0">
          <span class="text-white text-size-20 d-flex align-items-center mr-2">{{coursePrice}}</span>
          <a href="{{course.webPurchaseUrl}}"
              target="_blank" class="btn btn-locked btn-round">{{getBuyLinkText()}}</a>
        </div>
      </div>
    </div>
  </div>
