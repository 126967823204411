import {Pipe, PipeTransform} from '@angular/core';
import * as _ from 'underscore';

@Pipe({
  name: 'tagFilter'
})
export class TagFilterPipe implements PipeTransform {

  transform(value: any, tags?: [], showAll?: boolean): any {
    if (showAll) {
      return value;
    }
    return _.chain(value).filter(courseGroup => {
      const intersection = _.intersection(courseGroup['tags'], tags).length;
      return intersection > 0;
    }).value();
  }

}
