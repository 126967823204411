
<div class="login-component login-org" *ngIf="config">

  <nav class="navbar navbar-expand-lg navbar-light navbar">
    <a class="navbar-brand" [href]="school.schoolUrlLink">
      <img [src]="'https://dl.videos.sportys.com/onlinecourse/images/sportys-logo-block-airplane.svg'"
        [ngClass]="{ 'filter-blue-logo': !colorSchemeService.isDarkMode() }">
    </a>
  </nav>
  <div class="container-fluid container-fluid-login">

    <div class="marketing-header-phone row justify-content-md-center">
      <div class="col-md-12">
        <h1>
          <span class="part-1">Sporty's</span>
          <span class="part-2">Pilot Training Online</span>
        </h1>
      </div>
    </div>
    <div class="container-fluid-marketing justify-content-md-center marketing-header-phone">
      <img [src]="[!colorSchemeService.isDarkMode() ? school.primaryImageUrl : school.navBarLogoUrl]">
    </div>
    <div class="login-form-row row justify-content-md-center">
      <div class="col-md-12 col-sm-12">
        <div class="login-form-container card bg-primary">
          <div class="login-form">
            <h1>Log In</h1>
            <div class="login-form_message" [ngClass]="{'populated':message !== ''}">
              {{message}}
            </div>
            <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off" class="login-component-form">
              <div class="form-group">
                <input type="text" class="form-control" formControlName="username" id="username" required
                  autocomplete="false" placeholder="{{loginField}}">
              </div>
              <div class="form-group form-group-icon form-group-icon-primary">
                <div (click)="hidePassword = !hidePassword">
                  <i class="fa fa-eye" *ngIf="hidePassword"></i>
                  <i class="fa fa-eye-slash" *ngIf="!hidePassword"></i>
                </div>
                <input type="{{hidePassword?'password':'text'}}" class="form-control"
                  [ngClass]="{'form-control-dirty': form.get('password').dirty}" formControlName="password"
                  id="password" placeholder="Password" required>
              </div>
              <div class="form-footer">
                <button id="loginButton" [disabled]="working" type="submit"
                  class="btn btn-white btn-round btn-plane btn-w-75" [ngClass]="{'btn-loading': working === true}"
                  [disabled]="!form.touched || form.status === 'INVALID'">Log In
                </button>
              </div>
              <div class="form-footer">
                <div class="text-white text-size-12 px-5">
                  By logging in, you agree to the<br><a class="external-link" target="_blank" href="https://www.sportys.com/terms-of-use">Terms of Use</a> and <a
                    class="external-link" target="_blank" href="https://www.sportys.com/privacy/">Privacy Policy</a>
                </div>
              </div>
            </form>
            <div>

              <div class="container">
                <div class="accordion_container">
                  <input type="checkbox" id="accordion-1" class="accordion-control">
                  <label for="accordion-1" class="accordion-label font-size-16" data-label-closed=""
                    data-label-open="">Need help?</label>
                  <div class="accordion-content">
                    <div class="row">
                      <a class="external-link text-size-14" (click)="resetPassword($event)">- Forgot Password</a>
                    </div>
                    <div class="row">
                      <a class="external-link text-size-14" (click)="magicLink($event)">- Send me a link to login</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="text-white text-size-18 font-weight-bold"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid container-fluid-marketing"
    *ngIf="userMode === 'PAID'">

    <div *ngIf="enableAllContent(school)" class="marketing-header-tablet row justify-content-md-center">
      <div class="col-md-12">
        <h1>
          <span class="part-1">Sporty's</span>
          <span class="part-2">Pilot Training Online</span>
        </h1>
      </div>
    </div>
    <div *ngIf="enableAllContent(school)" class="row justify-content-center">
      <div class="col-md-12">
        <ul class="nav justify-content-center">
          <li class="nav-item">
            Pilot Training
          </li>
          <li class="nav-item">
            Currency
          </li>
          <li class="nav-item">
            Avionics
          </li>
          <li class="nav-item">
            Aircraft Transitions
          </li>
        </ul>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="marketing-content">
          <img [src]="school.primaryImageUrl">
          <p *ngIf="enableAllContent(school) && school.description">{{school.description}}</p>
        </div>
        <div *ngIf="enableAllContent(school)" class="demos-content">
          <a routerLink="/home/learn" class="btn btn-round btn-primary btn-width-fixed-240 btn-plane">All Courses</a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="userMode === 'YE'"
    class="container-fluid container-fluid-marketing d-flex flex-column">


    <div class="ye_header row d-flex">

      <img src="assets/images/Young_Eagles.png" style="max-width:240px; float:left; padding:10px;"
        alt="Young Eagles Logo">
      <h1><span class="part-1">Welcome EAA<br>Young Eagles!</span></h1>
    </div>

    <div class="plate info_plate" style="margin: 20px 0px;">
      <div class="d-flex">
        <div>
          <h2>Free Online Course Access</h2>

          <p>
            Sporty’s is proud to partner with Experimental Aircraft Association’s (EAA) Young Eagles program to offer
            you the “next step” in your aviation training. Access is provided to Sporty's Online Learn to Fly Course –
            ground school and flight training in one complete course. This course will not only prepare you for
            real-world flying, but will also allow you to receive your endorsement to take the FAA written exam.

          </p>
        </div>
        <div>
          <img style="width:100%;" src="http://dl.videos.sportys.com/onlinecourse/images/online_course_panel.jpg">
        </div>
      </div>
    </div>
    <div class="plate">
      <div>
        <h2>Your Young Eagles Flight Plan</h2>
      </div>
      <div class="card-container">
        <div class="card card-skinny">
          <div class="card-header" style="background-image: url('assets/images/ye_step1.png');">

          </div>
          <div class="card-footer" style="height:120px;">
            <h4 class="dark">Free first flight lesson</h4>
            Complete video Volumes 1 - 3 and be eligible to take a free first flight lesson.
          </div>
        </div>
        <!--<div>
          <img src="assets/images/black_arrow.png">
        </div>-->

        <div class="card card-skinny">
          <div class="card-header" style="background-image: url('assets/images/ye_step2.png');">

          </div>
          <div class="card-footer">
            <h4 class="dark">Free FAA written test</h4>
            Request your exam endorsement from the course and get reimbursed the cost of the exam by EAA.
          </div>
        </div>
        <!--
        <div>
          <img src="assets/images/black_arrow.png">
        </div>
        -->
        <div class="card card-skinny">
          <div class="card-header" style="background-image: url('assets/images/ye_step3.png');">
          </div>

          <div class="card-footer">
            <h4 class="dark">Flight Training Scholarships</h4>
            Gain access to scholarships through EAA after completing your course.
          </div>
        </div>
      </div>
    </div>
    <div class="plate additional_resources_plate">
      <div style="width: 100%;">
        <h2>Additional Resources</h2>
      </div>
      <div class="d-flex">
        <div>

          <h3 class="dark">For Young Eagle Parents</h3>

          <ul class="list-group list-group-flush">
            <li class="list-group-item"><a
                href="https://dl.videos.sportys.com/onlinecourse/documents/YoungEagleParents.pdf"
                target="_blank">Message to Parents of Young Eagles
                <app-icon glyph name="next" [disabled]="true"></app-icon>
              </a></li>
            <li class="list-group-item"><a href="http://www.youngeagles.org" target="_blank">About Young Eagles
                <app-icon glyph name="next" [disabled]="true"></app-icon>
              </a>
            </li>
            <li class="list-group-item"><a href="https://flighttrainingcentral.com/" target="_blank">Information on
                Learning to Fly
                <app-icon glyph name="next" [disabled]="true"></app-icon>
              </a>
            </li>
            <li class="list-group-item">
              <a href="https://www.sportys.com/pilotshop/learn-to-fly.html?utm_source=onlinecourse&utm_medium=referral"
                target="_blank">Sporty's Student Pilot Store
                <app-icon glyph name="next" [disabled]="true"></app-icon>
              </a>
            </li>
          </ul>

        </div>
        <div>
          <h3 class="dark">For Young Eagles</h3>
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <a href="https://dl.videos.sportys.com/onlinecourse/documents/YoungEagleFlightInstructors.pdf"
                target="_blank">Message for your Flight Instructor
                <app-icon glyph name="next" [disabled]="true"></app-icon>
              </a>
            </li>
            <li class="list-group-item"><a href="https://flighttrainingcentral.com/" target="_blank">Learn to Fly Here
                <app-icon glyph name="next" [disabled]="true"></app-icon>
              </a>
            </li>
            <li class="list-group-item"><a href="https://flighttrainingcentral.com/flight-school-directory/"
                target="_blank">Find a Flight School
                <app-icon glyph name="next" [disabled]="true"></app-icon>
              </a></li>
            <li class="list-group-item">
              <a href="https://flighttrainingcentral.com/category/get-started/" target="_blank">Sporty's Flight Training Tips
                <app-icon glyph name="next" [disabled]="true"></app-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="" style="clear:both; margin:20px; text-align: center;" *ngIf="userMode === 'PAID'">
      <a class="btn btn-primary" href="https://flighttrainingcentral.com/sportys-learn-to-fly-course/" target="_blank">Get
        the most out of your Learn to Fly Course</a>
    </div>

    <div class="plate features_plate" *ngIf="userMode === 'PAID'">
      <div>
        <h2>Online Course features - The Most Complete Online Pilot Course</h2>
      </div>

      <div class="d-flex align-content-start align-items-start justify-content-start">
        <div style="width:100%;">
          <ul>
            <li>All-new design uses the latest HTML5 technology</li>
            <li>Works anywhere you have a web browser*</li>
            <li>Compatible with Windows, Mac and iPad</li>
            <li>No Software to install – just sign in</li>
            <li>Access to CFI for assistance</li>
          </ul>
        </div>
        <div style="width:50%;">
          <img src="http://dl.videos.sportys.com/onlinecourse/images/sportysyecourse.jpg" alt="New HTML5 Study Buddy"
            style="width:100%;">
        </div>
      </div>

    </div>

    <div style="text-align: center; clear:both;">
      <p>To learn more about learning to fly, please visit
        <a href="https://flighttrainingcentral.com/" target="_blank">www.flighttrainingcentral.com</a>.
      </p>
    </div>
  </div>

</div>
