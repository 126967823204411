import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-tooltip',
  templateUrl: './icon-tooltip.component.html',
  styleUrls: ['./icon-tooltip.component.scss']
})
export class IconTooltipComponent implements OnInit {

  @Input() name: string;

  @Input() class: string;

  @Input() glyph: string;

  @Input() badge: string;

  @Input() radio: string;

  @Input() complete: boolean;

  @Input() disabled: boolean;

  @Input() rotate: string;

  @Input() large: string;

  @Input() border: string;

  @Input() scale = 1;

  @Input() alt: string;

  @Input() tooltip: string;


  constructor() { }

  ngOnInit(): void {
  }

}
