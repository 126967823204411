import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit, OnDestroy {

  video: any;//Video;

  constructor(public bsModalRef: BsModalRef) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

}
