import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IconComponent implements OnInit, OnChanges {

  @Input() name: string;

  @Input() glyph: string;

  @Input() badge: string;

  @Input() radio: string;

  @Input() complete: boolean;

  @Input() disabled: boolean;

  @Input() rotate: string;

  @Input() large: string;

  @Input() border: string;

  @Input() scale = 1;

  @Input() alt: string;

  classes: Array<string> = ['sportys-icon-incomplete'];
  transformation: string;

  display = 'block';

  constructor() {
  }

  ngOnInit(): void {
    //console.log('IconComponent', this.name, this.glyph, this.badge);
    this.buildClasses();
    //console.log('IconComponent', this.classes);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.buildClasses();
  }

  buildClasses() {
    this.display = 'none';
    const temp = [];

    if (typeof this.complete !== 'undefined' && (this.complete === false || this.disabled === true)) {
      temp.push('sportys-icon-incomplete');
    }

    if (this.glyph === '') {
      temp.push('sportys-icon');
    }
    if (this.badge === '') {
      temp.push('sportys-badge');
    }
    if (this.radio === '') {
      temp.push('sportys-radio');
    }
    if (this.border === '') {
      temp.push('border');
    }
    if (this.rotate === '') {
      temp.push('fa-rotate-90');
    }

    temp.push('sportys-' + (this.glyph === '' ? 'glyph-' : '') +
      (this.radio === '' ? 'radio-' : '') +
      (this.badge === '' ? 'badge' : '') +
      ((this.badge === '' && this.large !== '') ? '_mini' : '') +
      (this.badge === '' ? '-v1-' : '') + this.name);

    temp.push('sportys-' + (this.glyph === '' ? 'glyph-' : '') +
      (this.radio === '' ? 'radio-' : '') +
      (this.badge === '' ? 'badge' : '') +
      ((this.badge === '' && this.large !== '') ? '_mini' : '') +
      (this.badge === '' ? '-v1-' : '') + this.name + '-icon');

    this.transformation = `scale(${this.scale}) rotate(${this.rotate === '' ? '90' : '0'})`;

    this.classes = temp;

    this.display = 'block';
  }

}
