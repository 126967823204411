import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {TrainingService} from '../training.service';

@Injectable()
export class AuthGuard {
  config: any;

  constructor(private authService: AuthService,
              private router: Router,
              private trainingService: TrainingService) {
  }

  async canActivate(): Promise<boolean> {
    if(!this.router.navigated === false && !this.authService.deepLinkRoute  && this.router.url.indexOf("courses") > -1) {
      this.authService.deepLinkRoute = this.router.url;
    }

    this.config = await this.trainingService.loadConfig();
    return new Promise((resolve, reject) => {
      console.log('AuthGuard.canActivate');
      return this.authService.renew().then(value => {
        if (this.config['user.mode'] === 'YE') {
          console.log('AuthGuard.canActivate - user.mode === YE')
          //this.router.navigate(['/login']);
          resolve(true);
        } else {
          console.log('AuthGuard.canActivate - user.mode !== YE')
          this.router.navigate(['/home/courses']);
          resolve(false);
        }
      }, (error) => {
        console.log('map error returned from renew');
        resolve(true);
      }).catch(error => {
        resolve(true);
        console.log('catchError error returned from renew');
      });
    });
  }
}
