<div class="modal-header">
  <div class="modal-title">
    <h1>Attention Young Eagle Student</h1>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <app-icon glyph name="incorrect"></app-icon>
  </button>
</div>
<div class="modal-body">
  <div class="attention well" style="text-align:center; font-weight:bold;">
    <p>
      Complimentary access to Sporty’s online Learn to Fly Course for participants in the EAA Young Eagles Program
      expires at age 19.
      To purchase the complete program, please visit <a
      href="https://www.sportys.com/courses?utm_source=onlinecourse&utm_medium=referral">Sporty's</a>.
    </p>
    <br>
    <p>
      For additional assistance, please contact Sporty’s Customer Service at
      <a href="mailto:csmgr@sportys.com">coursesupport&#64;sportys.com</a> or 513-735-9000.
    </p>

  </div>
</div>
<div class="modal-footer" style="justify-content: center">
  <button class="btn btn-primary btn-width-fixed-240" (click)="bsModalRef.hide()">OK</button>
</div>
