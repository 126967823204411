import { AfterContentInit, AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { BaseChartDirective } from 'ng2-charts';
import { ChartConfiguration, Color } from 'chart.js';
import { scaleLinear } from 'd3-scale';
import { ColorSchemeService } from 'src/app/services/color-scheme.service';
import { size } from 'underscore';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})
export class DoughnutChartComponent implements OnInit, OnChanges, AfterContentInit, AfterViewInit {

  @Input()
  public percent: number;

  @Input()
  public gradient = false;

  @Input()
  public innerLabel = false;

  @Input()
  public rightLabel = false;

  @Input()
  public cutout = 50;

  @Input()
  public passingGrade = 70;

  @Input()
  public height = 300;

  @Input()
  public width = 100;

  @Input()
  public passed = true;

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  public doughnutChartLabels: string[] = null;
  public doughnutChartData: ChartConfiguration<'doughnut'>['data']['datasets'] = [];

  public doughnutColors: Color[] = null;

  public doughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = null;

  public doughnutChartLegend = false;

  public doughnutChartPlugins: ChartConfiguration<'doughnut'>['plugins'] = null;

  centerX = 0;
  centerY = 0;
  font = '';
  fillStyle = '';
  isDarkModeOn = false;

  constructor(private e: ElementRef, private colorSchemeService: ColorSchemeService) {
  }

  ngOnInit() {
    console.debug('DoughnutChartComponent:ngOnInit');
    this.isDarkModeOn = this.colorSchemeService.isDarkMode();
  }

  ngAfterViewInit() {
    console.debug('DoughnutChartComponent:ngAfterViewInit');
  }

  ngAfterContentInit() {
    console.debug('DoughnutChartComponent:ngAfterContentInit');

  }

  ngOnChanges(changes: SimpleChanges): void {
    console.debug('DoughnutChartComponent:ngOnChanges');
    this.init();
    //this.chart.ngOnChanges({});
  }

  init() {
    let result = ['#61b203', '#F0F0F0'];

    if (this.gradient) {
      const range = [50, 75, 100];

      const colorOptions = ['#d7191c', '#efef5d', '#61b203'];
      const colorScale = scaleLinear(range, colorOptions);

      const color = colorScale(this.percent);

      result = [color, '#F0F0F0'];
    } else {
      if (Math.round(this.percent) < this.passingGrade || !this.passed) {
        result = ['#d7191c', '#F0F0F0'];
      }
    }

    /*
    this.doughnutColors = [
      {
        borderColor: 'white',
        borderWidth: 2,
        backgroundColor: result,
      }
    ];*/


    this.doughnutChartData = [{
      data: [this.percent, 100 - this.percent],
      borderColor: ['transparent'],
      borderWidth: [2, 2],
      backgroundColor: result,
      hoverBackgroundColor: result,
      hoverBorderColor: result
    }];

    this.doughnutChartOptions = {
      responsive: true,
      aspectRatio: 1,
      maintainAspectRatio: false,
      cutout: this.cutout,
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              const label = context.label || '';
              const value = context.parsed || 0;
              const roundedValue = Math.round(value);
              return `${label}: ${roundedValue}%`;
            },
          },
          backgroundColor: 'black',
          bodyColor: 'white'
        },
      },
    };

  }

}
