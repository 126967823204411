import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

//import videojs from 'video.js';
//import * as HLS from '@videojs/http-streaming';
//import * as vttThumbnails from 'videojs-vtt-thumbnails';
import * as _ from 'underscore';
import {Observable, Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute} from '@angular/router';
import {HelpDataService} from 'src/app/services/help-data.service';
import {VideosService} from 'src/app/course/services/videos.service';
import {AuthContextService} from '../../services/auth-context.service';

/*
import * as hlsQualitySelector from 'videojs-hls-quality-selector';
import * as qualityLevels from 'videojs-contrib-quality-levels';
*/


@Component({
  selector: 'app-videojs',
  template: `
    <div>
      <video #target class="video-js video-js vjs-big-play-centered vjs-fluid vjs-default-skin"
             controls playsinline preload="none" crossorigin='anonymous'></video>
    </div>
  `,
  styleUrls: ['./videojs.component.scss']
})
export class VideojsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('target', {static: true}) target: ElementRef;
  // see options: https://github.com/videojs/video.js/blob/mastertutorial-options.html
  @Input() options: {
    fluid?: boolean,
    aspectRatio?: string,
    autoplay: boolean,
    poster: string,
    controls: boolean,
    is360: boolean,
    sources: {
      src: string,
      type: string,
    }[],
  };

  @Input() doPlay: Observable<any>;
  @Input() doGoTo: Observable<any>;
  @Input() doPause: Observable<any>;

  @Input()
  reviewTimeMillis: number;

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onPlay: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onComplete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onTime: EventEmitter<any> = new EventEmitter();

  @Output()
  on10SecondsLeft: EventEmitter<any> = new EventEmitter();

  @Output()
  on20SecondsLeft: EventEmitter<any> = new EventEmitter();

  tenSecondTimerFired = false;
  twentySecondTimerFired = false;

  player: any; //videojs.Player;
  fireOnPlayedAgain = true;
  queryParamsSub: Subscription;
  video: any;
  previousTrack: TextTrack | null = null;

  labels = {
    DEFAULT: [
      {height: 2160, bitrate: 16000000, label: '2160p 4K', visible: true},
      {height: 1440, bitrate: 12000000, label: '1440p 2K', visible: true},
      {height: 1080, bitrate: 8000000, label: '1080p HD', visible: true},
      {height: 810, bitrate: 3000000, label: 'best', visible: false},
      {height: 720, bitrate: 2200000, label: '720p', visible: true},
      {height: 540, bitrate: 1800000, label: 'good', visible: false},
      {height: 480, bitrate: 1400000, label: '480p', visible: true},
      {height: 360, bitrate: 1000000, label: '360p', visible: true}
    ],
    360: [
      {height: 2688, bitrate: 9000000, label: 'High (5K)', visible: true},
      {height: 1920, bitrate: 7500000, label: 'High (4K)', visible: false},
      {height: 1024, bitrate: 5000000, label: 'Medium (HD)', visible: true},
      {height: 960, bitrate: 3000000, label: 'Good', visible: false},
      {height: 640, bitrate: 1500000, label: 'Low (SD)', visible: true}
    ]
  };

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    private helpDataService: HelpDataService,
    private videosService: VideosService,
    private authContextService: AuthContextService
  ) {
    this.video = this.route.snapshot.data.video;
  }

  initVideo(options) {

    const scope = this;

    this.fireOnPlayedAgain = true;
    //let x = HLS;
    //let y = vttThumbnails;
    //let z = qualityLevels;
    //let a = hlsQualitySelector;
    console.log('VideojsComponent:initVideo', options);

    options = _.extend(options, {
      controls: true,
      autoplay: false,
      preload: 'none',
      responsive: true,
      fluid: true,
      playbackRates: [0.5, 1, 1.5, 2]
    });

    localStorage.setItem('videoJsSpaceBarPause', 'true');

    this.player = window['videojs'](this.target.nativeElement, options, function() {
      //var volume = this.cookieService.cookie('_player_volume');

      if (scope.cookieService.check('_player_volume')) {
        const playerVolume = scope.cookieService.get('_player_volume');
        const playerVolumeFloat = parseFloat(playerVolume);
        console.log('VideojsComponent:playerVolume:', playerVolume, playerVolumeFloat);
        this.volume(playerVolumeFloat);
      } else {
        console.log('VideojsComponent:playerVolume:not saved');
      }

      console.log('videojs:onPlayerReady', this);
      //this.nuevo({
      //  hdicon: false,
      //  hls: false
      //});

      let vttUrl = options.sources[0].src.replace('.m3u8', '_thumbs.vtt');
      vttUrl = vttUrl.replace('.mp4', '_thumbs.vtt');

      console.log('videojs:vttUrl', vttUrl);
      if (_.isFunction(this.vttThumbnails)) {
        scope.httpClient.head(vttUrl).subscribe(value => {
          console.log('VideojsComponent:vttUrl', value);
          this.vttThumbnails({
            src: vttUrl
            //src: 'https://raw.githubusercontent.com/chrisboustead/videojs-vtt-thumbnails/master/example/thumbs.vtt'
          });
        }, error => {
          console.log('VideojsComponent:vttUrl:error', error);
        });

      } else {
        scope.httpClient.head(vttUrl).subscribe(value => {
          this.vttThumbnails.src(vttUrl);
        }, error => {
          console.log('VideojsComponent:vttUrl:error', error);
        });
      }

      //this.airPlay();
      //this.chromecast();
      //this.qualityLevels();
      //this.hlsQualitySelector();


      const settings = this.textTrackSettings;
      settings.setValues({
        'backgroundColor': '#000',
        'backgroundOpacity': '0',
        'edgeStyle': 'uniform',
      });
      settings.updateDisplay();
      this.seekButtons({
        forward: 30,
        back: 10
      });

      this.airPlay();
      this.chromecast();

      this.hlsQualitySelector({
        vjsIconClass: 'vjs-icon-cog',
        displayCurrentQuality: false,
        labels: scope.labels[options.is360 ? '360' : 'DEFAULT']
      });

      const videoTrackList = this.videoTracks();
      console.log('videojs:videoTrackList', videoTrackList);
      // Listen to the "change" event.
      videoTrackList.addEventListener('change', () => {

        console.log('videojs:change', videoTrackList);
        // Log the currently enabled VideoTrack label.
        for (let i = 0; i < videoTrackList.length; i++) {
          const track = videoTrackList[i];
          if (track.enabled) {
            window['videojs'].log(track.label);
            return;
          }
        }
      });

      const track = new window['videojs'].VideoTrack({
        id: 'my-alternate-video-track',
        kind: 'subtitles',
        label: 'Director\'s Commentary',
        language: 'en',
        mode: 'showing'
      });

      // Add the track to the player's video track list.
      videoTrackList.addTrack(track);

      this.on('play', scope.play);
      this.on('ended', scope.complete);
      this.on('timeupdate', scope.time);
      this.on('volumechange', scope.volume);
      this.on('fullscreenchange', scope.fullscreen);
      this.on('enterpictureinpicture', scope.fullscreen);

      this.on('loadedmetadata', function() {
        console.log('on:loadedmetadata');
        if (localStorage.getItem('videoJsTextTrack') === 'true') {
          scope.loadedmetadata.apply(this);
        }
        if (scope.onLoad) {
          scope.onLoad.emit({video: this.player});
        }

        if (scope.options.is360 === true) {
          const tech = this.tech();
          console.log('tech before:', tech);
          if (tech.hls) {
            const reps = tech.hls.representations();
            console.log('reps before:', reps);
            const max = _.max(reps, (row) => {
              return row.bandwidth;
            });
            reps.forEach((rep) => {
              if (rep.bandwidth === max.bandwidth) {
                rep.enabled(true);
              } else if (rep.height >= 1024) {

              } else {
                rep.enabled(false);
              }
            });
            console.log('reps after:', reps);
          }
        }
      });
      this.on('loadstart', scope.loadstart);
      this.on('ready', () => {
        console.log('ready');
      });

      this.hotkeys({
        volumeStep: 0.1,
        seekStep: 5,
        enableInactiveFocus: false,
        enableModifiersForNumbers: false,
        // documentHotkeysFocusElementFilter: e => e.tagName.toLowerCase() === 'body',
        captureDocumentHotkeys: false,
        alwaysCaptureHotkeys: true,
      });

      this.qualityLevels().on('addqualitylevel', function(value) {
        console.log('addqualitylevel', value);
      });
      this.on('nuevoReady', function() {
        console.log('nuevoReady');
      });

      let keydown = function(event) {
        //if space pause the player

        if (!scope.helpDataService.helpOpened()) {
          if (event.code === 'KeyM') {
            scope.player.muted(false);
          }

          if (
            event.view.localStorage.videoJsSpaceBarPause === 'true' &&
            event.code === 'Space'
          ) {

            event.preventDefault();

            if (scope.player.paused()) {
              scope.player.play();
            } else {
              scope.player.pause();
            }
          }
        }
      };
      window.document.addEventListener('keydown', keydown);

    });

    if (options.is360) {
      this.player.mediainfo = this.player.mediainfo || {};
      this.player.mediainfo.projection = '360_CUBE';
      this.player.vr({projection: '360', debug: true, forceCardboard: false});
    }


  }

  updateVideo(options) {
    const currentPlaybackRate = this.player.playbackRate();
    const currentVolume = this.player.volume();
    let currentCaptions = this.player.textTracks();
    let currentCaption;

    this.player.src([options.sources]);
    this.player.poster(options.poster);
    //this.fireOnPlayedAgain = false;

    localStorage.setItem('videoJsTextTrack', 'false');

    for (let i = 0; i < currentCaptions.length; i++) {
      if (currentCaptions[i].mode === 'showing') {
        currentCaption = currentCaptions[i];
        break;
      }
    }

    this.player.ready(() => {
      this.player.playbackRate(currentPlaybackRate);
      this.player.volume(currentVolume);
      this.player.textTracks(currentCaption);
    });

    console.log('updateVideo:isInPictureInPicture', this.player.isInPictureInPicture());

    this.player = window['videojs'](this.target.nativeElement, options, function() {
      //var volume = this.cookieService.cookie('_player_volume');

      let vttUrl = options.sources[0].src.replace('.m3u8', '_thumbs.vtt');
      vttUrl = vttUrl.replace('.mp4', '_thumbs.vtt');

      this.vttThumbnails.src(vttUrl);
    });

    if (this.player.isInPictureInPicture()) {
      this.fullscreen({});
    }
  }

  ngOnInit() {
    // instantiate Video.js
    localStorage.setItem('videoJsTextTrack', 'true');
    console.log('VideojsComponent:ngOnInit', this.options);

    if (this.authContextService.user) {
      this.videosService.loadProgress(this.video.uuid)
        .subscribe((value) => {
          this.player.currentTime(value.progress);
        });
    } else {
      this.player.currentTime(0); // video starts from the beginning
    }

    this.player.on('play', this.onPlayHandler);
    this.player.on('pause', this.onPauseHandler);
    this.player.textTracks().addEventListener('change', (event) => {

      //const track = event.track;
      //const isEnabled = track.mode == "showing";
      const activeTracks = this.player.textTracks().tracks_.filter(track => track.mode === 'showing');
      console.log(activeTracks);
      this.saveCaptionSettings(activeTracks);
    });


    if (this.doPause) {
      this.doPause.subscribe(value => {
        console.log('VideojsComponent:doPause');
        this.player.pause();
      });
    }
    if (this.doPlay) {
      this.doPlay.subscribe(value => {
        console.log('VideojsComponent:doPlay');
        this.player.play();
      });
    }
    if (this.doGoTo) {
      this.doGoTo.subscribe(value => {
        console.log('VideojsComponent:doGoTo');
        this.player.currentTime(value / 1000);
        this.player.play();
      });
    }

    this.setPlayerCurrentTimeFromQueryParams();
  }

  ngOnDestroy() {
    // destroy player
    console.log('VideojsComponent:ngOnDestroy');
    localStorage.setItem('videoJsSpaceBarPause', 'false');

    const position = this.player.currentTime();
    const duration = this.player.duration();

    if (!!this.video?.uuid) {
      this.videosService.saveProgress(
        this.video.uuid,
        position == duration ? 0 : position
      );
    }

    if (this.player) {
      this.player.dispose();
    }

    this.queryParamsSub?.unsubscribe();
  }

  checkCaptionChange() {
    console.log('checkCaptionChange');
    setTimeout(() => {
      //const savedCaption = localStorage.getItem('selectedCaption');
      //const tracks = this.player.textTracks();
      //for (let i = 0; i < tracks.length; i++) {
      //  const track = tracks[i];
      //  track.mode = 'disabled';
      //}
      /*
      if (savedCaption) {
        const selectedCaption = JSON.parse(savedCaption);
        for (let i = 0; i < tracks.length; i++) {
          const track = tracks[i];
          if (
            track.label === selectedCaption.label &&
            track.language === selectedCaption.language &&
            selectedCaption.mode === 'showing'
          ) {
            track.mode = selectedCaption.mode;
            break;
          }
        }
      }*/
    }, 1000);

  }

  applyCaptionSettings() {
    console.log('applyCaptionSettings');
    const savedCaption = localStorage.getItem('selectedCaption');
    const tracks = this.player.textTracks();
    for (let i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      track.mode = 'disabled';
    }
    if (savedCaption) {
      const selectedCaption = JSON.parse(savedCaption) as  any[];
      for (let i = 0; i < tracks.length; i++) {
        const track = tracks[i];
        const foundCaption = selectedCaption.filter((caption) => {
          return (
            track.label === caption.label &&
            track.language === caption.language &&
            caption.mode === 'showing'
          );
        });
        if(foundCaption.length > 0) {
          track.mode = "showing";
        }
      }
    }
  }

  saveCaptionSettings(activeTracks: TextTrack[] | null) {
    console.log('saveCaptionSettings');
    if (activeTracks) {
      console.log('saveCaptionSettings', activeTracks);

      localStorage.setItem('selectedCaption', JSON.stringify(activeTracks.map(track => ({
        label: track.label,
        mode: track.mode,
        language: track.language,
        kind: track.kind,
        id: track.id
      }))));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('VideojsComponent:ngOnChanges', changes);

    if (this.player && changes['reviewTimeMillis'] && changes['reviewTimeMillis'].currentValue > 0) {
      console.log('VideojsComponent:ngOnChanges', changes['reviewTimeMillis'].currentValue);
      this.player.currentTime(changes['reviewTimeMillis'].currentValue / 1000);
    } else if (this.player && changes['options']) {
      console.log('VideojsComponent:ngOnChanges:playing new video');

      this.tenSecondTimerFired = false;
      this.twentySecondTimerFired = false;
      this.fireOnPlayedAgain = true;

      this.updateVideo(changes['options'].currentValue);

    } else if (changes['options']) {
      this.initVideo(changes['options'].currentValue);
    }
    //this.initVideo(changes['reviewTimeMillis'].currentValue);
  }

  private loadedmetadata = function() {
    console.log('loadedmetadata');
    localStorage.setItem('videoJsSpaceBarPause', 'true');
  };

  private loadstart = function() {
    console.log('loadstart');
    const tracks = this.textTracks();
    for (var i = 0; i < tracks.length; i++) {
      const track = tracks[i];
      console.log('loadstart track:', track);
    }

  };

  private play = (event) => {
    this.checkCaptionChange();
    //this.fireOnPlayedAgain = true;
    if (this.onPlay) {
      this.onPlay.emit({video: this.player, event: event});
    }
  };

  private complete = (event) => {
    if (this.onComplete && this.fireOnPlayedAgain) {
      console.log('Fire oncomplete from complete', event);
      this.fireOnPlayedAgain = false;
      this.onComplete.emit({video: this.player, event: event});
    }
  };

  private time = (event) => {
    //console.log('time', event);
    if (this.onTime) {
      this.onTime.emit({
        video: this.player,
        event: event,
        position: this.player.currentTime(),
      });
    }

    const duration = this.player.duration();

    const position = this.player.currentTime();
    //console.log('duration', duration);
    //console.log('position', position);

    this.videosService.saveProgress(this.video.uuid, position);

    const triggerTime = duration - 8;

    if (this.onComplete && this.fireOnPlayedAgain && triggerTime < position) {
      console.log('Fire oncomplete from time');
      this.fireOnPlayedAgain = false;
      this.onComplete.emit({
        video: this.player,
        event: event
      });
    }

    if (this.on20SecondsLeft && (duration - position < 20) && !this.twentySecondTimerFired) {
      console.log('Fire on20SecondsLeft');
      this.twentySecondTimerFired = true;
      this.on20SecondsLeft.emit({
        video: this.player,
        event: event
      });
    }

    if (this.on10SecondsLeft && (duration - position < 10) && !this.tenSecondTimerFired) {
      console.log('Fire on10SecondsLeft');
      this.tenSecondTimerFired = true;
      this.on10SecondsLeft.emit({
        video: this.player,
        event: event
      });
    }
  };

  private fullscreen = (event) => {
    console.log('VideojsComponent:fullscreenchange', event);
    this.fireOnPlayedAgain = false;
    this.onComplete.emit({
      video: this.player,
      event: event
    });
  };

  private onPlayHandler = (event) => {
    const position = this.player.currentTime();
    this.applyCaptionSettings();
    this.videosService.saveProgress(this.video.uuid, position);
  };

  private onPauseHandler = (event) => {
    const position = this.player.currentTime();
    this.applyCaptionSettings();
    this.videosService.saveProgress(this.video.uuid, position);
  };

  private volume = (event) => {
    //const el = $(this.controlBar.volumeMenuButton.volumeBar.volumeLevel.el());
    const volume = this.player.volume();
    //const level = (el.width() / el.offsetParent().width()) * 100;
    console.log('VideojsComponent:save playerVolume:' + volume);
    this.cookieService.set('_player_volume', volume, {expires: 2, path: '/'});
  };

  private setPlayerCurrentTimeFromQueryParams() {
    this.queryParamsSub = this.route.queryParams.subscribe(params => {
      if (params?.t) {
        const seconds = params.t.split('s')[0];
        this.player.currentTime(Number(seconds));
        this.player.play();
      }
    });
  }
}
