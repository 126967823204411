import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TrainingService} from './training.service';
import {Angulartics2} from "angulartics2";
import {firstValueFrom} from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private httpClient: HttpClient,
    private trainingService: TrainingService,
    private angulartics: Angulartics2,
  ) {
  }

  async uploadUserThumbnail(file: File) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const api = await firstValueFrom(this.trainingService.load());
    return firstValueFrom(this.httpClient.post(api.getRoute('thumbnailUploadUrl'), formData));
  }

  async updateProfile(profile: {
    firstName: string,
    lastName: string,
    address: string,
    city: string,
    state: string,
    zip: string,
    email: string,
    phone: string,
    hasCfiCertificate: boolean,
    isGroundInstructor: boolean,
    cfiCertificateNumber: string,
    cfiExpirationDate: string,
  }) {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe((api) => {

        this.httpClient.put(api.getRoute('updateProfileUrl'), profile).subscribe(user => {

          this.angulartics.setUserProperties.next({
            id: user['id'],
            sportysId: user['sportysId'],
            email: user['email'],
            lastName: user['lastName'],
            firstName: user['firstName'],
            validCfi: user['isValidCfi']
          });

          resolve(user);
        }, error => {
          reject(error);
        });
      });
    });
  }


}
