<nav class="navbar navbar-expand-lg fixed-top navbar-dark"
     [ngClass]="{'bg-cfi': authContextService.isValidCFIUser(),'bg-primary':!authContextService.isValidCFIUser()}">
  <div class="d-flex justify-content-start align-items-center">
    <a class="navbar-brand nd-block d-sm-none navbar-toggler left-hamburger-menu" (click)="toggleSidenav()">
      <span class="navbar-toggler-icon"></span>
    </a>
    <div><app-brand-container></app-brand-container></div>
    <div *ngIf="authContextService.isCFIUser() && !isCfiChief" class="tagline d-md-block d-none">Instructor Portal</div>
    <div *ngIf="authContextService.isCFIUser() && isCfiChief" class="tagline d-md-block d-none">Chief Instructor Portal</div>
  </div>

  <div class="d-flex justify-content-end">
    <button class="navbar-toggler " type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            [ngClass]="{'open': !collapsed}" (click)="toggleCollapsed()">
      <i class="fa fa-ellipsis-v"></i>
    </button>
    <app-user-info class="d-block d-lg-none" *ngIf="authContextService.user" [user]="authContextService.user" [course]="{ webAppId: 'FIRC_2023' }">
    </app-user-info>
  </div>
  <div class="navbar-collapse collapse show" [ngClass]="{'in': !collapsed}" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

    </ul>
    <ul class="navbar-nav float-lg-right">
      <li class="nav-item d-lg-none d-xl-none">
        <a class="nav-link" routerLink="/home/news" routerLinkActive="active">Sporty's News</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/home/courses" routerLinkActive="active">My Courses</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/home/learn" routerLinkActive="active">All Courses</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/home/learn-to-fly" routerLinkActive="active">Learn To Fly</a>
      </li>
      <li class="nav-item courier-inbox">
        <courier-toast></courier-toast>
        <courier-inbox></courier-inbox>
      </li>
      <app-user-info class="d-none d-lg-block" *ngIf="authContextService.user" [user]="authContextService.user" [course]="{ webAppId: 'FIRC_2023' }">
      </app-user-info>
      <li class="nav-item" *ngIf="!authContextService.user">
        <a class="nav-link btn btn-white" (click)="login()">Log In</a>
      </li>
    </ul>
  </div>
</nav>
<div class="cfi-body">
  <div class="sidenav-background" [ngClass]="{'sidenav-open':sidenav}" (click)="sidenav = false;">
  </div>
  <div class="sidenav d-none d-md-block" [ngClass]="{'sidenav_collapsed': !sidenav, 'sidenav-expanded': sidenav}">
    <app-sidenav-generic [backLink]="sidenavBacklink" [links]="sidenavLinks" (sidenavClicked)="sidenavClick()">
      <div class="cert-info" aboveNav *ngIf="authContextService.isCFIUser()">
        <ng-container *ngIf="authContextService.isValidCfiUser$ | async as isValidCfiUser; else noCFICertificate">
          <div class="d-flex cfi-expires-label">
            <app-icon *ngIf="isValidCfiUser" glyph name="correct" scale="0.7">
            </app-icon>
            <app-icon *ngIf="!isValidCfiUser" glyph name="incorrect" scale="0.7">
            </app-icon>
            <span class="cert-info--exists">CFI Expires {{authContextService.user.cfiExpirationDate |
              date:'mediumDate'}}</span>
          </div>
          <ng-container *ngIf="daysToCfiRenew$ | async as daysToCfiRenew; else zeroDaysToCfiRenew">
            <div class="alert alert-danger" *ngIf="daysToCfiRenew < 91">
              <ng-container *ngIf="daysToCfiRenew < 0; else positiveNrOfDaysToRenew">
                <span class="">Please update your CFI expiration date</span>
              </ng-container>
              <ng-template #positiveNrOfDaysToRenew>
                <span class="">Your CFI certificate will expire in {{daysToCfiRenew}} days</span>
              </ng-template>
              <div (click)="completeProfile()" class="font-weight-bold primary_blue cursor-pointer">
                Update expiration date
              </div>
              <div (click)="renewFree()" class="font-weight-bold primary_blue cursor-pointer">
                Renew free with Sporty’s FIRC
              </div>
            </div>
          </ng-container>
          <ng-template #zeroDaysToCfiRenew>
            <div class="alert alert-danger">
              <span class="">Your CFI certificate will expire today</span>
              <div (click)="completeProfile()" class="font-weight-bold primary_blue cursor-pointer">
                Update expiration date
              </div>
              <div (click)="renewFree()" class="font-weight-bold primary_blue cursor-pointer">
                Renew free with Sporty’s FIRC
              </div>
            </div>
          </ng-template>
        </ng-container>
      </div>
      <div class="cert-info" aboveNav
           *ngIf="authContextService.isLoggedIn() && !authContextService.isCFIUser() && !authContextService.accountIsNew && !authContextService.showSignupForm">
        <div class="plate gray_accent1 px-0 py-3">
          <div class="d-flex flex-row justify-content-between align-items-center">
            <app-icon glyph name="info" class="p-3"></app-icon>
            <div>
              You have no CFI credentials saved. <br/>
              <div (click)="completeProfile()" class="font-weight-bold primary_blue cursor-pointer">
                Complete Profile
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-sidenav-generic>
  </div>

  <div class="cfi-content w-100">
    <router-outlet></router-outlet>
  </div>

  <app-help class="help" [courseId]="'CFI_PORTAL'"></app-help>
</div>

<ng-template #noCFICertificate>
  <div *ngIf="!authContextService.accountIsNew && !authContextService.showSignupForm" class="plate gray_accent1 px-0 py-3">
    <div class="d-flex flex-row justify-content-between align-items-center">
      <app-icon glyph name="info" class="p-3"></app-icon>
      <div>
        You have no CFI credentials saved. <br/>
        <div (click)="completeProfile()" class="font-weight-bold primary_blue cursor-pointer">Complete Profile
        </div>
      </div>
    </div>
  </div>
</ng-template>
