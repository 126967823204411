import { Component, ElementRef, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'underscore';
import { HttpClient, HttpParams } from '@angular/common/http';
import { TrainingService } from '../../training.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger, useAnimation } from '@angular/animations';
import { useSlideFadeOutAnimation } from '../../ngx-animations/animations';
import { Analytics } from '../../name.annotation';
import { VolumesService } from '../../course/services/volumes.service';

@Component({
  selector: 'app-question-search',
  templateUrl: './question-search.component.html',
  styleUrls: ['./question-search.component.scss'],

  animations: [
    // the fade-in/fade-out animation.
    trigger('simpleFadeAnimation', [

      // the "in" style determines the "resting" state of the element when it is visible.
      state('in', style({ opacity: 1 })),

      // fade in when created. this could also be written as transition('void => *')
      transition(':enter', [
        style({ opacity: 0 }),
        animate(200)
      ]),

      // fade out when destroyed. this could also be written as transition('void => *')
      transition(':leave',
        animate(200, style({ opacity: 0 })))
    ])
  ]
})
@Analytics('question_search')
export class QuestionSearchComponent implements OnInit {

  course: any;

  totalResults: 0;
  results: any = [];

  size = 8;
  page = 0;
  pages = [];

  parser = new DOMParser();

  form = new UntypedFormGroup({
    keyword: new UntypedFormControl()
  });

  doSearch = (term, page, size) => {
    console.log('search', term);
    this.results = [];
    window.setTimeout(() => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.get(api.getRoute('searchQuestionsUrl'), {
          params: new HttpParams()
            .set('courseType', this.course.webAppId)
            .set('term', term)
            .set('page', page.toString())
            .set('size', size.toString())
            .set('categoryType', 'PREP')
        }).subscribe(value => {

          _.each(value['results'], (question) => {
            question['sentences'] = this.sentences(question['attributes'].synopsis);
          });

          this.results = value['results'];
          this.totalResults = value['totalElements'];
          this.pages = Array(Math.ceil(this.totalResults / size));
        });
      });
    }, 250);
  };

  search = _.debounce(async (term, page, size) => {
    this.doSearch(term, page, size);
  }, 500);

  constructor(
    private el: ElementRef,
    private router: Router,
    public bsModalRef: BsModalRef,
    private httpClient: HttpClient,
    private trainingService: TrainingService
  ) {

  }


  sentences(content) {
    if (content != null) {
      const lower = content.toLowerCase();
      if (lower.indexOf('<html') > -1 || lower.indexOf('<body') > -1) {
        const doc = this.parser.parseFromString(content, 'text/html');
        const p = doc.querySelector('p');
        if (p) {
          return p.innerHTML;
        } else {
          return 'NOPE';
        }
      } else {
        return content;
      }
    } else {
      return '';
    }
  }

  ngOnInit() {
    window.setTimeout(() => {
      const input = this.el.nativeElement.querySelector('input');

      if (input) {
        input.focus();
      }
    });
  }

  trackQuestionBy(index, item) {
    return item.uuid;
  }

  //navigate(question) {
  //  this.bsModalRef.hide();
  //  this.router.navigate(['/', 'course', this.course.webAppId, 'volumes', question.volumeUuid, question.uuid]);
  //}

  prev() {
    if (this.page > 0) {
      this.page = this.page - 1;
      this.doSearch(this.form.get('keyword').value, this.page, this.size);
    }
  }

  load(page) {
    this.page = page;
    this.doSearch(this.form.get('keyword').value, this.page, this.size);
  }

  next() {
    if (this.page < this.pages.length) {
      this.page = this.page + 1;
      this.doSearch(this.form.get('keyword').value, this.page + 1, this.size);
    }
  }
}
