import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-icon-with-title',
  templateUrl: './icon-with-title.component.html',
  styleUrls: ['./icon-with-title.component.scss']
})
export class IconWithTitleComponent implements OnInit {

  @Input() name: string;

  @Input() title: string;

  @Input() glyph: string;

  @Input() badge: string;

  @Input() class: string;

  @Input() radio: string;

  @Input() complete: boolean;

  @Input() disabled: boolean;

  @Input() rotate: string;

  @Input() large: string;

  @Input() border: string;

  @Input() scale = 1;

  @Input() alt: string;

  @Input() link: string;

  @Input() internalLink: boolean;

  @Input() target: string;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goToInternalLink(): void {
    if (this.internalLink) {
      this.router.navigate([this.link])
    }
  }

}
