import {Injectable} from '@angular/core';
import * as _ from 'underscore';
import {TestsService} from './tests.service';
import {Observable, ReplaySubject} from 'rxjs';
import {CountdownComponent} from 'ngx-countdown';
import {TestType} from '../tests/enum/test-type.enum';
import {QuestionHistory} from '../tests/question-history/question-history';
import { QuestionDetailsService } from 'src/app/services/question-details.service';
import { QuestionMarked } from '../../course/tests/testprep/questions/question-marked.model';
import { PingService } from 'src/app/services/ping.service';
import { TestAnswer } from '../tests/testprep/test/test-answer.model';

@Injectable({
  providedIn: 'root'
})
export class TestSessionService implements QuestionHistory {

  test: any;
  testQuestions: any;
  q: any;
  testResults: any;
  countdown: CountdownComponent;
  questionHistory = [];
  dirty = false;
  markedQuestions: QuestionMarked[] = [];
  testAnswers: TestAnswer[] = [];

  remainingTimeProvider = () => {
    return this.test.remainingTimeMillis;
  };

  private dataObs$ = new ReplaySubject(1);

  constructor(
    private testsService: TestsService,
    private pingService: PingService,
    private questionDetailsService: QuestionDetailsService
  ) {
  }

  async setTest(test: any) {
    if (test.testResult) {
      this.test = test.testResult;
    } else {
      this.test = test;
    }
    if (this.test && !this.test.questions) {
      this.test.questions = test.questions;
    }

    // find first unanswered question
    this.q = _.findWhere(test.questions, {userAnswer: null});
    if (this.q == null) {
      this.q = test.questions[0];
    }
    this.testResults = await this.grade();
    this.questionHistory = [];
  }


  registerRemainingTimeProvider(provider) {
    this.remainingTimeProvider = provider;
  }

  indexOf(question): number {
    return this.test.questions.indexOf(question);
  }

  isGraded(): boolean {
    return this.test.endTime != null;
  }

  isLearning(): boolean {
    return this.test.testType === TestType.LEARN;
  }

  isFlashcard(): boolean {
    return this.test.testType === TestType.FLASH;
  }

  isTest(): boolean {
    return this.test.testType === TestType.TEST;
  }


  selectedAnswer(question): any {
    if (question.userAnswer) {
      return _.find(question.answers, {id: question.userAnswer});
    } else {
      return null;
    }
  }

  correctAnswer(question): any {
    return _.findWhere(question.answers, {correct: true});
  }

  record(q) {

    if (q.correct != null) {
      if (this.questionHistory.indexOf(q) >= 0) {
        this.questionHistory.splice(this.questionHistory.indexOf(q), 1);
      }
      this.questionHistory.unshift(q);
      this.questionHistory.splice(5);
    }
  }

  hasPrev() {
    return this.test.questions.indexOf(this.q) > 0;
  }

  prev() {
    this.record(this.q);
    if (this.test.questions.indexOf(this.q) > 0) {
      this.q = this.test.questions[this.test.questions.indexOf(this.q) - 1];
      this.dataObs$.next(this.q);
    }
  }

  hasNext() {
    return this.test.questions.indexOf(this.q) < this.test.questions.length - 1;
  }

  next() {
    this.record(this.q);

    if (this.test.questions.indexOf(this.q) < this.test.questions.length) {
      this.q = this.test.questions[this.test.questions.indexOf(this.q) + 1];
      this.dataObs$.next(this.q);
    }
  }

  select(question) {
    this.record(this.q);
    this.q = question;
    this.dataObs$.next(this.q);
  }

  selectAnswer(question, answerId) {
    if (!this.isGraded()) {
      question.userAnswer = answerId;
    }
  }

  async ping() {
    try {
      const result = await this.pingService.ping();
      return result;
    } catch (error) {
      return error;
    }
  }

  async mark(question) {
    question.marked = !question.marked;
    const markedQuestion: QuestionMarked = {
      questionUuid: question.uuid,
      mark: question.marked
    };

    try {
      const existingIndex = this.markedQuestions.findIndex(q => q.questionUuid === question.uuid);
      if (existingIndex !== -1) {
        this.markedQuestions.splice(existingIndex,1);
      }
      else {
        this.markedQuestions.push(markedQuestion);
      }
      const result = await this.ping();

      if (result == 200) {
        this.questionDetailsService.markQuestion(this.markedQuestions).subscribe(() => {
          this.markedQuestions = [];
        });
      } else {
        console.error('Error while pinging:', result.message);
        return;
      }
    } catch (error) {
      console.log('Error while marking:', error);
    }
  }

  markCorrect(question) {
    question.correct = true;
    question.userAnswer = _.findWhere(question.answers, {correct: true})['id'];
    this.save().then(v1 => {
      this.grade();
    });
  }

  markIncorrect(question) {
    question.correct = false;
    question.userAnswer = _.findWhere(question.answers, {correct: false})['id'];;
    this.save().then(v1 => {
      this.grade();
    });
  }


  onQuestion(): Observable<any> {
    return this.dataObs$;
  }


  async cheat(grade: number) {
    const chance = (grade / 100);
    this.test.questions.forEach((question) => {
      const incorrect = _.findWhere(question.answers, {correct: false});
      const correct = _.findWhere(question.answers, {correct: true});
      const rand = Math.random();

      if (rand + chance > 1) {
        question.userAnswer = correct['id'];
        question.correct = true;
      } else if (incorrect) {
        question.userAnswer = incorrect['id'];
        question.correct = false;
      } else {
        question.correct = false;
      }

      const selectedAnswer = question.answers.find((a) => a.id === question.userAnswer);
      const currentDateUTC = this.getCurrentDateUtc();
      const testAnswer: TestAnswer = {
        questionUuid: question.uuid,
        answerUuid: selectedAnswer.uuid,
        //do not populate timestamp, the backend will
      };

      this.testAnswers.push(testAnswer);
    });
    this.save(null, true);

    this.testResults = this.grade();
  }

  getCurrentDateUtc() {
    return new Date(Date.UTC(
      new Date().getUTCFullYear(),
      new Date().getUTCMonth(),
      new Date().getUTCDate(),
      new Date().getUTCHours(),
      new Date().getUTCMinutes(),
      new Date().getUTCSeconds(),
      new Date().getUTCMilliseconds()
    ));
  }

  filterCorrect() {
    if (!this.test.unfilteredQuestions) {
      this.test.unfilteredQuestions = this.test.questions.slice();
    }

    this.test.questions = _.filter(this.test.unfilteredQuestions.slice(), (question) => {
      return question.correct;
    });

    this.q = this.test.questions[0];
  }

  filterIncorrect() {
    if (!this.test.unfilteredQuestions) {
      this.test.unfilteredQuestions = this.test.questions.slice();
    }
    this.test.questions = _.filter(this.test.unfilteredQuestions.slice(), (question) => {
      return !question.correct;
    });

    this.q = this.test.questions[0];
  }

  isDirty() {
    return this.dirty;
  }

  async save(courseId?, quit?): Promise<any> {
    if (this.testAnswers.length > 0 && quit) {
      const result = await this.ping();

      if (result == 200) {
        return new Promise((resolve, reject) => {
          console.log('TestSessionService:', this.test.id, this.test.remainingTimeMillis);
          this.testsService.saveTest(this.test.id, courseId, this.testAnswers).then((savedtest) => {
            console.log('savedtest', savedtest);
            this.dirty = false;
            resolve(savedtest);
            this.testAnswers = [];
            return;
          });
        });
      }
    }

    if (!quit) {
      const selectedAnswer = this.q.answers.find((a) => a.id === this.q.userAnswer);
      const currentDateUTC = this.getCurrentDateUtc();

      const testAnswer: TestAnswer = {
        questionUuid: this.q.uuid,
        answerUuid: selectedAnswer.uuid
        //do not populate timestamp, the backend will
      };

      this.dirty = true;
      console.log('TestSessionService:save:', this.test.id, this.test.remainingTimeMillis);
      if (this.remainingTimeProvider) {
        this.test.remainingTimeMillis = this.remainingTimeProvider();
      }

      if (!courseId && this.test.user && this.test.user.signupCourseCode) {
        courseId = this.test.user.signupCourseCode;
      }

      try {
        const result = await this.ping();

        if (result == 200) {
          this.testAnswers.push(testAnswer);
          return new Promise((resolve, reject) => {
            console.log('TestSessionService:', this.test.id, this.test.remainingTimeMillis);
            this.testsService.saveTest(this.test.id, courseId, this.testAnswers).then((savedtest) => {
              console.log('savedtest', savedtest);
              this.dirty = false;
              resolve(savedtest);
              this.testAnswers = [];
            });
          });
        } else {
          this.testAnswers.push(testAnswer);
        }
      }
        catch (error) {
      }
    }
  }

  async complete(user, course): Promise<any> {

    return new Promise((resolve, reject) => {
      const questionResult = this.grade();
      if (user) {
        this.save(null, true).then(() => {
          this.testsService.completeTest(questionResult.correct, course.webAppId, this.test.id).then((result) => {
            this.test.endTime = result.endTime;
            this.test.correct = result.correct;

            if (course.courseMixed || (course && course.isCourseMixed)) {
              this.testsService.completeExam(course.webAppId, this.test, questionResult).then(() => {
                resolve(questionResult);
              }, reason => {
                reject(reason);
              });
            } else {
              resolve(questionResult);
            }
          }, (error) => {
            reject(error);
          });

        }, (error) => {
          reject(error);
        });
      } else {
        resolve(questionResult);
      }
    });
  }

  grade(): {
    correct: number;
    incorrect: number;
    unanswered: number;
    answered: number;
    totalQuestions: number;
    success: number;
    marked: number;
    grade: number;
  } {

    const questions = (this.test.unfilteredQuestions || this.test.questions);
    questions.forEach((question) => {
      if (question.userAnswer) {
        const answer = _.findWhere(question.answers, {id: question.userAnswer});
        question.correct = answer['correct'];
      }
    });

    const questionResults = {
      correct: 0,
      incorrect: 0,
      unanswered: 0,
      answered: 0,
      totalQuestions: 0,
      success: 0,
      marked: 0,
      grade: 0,
    };

    questionResults.totalQuestions = questions.length;

    _.extend(questionResults,
      _.countBy(questions, (question) => {
        if (question['correct'] === false) {
          return 'incorrect';
        } else if (question['correct'] === true) {
          return 'correct';
        } else if (question['userAnswer'] === null) {
          return 'unanswered';
        }
      }));

    const answered = _.filter(questions, (question) => {
      return question['userAnswer'] !== null || question['correct'] !== null;
    });
    const marked = _.filter(questions, (question) => {
      return question['marked'] === true;
    });
    questionResults.answered = answered.length;
    questionResults.marked = marked.length;
    if (questionResults.answered > 0) {
      questionResults.success = Math.round(questionResults.correct * 100.0 / questionResults.answered);
    }
    questionResults['grade'] = Math.round(questionResults.correct * 100.0 / questions.length);

    this.testResults = questionResults;
    return questionResults;
  }
}
