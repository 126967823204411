import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-login-reset',
  templateUrl: './login-reset.component.html',
  styleUrls: ['./login-reset.component.scss']
})

export class LoginResetComponent implements OnInit {
  //FormGroup
  form = new FormGroup({
    password: new FormControl('', {
      validators: [Validators.required, Validators.minLength(5)]
    }),
    confirmPassword: new FormControl('', {
      validators: [Validators.required, Validators.minLength(5)]
    }),
  }, {
    validators: [this.passwordMatchValidator]
  });

  validateTokenResponse: any;
  token: string;
  error: any;


  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.queryParams.token;
    this.authService.validateResetPasswordToken(this.activatedRoute.snapshot.queryParams.token).subscribe({
      next: (validateTokenResponse: any) => {
        this.validateTokenResponse = validateTokenResponse;
      },
      error: () => {
        //this.router.navigate(['/login']);
      }
    });
  }

  submit() {
    this.authService.submitResetPassword(this.token, this.form.value.password).subscribe({
      next: () => {
        // log the user in
        this.router.navigate(['/login']);
      },
      error: (error: any) => {
        //this.router.navigate(['/login']);
        this.error = error;
      }
    });
  }

  passwordMatchValidator(form: FormGroup): ValidationErrors | null {
    console.log(form.get('password').value, form.get('confirmPassword').value);
    return form.get('password').value === form.get('confirmPassword').value ? null : {mismatch: true};
  }
}
