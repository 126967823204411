<div class="modal-header w-100 p-0">
  <div
    class="w-100 plate plate-dark_blue swoosh swoosh-cfi-home px-3 py-4 border-bottom-left-radius-0 border-bottom-right-radius-0 border-top-left-radius-modal border-top-right-radius-modal">
    <div class="d-flex justify-content-start align-items-center w-100 plate-header">
      <img class="w-100 modal-header-image d-none d-md-block"
           src="https://dl.videos.sportys.com/onlinecourse/images/sportys-logo-block-airplane.svg"/>
      <div class="tagline text-size-18 ml-2">Create a Free Sporty's Account
      </div>
      <div class="d-flex align-items-start pt-0 text-size-14 ml-5 pl-5 mr-5 cursor-pointer"
           (click)="bsModalRef.hide();">
        <span aria-hidden="true" class="text-white modal-close-btn text-size-20">×</span>
      </div>
    </div>
  </div>
</div>

<div class="modal-body">
  <div *ngIf="!course?.details?.signupMarketingHtml">
    <h3 class="font-weight-bold">With a FREE Sporty's account enjoy access to:</h3>
    <div class="marketing-reasons">
      <div>
        <app-icon glyph name="categories"></app-icon>
        Study all question categories
      </div>
      <div>
        <app-icon glyph name="test"></app-icon>
        Save 5 of your previous sessions
      </div>
      <div>
        <app-icon glyph name="category-breakdown"></app-icon>
        View your category breakdown
      </div>
      <div>
        <app-icon glyph name="marked"></app-icon>
        Take Practice FAA Tests
      </div>
    </div>
  </div>
  <div *ngIf="course?.details?.signupMarketingHtml" [innerHTML]="course?.details?.signupMarketingHtml"></div>
  <div class="">
    <div class="login-form_message" [ngClass]="{'populated':message !== ''}" style="color:red">
      {{message}}
    </div>
    <div class="login-form_message" *ngIf="!course || course === 'course'" style="color:red;">
      You must be in a course to register.
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="stylized">
      <div class="form-row">
        <div class="form-group col-xs-12 col-sm-6">
          <input type="text" class="form-control" formControlName="firstName" id="firstName" placeholder="First Name"
                 required>
        </div>
        <div class="form-group col-xs-12 col-sm-6">
          <input type="text" class="form-control" formControlName="lastName" id="lastName" placeholder="Last Name"
                 required>
        </div>
      </div>
      <div class="form-row">

        <div class="form-group col">
          <input type="text" class="form-control" formControlName="email" type="email" placeholder="Email Address"
                 required>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col">
          <span class="text-danger" *ngIf="form.errors?.mismatchedEmail && (form.touched || form.dirty)">Emails must
            match</span>
          <input type="email" class="form-control" formControlName="confirm_email" id="confirm_email" required
                 placeholder="Confirm Email Address">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group form-group-icon col">
          <div (click)="password = !password">
            <i class="fa fa-eye" *ngIf="password"></i>
            <i class="fa fa-eye-slash" *ngIf="!password"></i>
          </div>
          <input type="{{password?'password':'text'}}" class="form-control" formControlName="password" id="password"
                 placeholder="Password" required>
        </div>
      </div>
      <div class="form-footer">
        <div class="row d-flex align-items-center justify-content-start px-3">
          <button id="loginButton"
                  [disabled]="!course || course === 'course' || working || !form.touched || form.status === 'INVALID'"
                  type="submit" class="btn btn-sm btn-primary btn-round" [ngClass]="{'btn-loading': working === true}">Create
            Account
          </button>
          <button (click)="login()" class="btn btn-link ml-2">Already have a Sporty's Account? Log in here.</button>
        </div>

      </div>
      <div class="d-flex justify-content-center">
        <div class="text-size-12 px-5">
          By creating an account, you agree to the
          <a class="external-link" target="_blank" href="https://www.sportys.com/terms-of-use">Terms of Use</a> and
          <a class="external-link" target="_blank" href="https://www.sportys.com/privacy/">Privacy Policy</a>
        </div>
      </div>
    </form>
  </div>
</div>
