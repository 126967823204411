<!--<div class="modal-header">
  <h1 class="modal-title pull-left">{{title}}</h1>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <div class="sportys-icon sportys-incorrect sportys-incorrect-icon"></div>
  </button>
</div>
<div class="modal-body">
-->
<div class="responsive-content">

  <div class="responsive-content-header"></div>
  <div class="responsive-content-body">
    <!--
      <h1>
        Progress Sharing
      </h1>
      <div *ngIf="user.id">
        <p>
          <a style="float:right; margin-left: 10px;" href="" (click)="share($event)"
             class="btn btn-primary btn-sm btn-round btn-shadow">Share My Progress</a>
          Use the Share my Progress feature to allow your CFI, family or friends to track your video progress and test
          results as you advance through Sporty's pilot training courses.
        </p>
      </div>
      <h3 class="dark">My course progress shared with others</h3>
      <table class="table">
        <thead>
        <tr>
          <th>Shared By</th>
          <th>Shared On</th>
          <th style="text-align:center;">Revoked</th>
          <th>Shared With</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="!sharedByMe || sharedByMe.length == 0">
          <td colspan="4" style="color:gray; font-style: italic;">
            (you have not shared your progress with other users)
          </td>
        </tr>
        <ng-container *ngFor="let shared of sharedByMe">
          <tr *ngIf="sharedByMe.length > 0">
            <td>{{shared.sharingUser.first_name}} {{shared.sharingUser.last_name}} ({{shared.sharingUser.email}})</td>
            <td>{{shared.sharedDate | date}}</td>
            <td style="text-align:center;">
              <input type="checkbox" [(ngModel)]="shared.revoked" (change)="revoke(shared, shared.revoked)"
                     id="share-{{shared.id}}">
            </td>
            <td *ngIf="shared.sharedUser">{{shared.sharedUser.first_name}} {{shared.sharedUser.last_name}}
              ({{shared.sharedUser.email}})
            </td>
            <td *ngIf="!shared.sharedUser">{{shared.sharedEmail}}</td>
          </tr>
        </ng-container>
        </tbody>
      </table>
      -->
    <h1>
      Instructor Connections
    </h1>
    <div *ngIf="user">
      <p>
        <a style="float:right; margin-left: 10px;" href="" (click)="connect($event)"
           class="btn btn-primary btn-sm btn-round btn-shadow" *ngIf="!isDemoUser">Connect with Instructor</a>
        Use the Share my Progress feature to allow your instructor to track your video progress and test
        results as you advance through Sporty's pilot training courses.
      </p>
    </div>
    <h3 class="dark">My course progress shared with instructors</h3>
    <table class="table" style="overflow-y:auto;">
      <thead>
      <tr>
        <th class="hide_on_mobile">Student</th>
        <th>Invitation Sent</th>
        <th>Invitation Accepted</th>
        <th style="text-align:center;">&nbsp;</th>
        <th class="hide_on_mobile">Instructor Email</th>
      </tr>
      </thead>
      <tbody>
      <!--<tr *ngIf="!sharedByMe || sharedByMe.length == 0">
        <td colspan="4" style="color:gray; font-style: italic;">
          (you have not shared your progress with any instructors)
        </td>
      </tr>-->
      <ng-container *ngFor="let invitation of invitedInstructors">
        <tr class="show_on_mobile">
          <td colspan="3">
            Connected with: {{invitation.instructorEmail}}
          </td>
        </tr>
        <tr>
          <td class="hide_on_mobile">
            {{invitation.student.email}}
          </td>
          <td>
            {{invitation.createdDate | date}}
          </td>
          <td>
            {{invitation.acceptanceDate | date}}
          </td>
          <td>
            <btn class="btn btn-warning btn-round" (click)="revoke($event, invitation, 'student')">Remove</btn>
          </td>
          <td class="hide_on_mobile">
            {{invitation.instructorEmail}}
          </td>
        </tr>
      </ng-container>
      <ng-container *ngFor="let invitation of invitedStudents">
        <tr class="show_on_mobile">
          <td colspan="3">
            Connected with: {{invitation.enrolledStudent.instructor.user.email}}
          </td>
        </tr>
        <tr>
          <td class="hide_on_mobile">
            {{invitation.enrolledStudent.user.email}}
          </td>
          <td>
            {{invitation.createdDate | date}}
          </td>
          <td>
            {{invitation.acceptanceDate | date}}
          </td>
          <td>
            <btn class="btn btn-warning btn-round" (click)="revoke($event, invitation, 'instructor')">Remove</btn>
          </td>
          <td class="hide_on_mobile">
            {{invitation.enrolledStudent.instructor.user.email}}
          </td>

        </tr>
      </ng-container>
      </tbody>
    </table>
    <div>Looking for other user progress that has been shared with you?
      Visit the Student Sharing section of Sporty’s <a routerLink="/cfi/home"> CFI Portal</a> to view this data.
    </div>
    <!--
    <h3 class="dark">Users that have shared their course progress with me</h3>
    <p>
      Instructors! Earn a <a
      href="https://www.sportys.com/pilotshop/cfirebate?utm_source=onlinecourse&utm_medium=webbanner&utm_campaign=coursecfirebate"
      target="_new">$25 gift card</a> every time your students buy a Sporty's Course.
    </p>
    <table class="table">
      <thead>
      <tr>
        <th>Shared By</th>
        <th>Shared On</th>
        <th style="text-align:center;">Revoked</th>
        <th>Shared With</th>
        <th style="text-align:center; white-space: nowrap; width:100px;">View Progress</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngIf="sharedWithMe.length == 0">
        <td colspan="5" style="color:gray; font-style: italic;">
          (no users have shared their progress with you)
        </td>
      </tr>
      <ng-container *ngFor="let shared of sharedWithMe">
        <tr *ngIf="sharedWithMe.length > 0">
          <td>{{shared.sharingUser.first_name}} {{shared.sharingUser.last_name}} ({{shared.sharingUser.email}})</td>
          <td>{{shared.sharedDate | date}}</td>
          <td style="text-align:center;">
            <input type="checkbox" [(ngModel)]="shared.revoked" [attr.disabled]="true">
          </td>
          <td *ngIf="shared.sharedUser">{{shared.sharedUser.first_name}} {{shared.sharedUser.last_name}}
            ({{shared.sharedUser.email}})
          </td>
          <td *ngIf="!shared.sharedUser">{{shared.sharedEmail}}</td>
          <td style="text-align:center;">
            <a [href]="sharedLink +'#/progress/'+shared.token" [attr.disabled]="shared.revoked"
               class="btn btn-sm btn-primary btn-round btn-shadow">View</a>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  -->
  </div>

</div>
<!--
</div>
-->
