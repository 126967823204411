<div class="modal-body">
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <app-icon glyph name="incorrect"></app-icon>
  </button>

  <ul class="nav nav-tabs">
    <li class="nav-item">
      <a class="nav-link" href="#" (click)="setTab($event, 'GETTING_STARTED')"
         [ngClass]="{'active': tab==='GETTING_STARTED'}">Getting Started</a>
    </li>
    <li class="nav-item"
        *ngIf="course?.enableAskCfi">
      <a class="nav-link" href="#" (click)="setTab($event, 'ASK_A_CFI')"
         [ngClass]="{'active': tab==='ASK_A_CFI'}">Ask a CFI</a>
    </li>
    <li class="nav-item" *ngIf="course?.webWelcomeHtml !== ''">
      <a class="nav-link" href="#" (click)="setTab($event, 'WHATS_NEW')"
         [ngClass]="{'active': tab==='WHATS_NEW'}">What's New</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" href="#" (click)="setTab($event, 'SUPPORT')"
         [ngClass]="{'active': tab==='SUPPORT'}">Support</a>
    </li>
    <li class="nav-item" *ngIf="user">
      <a class="nav-link" href="#" (click)="setTab($event, 'RESET_PROGRESS')"
         [ngClass]="{'active': tab==='RESET_PROGRESS'}">Reset Progress</a>
    </li>

  </ul>

  <div class="tab-content">
    <div class="tab-body" *ngIf="tab === 'GETTING_STARTED'">
      <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(course?.webHelpHtml)">
      </div>
    </div>


    <div class="tab-body" *ngIf="tab === 'ASK_A_CFI'">
      <div *ngIf="purchased">
        <h1>Sporty's Ask A CFI</h1>
        <img class="cfi-image" src="https://dl.videos.sportys.com/onlinecourse/images/askcfi.jpg"/>
        <p>
          Looking for help understanding a particular aviation training topic,
          or have a question about the FAA written test?
        </p>
        <p>
          Send us an email at <a href="mailto:LearnToFly@sportys.com">LearnToFly&#64;sportys.com</a>
          and one of Sporty's experienced CFIs will get back to you within 24 hours.
        </p>
      </div>

      <div *ngIf="!purchased">
        <div class="marketing-block">
          <a class="btn btn-lg btn-purchase-new btn-w-75 btn-round" [href]="course.webPurchaseUrl" target="_blank">
            <app-icon *ngIf="!colorSchemeService.isDarkMode()" glyph name="lock-black"></app-icon>
            <app-icon *ngIf="colorSchemeService.isDarkMode()" glyph name="lock-white-small"></app-icon>
            <div>
              Purchase full course.
            </div>
          </a>
        </div>
      </div>
    </div>

    <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(course.webWelcomeHtml)"
         *ngIf="tab === 'WHATS_NEW'"></div>

    <div class="tab-body" *ngIf="tab === 'SUPPORT'">
      <h1>Customer Support</h1>
      <div>
        <i>Contact Sporty's Customer Service:</i><br>
        <ul>
          <li>
            <strong>Email:</strong> coursesupport&#64;sportys.com<br>
          </li>
          <li>
            <strong>Phone:</strong> 1.513.735.9000
          </li>
        </ul>
      </div>
      <div class="full-center">
        <img [src]="api['imagesLocation'] + '/Sportys_one_line_logo.png'"><br>
        Copyright &copy; 2021, Sporty's Pilot Shop<br>
      </div>

    </div>
    <div class="tab-body" *ngIf="tab === 'RESET_PROGRESS'">
      <h1>Reset your video progress</h1>

      <div class="alert alert-danger">
        Use the Reset button below to reset your video progress to 0%.
        This will not reset any quizzes or tests you've taken, or remove any completion certificates already earned.
        <br><br>
        This video progress cannot be recovered once it is reset.
      </div>
      <div id="reset_video_progress_view" *ngIf="!confirm" (click)="confirmResetCourseProgress()">
        <button id="reset_video_progress" class="btn btn-lg btn-danger">RESET</button>
      </div>
      <div id="reset_video_progress_confirm_view" *ngIf="confirm" (click)="resetCourseProgress()">
        <button id="reset_video_progress_confirm" class="btn btn-lg btn-danger">CONFIRM</button>
      </div>
      <div id="reset_video_progress_complete_view" *ngIf="confirmed">
        Your video progress is reset.
      </div>
    </div>
  </div>
</div>
