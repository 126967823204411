import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SessionType } from '../../course/tests/enum/session-type.enum';
import { TestBuilderService } from '../../course/services/test-builder.service';
import { CategoryType } from '../../course/tests/enum/category-type.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationModalComponent } from '../confirmation/confirmation-modal.component';
import { take } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TestsService } from '../../course/services/tests.service';
import { CoursesService } from '../../courses.service';

export class SessionOptions {
  allowResume: boolean;
  allowReview: boolean;
  allowDelete: boolean;
  allowCreate: boolean;

  constructor(allowResume: boolean, allowReview: boolean, allowDelete: boolean, allowCreate: boolean) {
    this.allowResume = allowResume;
    this.allowReview = allowReview;
    this.allowDelete = allowDelete;
    this.allowCreate = allowCreate;
  }
}

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {

  @Input()
  test: any;

  @Input()
  course: any;

  @Input()
  categoryType: CategoryType;

  @Input()
  user: any;

  @Input()
  marketing = false;

  @Input()
  homeRoute: Array<string>;

  @Input()
  purchased = false;

  @Input()
  enableTestPrepBreakdown = true;

  @Input()
  allowStartOptions = true;

  @Input()
  path: Array<any>;

  @Input()
  options: SessionOptions = {
    allowCreate: true,
    allowDelete: true,
    allowResume: true,
    allowReview: true
  };

  @Input()
  minimumScore: number;

  @Output()
  onDelete = new EventEmitter();


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private testBuilderService: TestBuilderService,
    private modalService: BsModalService,
    public testsService: TestsService,
    private coursesService: CoursesService
  ) {
  }

  ngOnInit(): void {

  }

  getPath(test): Array<any> {
    return this.path ? this.path : ['/', 'course', this.course.webAppId]
      .concat(...this.homeRoute).concat([test.testType.toLowerCase(), test.id]);
  }

  startQuestionsAnsweredCorrectly(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);
    this.testBuilderService.sessionType = SessionType.REVIEW_CORRECT;

    this.testBuilderService.startSession(test, SessionType.REVIEW_CORRECT, test.sessionType, this.user).then((savedtest) => {
      this.router.navigate([test.testType.toLowerCase()], { relativeTo: this.route });
    });

  }

  startIncorrectQuestions(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);
    this.testBuilderService.sessionType = SessionType.REVIEW_INCORRECT;

    this.testBuilderService.startSession(test, SessionType.REVIEW_INCORRECT, test.sessionType, this.user).then((savedtest) => {
      this.router.navigate([test.testType.toLowerCase()], { relativeTo: this.route });
    });

  }

  startMarkedQuestions(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);
    this.testBuilderService.sessionType = SessionType.REVIEW_MARKED;

    this.testBuilderService.startSession(test, SessionType.REVIEW_MARKED, test.sessionType, this.user).then((savedtest) => {
      this.router.navigate([test.testType.toLowerCase()], { relativeTo: this.route });
    });

  }

  retryTest(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);
    this.testBuilderService.sessionType = SessionType.REVIEW_TEST;

    this.testBuilderService.startSession(test, SessionType.REVIEW_TEST, test.sessionType, this.user).then((savedtest) => {
      this.router.navigate([test.testType.toLowerCase()], { relativeTo: this.route });
    });

  }


  resume(test: any) {
    this.router.navigate(this.getPath(test));
  }

  remove(test: any) {
    const courseId = this.route.snapshot.paramMap.get('id');
    const modal = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        message: 'Are you sure you want to delete this session?',
        warningMessage: null,
        yesLabel: 'Yes',
        noLabel: 'No',
        primaryButton: 'yes'
      }, class: 'rounded-modal'
    });

    this.modalService.onHide.pipe(take(1)).subscribe(value => {
      console.log('SessionComponent:', value);
      if (modal.content.response) {
        this.testsService.delete(test.id, courseId).then(() => {
          if (this.onDelete) {
            this.onDelete.emit({ 'test': test });
          }
        });
      }
      modal.hide();
    });

  }


  floor(num: number) {
    return Math.floor(num);
  }

  ceil(num: number) {
    return Math.ceil(num);
  }

  round(num: number){
    return Math.round(num);
  }

}
