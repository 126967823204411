import {Component, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AuthService} from '../../services/auth.service';
import {
  AbstractControl,
  EmailValidator,
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {LoginModalService} from '../../login-modal.service';
import {TrainingService} from '../../training.service';

@Component({
  selector: 'app-reset-modal',
  templateUrl: './reset-modal.component.html',
  styleUrls: ['./reset-modal.component.scss']
})
export class ResetModalComponent implements OnInit, OnDestroy {

  message: string;
  success: any;
  working: boolean;
  instructions = 'If there is an account associated with %s you will receive an email with a link to reset your password.';
  allowClose: boolean;
  validationMessage: string;

  course: any;
  onComplete: () => void;
  config: any;
  resetFields: string;

  form: FormGroup;
  showInstructions = false;

  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private loginModalService: LoginModalService,
    private trainingService: TrainingService
  ) {
  }

  async ngOnInit() {
    this.config = await this.trainingService.loadConfig();
    if (this.config['user.mode'] === 'YE') {
      this.resetFields = 'Username';
      this.validationMessage = 'Please enter your username (not email address) to continue.';
      this.form = new FormGroup({
        username: new FormControl('', [this.noAtCharacterValidator]),
      });
    } else {
      this.form = new FormGroup({
        username: new FormControl('', [Validators.email]),
      });
      this.validationMessage = 'Please enter a valid email address to continue.';
      this.resetFields = 'Email Address';
    }
  }

  ngOnDestroy() {
  }

  onSubmit() {
    console.log('LoginComponent.onSubmit');

    this.working = true;
    this.message = null;
    this.instructions = this.instructions.replace('%s', this.form.get('username').value);
    this.authService.resetPassword(this.form.get('username').value)
      .then((value: any) => {
        this.message = value.message;
        this.success = value.success;
        this.working = false;
        this.showInstructions = true;
        if (!this.allowClose) {
          this.loginModalService.showLogin(this.course, {allowClose: this.allowClose, onComplete: this.onComplete});
        }
      }, error => {
        this.message = error.error.message;
        this.success = error.error.success;
        this.working = false;
        this.showInstructions = false;
      });

    window.setTimeout(() => {
      this.message = '';
    }, 5000);
  }

  noAtCharacterValidator(control: AbstractControl): ValidationErrors | null {
    const forbidden = /@/.test(control.value);
    return forbidden ? {'noAtCharacter': {value: control.value}} : null;

  }
}
