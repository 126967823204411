import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthContextService} from '../../services/auth-context.service';
import {TrainingService} from 'src/app/training.service';
import {HttpClient} from '@angular/common/http';
import {School} from 'src/app/login/school.interface';

@Component({
  selector: 'app-brand-container',
  templateUrl: './brand-container.component.html',
  styleUrls: ['./brand-container.component.scss']
})
export class BrandContainerComponent implements OnInit {
  orgType = 'loading';
  isLandingPage = false;

  school: any;

  constructor(
    private authContextService: AuthContextService,
    private router: Router,
    private trainingService: TrainingService,
    private httpClient: HttpClient
  ) {
  }

  ngOnInit(): void {
    this.isLandingPage = this.router.url.indexOf('home/learn') !== -1;
    this.authContextService.onLogin().subscribe(() => {
      this.orgType = this.authContextService.orgType;
      this.getSchoolUiDetails();
    });
    this.authContextService.onRenew().subscribe(() => {
      this.orgType = this.authContextService.orgType;
      this.getSchoolUiDetails();
    });


    if (this.authContextService.orgType) {
      this.orgType = this.authContextService.orgType;
      this.getSchoolUiDetails();
    }


    this.router.events.forEach((event) => {
      this.isLandingPage = this.router.url.indexOf('home/learn') !== -1;
    });
  }

  getSchoolUiDetails() {
    if (!this.school) {
      this.trainingService.load().subscribe((api) => {
        this.httpClient
          .get(api.getRoute('schoolUIDetails'))
          .subscribe({
            next: (value: School) => {
              this.school = value;
            },
            error: (error) => {
              console.error(error);
            }
          });
      });
    }
  }
}
