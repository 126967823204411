<label>
  <app-icon radio [name]="model === value?'selected':'unselected'" [complete]="!disabled"></app-icon>
  <input [name]="name"
         type="radio"
         [disabled]="disabled"
         [(ngModel)]="model"
         [value]="value"
         (ngModelChange)="onChange()">

</label>
