import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IconModule } from 'src/app/components/icon/icon.module';

export interface SidenavItem {
  label: string;
  iconName?: string;
  routeParts?: string[];
  enabled?: boolean;
  onClick?: (event?) => void;
  skipActiveLinkHighlight?: boolean;
  visible?: boolean;
}

@Component({
  selector: 'app-nav-item',
  standalone: true,
  imports: [CommonModule, IconModule, RouterModule],
  template: `
    <div class="item">
      <div
        class="main"
        [class.top-border]="hasTopBorder()"
        [routerLinkActive]="!!mainItem()?.skipActiveLinkHighlight ? '' : 'active-link'"
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="mainItem().routeParts || null"
        (click)="expandContent($event)"
      >
        <div class="title">
          <app-icon glyph name="{{ mainItem().iconName }}"></app-icon>
          <span [innerHTML]="mainItem().label"></span>
        </div>
        @if (secondaryItems()?.length > 0) {
          <app-icon
            glyph
            name="down"
            class="toggle-button"
            [class.expanded]="isExpanded()"
            (click)="toggleContent($event)"
          />
        }
      </div>
      @if (secondaryItems()?.length > 0) {
        <ul
          class="sub-items"
          [class.collapsed]="!isExpanded()"
        >
          @for (si of secondaryItems(); track si) {
            @if (si.visible === undefined || si?.visible) {
              <li
                class="secondary"
                [class.disabled]="si.enabled !== undefined && !si?.enabled"
                [routerLinkActive]="!!si?.skipActiveLinkHighlight ? '' : 'active-link'"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="si?.routeParts || null"
                (click)="si?.onClick ? si.onClick($event) : null"
              >
                <div class="title">
                  @if (si.enabled === undefined || si?.enabled) {
                    <app-icon glyph name="circle-stroke"></app-icon>
                  } @else {
                    <app-icon glyph name="lock-blue" class="locked"></app-icon>
                  }
                  <span [innerHTML]="si.label"></span>
                </div>
              </li>
            }
          }
        </ul>
      }
    </div>
  `,
  styleUrls: ['./nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavItemComponent {
  mainItem = input<SidenavItem>();
  secondaryItems = input<SidenavItem[]>([]);
  isExpanded = input(true);
  hasTopBorder = input(true);

  contentToggle = output<boolean>();

  toggleContent(event) {
    event.stopPropagation();
    this.contentToggle.emit(!this.isExpanded());
  }

  expandContent(event) {
    event.stopPropagation();
    this.contentToggle.emit(true);
  }
}
