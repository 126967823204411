import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, switchMap} from 'rxjs';
import {TrainingService} from '../../training.service';
import {HttpClient, HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class VideosService {

  private lastSaveTime = 0;
  private debounceInterval = 20000; //20 seconds delay between requests
  private saveInProgress = false;

  constructor(
    private trainingService: TrainingService,
    private httpClient: HttpClient
  ) {
  }


  async load(courseId: string, webAppId: string, uuid: string): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);

    return new Promise(async (resolve, reject) => {
      this.httpClient.put(api.getRoute('videoDetailsUrl').replace('{webAppId}', webAppId), {'uuid': uuid}).toPromise()
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject();
        });
    });
  }

  async getVideo(webAppId: string, uuid: string) {
    const api = await this.trainingService.loadByCourse(webAppId);

    return new Promise(async (resolve, reject) => {
      this.httpClient.get(api.getRoute('videosByUUIDUrl').replace('{uuid}', uuid)).subscribe(
        {
          next: (response) => {
            resolve(response);
          },
          error: (error) => {
            reject();
          }
        });
    });
  }

  async getSignedUrl(webAppId: string, uuid: string): Promise<any> {
    const api = await this.trainingService.loadByCourse(webAppId);

    return new Promise(async (resolve, reject) => {
      this.httpClient.get(api.getRoute('signedVideoUrl').replace('{webAppId}', webAppId).replace('{uuid}', uuid)).toPromise()
        .then((response) => {
          resolve(response);
        }, (error) => {
          reject();
        });
    });
  }

  saveProgress(videoUuid: string, progress: number): Promise<void> {
    if (!this.saveInProgress && Date.now() > this.lastSaveTime + this.debounceInterval) {
      this.saveInProgress = true;
      return new Promise((resolve, reject) => {
        const requestData = {
          progress: Math.round(progress)
        };
        this.trainingService.load()
          .subscribe(api => {
            this.httpClient.post(api.getRoute('saveVideoProgressUrl').replace('{videoUuid}', videoUuid), null, {params: requestData})
              .subscribe(
                () => {
                  this.lastSaveTime = Date.now();
                  this.saveInProgress = false;
                  resolve(null);
                },
                (error) => {
                  this.saveInProgress = false;
                  reject(error);
                });
          });
      });
    }
  }

  loadProgress(videoUuid: string): Observable<any> {
    return this.trainingService
      .load()
      .pipe(
        switchMap((api) =>
          this.httpClient.get(api.getRoute('loadVideoProgressUrl').replace('{videoUuid}', videoUuid))
        ));
  }

  loadVolumeProgress(webAppId: string, volumeUuid: string): Observable<any> {
    return this.trainingService
      .load()
      .pipe(
        switchMap((api) =>
          this.httpClient.get(api.getRoute('loadVolumeVideosProgressUrl').replace('{webAppId}', webAppId).replace('{volumeUuid}', volumeUuid))
        ));
  }
}
