import {Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren, inject, signal } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CoursesService} from '../courses.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {LoginModalComponent} from '../components/login-modal/login-modal.component';
import {Observable, Subscription} from 'rxjs';
import {VideoSearchComponent} from '../components/video-search/video-search.component';
import {QuestionSearchComponent} from '../components/question-search/question-search.component';
import {WelcomeModalComponent} from '../components/welcome-modal/welcome-modal.component';
import {ProgressService} from '../progress.service';
import {SignupModalService} from '../signup-modal.service';
import {TrainingService} from '../training.service';
import {VolumesService} from './services/volumes.service';
import {CookieService} from 'ngx-cookie-service';
import {ColorSchemeService} from '../services/color-scheme.service';
import {FarSharedService} from './far/far-shared.service';
import {SidenavUiService} from "./services/sidenav-ui.service";
import {AuthContextService} from "../services/auth-context.service";
import {AuthService} from "../services/auth.service";
import {SharingService} from './services/sharing.service';
import {CourseSharedService} from './course-shared.service';
import {CourierService} from "../services/courier.service";
import { GlobalStateService } from '../services/global-state.service';
import { MatDialog } from '@angular/material/dialog';
import { getYear } from 'date-fns';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-course',
  templateUrl: './course.component.html',
  styleUrls: ['./course.component.scss'],
  providers: [CourseSharedService]
})
export class CourseComponent implements OnInit, OnDestroy {
  breakpointObserver = inject(BreakpointObserver);

  year = getYear(new Date());
  courseId = '';
  isNavbarCollapsed = signal(true);
  isSidenavOpen = signal(this.cookieService.get('isSideBarOpen') === '' ? true : this.cookieService.get('isSideBarOpen') === 'true');
  isSmallScreen = signal(false);
  isFarSmallScreen = signal(false);
  course: any = null;
  user: any = null;
  purchased = false;
  api: any = null;
  pinging = false;
  lastPing = Date.now();
  checkEvery = 1000 * 60; // 1000 * 60 * 5;
  sessionTimeoutModal: BsModalRef;
  loginModal: BsModalRef;
  renewSubscription: Subscription;
  loginSubscription: Subscription;
  logoutSubscription: Subscription;
  routerSubscription: Subscription;
  volumeGroups: Array<any>;
  isThemeLightSelected = !this.colorSchemeService.isDarkMode();
  config: any;
  helpModal: BsModalRef;
  searchModal: BsModalRef;
  navEnd: Observable<any>;
  isFarPage = signal(this.router.url.indexOf('/far') !== -1);
  isChatDpePage = signal(this.router.url.indexOf('/chat-dpe') !== -1);
  showStickySidenavHeader = false;

  @ViewChildren('courseSideNav') sideNav: QueryList<ElementRef>;
  @ViewChild("contentBody") contentBody: ElementRef;

  constructor(
    public authContextService: AuthContextService,
    public globalStateService: GlobalStateService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharingService,
    private coursesService: CoursesService,
    private courseSharedService: CourseSharedService,
    public farSharedService: FarSharedService,
    private httpClient: HttpClient,
    private modalService: BsModalService,
    private progressService: ProgressService,
    private signupModalService: SignupModalService,
    private trainingService: TrainingService,
    private colorSchemeService: ColorSchemeService,
    private cookieService: CookieService,
    private volumesService: VolumesService,
    private testsUiService: SidenavUiService,
    private courierService: CourierService,
    private dialog: MatDialog
  ) {
    this.routeEvent(this.router);

    this.courseSharedService.closeCourseSidenavReplaySubject.subscribe(resp => {
      this.toggleSidenav();
    });
  }

  logout(): void {
    this.authService.logout();
  }

  login() {
    const initialState = {
      title: 'Modal with component',
      course: this.course ? this.course : null,
      courseGroup: this.course && this.course.courseGroup ? this.course.courseGroup : null
    };

    this.loginModal = this.modalService.show(LoginModalComponent, {initialState, class: 'modal-lg rounded-modal'});
  }

  searchVideos() {
    const initialState = {
      title: 'Modal with component',
      course: this.course
    };

    this.searchModal = this.modalService.show(VideoSearchComponent, {initialState, class: 'modal-lg'});
  }

  searchQuestions() {
    const initialState = {
      title: 'Modal with component',
      course: this.course
    };

    this.searchModal = this.modalService.show(QuestionSearchComponent, {initialState, class: 'modal-lg'});
  }

  ngOnInit() {
    console.debug('CourseComponent:ngOnInit');
    this.isSmallScreen.set(window.innerWidth < 1075);
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe((result: BreakpointState) => {
        this.isFarSmallScreen.set(result.matches);
      });

    this.trainingService.loadConfig().then(config => {
      this.config = config;

      this.courierService.init(this.user, this.config);

      if (this.route.firstChild.snapshot.params['id']) {
        this.courseId = this.route.firstChild.snapshot.params['id'];
      } else if (this.route.snapshot.paramMap.get('id')) {
        this.courseId = this.route.snapshot.paramMap.get('id');
      } else if (this.route.snapshot.queryParamMap.get('id')) {
        this.courseId = this.route.snapshot.queryParamMap.get('id');
      }
      //this.course = this.coursesService.loadCourse(this.courseId);
      this.course = this.route.snapshot.data.course;
      this.user = this.route.snapshot.data.user;
      this.api = this.route.snapshot.data.api;
      this.purchased = this.authContextService.user && this.authContextService.user.coursePermissions && this.authContextService.user.coursePermissions[this.course?.webAppId];
      if(this.user && this.course) {
        this.coursesService.accessedCourse(this.course.webAppId);
      }
      this.showStickySidenavHeader = this.course && this.course.webAppId.toUpperCase().indexOf('FIRC') !== -1;
      this.sharedService.fircStickySidenavHeaderNotificationSubject.subscribe(resp => {
        this.showStickySidenavHeader = resp;
      })

      if (this.course) {
        const volumePromises = Array<Promise<any>>();

        volumePromises.push(this.volumesService.loadGroups(this.course.webAppId));

        Promise.all(volumePromises).then(results => {
          this.volumeGroups = results[0];
        });

        if (this.user) {
          window.setTimeout(() => {
            this.progressService.refreshProgress(this.course.webAppId).then(value => {
              if (
                value &&
                value[this.course.webAppId] &&
                this.course.webWelcomeHtml &&
                this.course.webWelcomeHtml != '' &&
                (!value[this.course.webAppId].userCourse ||
                  !value[this.course.webAppId].userCourse.viewedWelcome)
              ) {
                this.dialog.open(WelcomeModalComponent, {
                  panelClass: 'welcome-dialog-panel',
                  width: '550px',
                  data: {
                    course: this.course
                  }
                });
                this.coursesService.viewedWelcome(this.course.webAppId);
              }
            });
          }, 100);
        } else if (this.course.requireRegistrationForPreview && !this.user) {
          this.signupModalService.showSignupModal(this.course, {
            allowClose: false,
            onComplete: () => {
            }
          });
        }
      }

      if (!this.renewSubscription) {
        this.renewSubscription = this.authContextService.onRenew().subscribe(value => {
          if (this.course) {
            this.coursesService.accessedCourse(this.course.webAppId).then(value => {
              console.debug('CourseComponent:ngOnInit:accessedCourse', value);
            });
          }
        });
      }

      if (!this.loginSubscription) {
        this.loginSubscription = this.authContextService.onLogin().subscribe({
            next: value => {
              if (value) {
                this.user = value;
                this.purchased = this.course && this.authContextService.user.coursePermissions[this.course.webAppId];
              } else {
                this.user = null;
                this.purchased = null;
              }
            }, error: err => {
              console.debug('CourseComponent:ngOnInit:onLogin', err);
            }
          }
        );
      }

      if (!this.logoutSubscription) {
        this.logoutSubscription = this.authContextService.onLogout().subscribe(value => {
          console.debug('CourseComponent:onLogout');
          this.user = null;
          this.purchased = false;
        });
      }

      this.authService.renew().then(value => {
        // force renew
      });

      //this.observeSession();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallScreen.set(window.innerWidth < 1075);
  }

  openSidenav(): void {
    this.isSidenavOpen.set(true);
    this.cookieService.set('isSideBarOpen', this.isSidenavOpen().toString());
  }

  ngOnDestroy() {
    this.loginSubscription?.unsubscribe();
    this.routerSubscription?.unsubscribe();
    this.logoutSubscription?.unsubscribe();
    this.renewSubscription?.unsubscribe();
    this.courierService.destroy();
  }

  toggleNavbar(): void {
    this.isNavbarCollapsed.update(value => !value);
  }

  toggleSidenav(): void {
    if (this.isFarPage()) {
      this.farSharedService.isFarSidenavOpen.update(value => !value);
      this.cookieService.set('isSideBarOpenFarPage', this.farSharedService.isFarSidenavOpen().toString());
    } else {
      this.isSidenavOpen.update(value => !value);
      this.cookieService.set('isSideBarOpen', this.isSidenavOpen().toString());
    }
  }

  closeSidenav(): void {
    this.isSidenavOpen.set(false);
    this.cookieService.set('isSideBarOpen', this.isSidenavOpen().toString());
  }

  isCustomSideNav() {
    return this.testsUiService.isActive;
    //return window.location.href.indexOf('/testprep/test') !== -1 ||
    //  window.location.href.indexOf('/testprep/learn') !== -1 ||
    //  window.location.href.indexOf('/testprep/flash') !== -1 ||
    //  window.location.href.indexOf('/checkride/prep/learn') !== -1 ||
    //  window.location.href.indexOf('/checkride/prep/flash') !== -1

  }

  routeEvent(router: Router) {
    this.routerSubscription = router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.isNavbarCollapsed.set(true);
        this.isFarPage.set(e.url.indexOf('/far') !== -1);

        if (this.isSmallScreen()) {
          this.isSidenavOpen.set(false);
        }
      }
    });
  }

  signup() {
    this.signupModalService.showSignupModal(this.course, {
      allowClose: false,
      onComplete: () => {
      }
    });
  }

  /*
  private doPing() {
    //console.debug('CourseComponent:doPing');
    // && !this.cookieService.check("PLAY_SESSION")
    if (this.user) {
      let headers = new HttpHeaders();
      headers = headers.append('x-user-id', [this.user.id]);
      const options = {
        headers: headers
      };

      const sessionCookie = this.cookieService.get('PLAY_SESSION');
      //console.debug('CourseComponent:doPing:sessionCookie', sessionCookie);
      if (!sessionCookie) {
        //console.debug('CourseComponent:doPing:sessionCookie:logout');
        this.authService.logout();
        return;
      }

      const timeSinceLastCheck = Date.now() - this.lastPing;
      if (timeSinceLastCheck > this.checkEvery && !this.pinging) { //ping every 5 minutes
        this.pinging = true;
        this.httpClient.head(this.api.getRoute("pingUrl"), options).subscribe((value) => {
          this.pinging = false;
          this.lastPing = Date.now();
        }, error => {
          console.debug('CourseComponent:pingError', error, this.user);
          if (error.status === 419 && this.user) {
            //const initialState = {
            //  title: 'Login',
            //  user: this.user,
            //  course: this.course
            //};
            this.authService.logout();

            //var modal = this.modalService.show(LoginModalComponent, {initialState, class: 'rounded-modal'});
            //modal.content.sessionTimedOut = true;
            // this.sessionTimeoutModal = this.modalService.show(SessionTimeoutComponent, { initialState });
            // this.sessionTimeoutModal.content.closeBtnName = 'Close';
          }
        });
      } else {
        //console.debug('CourseComponent:Skip session check');
      }
    }
  }

   */

  showFarPage(event) {
    event.preventDefault();

    this.router.navigateByUrl(`/course/${this.course?.webAppId}/far`);
  }

  /*
  observeSession(): void {
    console.debug('CourseComponent:observeSession');
    this.doPing();

    const ping = () => {
      this.doPing();
    };

    window.setInterval(ping, 5000);//1000 * 60 * 5); //every 5 minutes

    setTimeout(() => {
      // check the visiblility of the page
      let hidden;
      let visibilityState;
      let visibilityChange;

      if (typeof document.hidden !== 'undefined') {
        hidden = 'hidden', visibilityChange = 'visibilitychange', visibilityState = 'visibilityState';
      } else if (typeof document['mozHidden'] !== 'undefined') {
        hidden = 'mozHidden', visibilityChange = 'mozvisibilitychange', visibilityState = 'mozVisibilityState';
      } else if (typeof document['msHidden'] !== 'undefined') {
        hidden = 'msHidden', visibilityChange = 'msvisibilitychange', visibilityState = 'msVisibilityState';
      } else if (typeof document['webkitHidden'] !== 'undefined') {
        hidden = 'webkitHidden', visibilityChange = 'webkitvisibilitychange', visibilityState = 'webkitVisibilityState';
      }


      if (typeof document.addEventListener === 'undefined' || typeof hidden === 'undefined') {
        // not supported
      } else {
        document.addEventListener(visibilityChange, () => {

          switch (document[visibilityState]) {
            case 'visible':
              ping();
              break;
            case 'hidden':
              break;
          }
        }, false);
      }

      if (document[visibilityState] === 'visible') {
        // visible
      }

    });


    window.addEventListener('focus', this.doPing);
    window.addEventListener('blur', this.doPing);
    document.addEventListener('mouseover', this.doPing);
    window.addEventListener('touchstart', this.doPing);
  }*/

  changeTheme(): void {
    this.colorSchemeService.update(this.isThemeLightSelected ? 'dark' : 'light');
    this.isThemeLightSelected = !this.isThemeLightSelected;
  }
}
