import {Injectable} from '@angular/core';
import {TestsService} from './tests.service';
import {SessionType} from '../tests/enum/session-type.enum';
import {TestType} from '../tests/enum/test-type.enum';
import {TestSessionService} from './test-session.service';
import {CategoryType} from '../tests/enum/category-type.enum';
import {ActivatedRoute, Router} from '@angular/router';
import {AnalyticsService} from '../../analytics/analytics.service';
import {AuthContextService} from '../../services/auth-context.service';
import _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class TestBuilderService {

  new = true;
  testType: TestType;
  selectedCategories: Array<any>;
  sessionType: SessionType = SessionType.DEFAULT;
  numberOfQuestions: number;
  course: any;
  categoryType: CategoryType;
  questionUuids: any = [];

  constructor(
    private testsService: TestsService,
    private authContextService: AuthContextService,
    private testSessionService: TestSessionService,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService
  ) {
  }

  begin(course: any, categoryType: CategoryType) {
    this.course = course;
    this.numberOfQuestions = this.course.testQuestionCount;
    this.categoryType = categoryType;
    this.selectedCategories = [];
  }

  reset(categoryType: CategoryType) {
    this.new = true;
    this.testType = null;
    this.numberOfQuestions = this.course.questions;

    this.selectedCategories = [];
    this.sessionType = SessionType.DEFAULT;
    this.testSessionService.test = null;
    this.categoryType = categoryType;
  }

  startTest() {
    return new Promise((resolve, reject) => {
      console.log('TestBuilder.startTest');
      this.testsService.startTest(this.course.webAppId, this.numberOfQuestions, this.testType, this.testType === 'TEST' ? this.course.testTimeLimitSeconds * 1000 : 0, this.sessionType, this.categoryType, this.selectedCategories, this.questionUuids).then((test) => {
        if (this.categoryType === CategoryType.EXAM && this.sessionType === SessionType.PRACTICE_TEST) {
          this.analyticsService.startTestprepSession(this.course, this.sessionType, this.numberOfQuestions);
        } else if (this.categoryType === CategoryType.EXAM && this.sessionType !== SessionType.PRACTICE_TEST) {
          this.analyticsService.startReviewSession(this.course, this.sessionType, this.numberOfQuestions);
        } else if (this.categoryType === CategoryType.PREP) {
          this.analyticsService.startCheckrideSession(this.course, this.sessionType, this.numberOfQuestions);
        }
        this.testSessionService.setTest(test);
        this.testsService.modifyQuestions(test.questions);
        resolve(test);
      });
    });
  };


  async startSession(test: any, sessionType: SessionType, oldSessionType: SessionType, user: any): Promise<any> {
    console.log('TestBuilder.startSession');

    let questionPromise: Promise<any> = null;

    if (test.testType === 'FLASH') {
      this.testType = TestType.FLASH;
    } else {
      this.testType = TestType.LEARN;
    }
    if (test) {
      if (sessionType === SessionType.REVIEW_TEST) {
        this.sessionType = SessionType.REVIEW_TEST;
        questionPromise = this.testsService.repeatTestQuestions(this.course.webAppId, test.id);
      } else if (sessionType === SessionType.REVIEW_CORRECT) {
        this.sessionType = SessionType.REVIEW_CORRECT;
        questionPromise = this.testsService.loadCorrectQuestions(this.course.webAppId, test.id);
      } else if (sessionType === SessionType.REVIEW_INCORRECT) {
        this.sessionType = SessionType.REVIEW_INCORRECT;
        questionPromise = this.testsService.loadIncorrectQuestions(this.course.webAppId, test.id);
      } else if (sessionType === SessionType.REVIEW_MARKED) {
        this.sessionType = SessionType.REVIEW_MARKED;
        questionPromise = this.testsService.loadMarkedQuestions(this.course.webAppId, test.id);
      }
    }

    if (oldSessionType === SessionType.RANDOM_SESSION) {
      this.sessionType = oldSessionType;
    }

    return new Promise((resolve, reject) => {
      questionPromise.then((questions) => {
        console.log(`Start session with ${questions.length} questions`);
        const questionUuids = _.map(questions, (question) => {
          return question.uuid;
        });
        this.testsService.startTest(this.course.webAppId, questions.length, this.testType, this.testType === TestType.TEST ? this.course.testTimeLimitSeconds * 1000 : 0, this.sessionType, this.categoryType, this.selectedCategories, questionUuids).then(async (newtest) => {
          newtest.questions = questions;
          this.testSessionService.setTest(newtest);

          if (this.categoryType === CategoryType.EXAM && this.sessionType === SessionType.PRACTICE_TEST) {
            this.analyticsService.startReviewSession(this.course, this.sessionType, questions.length);
          } else if (this.categoryType === CategoryType.EXAM && this.sessionType !== SessionType.PRACTICE_TEST) {
            this.analyticsService.startReviewSession(this.course, this.sessionType, questions.length);
          } else if (this.categoryType === CategoryType.PREP) {
            this.analyticsService.startCheckrideSession(this.course, this.sessionType, questions.length);
          }
          resolve(newtest);
        });
      });
    });
  };

  /*
    loadTest(id) {
      return new Promise((resolve, reject) => {
        if (id === null) {
          resolve(this.test);
        } else {
          this.testsService.getTest(id).then((test) => {
              resolve(test);
            }, (error) => {
              reject(error);
            }
          );
        }
      });
    };

    loadQuestions(id) {
      return this.testsService.loadTestQuestions(id);
    };

  */
}
