<div class="pull-right" style="margin: 10px 10px 0px 0px">
  <button type="button" matDialogClose class="close" aria-label="Close">
    <app-icon glyph name="incorrect"></app-icon>
  </button>
</div>
<mat-dialog-content>
  <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(course.webWelcomeHtml)">
    <!--
    <p>It’s not your imagination—Sporty’s Learn to Fly Course has an all-new look. The same great information is still
      here, but it’s now faster, easier to use, and completely mobile friendly.</p>
    <img src="https://dl.videos.sportys.com/onlinecourse/images/pilottrainingonlinefeature.png"
         style="display: block; margin-left: auto; margin-right: auto; width: 85%;">
    <p>
    <h2>Highlights:</h2>
    <ul>
      <li>Simpler navigation: select the main training tools on the left side of the screen, then drill down to specific
        sections with our easy-to-use visual menu.
      </li>
      <li>Test prep question search: build your own study session from any keyword you choose. Select this option from the
        Start New Study Sessions option in Test Prep.
      </li>
      <li>Enhanced flashcard mode: Our new flashcard mode, for both written test and checkride prep, allows you to
        digitally flip the cards. Available in both Test Prep and Checkride Prep.
      </li>
      <li>Microsoft Flight Simulator lessons: This detailed lesson guide is the bridge between Sporty’s course and the
        latest home flight simulator. Available in the Resources section.
      </li>
      <li>ACS with embedded videos: only Sporty’s includes related video segments for each section of the Airman
        Certification Standards. Check it out under Checkride Prep.
      </li>
      <li>Bring your own documents: now you can upload your own files, whether it’s a scanned copy of your airplane’s
        manual or your flight school’s procedures. See My Documents in the Resources section.
      </li>
      <li>Video transcripts: under each video you’ll notice a new tab that includes complete transcripts for each video
        segment.
      </li>
    </ul>
    <p>Have a question about the new design or where to find a particular feature? Contact our pilot support team at <a
      href="mailto:coursesupport@sportys.com">coursesupport@sportys.com</a> and we’ll get right back with you.</p>-->
  </div>
</mat-dialog-content>
