import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TrainingService} from '../training.service';
import {PingService} from '../services/ping.service';
import {MaintenanceModeService} from '../maintenance-mode.service';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html'
})
export class MaintenanceComponent implements OnInit {
  private config: any;
  maintenanceMode: string;
  isYe: boolean = false;

  constructor(
    private maintenanceModeService: MaintenanceModeService,
    private pingService: PingService,
    private router: Router,
    private trainingService: TrainingService) {
      this.maintenanceMode = this.maintenanceModeService.getMaintenanceMode();
  }

  ngOnInit() {
    this.config = this.trainingService.loadConfig().then((config) => {
      if (this.config['user.mode'] === 'YE') {
        this.isYe = true;
      }
    });
    if (this.maintenanceMode != 'true') {
      this.pingService.ping().then((result) => {
        this.router.navigate(['/login'], { queryParamsHandling: 'preserve' });
      });
    }
      console.log('MaintenanceComponent');
    };
}

