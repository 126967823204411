import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, ReplaySubject} from 'rxjs';
import {TrainingService} from './training.service';
import {HttpClient} from '@angular/common/http';
import {AuthContextService} from './services/auth-context.service';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  subject = null;
  courseType = null;

  constructor(
    private trainingService: TrainingService,
    private httpClient: HttpClient,
    private authContextService: AuthContextService
  ) {

    this.authContextService.onLogout().subscribe(
      value => {
        console.log('ProgressService:onLogout');
        this.subject = null;
        this.courseType = null;
      }
    );


  }

  async getApi(): Promise<any> {
    return await firstValueFrom(this.trainingService.load());
  }

  loadOne(courseType: string): Observable<any> {
    console.log('ProgressService:loadOne');
    if (this.courseType != null && this.courseType === courseType && this.subject != null) {
      return this.subject;
    }
    if (this.subject == null || this.courseType !== courseType) {
      this.courseType = courseType;
      this.subject = new ReplaySubject(1);


      this.getApi().then(api => {
        this.httpClient.get(api.getRoute('courseProgressUrl'), {
          params: {
            'webAppId': courseType
          }
        }).subscribe(value => {
          this.subject.next(value);
        });
      });

    }

    return this.subject;
  }

  async refreshProgress(courseType: string): Promise<any> {
    console.log('ProgressService:refreshProgress:' + courseType);
    if (typeof courseType === 'undefined') {
      throw new Error('courseType is undefined');
    }
    if (this.subject == null) {
      this.subject = new ReplaySubject(1);
    }

    const api = await this.getApi();

    return new Promise((resolve, reject) => {
      this.httpClient.get(api.getRoute('courseProgressUrl'), {
        params: {
          'webAppId': courseType
        }
      }).subscribe({
        next: (value) => {
          this.subject.next(value);
          resolve(value);
        }, error: (error) => {
          reject(error);
        }
      });
    });
  }

  clearProgress() {
    console.log('ProgressService:clearProgress');
    if (this.subject) {
      this.subject.next({});
      this.subject = null;
    }
  }


  async loadManySummaries(courseTypes: string[]): Promise<any> {
    const api = await this.getApi();
    return this.httpClient.put(api.getRoute('courseProgressSummaryUrl'), {'webAppIds': courseTypes}).toPromise();

  }

  async loadMany(courseType: string): Promise<any>  {
    const api = await this.getApi();
    return firstValueFrom(this.httpClient.get(api.getRoute('courseProgressUrl'), {
      params: {
        'webAppId': courseType
      }
    }));
  }

  async findInvitationV2(token: string) {
    const api = await this.getApi();
    return firstValueFrom(this.httpClient.get(api.getRoute('cfi-student-findInvitation').replace('{uuid}', token)));
  }

  async acceptInvitationV2(token: string) {
    const api = await this.getApi();
    return this.httpClient.put(api.getRoute('cfi-student-acceptInvitation').replace('{uuid}', token), {}).toPromise();
  }

  async declineInvitationV2(token: string) {
    const api = await this.getApi();
    return this.httpClient.put(api.getRoute('cfi-student-declineInvitation').replace('{uuid}', token), {}).toPromise();
  }
}
