import { Component, OnInit } from '@angular/core';
import { SessionType } from '../enum/session-type.enum';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { TestBuilderService } from '../../services/test-builder.service';
import { CategoryType } from '../enum/category-type.enum';

@Component({
  selector: 'app-analysis-modal',
  templateUrl: './analysis-modal.component.html',
  styleUrls: ['./analysis-modal.component.scss']
})
export class AnalysisModalComponent implements OnInit {

  course = null;
  user = null;
  test = null;
  categoryType: CategoryType;

  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private testBuilderService: TestBuilderService
  ) {
  }

  ngOnInit() {
  }


  startQuestionsAnsweredCorrectly(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);

    this.testBuilderService.startSession(test, SessionType.REVIEW_CORRECT, test.sessionType, this.user).then((savedtest) => {
      this.bsModalRef.hide();
      this.router.navigate(['/', 'course', this.course.webAppId, 'testprep', 'learn', savedtest.id], { relativeTo: this.route });
    });

  }

  startIncorrectQuestions(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);

    this.testBuilderService.startSession(test, SessionType.REVIEW_INCORRECT, test.sessionType, this.user).then((savedtest) => {
      this.bsModalRef.hide();
      this.router.navigate(['/', 'course', this.course.webAppId, 'testprep', 'learn', savedtest.id], { relativeTo: this.route });
    });

  }

  startMarkedQuestions(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);

    this.testBuilderService.startSession(test, SessionType.REVIEW_MARKED, test.sessionType, this.user).then((savedtest) => {
      this.bsModalRef.hide();
      this.router.navigate(['/', 'course', this.course.webAppId, 'testprep', 'learn', savedtest.id], { relativeTo: this.route });
    });

  }

  retryTest(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);

    this.testBuilderService.startSession(test, SessionType.REVIEW_TEST, test.sessionType, this.user).then((savedtest) => {
      this.bsModalRef.hide();
      this.router.navigate(['/', 'course', this.course.webAppId, 'testprep', 'learn', savedtest.id], { relativeTo: this.route });
    });

  }
}
