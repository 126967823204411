import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ProgressService} from '../../progress.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {CourseService} from '../../course/services/course.service';
import {TrainingService} from '../../training.service';
import * as _ from 'underscore';
import {identityRevealedValidator} from '../../validators/emailvalidator';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthContextService} from "../../services/auth-context.service";


@Component({
  selector: 'app-request-certificate',
  templateUrl: './request-certificate.component.html',
  styleUrls: ['./request-certificate.component.scss']
})
export class RequestCertificateComponent implements OnInit, OnDestroy {

  course: any;
  user: any;
  progress: any;

  allowSubmit = false;
  allowDownload = false;

  downloadUrl: string;

  loading: boolean;

  formDisabled = true;

  progressServiceSubscription: Subscription;

  result: any;

  form = new UntypedFormGroup({
    name: new UntypedFormControl(),
    address: new UntypedFormControl(),
    city: new UntypedFormControl(),
    state: new UntypedFormControl(),
    zip: new UntypedFormControl(),
    phone: new UntypedFormControl(),
    email: new UntypedFormControl(),
    confirmEmail: new UntypedFormControl()
  }, {validators: identityRevealedValidator});

  constructor(
    private authService: AuthService,
    private progressService: ProgressService,
    private courseService: CourseService,
    private trainingService: TrainingService,
    private route: ActivatedRoute,
    private httpClient: HttpClient,
    private authContext: AuthContextService
  ) {
  }

  ngOnInit() {
    this.course = this.route.snapshot.data.course;
    console.log('RequestEndorsementComponent:init', this.course.webAppId);
    this.progressService.refreshProgress(this.course.webAppId).then(progress => {
      console.log('RequestEndorsementComponent:init:on progress', this.course.webAppId);
      if (progress && progress[this.course.webAppId]) {
        console.log('RequestEndorsementComponent:init:on progress', this.course.webAppId);
        this.progress = progress[this.course.webAppId];

        if (this.progress.writtenExamRequested) {
          this.allowDownload = true;
          this.downloadUrl = this.progress.certificationRequests.COURSE_COMPLETION.downloadUrl;
        } else {
          this.formDisabled = false;
          this.allowSubmit = true;
        }

      } else {
        this.progress = null;
      }
    });

  }

  ngOnDestroy() {
    if (this.progressServiceSubscription) {
      this.progressServiceSubscription.unsubscribe();
    }
  }


  onSubmit() {
    this.loading = true;
    const value = _.clone(this.form.value);
    value.modeType = 'COURSE';
    value.webAppId = this.course.webAppId; // to be updated with webAppId
    value.certificationArtifactType = "pdf";

    const callback = async (response) => {
      this.downloadUrl = await this.courseService.getCertificateDownloadUrl(response);
      this.result = response;
      if (response.success) {
        this.allowSubmit = false;
        this.allowDownload = true;
      } else {
        this.allowSubmit = true;
      }
      this.loading = false;
      this.formDisabled = true;
      this.progressService.refreshProgress(this.course.webAppId);
    };


    if (this.course.isCourseMixed) {
      this.courseService.submitCourseCompletionRequestMixed(this.course.webAppId, value).then(callback);
    } else {
      this.courseService.submitCourseCompletionRequest(this.course.webAppId, value).then(callback);
    }
  }

}
