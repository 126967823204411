import { Injectable, Renderer2, RendererFactory2, computed, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ColorSchemeService {
  colorScheme = signal('');
  colorMode = signal('');
  isDarkMode = computed(() => this.colorScheme() === 'dark');

  // Define prefix for clearer and more readable class names in scss files
  private colorSchemePrefix = 'color-scheme-';
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    // Create new renderer from renderFactory, to make it possible to use renderer2 in a service
    this.renderer = rendererFactory.createRenderer(null, null);
    this.load();
  }

  _detectPrefersColorScheme() {
    // Detect if prefers-color-scheme is supported
    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      // Set colorScheme to Dark if prefers-color-scheme is dark. Otherwise, set it to Light.
      this.colorScheme.set('light');
      this.colorMode.set('light');
    } else {
      // If the browser does not support prefers-color-scheme, set the default to dark.
      this.colorScheme.set('light');
    }
  }

  _setColorScheme(scheme: string) {
    this.colorScheme.set(scheme);
    localStorage.setItem('prefers-color', scheme);
  }

  _getColorScheme() {
    let localStorageColorScheme = localStorage.getItem('prefers-color');
    let localStorageColorMode = localStorage.getItem('color-mode');
    // Check if any prefers-color-scheme is stored in localStorage
    if (localStorageColorScheme) {
      if (localStorageColorScheme === 'system') {
        localStorageColorScheme = this.getSystemColorScheme();
      }
      // Save prefers-color-scheme from localStorage
      this.colorScheme.set(localStorageColorScheme);
      this.colorMode.set(localStorageColorMode);
    } else {
      // If no prefers-color-scheme is stored in localStorage, try to detect OS default prefers-color-scheme
      this._detectPrefersColorScheme();
    }
  }

  load() {
    this._getColorScheme();
    this.renderer.addClass(document.body, this.colorSchemePrefix + this.colorScheme());
  }


  update(scheme: string) {
    localStorage.setItem('color-mode', scheme);
    if (scheme === 'system') {
      scheme = this.getSystemColorScheme();
    }
    this._setColorScheme(scheme);
    // Remove the old color-scheme class
    this.renderer.removeClass(
      document.body,
      this.colorSchemePrefix + (this.colorScheme() === 'dark' ? 'light' : 'dark')
    );
    // Add the new / current color-scheme class
    this.renderer.addClass(document.body, this.colorSchemePrefix + scheme);
  }

  getSystemColorScheme(): 'light' | 'dark' {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    } else {
      return 'light';
    }
  }

}
