import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SecurityContext, SimpleChanges } from '@angular/core';
import { FigureModalComponent } from '../../course/tests/figure-modal/figure-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'underscore';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CourseSharedService } from 'src/app/course/course-shared.service';

@Component({
  selector: '[app-question]',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent implements OnInit, AfterViewInit, OnChanges {

  @Input()
  question: any;

  @Input()
  course: any;

  @Input()
  purchased: boolean;

  @Input()
  user: any;

  @Input()
  imagesClass = ['justify-content-start'];

  @Input()
  correct = undefined;

  figureModal = null;
  html: SafeHtml;
  images = Array<any>();


  constructor(
    private element: ElementRef,
    private modalService: BsModalService,
    private _sanitizer: DomSanitizer,
    //public courseSharedService: CourseSharedService
  ) {
  }

  ngOnInit(): void {
    this.html = this._sanitizer.bypassSecurityTrustHtml(this.question.parsed_questionText);
  }


  ngAfterViewInit = () => {
    //this.courseSharedService.notifyCloseCourseSidenav(true);
    const links = this.element.nativeElement.querySelectorAll('a.figure, a.legend');
    this.images.length = 0;

    _.forEach(links, (link) => {
      console.log('QuestionComponent:add link', link);

      link.addEventListener('click', this.onclick);


      this.images.push({
        type: (link.classList.contains('figure')) ? 'figure' : 'legend',
        code: link.innerText
      });
    });
  };

  onclick = (event) => {
    console.log('QuestionComponent:link:click', event);
    event.preventDefault();
    event.stopPropagation();


    if (event.target.attributes['data-figure']) {
      this.showFigure(event.target.attributes['data-figure'].value);
    } else if (event.target.attributes['data-legend']) {
      this.showLegend(event.target.attributes['data-legend'].value);
    }
  };


  ngOnChanges(changes: SimpleChanges) {
    console.log('QuestionComponent:ngOnChanges', changes);
    if (changes['question']) {
      const links = this.element.nativeElement.querySelectorAll('a.figure, a.legend');
      _.forEach(links, (link) => {
        console.log('QuestionComponent:remove link', link);
        link.removeEventListener('click', this.onclick);
      });

      this.html = this._sanitizer.bypassSecurityTrustHtml(changes['question']['currentValue']['parsed_questionText']);
      window.setTimeout(() => {
        this.ngAfterViewInit();
      });
    }
  }

  show(event, image) {
    event.preventDefault();
    event.stopPropagation();

    if (image.type === 'figure') {
      this.showFigure(image.code);
    } else {
      this.showLegend(image.code);
    }
  }

  showFigure(figure) {

    console.log('showFigure', this.question);

    const initialState = {
      title: 'Modal with component',
      course: this.course,
      purchased: this.purchased,
      user: this.user,
      question: this.question,
      type: 'figure',
      code: figure ? figure : this.question.figures[0]
    };

    this.figureModal = this.modalService.show(FigureModalComponent, { initialState, class: 'modal-lg modal-figure' });
    this.figureModal.content.closeBtnName = 'Close';
  }

  showLegend(legend) {

    console.log('showLegend', this.question);


    const initialState = {
      title: 'Modal with component',
      course: this.course,
      purchased: this.purchased,
      user: this.user,
      question: this.question,
      type: 'legend',
      code: legend
    };

    this.figureModal = this.modalService.show(FigureModalComponent, { initialState, class: 'modal-lg modal-figure' });
    this.figureModal.content.closeBtnName = 'Close';
  }
}
