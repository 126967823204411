<ng-container *ngIf="isYe; else paid">

  <div class="container" style="margin-top: 20px;">
    <div class="row">
      <div class="col-md-4 d-flex justify-content-end">
        <div>
          <img src="assets/images/Young_Eagles.png" style="max-width:240px; float:left; padding:10px;"
               alt="Young Eagles Logo">
        </div>
      </div>
      <div class="col-md-8 d-flex align-items-center justify-content-center">
        <div>
          <h1 style="font-size: 56px;">Welcome EAA<br>Young Eagles!</h1>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 d-flex align-items-center justify-content-center">
      <div>
        <!-- ornate html that is aviation themed -->
        <h1 class="display-4">Maintenance Mode</h1>
        <div class="alert alert-info">
          <p class="lead">We are currently updating Sporty’s Courses for Young Eagles. Please check back soon for some
            exciting new features.</p>
          <p class="lead">Thank you for your patience.</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #paid>

  <div class="container" style="margin-top: 20px;">
    <div class="row">
      <div class="col-lg-4 col-md-12 d-flex align-items-center justify-content-center">
        <img class="filter-blue-logo"
             style="width:300px !important; height: 200px !important;"
             src="https://dl.videos.sportys.com/onlinecourse/images/sportys-logo-block-airplane.svg"
             alt="Sporty's">
      </div>
      <div class="col-lg-8 col-md-12 d-flex align-items-center justify-content-center">
        <div style="text-align: center;">
          <h1 style="font-size: 56px;">
            Sporty's Pilot Training Online
          </h1>
        </div>
      </div>
      <div class="col-md-12 d-flex align-items-center justify-content-center">

        <!-- ornate html that is aviation themed -->
        <h1 class="display-4">Maintenance Mode</h1>
      </div>
      <div class="col-md-12 d-flex align-items-center justify-content-center">
        <div class="alert alert-info">
          <p class="lead">We are currently updating Sporty’s Courses. Please check back soon for some
            exciting new features.</p>
          <br>
          <p class="lead">Thank you for your patience.</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>




