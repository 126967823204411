import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(duration: string, ...args: unknown[]): unknown {
    return duration.replace(/00:/, '');
  }
}
