import {Component, OnInit} from "@angular/core";

@Component({
  selector: 'app-null',
  template: ''
})
export class NullComponent implements OnInit {

  ngOnInit(): void {
  }
}
