import { NgModule } from '@angular/core';
import { DoughnutChartComponent } from '../components/doughnut-chart/doughnut-chart.component';
import { VideoSearchComponent } from './video-search/video-search.component';
import { CommonModule } from '@angular/common';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { VideoQuizComponent } from './video-quiz/video-quiz.component';
import { NgxAnimationsModule } from '../ngx-animations/ngx-animations.module';
import { VideoModalComponent } from './video-modal/video-modal.component';
import { VideojsComponent } from './videojs/videojs.component';
import { FlippyComponent } from '../course/tests/flippy/flippy.component';
import { ConfirmationModalComponent } from './confirmation/confirmation-modal.component';
import { QuestionSearchComponent } from './question-search/question-search.component';
import { CustomRadioComponent } from './custom-radio/custom-radio.component';
import { MarketingPlateComponent } from './marketing-plate/marketing-plate.component';
import { FaaTestMessageComponent } from './faa-test-message/faa-test-message.component';
import { QuestionComponent } from './question/question.component';
import { ResetModalComponent } from './reset-modal/reset-modal.component';
import { TestModalViewComponent } from './modal-view/test-modal-view.component';
import { EmptyComponent } from './empty/empty.component';
import { YoungEaglesWarningComponent } from './young-eagles-warning/young-eagles-warning.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { RouterModule } from '@angular/router';
import { SignupComponent } from './signup/signup.component';
import { LoginComponent } from './login/login.component';
import { IconWithTitleComponent } from './icon-with-title/icon-with-title.component';
import { IconWithTitleListComponent } from './icon-with-title-list/icon-with-title-list.component';
import { CourseListComponent } from './course-list/course-list.component';
import { InfoTooltipComponent } from './info-tooltip/info-tooltip.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { UserInfoComponent } from './user-info/user-info.component';
import { GenericModalService } from './generic-modal/generic-modal.service';
import { GenericModalComponent } from './generic-modal/generic-modal.component';
import { SessionComponent } from './session/session.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { BadgeTooltipComponent } from './badge-tooltip/badge-tooltip.component';
import { IconTooltipComponent } from './icon-tooltip/icon-tooltip.component';
import { MagicLinkModalComponent } from './magic-link-modal/magic-link-modal.component';
import { BrandContainerComponent } from './brand-container/brand-container.component';
import { PurchaseCourseBannerComponent } from './purchase-course-banner/purchase-course-banner.component';
import { NgChartsModule } from 'ng2-charts';
import { NullComponent } from './null.component';
import { PipeModule } from '../pipes/pipes.module';
import { AccordionMenuComponent } from '../course/sidenav/accordion-menu/accordion-menu.component';
import { IconModule } from './icon/icon.module';
import { AcsSessionComponent } from './acs-session/acs-session.component';

@NgModule({
  declarations: [
    DoughnutChartComponent,
    VideoSearchComponent,
    VideoQuizComponent,
    VideoModalComponent,
    VideojsComponent,
    QuestionSearchComponent,
    FlippyComponent,
    ConfirmationModalComponent,
    CustomRadioComponent,
    MarketingPlateComponent,
    FaaTestMessageComponent,
    QuestionComponent,
    ResetModalComponent,
    IconWithTitleComponent,
    IconWithTitleListComponent,
    InfoTooltipComponent,
    CourseListComponent,
    TestModalViewComponent,
    EmptyComponent,
    YoungEaglesWarningComponent,
    SidenavComponent,
    GenericModalComponent,
    SignupComponent,
    LoginComponent,
    UserInfoComponent,
    SessionComponent,
    AcsSessionComponent,
    ProgressBarComponent,
    BadgeTooltipComponent,
    IconTooltipComponent,
    MagicLinkModalComponent,
    BrandContainerComponent,
    PurchaseCourseBannerComponent,
    NullComponent
  ],
  exports: [
    DoughnutChartComponent,
    VideoQuizComponent,
    VideojsComponent,
    FlippyComponent,
    CustomRadioComponent,
    MarketingPlateComponent,
    QuestionComponent,
    IconWithTitleComponent,
    IconWithTitleListComponent,
    BadgeTooltipComponent,
    IconTooltipComponent,
    InfoTooltipComponent,
    CourseListComponent,
    SidenavComponent,
    SignupComponent,
    LoginComponent,
    UserInfoComponent,
    SessionComponent,
    AcsSessionComponent,
    ProgressBarComponent,
    BrandContainerComponent,
    PurchaseCourseBannerComponent,
    NullComponent,
    AccordionMenuComponent,
    IconModule
  ],
  imports: [
    NgChartsModule,
    CommonModule,
    FormsModule,
    PipeModule,
    ReactiveFormsModule,
    NgxAnimationsModule,
    PopoverModule,
    RouterModule,
    AccordionMenuComponent,
    IconModule
  ],
  providers: [GenericModalService]
})
export class ComponentModule { }
