import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../services/auth.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ProgressService } from '../../progress.service';
import { Subscription } from 'rxjs';
import { SignupModalService } from '../../signup-modal.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import { TrainingService } from '../../training.service';
import { ResetModalComponent } from '../reset-modal/reset-modal.component';
import { MagicLinkModalComponent } from '../magic-link-modal/magic-link-modal.component';
import { CoursesService } from 'src/app/courses.service';
import { SignupModalComponent } from '../signup-modal/signup-modal.component';
import { take } from 'rxjs/operators';
import { InstructorService } from 'src/app/cfi/students/instructor.service';
import {ActivatedRoute, Router} from '@angular/router';
import { AuthContextService } from 'src/app/services/auth-context.service';
import { GenericModalButtonModel } from '../models/generic-modal-button-model';
import { GenericModalService } from '../generic-modal/generic-modal.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit, OnDestroy {

  message: string;
  working: boolean;
  course: any;
  signupModal: BsModalRef;
  loginSubscription: Subscription;
  signupModalSubscription: Subscription;
  password = true;
  resetModal: BsModalRef;
  onComplete: () => void;
  allowClose = true;
  config: any;
  public sessionTimedOut = false;
  public invitationToken = '';

  form = new UntypedFormGroup({
    username: new UntypedFormControl(),
    password: new UntypedFormControl(),
  });
  cfiCourse: any;

  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private progressService: ProgressService,
    private modalService: BsModalService,
    private signupModalService: SignupModalService,
    private analyticsService: AnalyticsService,
    private trainingService: TrainingService,
    private instructorService: InstructorService,
    private coursesService: CoursesService,
    private activatedRoute: ActivatedRoute,
    private authContextService: AuthContextService,
    private modalRef: BsModalRef,
    private genericModalService: GenericModalService,
    private router: Router
  ) {

  }

  ngOnInit() {
    //this.bsModalRef.setClass('modal-lg');
    this.trainingService.loadConfig().then(val => {
      this.config = val
    });

    this.coursesService.loadCourse('CFI_PORTAL').subscribe(value => {
      this.cfiCourse = value;
    });
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.invitationToken = params.token;
    });

  }

  ngOnDestroy() {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
    if (this.signupModalSubscription) {
      this.signupModalSubscription.unsubscribe();
    }
  }

  onSubmit() {
    console.log('LoginComponent.onSubmit');
    this.working = true;

    this.loginSubscription = this.authService.login(this.form.get('username').value, this.form.get('password').value, this.course).subscribe(value => {
        if (!!this.invitationToken){
          this.instructorService.getInstructorInvitation(this.invitationToken).subscribe(resp=>{
            if (resp.email.toUpperCase() === this.authContextService.user.email.toUpperCase()){
              this.instructorService.acceptInvitation(this.invitationToken).subscribe(()=>{
                const buttons = [
                  new GenericModalButtonModel('okayBtn', 'btn p-2 text-wrap', 'OK'),
                ];
                this.modalRef = this.genericModalService.openGenericConfirmationModal(`Success!`, `You and your students are now connected to ${resp.school.name}.`, 'sm', buttons);
                this.modalRef.content.result.subscribe(res => {
                  this.renewConnection();
                  this.modalRef.hide();
                });
              });
            }
          });
      }else{
        this.renewConnection();
        if(this.authService.deepLinkRoute){
          this.router.navigate([this.authService.deepLinkRoute]);
          this.authService.deepLinkRoute = null
        }
      }
    }, error => {
      console.error(error.error.message);
      this.message = error.error.message;
      this.working = false;
    });
  }

  private renewConnection() {
    this.authService.renew().then(renewResult => {
      if (this.course) {
        this.analyticsService.login(this.course);
        this.progressService.refreshProgress(this.course.webAppId);
      }
      this.bsModalRef.content.user = renewResult;
      this.bsModalRef.hide();

      if (this.onComplete) {
        this.onComplete();
      }

    });
  }

  /*
  signup() {

    this.bsModalRef.hide();
    if (this.onComplete) {
      this.signupModalService.showSignupModal(this.course, { allowClose: this.allowClose, onComplete: this.onComplete });
    } else {
      this.signupModalService.showSignup(this.course);
    }
  }*/

  // resetPassword($event) {
  //   this.bsModalRef.hide();
  //   this.signupModalService.showReset(this.course, { allowClose: this.allowClose, onComplete: this.onComplete });
  // }


  resetPassword($event) {
    $event.stopPropagation();
    $event.preventDefault();
    //this.authService.resetPassword(this.form.get('username').value).then(value => {
    //  console.log('LoginComponent:resetPassword', value);
    //});

    const initialState = {};

    this.resetModal = this.modalService.show(ResetModalComponent, { initialState, class: 'modal-lg rounded-modal' });

  }

  openSignupForm($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.bsModalRef.hide();

    const initialState = { onComplete: this.onLoginComplete, course: this.course };

    this.signupModal = this.modalService.show(SignupModalComponent, { initialState, class: 'modal-lg rounded-modal' });
    this.signupModal.content.closeBtnName = 'Close';

    this.modalService.onHide.pipe(take(1)).subscribe(value => {
      console.log('LoginModalComponent:' + value);

      if (this.signupModal.content.user) {
        this.onLoginComplete();
      }
    });
  }

  onLoginComplete() {

    this.authService.renew().then(value => {
    });
  }

  magicLink($event) {
    $event.stopPropagation();
    $event.preventDefault();
    const initialState = {};
    this.resetModal = this.modalService.show(MagicLinkModalComponent, { initialState, class: 'modal-lg rounded-modal' });

  }
}
