import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {TrainingService} from '../../training.service';
import {ReplaySubject, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  fircStickySidenavHeaderNotificationSubject: Subject<boolean> = new Subject<boolean>();
  fircEnableQuizNotificationSubject: Subject<boolean> = new Subject<boolean>();
  fircQuizzCompletedModalClicked: Subject<string> = new Subject<string>();


  constructor(private httpClient: HttpClient, private trainingService: TrainingService) {
  }

  async loadSharedWithMe(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.get(api.getRoute('progress-sharedWithMe')).subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject();
        });
      });
    });
  }

  async sharedByMe(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.get(api.getRoute('progress-sharedByMe')).subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject();
        });
      });
    });
  }

  async revokeSharing(share, revoked): Promise<any> {
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    const body = new HttpParams()
      .set('token', share.token)
      .set('revoke', revoked);

    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('progress-revokeSharing'), body, {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        }).subscribe(result => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
      });
    });
  }

  async shareWithEmail(email): Promise<any> {
    const body = new HttpParams()
      .set('email', encodeURIComponent(email));

    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('progress-shareWithEmail'), body, {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        }).subscribe(result => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
      });
    });
  }
}
