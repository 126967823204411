<div class="content d-flex justify-content-center align-items-center mt-5">
  @if (this.error) {
    <div class="plate plate-light_blue p-0 m-2" style="max-width:900px;">
      <div class="plate plate-dark_blue swoosh swoosh-cfi-home">
        <div>
          <h1>Authentication error</h1>
        </div>
      </div>
      <div class="plate-header p-4">
        This direct login link has expired. Please <a [routerLink]="['/','login']">go here to login</a> to your Sporty’s
        account to access your courses.
      </div>
    </div>
  }
</div>
