<div class="modal-header about-modal-header">
  <h3 class="dark pull-left">About the FAA Knowledge Test ({{page}}/2)</h3>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <app-icon glyph name="incorrect"></app-icon>
  </button>
</div>
<div class="modal-body about-modal">
  <div class="about-page page-1" *ngIf="page===1">
    <div>
      <ul>
        <li>
          You can take the test at one of hundreds of computer testing centers. Visit FAA.gov for information
          on
          testing centers.
        </li>
        <li>
          The FAA does not currently publish written test questions. Questions that appear in Sporty’s Written
          Test Prep are representative of actual test questions you may encounter on the
          FAA written exam.
        </li>
      </ul>
    </div>
    <div class="about-modal-test">
      <h3 class="dark">The Test</h3>
      <div>
        <div>
          <app-icon glyph name="test"></app-icon>
          70% Passing Score<br>
          <span class="inactive">80% for Sporty's Endorsement</span>
        </div>
        <div>
          <app-icon glyph name="test_prep"></app-icon>
          Carefully read the written instructions
        </div>
        <div>
          <app-icon glyph name="marked"></app-icon>
          If difficult, mark and return later
        </div>
        <div>
          <app-icon glyph name="calculator"></app-icon>
          When solving calculations, choose the answer
        </div>
      </div>
    </div>
    <div class="about-modal-required">
      <h3 class="dark">Required Items</h3>
      <ul>
        <li>
          Identification
        </li>
        <li>
          Logbook endorsement or other accepted form of identification.
        </li>
      </ul>
    </div>

  </div>
  <div class="about-page page-2" *ngIf="page===2">
    <div class="about-modal-aids">
      <h3 class="dark">Use of Test Aids</h3>
      <ul>
        <li>
          You may use reference materials provided with the test along with straight lines, protractors,
          plotters,
          flight computers and log sheets.
        </li>
        <li>
          Centers may request memory be cleared prior to using a flight computer.
        </li>
      </ul>
    </div>
    <div class="about-modal-reports">
      <h3 class="dark">Test Reports</h3>
      <ul>
        <li>
          Upon completion, you will receive your Airman Knowledge Test Report reflecting your score, which
          will
          have to be presented to the examiner prior to taking the practical
          test.
        </li>
        <li>
          Test reports are valid for 24 calendar months.
        </li>
        <li>
          Your instructor is required to provide instruction on each of the knowledge areas listed on your
          Airman
          Knowledge Test Report.
        </li>
        <li>
          Duplicate Test Reports may be obtained from FAA for a fee.
        </li>
      </ul>
    </div>
  </div>
  <div class="page-controls">
    <div></div>
    <div class="page-control-dots">
      <!--<span (click)="page = 1" [ngClass]="{'dot-active':page == 1}">&middot;</span>
      <span (click)="page = 2" [ngClass]="{'dot-active':page == 2}">&middot;</span>-->
      <button class="btn btn-sm btn-primary btn-round btn-shadow" (click)="bsModalRef.hide()">
        Begin Test
      </button>
    </div>
    <div class="page-control-nav">
      <button class="btn btn-sm btn-white btn-round btn-shadow" *ngIf="page === 1" (click)="page = 2">
        Continue to Page 2
      </button>
      <button class="btn btn-sm btn-white btn-round btn-shadow" *ngIf="page === 2" (click)="page = 1">
        Return to Page 1
      </button>
    </div>
  </div>
</div>