<div class="responsive-content">
  <div class="responsive-content-header">
  </div>
  <div class="responsive-content-body">

    <p class="mb-2">Congratulations on completing Sporty’s {{course.courseGroup.name}}! You are now eligible to receive FAA
      WINGS credit for
      your training.</p>
    <div>
      <p class="mb-2">WINGS is a pilot proficiency initiative designed to help you create a continuing education program for your
        unique flying requirements through a variety of accepted courses. To register and to learn more, please visit
        <a href="https://www.faasafety.gov/WINGS/pub/learn_more.aspx" target="_blank">https://www.faasafety.gov/WINGS/pub/learn_more.aspx</a>.
      </p>

      <p class="mb-2">
        If you wish to receive WINGS credit, complete the form below. Please note, you must have a WINGS account
        established and use the matching email address in your request.
      </p>

      <p class="mb-2">
        If you have met these minimum requirements, please submit the information below.
      </p>
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="stylized">
      <div class="form-row">

        <div class="form-group col-md-6">
          <label>Name on Photo ID</label>
          <input formControlName="name" class="form-control form-control-sm form-control form-control-sm-sm" type="text"
                 minlength="4"
                 [attr.disabled]="formDisabled || null" required/>
        </div>
        <div class="form-group col-md-6">
          <label>Street Address</label>
          <input formControlName="address" class="form-control form-control-sm" type="text"
                 [attr.disabled]="formDisabled || null"
                 required/>
        </div>
        <div class="form-group col-md-6">
          <label>City</label><input formControlName="city" class="form-control form-control-sm"
                                    [attr.disabled]="formDisabled || null"
                                    type="text"
                                    required>
        </div>
        <div class="form-group col-md-6">
          <label>State</label>
          <select name="State" formControlName="state" class="form-control form-control-sm"
                  [attr.disabled]="formDisabled || null"
                  required>
            <option value="" selected="selected">Select a State</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
            <option value="--" label="Other Country">Other Country</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label>Zip</label>
          <input formControlName="zip" class="form-control form-control-sm" type="text"
                 [attr.disabled]="formDisabled || null"
                 required>
        </div>
        <div class="form-group col-md-6">
          <label>Phone</label><input formControlName="phone" class="form-control form-control-sm"
                                     [attr.disabled]="formDisabled || null"
                                     type="text" required>
        </div>
        <div class="form-group col-md-6">
          <label>Email <span class="text-danger" *ngIf="form.errors?.mismatchedEmail && (form.touched || form.dirty)">Emails must match</span></label>
          <input formControlName="email" class="form-control form-control-sm"
                 [attr.disabled]="formDisabled || null"
                 type="email" required>
        </div>

        <div class="form-group col-md-6">
          <label>Confirm Email <span class="text-danger"
                                     *ngIf="form.errors?.mismatchedEmail && (form.touched || form.dirty)">Emails must match</span></label>
          <input formControlName="confirmEmail" [attr.disabled]="formDisabled || null"
                 class="form-control form-control-sm"
                 type="email" required>
        </div>
        <div class="col-12" *ngIf="course.passingTestsRequired > 1">
          <p>By submitting this information, I certify that I have met the eligibility requirements for the Sporty's
            Completion Certificate having completed the entire video training course and completed at least two practice
            examinations with a score of at least 80%.</p>
        </div>
        <div class="col-12 w-100">
          <div class="alert alert-success" *ngIf="result">
              {{result.message}}
          </div>
        </div>
        <div class="col-12 w-100">
          <div class="alert alert-danger" *ngIf="error">
            {{error}}
          </div>
        </div>

        <div class="col-12 w-100 submit-footer">
          <div class="d-flex justify-content-center">
            <button type="submit" class="btn btn-round btn-primary btn-width-fixed-240"
                    *ngIf="allowSubmit"
                    [attr.disabled]="!form.touched || form.status === 'INVALID' || formDisabled || loading">Submit
            </button>
          </div>
        </div>
      </div>

    </form>
  </div>
</div>
