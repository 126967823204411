import { Component, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-young-eagles-warning',
  templateUrl: './young-eagles-warning.component.html',
  styleUrls: ['./young-eagles-warning.component.scss']
})
export class YoungEaglesWarningComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
  }

}
