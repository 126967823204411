<div class="modal-body">
  <div class="step-1" *ngIf="step === 1">
    <div class="grade-header">
      <div class="graph">
        <app-doughnut-chart [percent]="grade" [innerLabel]="true" cutout="80"></app-doughnut-chart>
      </div>
      <p>
        You answered {{questionResults.correct}} out of {{questionResults.totalQuestions}} questions correctly.
      </p>
      <div>
        <span>Time Spent:</span>
        <span>{{timeSpent | time}}</span>
      </div>
    </div>
    <div class="review-buttons" *ngIf="purchased">
      <button class="btn btn-white btn-round btn-shadow" (click)="reviewAll()"
        [attr.disabled]="questionResults.correct === 0?'disabled':null">Review All Questions</button>
      <button class="btn btn-white btn-round btn-shadow" (click)="reviewMissed()"
        [attr.disabled]="questionResults.incorrect === 0?'disabled':null">Review Missed questions</button>
      <button class="btn btn-white btn-round btn-shadow" (click)="newSession()">New Study Session...</button>
    </div>
    <div class="review-buttons" *ngIf="user && !purchased">
      TODO Purchase the course sales message.
    </div>
    <div class="review-buttons" *ngIf="!user">
      TODO Purchase the course sales message.
      <div *ngIf="!user">
        <button class="w-100 btn btn-white btn-round btn-shadow btn-icon" (click)="signup()">
          <div class="sportys-log-in sportys-log-in-icon"></div>
          Create account or log in to save session
        </button>
      </div>
    </div>
  </div>
  <div class="step-2" *ngIf="step === 2">

    <div *ngIf="!user">
      <p>
        <a (click)="step = 1" class="btn-link"><i class="fa fa-chevron-left"></i></a>
        Create an Account or Log In to get access to advanced study modes.
      </p>
    </div>
    <div class="review-buttons" *ngIf="!user">

      <a class="btn btn-white btn-round btn-shadow" (click)="signup()">
        <div class="sportys-log-in sportys-log-in-icon"></div>
        Create Account or Log In
      </a>
    </div>
    <div>
      <p>Create a new study session using this graded session.<br>Create a new session based on:</p>
    </div>
    <div class="review-buttons">
      <button class="btn btn-white btn-round btn-shadow btn-icon" (click)="startQuestionsAnsweredCorrectly(this.test)"
        [attr.disabled]="!user || questionResults.correct === 0?'disabled':null">
        <div class="sportys-correct sportys-correct-icon"></div>
        Correct Questions
      </button>
      <button class="btn btn-white btn-round btn-shadow btn-icon" (click)="startIncorrectQuestions(this.test)"
        [attr.disabled]="!user || questionResults.incorrect === 0?'disabled':null">
        <div class="sportys-incorrect sportys-incorrect-icon"></div>
        Incorrect Questions
      </button>
      <button class="btn btn-white btn-round btn-shadow btn-icon" (click)="startMarkedQuestions(this.test)"
        [attr.disabled]="!user || questionResults.marked === 0?'disabled':null">
        <div class="sportys-marked sportys-marked-icon"></div>
        Marked Questions
      </button>
      <button class="btn btn-white btn-round btn-shadow btn-icon" (click)="retryTest(this.test)"
        [attr.disabled]="!user?'':null">
        <div class="sportys-retry sportys-retry-icon"></div>
        Retry Entire Session
      </button>
    </div>
  </div>
  <div class="grade-footer" *ngIf="!user">
    <a (click)="returnToTestPrep()" class="basic-link">Return to Learn Home</a>
  </div>
  <div class="grade-footer" *ngIf="user">
    <a (click)="returnToTestPrep()" class="basic-link">Return to Learn Home</a>
  </div>
</div>