import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginModalComponent } from '../login-modal/login-modal.component';

@Component({
  selector: 'app-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.scss']
})
export class SessionTimeoutComponent implements OnInit {

  course: any;

  constructor(public bsModalRef: BsModalRef, private modalService: BsModalService) {
  }

  ngOnInit() {
  }

  close() {
    this.bsModalRef.hide();
  }

  login() {
    this.bsModalRef.hide();
    const initialState = {
      title: 'Modal with component',
      course: this.course,
      courseGroup: this.course.courseGroup
    };

    var modal = this.modalService.show(LoginModalComponent, { initialState, class: 'rounded-modal' });
    modal.content.sessionTimedOut = true;
  }


}
