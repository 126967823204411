import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {TrainingService} from './training.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import * as _ from 'underscore';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {ProgressService} from './progress.service';
import moment from 'moment';
import {CourseHiddenStatus} from './course/enums/course-hidden-status.enum';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  api = null;
  config: any;

  private courseTypes: any = null;
  private courseTypesByName: any = null;
  private dataObs$ = new ReplaySubject(1);
  private courses = new Map();
  private courseObservables = new Map();
  private loadedCourses = false;

  constructor(private trainingService: TrainingService,
              private progressService: ProgressService,
              private httpClient: HttpClient,
              private router: Router,
              private location: Location) {


    this.trainingService.load().subscribe(value => {
      this.api = value;
    });

    this.trainingService.loadConfig().then(value => {
      this.config = value;
    });
  }

  loadApi(): Observable<any> {
    return this.dataObs$;
  }

  public getRecentCourses(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.api.getRoute('recentCoursesUrl'));
  }

  openCourse(course: any, userCourse?) {
    course.courseUrl = '/course/' + course.webAppId;
    console.log(course.courseUrl);
    if (_.isString(course)) {
      const route = ['/', 'course', course];
      if (userCourse?.homePage) {
        route.push(userCourse?.homePage);
      }
      this.router.navigate(route);
    } else if (course.courseUrl && course.courseUrl.toLowerCase().startsWith('http')) {
      window.location.replace(course.courseUrl);
    } else {
      const route = [course.courseUrl];
      if (userCourse?.homePage) {
        route.push(userCourse?.homePage);
      }
      this.router.navigate(route);
    }
  }

  load(): Observable<any> {

    if (!this.loadedCourses) {
      this.loadedCourses = true;
      this.trainingService.load().subscribe(value => {
        this.api = value;
        this.httpClient.get(this.api.getRoute('coursesUrl')).subscribe(value => {
          let allCourseTypes = _.values(value);

          allCourseTypes.map(course => {
            if (course.homePageType != null) {
              let homePageType = course.homePageType;
              course.homePageType = {};
              course.homePageType = {
                enumName: homePageType,
                route: homePageType.toLowerCase()
              };
            }
          });

          allCourseTypes = _.sortBy(allCourseTypes, (course) => {
            return course['orderNum'];
          });

          let hiddenCourses = new Map();
          _.chain(allCourseTypes).filter((course) => {
            return course.status === CourseHiddenStatus.HIDDEN;
          }).each((course) => {
            if (course.status === CourseHiddenStatus.HIDDEN) {
              hiddenCourses.set(course.webAppId, course);
            }
          });

          let removedCourses = new Map();
          _.chain(allCourseTypes).filter((course) => {
            return course.status === CourseHiddenStatus.REMOVED;
          }).each((course) => {
            if (course.status === CourseHiddenStatus.REMOVED) {
              removedCourses.set(course.webAppId, course);
            }
          });

          allCourseTypes = _.filter(allCourseTypes, (course) => {
            return course.status !== CourseHiddenStatus.REMOVED;
          });


          let courses = {};
          for (var i = 0; i < allCourseTypes.length; ++i) {
            courses[allCourseTypes[i].webAppId] = allCourseTypes[i];
          }

          //find grouped courses
          let courseGroups = _.map(allCourseTypes, (course) => {
            if (course && course.courseGroup) {
              return course.courseGroup;
            }
            return null;
          });

          courseGroups = _.reject(courseGroups, _.isNull);

          //find unique groups
          courseGroups = _.uniq(courseGroups, (courseGroup) => {
            return courseGroup.id;
          });

          //assign courses to their groups
          _.each(courseGroups, (courseGroup) => {
            const courses = _.filter(allCourseTypes, (course) => {
              return course &&
                course.status === CourseHiddenStatus.PUBLISHED &&
                course.courseGroup &&
                course.courseGroup.id === courseGroup.id;
            });
            courseGroup.courses = courses;

            _.each(courses, (course) => {
              const i = allCourseTypes.indexOf(course);
              allCourseTypes.splice(i, 1);
            });
          });

          //map remaining courses to a course group
          _.each(allCourseTypes, (course) => {
            const courseGroup = {
              id: course.id,
              name: course.longName,
              courses: [course],
              primaryCourse: course
            };
            courseGroups.push(courseGroup);

            course.courseGroup = courseGroup;
          });

          _.each(_.values(value), (course) => {
            if (course && course.courseGroup) {
              course.courseGroup = _.findWhere(courseGroups, {id: course.courseGroup.id});
            }
          });

          this.courseTypes = courses;

          this.courseTypesByName = value;

          this.dataObs$.next({
            allCourseTypes: courses,
            courseTypes: allCourseTypes,
            courseGroups: courseGroups,
            hiddenCourses: hiddenCourses,
            removedCourses: removedCourses
          });
          this.dataObs$.complete();

        }, error => {
          this.loadedCourses = false;
          console.error(error);
          this.dataObs$.error(error);
        });
      });
    }

    return this.dataObs$;
  }

  loadCourses(initialData, user, isProgress = false) {
    return new Promise((resolve, reject) => {
      this.load().subscribe(async (value) => {
        const result: any = {
          tags: initialData.tags,
          selectedTags: initialData.selectedTags,
          defaultCourseGroups: [],
          ownedCourseGroups: [],
          preownedCourseGroups: [],
          unownedCourseGroups: [],
          courseGroups: []
        };

        const courseGroups = value.courseGroups;
        const ownedCourses = [];
        let relatedCourseIds = [];

        result.tags = this.getTags(courseGroups);
        result.selectedTags = result.tags.slice(0);

        _.each(courseGroups, (courseGroup) => {
          //console.log('Checking permissions for', courseGroup);

          let owned = false;

          _.each(courseGroup['courses'], (course) => {
            course['purchased'] = user && user.coursePermissions[course['webAppId']] == true;
            console.log(`${course['webAppId']} is owned: ${course['purchased']}`);
            owned = owned || course['purchased'];

            if (course['purchased']) {
              ownedCourses.push(course);

              const relatedCourses = course.relatedCourses;
              if (relatedCourses) {
                relatedCourseIds = relatedCourseIds.concat(relatedCourses.split(','));
              }
            }
          });

          if (courseGroup.courses.some(course => ['PRIVATE', 'INSTRUMENT', 'COMMERCIAL'].includes(course.webAppId))) {
            result.defaultCourseGroups.push(courseGroup);
          }

          if (this.config['user.mode'] === 'YE') {
            result.defaultCourseGroups = result.defaultCourseGroups.filter(courseGroup => !courseGroup.courses.some(
              course => course.webAppId === 'INSTRUMENT' || course.webAppId === 'COMMERCIAL'
            ));
          }

          //put tags on course group
          if (owned && (courseGroup.courses[0].webAppId === ('GETTING_STARTED') || courseGroup.courses[0].webAppId === 'FLIGHTSIM')) {
            console.log('Adding Course Group PREOWNED', courseGroup);
            result.preownedCourseGroups.push(courseGroup);
          } else if (owned && (courseGroup.courses[0].webAppId !== ('GETTING_STARTED' || 'FLIGHTSIM'))) {
            console.log('Adding Course Group OWNED', courseGroup);
            result.ownedCourseGroups.push(courseGroup);
          } else {
            console.log('Adding Course Group UNOWNED', courseGroup);
            result.unownedCourseGroups.push(courseGroup);
          }

          courseGroup['tags'] = _.chain(courseGroup['courses']).map(course => {
            if (course.tagsList) {
              return _.pluck(course.tagsList, 'text');
            } else {
              return [];
            }
          }).flatten().uniq().value();
        });

        result.courseGroups = courseGroups;


        result.ownedCourseGroups = _.sortBy(result.ownedCourseGroups, courseGroup => {
          return courseGroup.courses.length > 0 ? courseGroup.courses[0].orderNum : -1;
        });

        result.unownedCourseGroups = _.sortBy(result.unownedCourseGroups, courseGroup => {
          return courseGroup.courses.length > 0 ? courseGroup.courses[0].orderNum : -1;
        });

        if (isProgress) {
          await this.loadCoursesProgressSummary(ownedCourses, result);
        }

        if (isProgress) {
          result.relatedCourseGroups = [];
          this.chainRelatedCourses(relatedCourseIds, result);
        }


        resolve(result);
      });
    });
  }

  private loadCoursesProgressSummary(ownedCourses, result) {
    return new Promise((resolve, reject) => {
      const coursesToGet = ownedCourses.map((course) => {
        return course['webAppId'];
      });

      this.progressService.loadManySummaries(coursesToGet).then(progress => {

        _.each(ownedCourses, (course) => {
          course.progress = progress[course['webAppId']];
        });

        let accessedCourses = _.chain(ownedCourses).filter(course => {
          return course && course['progress'] && course['progress']['userCourse'] && course['progress']['userCourse']['lastAccessDate'];
        }).value();

        accessedCourses.sort((a, b) => {
          let aMoment = moment(a['progress'].userCourse.lastAccessDate);
          let bMoment = moment(b['progress'].userCourse.lastAccessDate);

          console.log(`comparing ${a.webAppId}:${aMoment} to ${b.webAppId}:${bMoment}`);

          let result = aMoment.isBefore(bMoment) ? -1 : 1;
          console.log(`result is ${result}`);
          return result;
        }).reverse();


        result.latestCourse = accessedCourses[0];

        if (result.latestCourse && result.latestCourse.courseGroup && result.latestCourse.courseGroup.courses.length > 0) {
          const primary = _.filter(result.latestCourse.courseGroup.courses, (course) => {
            return course['progress'] && course['progress'].userCourse && course['progress'].userCourse.primaryOfGroup;
          });

          if (primary.length > 0) {
            result.latestCourse = primary[0];
          }
        }

        _.each(result.courseGroups, (courseGroup) => {
          const primaryCourse = _.filter(courseGroup.courses, (course) => {
            return course['progress'] && course['progress'].userCourse && course['progress'].userCourse.primaryOfGroup;
          });
          if (primaryCourse.length > 0) {
            courseGroup.primaryCourse = primaryCourse[0];
          }
        });

        resolve(result);

      });
    });
  }

  private chainRelatedCourses(relatedCourseIds, result) {
    _.chain(relatedCourseIds).uniq().each((courseId) => {
      const relatedUnownedCourseGroups = _.filter(result.unownedCourseGroups, (courseGroup) => {
        const unownedCourse = _.find(courseGroup.courses, (course) => {
          return course['webAppId'] === courseId;
        });
        return unownedCourse != null;
      });

      result.relatedCourseGroups = result.relatedCourseGroups.concat(relatedUnownedCourseGroups);
    }).value();
  }

  loadCourse(courseId: string, hidden = false, removed = false): Observable<any> {
    if (this.courses[courseId]) {
      return of(this.courses[courseId]);
    } else if (this.courseObservables[courseId]) {
      return this.courseObservables[courseId];
    }
    if (hidden || hidden) {
      return this.load().pipe(map(value => {
        const course = value['hiddenCourses'].get(courseId);
        if (!course) {
          return value['removedCourses'].get(courseId);
        } else {
          return course;
        }
      }));
    } else {
      this.courseObservables[courseId] = this.load().pipe(map(value => {
        this.courses[courseId] = value['allCourseTypes'][courseId];
        return this.courses[courseId];
      }));
      return this.courseObservables[courseId];
    }
  }

  loadCourseLicense(courseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(courseId).then(api => {
        this.httpClient.get(api.getRoute('courseLicenseUrl')).subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
      });
    });
  }

  loadCourseLicenses(courseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(courseId).then(api => {
        this.httpClient.get(api.getRoute('courseLicensesUrl')).subscribe(value => {
          resolve(value);
        });
      });
    });

  }

  generateNewLicense(courseId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(courseId).then(api => {
        this.httpClient.put(api.getRoute('generateNewLicenseUrl'), {}).subscribe(value => {
          resolve(value);
        });
      });
    });
  }

  loadCourseMilestones(courseId: string, licenseId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(courseId).then(api => {
        const url = api.getRoute('milestonesByCourseUrl') + '?licenseId=' + licenseId;
        this.httpClient.get(url).subscribe(value => {
          resolve(value);
        });
      });
    });
  }

  logSectionTime(courseId: string, videoTrainingSectionId: number) {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(courseId).then(api => {
        const url = api.getRoute('logSectionTimeUrl').replace('{videoTrainingSectionId}', videoTrainingSectionId.toString());
        this.httpClient.post(url, {}).subscribe(value => {
          resolve(value);
        });
      });
    });
  }

  favorite(course: any, favorite: boolean) {
    this.trainingService.load().subscribe(api => {
      this.httpClient.post(api.getRoute('favoriteCourseUrl'), {
        courseType: course.webAppId,
        favorite: favorite
      }).subscribe(value => {

      });
    });
  }

  homePage(course: any, homePage: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('homePageCourseUrl'), {
          courseType: course?.webAppId,
          homePage
        }).subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
      });
    });
  }

  public selectForCourseGroup(course: any): Observable<any> {
    return new Observable((observer) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('selectCourseForCourseGroupUrl'),
          {courseType: course.webAppId})
          .subscribe(value => {
            observer.next(value);
          }, error => {
            observer.error(error);
            console.error(error);
          });
      });
    });
  }

  getCourseTypes() {
    return this.courseTypes;
  }

  getCourseTypesByName() {
    return this.courseTypesByName;
  }


  public async accessedCourse(courseId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(courseId).then(api => {
        this.httpClient.put(api.getRoute('viewedCourseUrl'), {}).subscribe(value => {
          resolve(null);
        }, error => {
          if (error.status != 401) {
            reject(error);
          }
        });
      });
    });
  }

  public async viewedWelcome(courseId: string): Promise<void> {
    return new Promise(resolve => {
      this.trainingService.loadByCourse(courseId).then(api => {
        this.httpClient.put(api.getRoute('viewedWelcomeUrl'), {}).subscribe(value => {
          resolve(null);
        });
      });
    });
  }

  resetCourseProgress(webAppId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.put(api.getRoute('resetCourseProgress').replace('{webAppId}', webAppId), {}).subscribe(value => {
          resolve(null);
        });
      });
    });
  }

  restartCourseProgress(webAppId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.put(api.getRoute('restartCourseProgress').replace('{webAppId}', webAppId), {}).subscribe(value => {
          resolve(null);
        });
      });
    });
  }

  resetVideoProgress(webAppId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.put(api.getRoute('resetVideoProgress').replace('{webAppId}', webAppId), {}).subscribe(value => {
          resolve(null);
        });
      });
    });
  }

  markAllVideosWatched(webAppId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.put(api.getRoute('markAllVideosWatched').replace('{webAppId}', webAppId), {}).subscribe(value => {
          resolve(null);
          this.progressService.refreshProgress(webAppId);
        });
      });
    });
  }

  getTags(courseGroups) {
    return _.chain(courseGroups)
      .pluck('courses')
      .flatten()
      .map((course) => {
        if (course.tagsList) {
          return _.pluck(course.tagsList, 'text');
        }
      }).flatten().uniq().value();
  }
}
