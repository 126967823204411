<div class="modal-header">
    <div class="modal-title">
        <h1>{{modalTitle}}</h1>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    {{modalBodyMessage}}
</div>
<div class="modal-footer d-flex justify-content-start">
    <ng-container *ngFor="let button of modalButtons">
        <button class="{{button.classes}}" id="{{button.id}}" (click)="btnClicked(button.id)">{{button.text}}</button>
    </ng-container>
</div>