import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-magic-link-modal',
  templateUrl: './magic-link-modal.component.html',
  styleUrls: ['./magic-link-modal.component.scss']
})
export class MagicLinkModalComponent {
  message: string;
  working: boolean;
  success = null;
  instructions = 'Please enter your %s below to receive a password reset link.';
  allowClose: boolean;

  course: any;
  onComplete: () => void;

  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email])
  });

  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService
  ) {}

  onSubmit() {
    console.log('LoginComponent.onMagicLinkFormSubmit');
    this.working = true;
    const _this = this;
    this.authService.sendMagicLink(this.form.get('email').value).then(value => {
      console.log('LoginComponent.onMagicLinkFormSubmit.success');
      this.working = false;
      this.success = true;
      this.message = `We've sent an email to ${this.form.get('email').value} with a link to log you in. The link expires shortly, so please click on it soon.`;
    })
      .catch(err => {
        console.log('LoginComponent.onMagicLinkFormSubmit.error');
        console.log(err.error);
        this.working = false;
        this.success = false;
        this.message = err.error.title;
      });

    window.setTimeout(() => {
      this.message = '';
    }, 5000);
  }

}
