import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from 'src/app/services/auth.service';
import {CoursesService} from '../../courses.service';
import {AuthContextService} from '../../services/auth-context.service';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListComponent implements OnInit {

  @Input() courseList: {
    details: {
      thumbnailUrl: string,
      longName: string,
    }, enumName: string, courseUrl: string
  }[];
  userLoggedIn = false;

  constructor(public authContextService: AuthContextService, private coursesService: CoursesService) {
    this.userLoggedIn = authContextService.isLoggedIn();
  }

  ngOnInit(): void {
  }

}
