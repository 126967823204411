import {Injectable} from '@angular/core';
import {TrainingService} from '../training.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

import {Angulartics2} from 'angulartics2';
import {Router} from '@angular/router';
import {AuthContextService} from './auth-context.service';
import {getAnalytics, setUserId, setUserProperties} from 'firebase/analytics';
import * as _ from 'underscore';
import {ClientConsent} from '../components/user-info/oauth2-client-consent.model';
import {datadogRum} from '@datadog/browser-rum';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _deepLinkRoute = null;

  constructor(
    private trainingService: TrainingService,
    private httpClient: HttpClient,
    private angulartics: Angulartics2,
    private router: Router,
    private authContextService: AuthContextService
  ) {
  }

  login(username, password, course?): Observable<any> {
    console.log('AuthService:login', username);
    const payload = {
      'username': username,
      'password': password
    };
    return new Observable((observer) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('loginUrl'), payload).subscribe(value => {
          // this returns a jwt token, fetch the user
          this.authContextService.setToken(value['id_token']);
          this.authContextService.user = value;
          window.localStorage.setItem('token', value['id_token']);
          this.httpClient.get(api.getRoute('meUrl')).subscribe(value => {
            // this returns a jwt token, fetch the user
            console.log('AuthService:login:result', value);
            this.authContextService.user = value;

            this.angulartics.setUsername.next(value['user_name']);

            this.angulartics.setUserProperties.next({
              id: value['id'],
              sportysId: value['sportys_id'],
              email: value['email'],
              lastName: value['last_name'],
              firstName: value['first_name'],
              validCfi: value['is_valid_cfi']
            });

            datadogRum.setUser({
              id: value['id'],
              email: value['email'],
              sportysId: value['sportys_id']
            });

            this.setFirebase(value);

            this.authContextService.loginSubject.next(value);
            observer.next(value);
          });
        }, error => {
          observer.error(error);
        });
      });

    });
  }

  private setFirebase(user: Object) {
    const keys = _.keys(user['coursePermissions']);
    const purchasedCourses = _.filter(keys, (key) => {
      return key !== 'GETTING_STARTED' && key !== 'FLIGHTSIM' && user['coursePermissions'][key] === true;
    });
    if (window['_firebase']) {
      console.log('AuthService:login:firebase', user);
      const analytics = getAnalytics(window['_firebase']);
      setUserId(analytics, user['id']);

      const props = {
        sportysId: user['sportys_id'],
        email: user['email'],
        lastName: user['last_name'],
        firstName: user['first_name'],
        //Purchasers - a user who has purchased at least one course (has a license); should not count Getting Started or Flight Sim license
        //
        // StudyBuddy - a user who has a Sporty’s user account, but has not purchased a course or have a license; should not count Getting Started or Flight Sim license
        //
        // PilotTraining+Monthly - a user who has an active Flight Crew monthly license
        //
        // PilotTraining+Annual - a user who has an active Flight Crew annual license
        //
        // CFI - a user who is a registered CFI in the portal

      };
      if (purchasedCourses.length > 0) {
        props['Purchaser'] = purchasedCourses.length > 0 ? 'TRUE' : null;
      } else {
        props['StudyBuddy'] = purchasedCourses.length === 0 ? 'TRUE' : null;
      }
      if (user['isValidCfi']) {
        props['validCfi'] = 'TRUE';
      }
      if (user['package_type']) {
        if (user['package_type'] === 'FLIGHT_CLUB_PLATINUM_MONTHLY') {
          props['PilotTraining+Monthly'] = user['package_type'];
        } else {
          props['PilotTraining+Annual'] = user['package_type'];
        }
      }
      setUserProperties(analytics, props);
    } else {
      console.log('AuthService:login:firebase not ready');
    }
  }

  private clearFirebase() {
    if (window['_firebase']) {
      const analytics = getAnalytics(window['_analytics']);
      if (analytics) {
        setUserId(analytics, null);
        setUserProperties(analytics, {
          sportysId: null,
          email: null,
          lastName: null,
          firstName: null,
          validCfi: null,
          Purchaser: null,
          StudyBuddy: null,
          'PilotTraining+Monthly': null,
          'PilotTraining+Annual': null,
        });
      }
    }
  }

  async renew(): Promise<any> {
    if (!this.authContextService.isLoggedIn()) {
      return new Promise((resolve, reject) => {
        reject('Not logged in');
      });
    }
    console.log('AuthService.renew');

    return new Promise(async (resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        console.log('AuthService.renew:loadedApi');
        this.httpClient.get(api.getRoute('meUrl')).subscribe({
          next: (value) => {
            console.log('AuthService.renew:gotUser');
            this.authContextService.user = value;

            this.angulartics.setUsername.next(value['user_name']);

            this.angulartics.setUserProperties.next({
              id: value['id'],
              sportysId: value['sportys_id'],
              email: value['email'],
              lastName: value['last_name'],
              firstName: value['first_name']
            });

            if (window['newrelic']) {
              window['newrelic'].setCustomAttribute('username', value['email']);
              window['newrelic'].setCustomAttribute('userId', value['id']);
              window['newrelic'].setCustomAttribute('sportysId', value['sportys_id']);
            }


            datadogRum.setUser({
              id: value['id'],
              email: value['email'],
              sportysId: value['sportys_id']
            });

            if (window['ineum']) {
              if (!this.authContextService.user) {

              } else {
                window['ineum']('user', this.authContextService.user.id, this.authContextService.user.first_name + this.authContextService.user.last_name, this.authContextService.user.email);
                window['ineum']('meta', 'guest', 'false');
              }
            }


            this.setFirebase(value);

            this.authContextService.renewSubject.next(value);

            resolve(value);
          }, error: (error) => {
            if (error.status === 403) {
              this.authContextService.anonymous = true;
            }
            console.error('AuthService.renew:error');
            console.error(error);
            if (window['ineum']) {
              window['ineum']('meta', 'guest', 'true');
              window['ineum']('user', null, null, null);
            }
            reject(error);
          }
        });
      });
    });
  }

  async register(firstName: string, lastName: string, email: string, password: string, course: any, cfiNumber?: number, cfiExpirationDate?: string) {
    console.log(encodeURIComponent(password));

    const payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      courseType: course ? course.webAppId : '',
      cfiCertificationNumber: cfiNumber ? cfiNumber : '',
      cfiExpirationDate: cfiExpirationDate ? cfiExpirationDate : ''
    };
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('registerUrl'), payload).subscribe({
          next: (value) => {
            this.login(email, password).subscribe({
              next: (value) => {
                resolve(value);
              }, error: (error) => {
                console.log(error);
                reject(error);
              }
            });
          }, error: (error) => {
            console.log(error);
            reject(error);
          }
        });
      });
    });
  }


  logout(): void {

    console.log('AuthService:logout:begin');
    this.authContextService.user = null;
    window.localStorage.removeItem('token');

    console.log('AuthService:logout:subjects notified');
    //this.httpClient.get(api.getRoute('logoutUrl')).subscribe(value => {
    this.angulartics.setUsername.next('anonymous');

    this.angulartics.setUserProperties.next({
      id: 'anonymous',
      sportysId: 'anonymous',
      email: 'anonymous',
      lastName: 'anonymous',
      firstName: 'anonymous'
    });

    if (window['ineum']) {
      window['ineum']('meta', 'guest', 'true');
      window['ineum']('user', null, null, null);
    }

    if (window['newrelic']) {
      window['newrelic'].setCustomAttribute('username', null);
      window['newrelic'].setCustomAttribute('userId', null);
      window['newrelic'].setCustomAttribute('sportysId', null);
    }

    datadogRum.clearUser();

    this.clearFirebase();

    this.authContextService.logoutSubject.next(null);
    /*this.loginSubject.next(null);*/
    this.router.navigate(['login']);

  };

  async updateCFICredentials(credentials: { cfi_number: string, cfi_expiration_date: string }) {

    const payload = {
      'cfiCertificationNumber': credentials.cfi_number,
      'cfiExpirationDate': credentials.cfi_expiration_date
    };

    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        //console.log(this.trainingService.api);
        this.httpClient.put(api.getRoute('updateInstructorUrl'), payload).subscribe(user => {

          this.angulartics.setUserProperties.next({
            id: user['id'],
            sportysId: user['sportys_id'],
            email: user['email'],
            lastName: user['last_name'],
            firstName: user['first_name'],
            validCfi: user['isValidCfi']
          });

          if (window['newrelic']) {
            window['newrelic'].setCustomAttribute('username', user['email']);
            window['newrelic'].setCustomAttribute('userId', user['id']);
            window['newrelic'].setCustomAttribute('sportysId', user['sportys_id']);
          }

          datadogRum.setUser({
            id: user['id'],
            email: user['email'],
            sportysId: user['sportys_id']
          });

          this.setFirebase(user);

          resolve(user);
        }, error => {
          reject(error);
        });
      });
    });
  }


  async resetPassword(email: string) {
    const payload = new HttpParams()
      .set('email', encodeURIComponent(email));

    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.put(api.getRoute('resetPasswordUrl'), payload, {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        }).subscribe(value => {
          resolve(value);
        }, error => {
          reject(error);
        });
      });
    });
  }

  //validateResetPasswordTokenUrl
  validateResetPasswordToken(token: string): Observable<any> {
    return new Observable((observer) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.put(api.getRoute('validateResetPasswordTokenUrl') + '?token=' + token, {}).subscribe(value => {
          observer.next(value);
        }, error => {
          observer.error(error);
        });
      });
    });
  }

  submitResetPassword(token: string, password: string): Observable<any> {
    const payload = {
      newPassword: password,
      token: token
    };

    return new Observable((observer) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('submitResetPasswordUrl'), payload).subscribe({
          next: (value) => {
            observer.next(value);
          }, error: (error) => {
            observer.error(error);
          }
        });
      });
    });
  }

  async sendMagicLink(email: string): Promise<any> {
    console.log('AuthService.sendMagicLink');

    return new Promise(async (resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        const url = api.getRoute('sendMagicLinkUrl');
        console.log(url);
        this.httpClient.post(url, email).subscribe(value => {
          resolve(value);
        }, error => {
          console.error(error);
          reject(error);
        });
      });
    });
  }


  emitNewAccount(): void {
    this.authContextService.accountIsNew = true;
  }

  emitShowSignupForm(): void {
    this.authContextService.showSignupForm = true;
  }

  getOath2UserConsets(): Observable<any> {
    return new Observable((observer) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.get(api.getRoute('getUserOauth2Consent')).subscribe(value => {
          observer.next(value);
        }, error => {
          observer.error(error);
        });
      });
    });
  }

  revokeConsent(client: ClientConsent, authority: String): Observable<any> {
    const options = {
      body: authority
    };

    return new Observable((observer) => {
      this.trainingService.load().subscribe(api => {
        const url = api.getRoute('revokeOauth2Consent').replace('{clientId}', client.registeredClientId);

        this.httpClient.delete(url, options).subscribe(
          value => observer.next(value),
          error => observer.error(error)
        );
      });
    });
  }

  get deepLinkRoute(): string {
    return this._deepLinkRoute;
  }

  set deepLinkRoute(value: string) {
    this._deepLinkRoute = value;
  }

}
