<div *ngFor="let course of courseList">
  <div class="row mt-3 cursor-pointer no-gutters" [routerLink]="['/', 'course', course.webAppId]">
    <div class="col-1">
      <img width="32" height="32" src="{{course.thumbnailUrl}}" class="course-image"/>
    </div>
    <div class="col-md-11 col-10 d-flex align-items-center pr-0">
      <div class="col-10 course-name-font-size">
        {{course.longName}}
      </div>
      <div *ngIf="userLoggedIn" class="col-2 d-flex justify-content-end">
        <app-icon glyph name="right-arrow"></app-icon>
      </div>
    </div>
  </div>
  <hr *ngIf="courseList.indexOf(course) < courseList.length-1">
</div>
