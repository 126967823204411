import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IconModule } from '../icon/icon.module';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';

@Component({
  selector: 'app-welcome-modal',
  templateUrl: './welcome-modal.component.html',
  styleUrls: ['./welcome-modal.component.scss'],
  standalone: true,
  imports: [IconModule, MatDialogModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeModalComponent {
  course: any;
  sanitizer = inject(DomSanitizer);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { course: any }) {
    this.course = data.course;
  }
}
