import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GenericModalButtonModel } from '../models/generic-modal-button-model';
import { GenericModalComponent } from './generic-modal.component';

@Injectable()
export class GenericModalService {
    modal: BsModalRef<GenericModalComponent>;

    constructor(
        private modalService: BsModalService) { }

    public openGenericConfirmationModal(
        title: string,
        message: string,
        dialogSize: 'sm' | 'lg' | 'xlg' = 'sm',
        modalButtons: GenericModalButtonModel[]): BsModalRef {
        const initialState = {};
        this.modal = this.modalService.show(GenericModalComponent, { initialState, class: 'modal-' + dialogSize });
        this.modal.content.modalTitle = title;
        this.modal.content.modalBodyMessage = message;
        this.modal.content.modalButtons = modalButtons;

        this.modal.content.onCancel.subscribe(cancel => {
            if (cancel == true) {
                this.modal.hide();
            }
        });

        return this.modal;
    }

    public close() {
        this.modal.hide();
    }
}
