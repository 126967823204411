import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PasswordValidator } from 'src/app/validators/password.validator';
import { MagicLinkModalComponent } from '../magic-link-modal/magic-link-modal.component';
import { ResetModalComponent } from '../reset-modal/reset-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isLoading = false;
  errorMessage = null;
  loginSubscription: Subscription;
  showRegisterForm = false;
  resetModal: BsModalRef;

  form: UntypedFormGroup;

  @Output() onComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onCancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onShowSignupForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private authService: AuthService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.form = this.initializeFormGroup();
  }

  ngOnDestroy(): void {
    this.errorMessage = '';
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  onSubmit() {
    console.log('LoginComponent.onSubmit');
    this.isLoading = true;

    this.loginSubscription = this.authService.login(this.form.get('username').value, this.form.get('password').value).subscribe(value => {
      this.authService.renew().then(renewResult => {
        this.isLoading = false;
        this.onComplete.emit(true);
      });
    }, error => {
      console.error(error.error.message);
      this.errorMessage = error.error.message;
      this.isLoading = false;
    });
  }

  onKeyPress($event) {
    if ($event.keyCode === 13) {
      this.onSubmit();
    }
  }

  cancel(): void {
    this.onCancel.emit();
  }

  onShowRegisterForm(): void {
    this.onShowSignupForm.emit(true);
  }

  private initializeFormGroup(): UntypedFormGroup {
    const form: UntypedFormGroup = new UntypedFormGroup({});

    form.addControl('username', new UntypedFormControl(null, [Validators.required]));
    form.addControl('password', new UntypedFormControl(null, [Validators.required]));

    return form;
  }

  resetPassword($event) {
    $event.stopPropagation();
    $event.preventDefault();
    //this.authService.resetPassword(this.form.get('username').value).then(value => {
    //  console.log('LoginComponent:resetPassword', value);
    //});

    const initialState = {};

    this.resetModal = this.modalService.show(ResetModalComponent, { initialState, class: 'modal-lg rounded-modal' });

  }

  magicLink($event) {
    $event.stopPropagation();
    $event.preventDefault();
    const initialState = {};
    this.resetModal = this.modalService.show(MagicLinkModalComponent, { initialState, class: 'modal-lg rounded-modal' });

  }

}
