import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceModeService {
  maintenanceMode: boolean = null;

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      const params = this.activatedRoute.snapshot.queryParams;
      if (params['maintenance-mode'] !== undefined) {
        localStorage.setItem('maintenanceMode', params['maintenance-mode']);
        this.maintenanceMode = params['maintenance-mode'];
      } else {
        this.maintenanceMode = null;
      }
    });
  }

  getMaintenanceMode(): string {
    return this.maintenanceMode != null ? this.maintenanceMode.toString(): null;
  }
}
