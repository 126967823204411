import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginModalComponent } from '../../../components/login-modal/login-modal.component';
import { SessionType } from '../enum/session-type.enum';
import { TestBuilderService } from '../../services/test-builder.service';
import { CategoryType } from '../enum/category-type.enum';
import { take } from 'rxjs/operators';
import { SignupModalService } from '../../../signup-modal.service';

@Component({
  selector: 'app-grade-modal',
  templateUrl: './grade-modal.component.html',
  styleUrls: ['./grade-modal.component.scss']
})
export class GradeModalComponent implements OnInit {

  questionResults = null;
  course = null;
  purchased = false;
  user = null;
  grade = null;
  timeSpent = null;
  loginModal: BsModalRef;
  step = 1;
  test: any = null;
  categoryType: CategoryType;
  homeRoute: string[];


  constructor(
    public bsModalRef: BsModalRef,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private testBuilderService: TestBuilderService,
    private signupModalService: SignupModalService
  ) {
  }

  ngOnInit() {
    console.log('GradeModalComponent:questionResults', this.questionResults);
  }

  reviewAll() {
    this.bsModalRef.content.response = 'ALL';
    this.bsModalRef.hide();
  }

  reviewMissed() {
    this.bsModalRef.content.response = 'INCORRECT';
    this.bsModalRef.hide();
  }

  newSession() {
    this.step = 2;
  }

  login() {
    const initialState = {
      title: 'Modal with component',
      course: this.course,
      courseGroup: this.course.courseGroup
    };

    this.loginModal = this.modalService.show(LoginModalComponent, { initialState, class: 'rounded-modal' });
  }

  returnToTestPrep() {


    this.modalService.onHide.pipe(take(1)).subscribe(value => {
      this.router.navigate(['/', 'course', this.course.webAppId].concat(this.homeRoute));
    });

    this.bsModalRef.hide();


  }

  startQuestionsAnsweredCorrectly(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);
    this.testBuilderService.sessionType = SessionType.REVIEW_CORRECT;

    this.testBuilderService.startSession(test, SessionType.REVIEW_CORRECT, test.sessionType, this.user).then((savedtest) => {
      this.bsModalRef.hide();
      this.router.navigate(['/', 'course', this.course.webAppId, 'testprep', 'learn', savedtest.id], { relativeTo: this.route });
    });

  }

  startIncorrectQuestions(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);
    this.testBuilderService.sessionType = SessionType.REVIEW_INCORRECT;

    this.testBuilderService.startSession(test, SessionType.REVIEW_INCORRECT, test.sessionType, this.user).then((savedtest) => {
      this.bsModalRef.hide();
      this.router.navigate(['/', 'course', this.course.webAppId, 'testprep', 'learn', savedtest.id], { relativeTo: this.route });
    });

  }

  startMarkedQuestions(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);
    this.testBuilderService.sessionType = SessionType.REVIEW_MARKED;

    this.testBuilderService.startSession(test, SessionType.REVIEW_MARKED, test.sessionType, this.user).then((savedtest) => {
      this.bsModalRef.hide();
      this.router.navigate(['/', 'course', this.course.webAppId, 'testprep', 'learn', savedtest.id], { relativeTo: this.route });
    });

  }

  retryTest(test: any) {
    this.testBuilderService.begin(this.course, this.categoryType);
    this.testBuilderService.sessionType = SessionType.REVIEW_TEST;

    this.testBuilderService.startSession(test, SessionType.REVIEW_TEST, test.sessionType, this.user).then((savedtest) => {
      this.bsModalRef.hide();
      this.router.navigate(['/', 'course', this.course.webAppId, 'testprep', 'learn', savedtest.id], { relativeTo: this.route });
    });

  }

  signup() {
    this.signupModalService.showSignup(this.course);
  }
}
