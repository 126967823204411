import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {NavLink} from '../components/sidenav/models/nav-link';
import {LoginModalComponent} from '../components/login-modal/login-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';
import {InstructorService} from './students/instructor.service';
import {AuthContextService} from '../services/auth-context.service';
import {map} from 'rxjs';
import {TrainingService} from '../training.service';
import {CourierService} from '../services/courier.service';
import {AuthService} from '../services/auth.service';
import moment from 'moment';

@Component({
  selector: 'app-cfi',
  templateUrl: './cfi.component.html',
  styleUrls: ['./cfi.component.scss']
})
export class CfiComponent implements OnInit, AfterViewInit, OnDestroy {
  collapsed = true;
  sidenav = false;
  daysToCFIRenew: number;
  user: any;

  daysToCfiRenew$ = this.authContextService.isValidCfiUser$.pipe(
    map((_) => {
      return this.getDaysToCFIRenew();
    })
  );

  getDaysToCFIRenew(): number {
    return Math.round(
      (moment(this.authContextService.user.cfiExpirationDate).toDate().valueOf() -
        new Date().valueOf()) /
      (1000 * 3600 * 24)
    );
  }

  sidenavBacklink: NavLink = {
    label: 'My Courses',
    routerLink: ['/', 'home', 'courses'],
    glyph: 'back'
  };

  sidenavLinks: NavLink[] = [];
  isCfiChief = false;

  constructor(
    public authContextService: AuthContextService,
    private modalService: BsModalService,
    private router: Router,
    private instructorService: InstructorService,
    private activatedRoute: ActivatedRoute,
    private trainingService: TrainingService,
    private courierService: CourierService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.isCfiChief = this.activatedRoute.snapshot.data.instructor.isCfiChief;
    this.sidenavLinks = this.getSidenavLinks();
    this.user = this.authContextService.user;
    this.trainingService.loadConfig().then(config => {
      this.courierService.init(this.user, config);
    });

    this.authContextService.onLogin().subscribe((user) => {

      this.user = user;
      this.sidenavLinks = this.getSidenavLinks();

      //TODO this is important even though the data isn't used
      this.instructorService.getAllClasses(
        this.activatedRoute.snapshot.data.instructor.isCfiChief
      );
    });
    this.authContextService.onLogout().subscribe(() => {
      this.user = null;
      this.sidenavLinks = this.getSidenavLinks();
    });
    this.authContextService.onRenew().subscribe(() => {
      this.sidenavLinks = this.getSidenavLinks();
      //TODO this is important even though the data isn't used
      this.instructorService.getAllClasses(
        this.activatedRoute.snapshot.data.instructor.isCfiChief
      );
    });

    this.instructorService.getAllClasses(
      this.activatedRoute.snapshot.data.instructor.isCfiChief
    );

    this.authService.renew().then(value => {

    });

  }

  ngAfterViewInit() {


  }

  sidenavClick(): void {
    this.sidenav = false;
  }

  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }

  toggleSidenav(): void {
    this.sidenav = !this.sidenav;
  }

  private getSidenavLinks(): NavLink[] {
    let result = [
      {
        label: 'CFI Home',
        routerLink: '/cfi/home',
        glyph: 'home',
        isDisabled: false,
      }
    ];

    if (this.activatedRoute.snapshot.data.instructor.isCfiChief) {
      result.push({
        label: 'Instructors',
        routerLink: '/cfi/instructors',
        glyph: 'graduation',
        isDisabled: false,
      });
    }

    result.push(
      {
        label: 'Students',
        routerLink: '/cfi/students',
        glyph: 'graduation',
        isDisabled:
          !this.authContextService.isLoggedIn() ||
          (this.authContextService.isLoggedIn() &&
            !this.authContextService.isCFIUser()),
      },
      {
        label: 'FIRC',
        routerLink: '/cfi/firc',
        glyph: 'firc',
        isDisabled: false //!this.authContextService.isLoggedIn() || (this.authContextService.isLoggedIn() && !this.authContextService.isValidCFIUser())
      },
      {
        label: 'Special Offers',
        routerLink: '/cfi/special-offers',
        glyph: 'giftcard',
        isDisabled: false
      },
      {
        label: 'FAA Library',
        routerLink: '/cfi/resources/RESOURCES',
        glyph: 'resources',
        isDisabled:
          !this.authContextService.isLoggedIn() ||
          (this.authContextService.isLoggedIn() &&
            !this.authContextService.isValidCFIUser())
      },
      {
        label: 'Sporty\'s Lesson Plan Guides',
        routerLink: '/cfi/resources/LESSONS',
        glyph: 'resources',
        isDisabled:
          !this.authContextService.isLoggedIn() ||
          (this.authContextService.isLoggedIn() &&
            !this.authContextService.isValidCFIUser())
      },
      {
        label: 'My Documents',
        routerLink: '/cfi/resources/MY_DOCUMENTS',
        glyph: 'resources',
        isDisabled:
          !this.authContextService.isLoggedIn() ||
          (this.authContextService.isLoggedIn() &&
            !this.authContextService.isValidCFIUser())
      }
    );

    return result;
  }

  login() {
    const initialState = {};

    this.modalService.show(LoginModalComponent, {
      initialState,
      class: 'modal-lg rounded-modal'
    });
  }

  completeProfile(): void {
    this.authContextService.showSignupForm = true;
    this.router.navigate(['/', 'cfi', 'home']);
  }

  renewFree(): void {
    this.router.navigate(['/', 'cfi', 'firc']);
  }

  ngOnDestroy() {
    console.log('CFIComponent.ngOnDestroy');
    this.courierService.destroy();
  }
}
