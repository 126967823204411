export class GenericModalButtonModel {
    id: string;
    classes: string;
    text: string;

    constructor(id: string, classes: string, text: string) {
        this.id = id;
        this.classes = classes;
        this.text = text;
    }
}