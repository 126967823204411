import { SessionType } from "../../enum/session-type.enum";
import { TestType } from "../../enum/test-type.enum";

export class StartTestRequest {
  totalQuestions: number;
  testType: TestType;
  sessionType: SessionType;
  totalTimeMillis: number;
  codeLookups: number[];
  uuids: string[];
}
