import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  computed,
  inject,
  input,
  signal,
  viewChild,
  viewChildren
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, tap } from 'rxjs';
import { NotesService } from 'src/app/notes.service';
import { HelpDataService } from 'src/app/services/help-data.service';
import { TrainingService } from 'src/app/training.service';
import { QuillEditorComponent } from 'ngx-quill';
import { Router } from '@angular/router';
import { QuestionDataService } from 'src/app/services/question-data.service';
import { TestSessionService } from 'src/app/course/services/test-session.service';
import { MatDrawer } from '@angular/material/sidenav';

const DEFAULT_NOTE = '<h2><br></h2>';
const VIDEO_NOTE_EXPLANATION =
  '<em>A link to the current video will automatically be added when starting a new note from a {{LESSON_TYPE}} lesson.</em>';
const VIDEO_NOTE = `<h2><br></h2>
<p><br></p>
<p><br></p>
<p><strong>Watch this video in the course:</strong></p>
<p><a href="{{VIDEO_URL}}">{{VIDEO_NAME}}</a></p>`;
const QUESTION_NOTE = `<h2><br></h2>
<p><br></p>
<p><br></p>
<p><strong>Read this question in the course:</strong></p>
<p><a href="{{QUESTION_URL}}">{{QUESTION_TEXT}}</a></p>`;
const DEFAULT_VIDEO_NAME = 'Link to video';
const DEFAULT_QUESTION_NAME = 'Link to question';
const WELCOME_NOTE = `<h2>Welcome to Sporty's Smart Notes</h2>
<p>The Smart Notes feature allows you to jot your thoughts, ideas on questions down from any location in the course. You can create as many notes as you'd like, and access them from the menu on the left.</p><p><br></p>
<p>When you create a new note from the Video Training section of the course, a link will automatically be added to that video section allowing you to quickly return to that lesson.</p><p><br></p>
<p>Click the Add Note button to start writing a new note.</p>`;

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnChanges, OnDestroy {
  notesListRef = viewChildren<ElementRef>('notesListRef');
  editor = viewChild<QuillEditorComponent>('editor');

  courseId = input<string>();

  noteForm: FormGroup;
  notes = signal<any[]>([]);
  selectedNoteIndex = signal(0);
  isSmallDevice = signal(false);
  parsedNotes = computed(() => {
    return this.notes().map((n) => {
      const dp = new DOMParser();
      const elements = [];
      const note = dp.parseFromString(n?.note, 'text/html');
      const allNoteElements = note.body.querySelectorAll('*:not(br)');

      // traverse the first 3 elements (which are not br elements), the third one is needed for
      // the '...' styling is applied to the list element in the drawer. This let's the user know
      // that there is more text in the note
      for (let i = 0; i < 3; i++) {
        if (allNoteElements[i]) {
          elements.push(allNoteElements[i]);
        }
      }

      const parsedNote = elements.map(el => el?.outerHTML || '').join('');

      return parsedNote;
    });
  });

  helpDataService = inject(HelpDataService);
  questionDataService = inject(QuestionDataService);
  notesService = inject(NotesService);
  cdr = inject(ChangeDetectorRef);
  breakpointObserver = inject(BreakpointObserver);
  trainingService = inject(TrainingService);
  router = inject(Router);
  renderer = inject(Renderer2);
  testSessionService = inject(TestSessionService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.courseId?.currentValue) {
      this.initializeNotes();
    }
  }

  ngOnInit(): void {
    // Reactivate this if need to disable scroll when the notes component is created
    // this.renderer.setStyle(document.body, 'overflow', 'hidden');

    this.noteForm = new FormGroup({
      note: new FormControl('')
    });

    // detect screen size changes
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((result: BreakpointState) => {
        this.isSmallDevice.set(result.matches);
      });

    this.registerNoteChanges();
  }

  ngOnDestroy(): void {
    // Reactivate this if need to enable scroll when the notes component is destroyed
    // this.renderer.setStyle(document.body, 'overflow', 'auto');
  }

  newNote() {
    this.notes.update((notes) => [...notes, '']);
    this.selectedNoteIndex.set(this.notes().length - 1);
    let newNoteContent = DEFAULT_NOTE;

    newNoteContent = this.applyVideoLinkToNewNote(newNoteContent);
    newNoteContent = this.applyQuestionLinkToNewNote(newNoteContent);

    this.notesService.saveNote(this.courseId(), newNoteContent).subscribe((note) => {
      this.notes.update((notes) => {
        notes[this.selectedNoteIndex()] = note;

        return [...notes];
      });
      this.noteForm.get('note').setValue(newNoteContent);
      this.editor()?.quillEditor?.focus();

      // after the note is added to the list, selected and created on the server we scroll to it smoothly
      this.notesListRef()[this.notesListRef().length - 1].nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest'
      });
    });
  }

  selectNote(i) {
    this.selectedNoteIndex.set(i);
    this.noteForm.get('note').setValue(this.notes()[i]?.note ?? null);
    this.editor()?.quillEditor?.focus();
    this.cdr.markForCheck();
  }

  deleteNote(index) {
    this.notesService.deleteNote(this.notes()[index].id).subscribe((_) => {});
    this.notes.update((notes) => notes.filter((_, i) => i !== index));

    this.selectNote(index === this.notes().length ? index - 1 : index);
  }

  openNotesListDrawer(drawer: MatDrawer) {
    drawer.open();

    const selectedNoteElem = this.notesListRef()[this.selectedNoteIndex()];

    selectedNoteElem.nativeElement.scrollIntoView({
      behavior: 'instant',
      block: 'start'
    });
  }

  private registerNoteChanges() {
    this.noteForm
      .get('note')
      .valueChanges.pipe(
        tap((value) => {
          this.notes.update((notes) => {
            return notes.map((n, i) => {
              if (i === this.selectedNoteIndex()) {
                return {
                  ...n,
                  note: value
                };
              }

              return n;
            });
          });
        }),
        debounceTime(500)
      )
      .subscribe((value) => {
        const currentNote = this.notes()[this.selectedNoteIndex()];

        if (!currentNote) {
          return;
        }

        this.notesService.updateNote(currentNote.id, value ?? '').subscribe((note) => {});
      });
  }

  private initializeNotes() {
    this.notesService.getNotes(this.courseId()).subscribe((notes) => {
      let sortedNotes = notes
        ? notes.sort(
            (a, b) =>
              new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime()
          )
        : [];

      this.notes.set(sortedNotes);
      this.noteForm
        .get('note')
        .setValue(this.notes()?.length ? this.notes()[0].note : '');
      this.selectedNoteIndex.set(0);

      if (this.notes().length === 0) {
        this.notesService.saveNote(this.courseId(), WELCOME_NOTE).subscribe((note) => {
          this.notes.update((notes) => [...notes, note]);
          this.noteForm.get('note').setValue(WELCOME_NOTE);
          this.selectedNoteIndex.set(0);
        });
      }

      this.cdr.markForCheck();
    });
  }

  private videoExplanationExists() {
    const explanation = VIDEO_NOTE_EXPLANATION.replace(
      '{{LESSON_TYPE}}',
      this.getLessonType()
    );

    if (this.notes().find((n) => n?.note?.includes(explanation))) {
      return true;
    }

    return false;
  }

  private applyVideoLinkToNewNote(newNoteContent) {
    if (!this.helpDataService.fullVideoUrl) {
      return newNoteContent;
    }

    newNoteContent = VIDEO_NOTE.replace(
      '{{VIDEO_NAME}}',
      this.helpDataService.videoName || DEFAULT_VIDEO_NAME
    ).replace('{{VIDEO_URL}}', this.helpDataService.fullVideoUrl);

    if (!this.videoExplanationExists()) {
      newNoteContent = this.appendExplanationToVideoNoteContent(newNoteContent);
    }

    return newNoteContent;
  }

  private applyQuestionLinkToNewNote(newNoteContent) {
    if (this.router.url.includes('/testprep/') && this.testSessionService.q) {
      const questionUrl = this.router.createUrlTree([
        'course',
        this.courseId(),
        'question',
        this.testSessionService.q.uuid
      ]);
      const originAndBasePath = window.location.href.split('/course/')[0];
      const fullUrl = originAndBasePath + questionUrl;

      return QUESTION_NOTE.replace(
        '{{QUESTION_TEXT}}',
        this.testSessionService.q.questionText || DEFAULT_QUESTION_NAME
      ).replace('{{QUESTION_URL}}', fullUrl);
    }

    if (!this.questionDataService.fullQuestionUrl) {
      return newNoteContent;
    }

    return QUESTION_NOTE.replace(
      '{{QUESTION_TEXT}}',
      this.questionDataService.questionText || DEFAULT_QUESTION_NAME
    ).replace('{{QUESTION_URL}}', this.questionDataService.fullQuestionUrl);
  }

  private appendExplanationToVideoNoteContent(noteContent) {
    return `${noteContent}${VIDEO_NOTE_EXPLANATION.replace(
      '{{LESSON_TYPE}}',
      this.getLessonType()
    )}`;
  }

  private getLessonType() {
    let lessonType = 'Video Training';

    if (this.router.url.includes('/volumes/')) {
      lessonType = 'Video Training';
    } else if (this.router.url.includes('/maneuvers/')) {
      lessonType = 'Flight Maneuvers';
    }

    return lessonType;
  }
}
