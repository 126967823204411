import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: '[app-custom-radio]',
  templateUrl: './custom-radio.component.html',
  styleUrls: ['./custom-radio.component.scss']
})
export class CustomRadioComponent implements OnInit {

  modelValue: string;

  @Input()
  get model() {
    return this.modelValue;
  }

  set model(val) {
    this.modelValue = val;
    this.modelChange.emit(this.modelValue);

  }

  @Output()
  modelChange = new EventEmitter();

  @Input()
  value;

  @Input()
  name;

  @Input()
  disabled = false;

  @Output()
  doModelChange: EventEmitter<any> = new EventEmitter<any>();


  constructor() {
  }

  ngOnInit(): void {
  }

  onChange() {
    this.doModelChange.emit();
  }

}
