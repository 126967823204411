import {ModalViewComponent} from './modal-view.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Component, ElementRef, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {ProgressService} from '../../progress.service';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {take} from 'rxjs/operators';
import {AuthContextService} from '../../services/auth-context.service';

@Component({
  selector: 'app-test-modal-view',
  templateUrl: './modal-view.component.html',
  styleUrls: ['./modal-view.component.scss']
})
export class TestModalViewComponent extends ModalViewComponent {


  constructor(route: ActivatedRoute,
              elementRef: ElementRef,
              router: Router,
              authContextService: AuthContextService,
              authService: AuthService,
              progressService: ProgressService,
              modalService: BsModalService) {
    super(route, elementRef, router, authContextService, progressService, modalService);
  }

  init() {
    console.log('TestModalViewComponent');
  }

  destroy() {
  }
}
