export enum SessionType {
DEFAULT,
  PRACTICE_TEST= 'PRACTICE_TEST',
  LEARN_CATEGORIES = 'LEARN_CATEGORIES',
  FLASH_CATEGORIES = 'FLASH_CATEGORIES',
  SMART_SESSION = 'SMART_SESSION',
  RANDOM_SESSION = 'RANDOM_SESSION',
  REVIEW_TEST = 'REVIEW_TEST',
  REVIEW_MARKED = 'REVIEW_MARKED',
  REVIEW_CORRECT = 'REVIEW_CORRECT',
  REVIEW_INCORRECT = 'REVIEW_INCORRECT',
  REVIEW_INCORRECT_AT_LEAST_ONCE = 'REVIEW_INCORRECT_AT_LEAST_ONCE',
  QUESTION_SEARCH = 'QUESTION_SEARCH',
  NEVER_SEEN = 'NEVER_SEEN'
}
