import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {Observable} from 'rxjs';
import {CoursesService} from '../courses.service';
import { AuthService } from '../services/auth.service';
import { TrainingService } from '../training.service';

@Injectable({
  providedIn: 'root'
})
export class AccessCourseGuard  {
  config: any;
  constructor(private coursesService: CoursesService, private router: Router, private authService: AuthService, private trainingService: TrainingService) {

  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree> {
      this.config = await this.trainingService.loadConfig();

    let courseId = null;
    if (route.paramMap.get('id')) {
      courseId = route.paramMap.get('id');
    } else if (route.queryParamMap.get('id')) {
      courseId = route.queryParamMap.get('id');
    } else if (route.firstChild.params['id']) {
      courseId = route.firstChild.params['id'];
    }
    return new Promise((resolve, reject) => {

      this.trainingService.loadConfig().then(value => {
        if (value['user.mode'] = 'YE') {
          return this.authService.renew().then(value => {
            this.coursesService.loadCourseLicenses(courseId).then(softwareLicense => {
              if (!softwareLicense) {
                this.router.navigate(['/', 'home', 'courses']);
                resolve(false);
              } else {
                resolve(true);
              }
            });
          });
        }
      });

  });
  }

}
