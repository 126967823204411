import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {Observable, of} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {TrainingService} from '../training.service';

@Injectable()
export class UserGuard {
  config: any;

  constructor(private authService: AuthService,
              private router: Router,
              private trainingService: TrainingService) {
  }

  async canActivate(): Promise<boolean> {
    return this.authService.renew();
  }
}
