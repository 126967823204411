import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { TrainingService } from '../../training.service';
import { InvitationsFilters } from './Invitations-filters.interface';

@Injectable({
  providedIn: 'root'
})
export class StudentService {
  api = null;
  private dataObs$ = new ReplaySubject(1);

  constructor(private httpClient: HttpClient, private trainingService: TrainingService) {
    this.trainingService.load().subscribe(value => {
      this.api = value;
      this.dataObs$.next(value);
    });
  }

  load(): Observable<any> {
    return this.dataObs$;
  }

  getAllStudents(): Observable<any[]> {
    return this.httpClient.get<any[]>(this.api.getRoute('cfi-getStudents'));
  }

  searchAllEnrolledStudents(options: { page: number, size: number, sortBy: string, sortDirection: string, searchTerm: string, classroomId: number }): Observable<any> {
    let params = new HttpParams()
      .set('page', options.page.toString())
      .set('size', options.size.toString())
      .set('sort', `${options.sortBy},${options.sortDirection}`)
      .set('searchTerm', options.searchTerm)
      .set('classroomId', options.classroomId);

    return this.httpClient.get<any>(this.api.getRoute('cfi-getenrolledstudents'), { params });
  }

  getStudentDetails(studentId: string): Observable<any> {
    return this.httpClient.get<any[]>(this.api.getRoute('cfi-getStudentDetails').replace('{studentId}', studentId));
  }

  getStudentDetailsPermissions(studentId: string): Observable<any> {
    return this.httpClient.get<any[]>(this.api.getRoute('cfi-getStudentDetails').replace('{studentId}', studentId) + "/permissions");
  }

  updateStudentClassroom(studentId: number, classroomId: number): Observable<any> {
    return this.httpClient.put(this.api.getRoute('cfi-addStudentToClassroom').replace('{classroomId}', classroomId).replace('{studentId}', studentId), {});
  }

  getCourseInfo(studentId: number, courseType: string): Observable<any> {
    return this.httpClient.get<any[]>(this.api.getRoute('cfi-getStudents') + '/' + studentId + '/' + courseType);
  }

  getEndorsmentCertificate(studentId: number, certificateId: number): Observable<Blob> {
    return this.httpClient.get(this.api.getRoute('cfi-studentCertificate').replace('{studentId}', studentId).replace('{certificateId}', certificateId), { responseType: "blob" });
  }

  loadInvitationsByClassroom(classId: number): Observable<any[]> {
    return this.httpClient.get<any[]>(this.api.getRoute('cfi-addClassroom') + '/' + classId);
  }

  getStudentsByClassroom(classId: number): Observable<any> {
    return this.httpClient.get<any[]>(this.api.getRoute('cfi-addClassroom') + '/' + classId);
  }

  getStudentProgress(studentId: number, webAppId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(this.api.getRoute('cfi-studentProgressSummary').replace('{studentId}', studentId).replace('{webAppId}', webAppId) + '/progress', { observe: 'response' }).subscribe(value => {
        if (value.status === 200) {
          resolve(value.body);
        } else {
          reject(null);
        }
      });
    });
  }

  getStudentSessions(studentId: number, webAppId: string, page: number, size: number, testTypes: string[]): Promise<any> {

    let data = new HttpParams()
      .set('page', page)
      .set('size', size);

    testTypes.forEach(testType => {
      data = data.append('testTypes', testType);
    });

    return this.httpClient.get(this.api.getRoute('cfi-getStudentSessions').replace('{studentId}', studentId).replace('{webAppId}', webAppId), { params: data }).toPromise();
  }

  getStudentGroundLessons(webAppId: string): Observable<any> {
    return this.httpClient.get<any[]>(this.api.getRoute('getSyllabus').replace('{webAppId}', webAppId));
  }

  async getTestProgress(studentId: number, webAppId: string): Promise<any> {
    return this.httpClient.get(this.api.getRoute('cfi-getTestProgress').replace('{studentId}', studentId).replace('{webAppId}', webAppId)).toPromise();
  }

  getCfiChiefStudentsLatestProgress(filters: InvitationsFilters): Observable<any> {
    const params = this.getParamsFromInvitationsFilters(filters);

    return this.httpClient.get<any>(
      this.api.getRoute('cfi-chief-getStudentsLatestProgress'),
      { params, observe: 'response', responseType: 'blob' as 'json' }
    );
  }

  getAvailableAiAssistants(): Observable<Array<any>> {
    return this.httpClient.get<any>(this.api.getRoute('availableUiAssistantsUrl'));
  }

  getCfiStudentsLatestProgress(filters: InvitationsFilters): Observable<any> {
    const params = this.getParamsFromInvitationsFilters(filters);

    return this.httpClient.get<any>(this.api.getRoute('cfi-getStudentsLatestProgress'), {
      params,
      observe: 'response',
      responseType: 'blob' as 'json'
    });
  }

  private getParamsFromInvitationsFilters(filters: InvitationsFilters): HttpParams {
    let params = new HttpParams();
    let filtersDto: any = { ...filters };

    if (filters.sortBy && filters.sortDirection) {
      filtersDto.sort = `${filters.sortBy},${filters.sortDirection}`;
      delete filtersDto.sortBy;
      delete filtersDto.sortDirection;
    }

    for (let key in filtersDto) {
      if (filtersDto[key] !== null) {
        params = params.set(key, filtersDto[key]);
      }
    }

    return params;
  }
}
