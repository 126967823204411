export const nameKey = Symbol('analytics_name');

/**
 * To perserve class name though mangling.
 * @example
 * @name('Customer')
 * class Customer {}
 * @param className
 */

export function Analytics(name: string): ClassDecorator {
  return (target: any) => {
    target[nameKey] = name;
    return target;
  };
}
/**
 * @example
 * const type = Customer;
 * getName(type); // 'Customer'
 * @param type
 */
export function getAnalyticsName(type: Function): string {
  return type[nameKey];
}
/**
 * @example
 * const instance = new Customer();
 * getInstanceName(instance); // 'Customer'
 * @param instance
 */
//export function getInstanceName(instance: Object): string {
//  return (Reflect as any).getMetadata(nameKey, instance.constructor);
//}
