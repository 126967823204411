export class TestProgress {
  grades: Array<Grade>;
  minimumScore: number;
  requiredTests: number;
}

export class Grade {
  passed: boolean;
  score: number;

  constructor(passed: boolean, score:number) {
    this.passed = passed;
    this.score =score;
  }
}
