export enum SessionTypeLabels {
  DEFAULT = 'Original',
  PRACTICE_TEST = 'Practice Test',
  LEARN_CATEGORIES = 'Study Categories',
  FLASH_CATEGORIES = 'Study Categories',
  SMART_SESSION = 'Smart Study',
  RANDOM_SESSION = 'Random Questions',
  REVIEW_TEST = 'Review a Test',
  REVIEW_MARKED = 'Marked Questions',
  REVIEW_CORRECT = 'Review Correct',
  REVIEW_INCORRECT = 'Incorrect Questions',
  REVIEW_INCORRECT_AT_LEAST_ONCE = 'Incorrect Questions At Least Once',
  QUESTION_SEARCH = 'Question Search',
  NEVER_SEEN = 'Never Seen Questions',
}
