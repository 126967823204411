<div class="modal-header w-100 p-0">
    <div
        class="w-100 plate plate-dark_blue swoosh swoosh-cfi-home px-3 py-4 border-bottom-left-radius-0 border-bottom-right-radius-0 border-top-left-radius-modal border-top-right-radius-modal">
        <div class="d-flex justify-content-start align-items-center w-100 plate-header">
            <img class="w-100 modal-header-image d-none d-md-block"
                src="https://dl.videos.sportys.com/onlinecourse/images/sportys-logo-block-airplane.svg" />
            <div class="tagline text-size-18 ml-2">Login Assistance</div>
            <div class="d-flex align-items-start pt-0 text-size-14 ml-5 pl-5 mr-5 cursor-pointer"
                (click)="bsModalRef.hide();">
                <span aria-hidden="true" class="text-white modal-close-btn text-size-20">×</span>
            </div>
        </div>
    </div>
</div>
<div class="modal-body">
    <div class="alert"[ngClass]="{'alert-success': success, 'alert-warning': !success}" *ngIf="message">
        {{message}}
    </div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="stylized">
        <div class="my-2 font-weight-bold font-size-16">We'll email you a magic link for a password-free sign in</div>
        <div class="form-group my-4">
            <input type="text" class="form-control" formControlName="email" id="email" required
                placeholder="Email Address">
        </div>

        <div class="mb-3">
            <button id="loginButton" type="submit" class="btn btn-sm btn-primary btn-round"
                [ngClass]="{'btn-loading': working === true}" [disabled]="working || form.status === 'INVALID'">Sign In
                with Email</button>
        </div>

    </form>
</div>
