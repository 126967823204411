import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationModalComponent } from '../confirmation/confirmation-modal.component';
import { take } from 'rxjs/operators';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TestsService } from '../../course/services/tests.service';
import { FaaTestResult } from 'src/app/course/tests/upload-test/faaTestResult-model';

@Component({
  selector: 'app-acs-session',
  templateUrl: './acs-session.component.html',
  styleUrls: ['./acs-session.component.scss']
})
export class AcsSessionComponent implements OnInit {

  @Input()
  test: FaaTestResult;

  @Input()
  course: any;

  @Input()
  user: any;

  @Output()
  onDelete = new EventEmitter();

  constructor(
    private modalService: BsModalService,
    public testsService: TestsService
  ) {
  }

  ngOnInit(): void {
  }

  getDetails() {
    this.test.open = !this.test.open;

    if (this.test.open && this.user) {
      this.testsService.getFaaTestResult(this.test.id).then(
        (resp: any) => {
          this.test = { ...resp, open: this.test.open };
        },
        (error: any) => {
          console.error('Error fetching test details:', error);
        }
      );
    }
  }

  remove() {
    const modal = this.modalService.show(ConfirmationModalComponent, {
      initialState: {
        message: 'Are you sure you want to delete this Test ?',
        warningMessage: null,
        yesLabel: 'Yes',
        noLabel: 'No',
        primaryButton: 'yes'
      }, class: 'rounded-modal'
    });

    this.modalService.onHide.pipe(take(1)).subscribe(value => {
      console.log('SessionComponent:', value);
      if (modal.content.response) {
        this.testsService.deleteFaaTestResult(this.test.id).then(() => {
          if (this.onDelete) {
            this.onDelete.emit({ 'test': this.test });
          }
        });
      }
      modal.hide();
    });

  }

  viewTest(url: string) {
    window.open(url, '_blank');
  }

}
