import {Injectable} from '@angular/core';
import {AuthContextService} from './auth-context.service';
import _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class CourierService {

  private static initializing = false;
  private static initialized = false;
  private onLoginSubscription: any;
  private onLogoutSubscription: any;
  private onRenewSubscription: any;
  private timeout: number;

  private currentUser = null;

  constructor(private authContextService: AuthContextService) {
    window['courier'].on('inbox/init', () => {
      console.log('Courier:inbox init');
    });

    window['courier'].on('toast/init', () => {
      console.log('Courier:toast init');
    });
  }

  public init(user: any, config: any) {
    //if (user) {
    //  this.initCourier(user, config, 0);
    //}

    this.currentUser = user;

    if (!this.onRenewSubscription) {
      this.onRenewSubscription = this.authContextService.onRenew().subscribe(_.throttle((user) => {
        console.log('onRenew:initCourier', user);
        this.initCourier(user, config);
      }, 5000));
    }
    if (!this.onLoginSubscription) {
      this.onLoginSubscription = this.authContextService.onLogin().subscribe((user) => {
        //console.log('onLogin:initCourier', user);
        //this.initCourier(user, config, 0);
      });
    }

    if (!this.onLogoutSubscription) {
      this.onLogoutSubscription = this.authContextService.onLogout().subscribe((user) => {
        console.log('onLogout:destroyCourier', user);
        this.destroy();
      });
    }
  }

  private initCourier(user: any, config: any) {
    console.log('initCourier', CourierService.initializing, CourierService.initialized);//, this.exists());
    this.currentUser = user;
    //CourierService.initializing = retries != 0;
    //if (retries > 0) {
    //  CourierService.initialized = this.exists();
    //}
    //console.log('initCourier:b', retries, CourierService.initializing, CourierService.initialized, this.exists());
    ;
    //const courierInboxElements = window.document.getElementsByTagName("courier-inbox");
    //if (user && !CourierService.initializing && !CourierService.initialized && (config['courier.enabled'] == true || config['courier.enabled'] === 'true')) {// && courierInboxElements.length > 0) {
    //  console.log('initCourier:willDoInit');

    //  window.setTimeout(() => {
    if (user && !CourierService.initializing && !CourierService.initialized && config['user.mode'] !== 'YE' && (config['courier.enabled'] == true || config['courier.enabled'] === 'true')) {
      CourierService.initializing = true;
      //this.destroy();
      this.timeout = window.setTimeout(() => {
        if (!CourierService.initialized) {
          this.doInit(user, config).then(() => {
            //this.timeout = window.setTimeout(() => {
            //if (this.exists()) {
            CourierService.initializing = false;
            CourierService.initialized = true;
            //} else {
            //console.log('initCourier:retry');
            //this.initCourier(user, config, 10);
            //}
            //}, 5000);
          });
        }
      }, 200);
    } else if (config['courier.enabled'] == false || config['courier.enabled'] === 'false') {
      console.log('initCourier:disabled', config['courier.enabled']);
    } else {
      console.log('initCourier:skipping', user, CourierService.initializing);
    }
    //} else if (!courierInboxElements || courierInboxElements.length == 0) {
    //console.log('initCourier:retry:no elements');
    //this.initCourier(user, config, retries - 1);
    //} /*
    /*else if (retries > 0 && CourierService.initializing && !CourierService.initialized) {
      window.setTimeout(() => {
        if (this.exists()) {
          CourierService.initializing = false;
          CourierService.initialized = true;
        } else {
          console.log('initCourier:retry:again');
          this.initCourier(user, config, retries - 1);
        }
      }, 1000);

    } */
    //else {
    //  console.log("initCourier:done");
    //}
    //}
  }

  private async doInit(user: any, config: any) {
    console.log('courier:DO_INIT_COURIER');
    const courier = window['courier'];
    return courier.init({
      //elementId: 'sportys-courier-container',
      clientKey: config['courier.clientKey'],//ZTFiZTZhNzAtNTYzMC00MmMzLTk0ZTktNTU3MTRkM2E5ZjExL3Rlc3Q=
      userId: config['user.mode'] === 'PAID' ? user.sportysId : user.userName,
      inbox: {
        brandId: 'DMJ2H5RHDXMWYXQ1DP89HMXCDSY3',
      }
    });
  }

  /*
  window['courierConfig'] = {
    clientKey: "ZTFiZTZhNzAtNTYzMC00MmMzLTk0ZTktNTU3MTRkM2E5ZjExL3Rlc3Q=",
    userId: 226837,
    inbox: {
      brandId: 'DMJ2H5RHDXMWYXQ1DP89HMXCDSY3',
    }
  }*/

  /*
  private t() {
    window['courierConfig'] = {
      clientKey: "ZTFiZTZhNzAtNTYzMC00MmMzLTk0ZTktNTU3MTRkM2E5ZjExL3Rlc3Q=",
      userId: 226837,
      inbox: {
        brandId: 'DMJ2H5RHDXMWYXQ1DP89HMXCDSY3',
      }
    }

    const observer = new MutationObserver((mutationsList) => {
      console.log('COURIER: mutationsList', mutationsList);
      for (const mutation of mutationsList) {
        switch (mutation.type) {
          case "childList": {
            mutation.addedNodes.forEach((component) => {
              const element = component;
              const tagName = element?.tagName?.toLowerCase();
              console.log('COURIER: tagName', element);

              switch (tagName) {
                case "courier-inbox":
                  console.log('COURIER: init:courier-inbox');
                  window.courier.init();
                  return;

                case "courier-toast":
                  console.log('COURIER: init:courier-toast');
                  window.courier.init();
                  return;
              }
            });
          }
        }
      }
    });

    observer.observe(document.body,{ //getElementsByClassName("route-container")[0], {
      attributes: false,
      childList: true,
      subtree: true,
    });

  }
*/
  private removeToast() {
    window.document.body.getElementsByTagName('courier-toast')[0].remove();
  }

  public destroy() {
    console.log('destroyCourier');
    /*
    const inboxes = window.document.body.getElementsByClassName("inbox-bell");
    if (inboxes.length > 0) {
      for (let i = 0; i < inboxes.length; i++) {
        inboxes[i].remove();
      }
    }

    const toasts = window.document.body.getElementsByClassName("Toastify");
    if (toasts.length > 0) {
      for (let i = 0; i < toasts.length; i++) {
        toasts[i].remove();
      }
    }

    const roots = window.document.body.getElementsByTagName("courier-root");
    if (roots.length > 0) {
      for (let i = 0; i < roots.length; i++) {
        roots[i].remove();
      }
    }
*/
    //CourierService.initializing = false;
    CourierService.initialized = false;


    if (this.timeout) {
      CourierService.initializing = false;
      window.clearTimeout(this.timeout);
    }
    /*
        if (this.currentUser && window['courier'] && window['courier'].transport) {
          console.log('destroyCourier:unsubscribe', this.currentUser.sportys_id);
          window['courier'].transport.unsubscribe(this.currentUser.sportys_id);
        }
        if (window['courier'] && window['courier'].transport && window['courier'].transport.closeConnection) {
          console.log('destroyCourier:closeConnection');
          window['courier'].transport.closeConnection();
        }

     */
    console.log('destroyedCourier');
  }

  public exists() {
    const elements = window.document.body.getElementsByClassName('inbox-bell');
    console.log('initCourier:exists', elements);
    return elements.length > 0;
  }

}
