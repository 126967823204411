import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {TrainingService} from '../../training.service';
import _ from 'underscore';
import {VolumeQuizView} from '../../views/volume-quiz.view';
import {TestsService} from './tests.service';
import {ProgressService} from '../../progress.service';
import {AuthContextService} from 'src/app/services/auth-context.service';
import {CertificationType} from '../enums/certification-type.enum';
import {TestProgress} from '../../domain/interfaces/test-progress.interface';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CourseService {

  testProgressSubject: ReplaySubject<TestProgress> = null;

  constructor(
    private httpClient: HttpClient,
    private trainingService: TrainingService,
    private progressService: ProgressService,
    private authContextService: AuthContextService
  ) {
  }

  public async videoWatched(courseId: string, uuid: string): Promise<any> {

    const api = await this.trainingService.loadByCourse(courseId);

    return this.httpClient.put(api.getRoute('videoWatched').replace('{uuid}', uuid), {}).toPromise();
  }

  public async documentRead(courseId: string, uuid: string): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);

    return this.httpClient.put(api.getRoute('documentRead').replace('{uuid}', uuid), {}).toPromise();
  }

  public async lessonComplete(courseId: string, uuid: string): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);

    return this.httpClient.put(api.getRoute('lessonCompleted').replace('{uuid}', uuid), {}).toPromise();
  }

  public async quizTake(courseId: string, uuid: string, questionResults: any): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);

    return this.httpClient.put(api.getRoute('quizTaken')
      .replace('{uuid}', uuid), questionResults).toPromise();
  }

  public async markVolumeComplete(webAppId: string, volumeUuid: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(webAppId).then(api => {
        this.httpClient.post(api.getRoute('markVolumeComplete').replace('{webAppId}', webAppId).replace('{volumeUuid}', volumeUuid), {}).subscribe(response => {
          resolve(response);
          this.progressService.refreshProgress(webAppId);
        }, error => {
          reject(error);
        });
      });
    });

  }

  public async markCourseComplete(webAppId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(webAppId).then(api => {
        this.httpClient.post(api.getRoute('markCourseComplete').replace('{webAppId}', webAppId), {}).subscribe(response => {
          resolve(response);
          this.progressService.refreshProgress(webAppId);
        }, error => {
          reject(error);
        });
      });
    });

  }

  public async getCertificateDownloadUrl(value: any): Promise<string> {
    return new Promise(resolve => {
      this.trainingService.load().subscribe(api => {
        resolve(`${api.getRoute('downloadCertificate')}`.replace('{certificateId}', value.id || value.entityId));
      });
    });
  }

  public async getCoursePrice(courseId: string): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);

    return this.httpClient.get(api.getRoute('getCoursePrice'), {}).toPromise();
  }

  public async submitExamEndorsementRequest(courseId: any, value: any, isCourseMixed: boolean, defaultCertificationType: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(courseId).then(api => {
        let url = api.getRoute('submitExamEndorsementRequest');
        if (isCourseMixed && defaultCertificationType === CertificationType.EXAM_ENDORSEMENT) {
          url = api.getRoute('submitCourseCompletionRequestMixed');
        }
        this.httpClient.put(url, value).subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
      });
    });

  }

  public async submitCourseCompletionRequest(courseId: any, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(courseId).then(api => {
        this.httpClient.put(api.getRoute('submitCourseCompletionRequest'), value).subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
      });
    });
  }


  public async submitCourseCompletionRequestMixed(courseId: any, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(courseId).then(api => {

        this.httpClient.put(api.getRoute('submitCourseCompletionRequestMixed'), value).subscribe({
          next: (response) => {

            resolve(response);
          }, error: (error) => {

            reject(error);
          }
        });
      });
    });
  }

  public async submitFaaWingsRequest(courseId: any, value: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.loadByCourse(courseId).then(api => {
        this.httpClient.put(api.getRoute('submitFaaWingsRequest'), value).subscribe({
          next: (response) => {
            resolve(response);
          }, error: (error) => {
            reject(error);
          }
        });
      });
    });
  }

  public async downloadCertificates(url, certificationFileType: string) {

    let headers = new HttpHeaders();
    headers = headers.append('Bearer', [this.authContextService.getToken()]);

    let anchor = document.createElement('a');
    document.body.appendChild(anchor);

    this.httpClient.get(url, {
      headers: headers,
      observe: 'response',
      responseType: 'blob' as 'json',
      params: new HttpParams().set('type', certificationFileType)
    }).subscribe(value => {
      let objectUrl = window.URL.createObjectURL(value.body as Blob);

      //get filename from content disposition header
      console.log(value.headers);

      const contentDisposition = value.headers.get('Content-Disposition');

      let fileName = contentDisposition.split(';')[2].trim().split('=')[`1`].replace(/"/g, '');

      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
      document.body.removeChild(anchor);
    });
  }

  public getTestProgress(courseId: string, refresh: boolean = false): Observable<TestProgress> {
    if (this.testProgressSubject && !refresh) {
      return this.testProgressSubject;
    }
    if(!this.testProgressSubject) {
      this.testProgressSubject = new ReplaySubject<TestProgress>(1);
    }
    this.trainingService.loadByCourse(courseId).then(api => {
      this.httpClient.get(api.getRoute('getTestProgress'), {}).subscribe({
        next: (value) => {
          this.testProgressSubject.next(value as TestProgress);
        },
        error: (error) => {
          this.testProgressSubject.error(error);
        }
      });
    });
    return this.testProgressSubject;
  }
}
