import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {TrainingService} from '../../training.service';
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  constructor(private httpClient: HttpClient, private trainingService: TrainingService) {
  }

  async getConnectionRequests(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.get(api.getRoute('cfi-getConnectionRequests')).subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject();
        });
      });
    });
  }

  async getInvitationRequests(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.get(api.getRoute('cfi-getInvitationRequests')).subscribe((result) => {
          resolve(result);
        }, (error) => {
          reject();
        });
      });
    });
  }

  async revokeConnection(invitation, revoked): Promise<any> {

    const body = new HttpParams()
      .set('token', invitation.uuid)
      .set('revoke', revoked);

    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('cfi-revokeConnection'), body, {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        }).subscribe(result => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
      });
    });
  }

  async revokeInvitation(invitation, revoked): Promise<any> {

    const body = new HttpParams()
      .set('token', invitation.uuid)
      .set('revoke', revoked);

    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('cfi-revokeInvitation'), body, {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
        }).subscribe(result => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
      });
    });
  }

  async sendConnectionRequest(email): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('cfi-sendConnectionRequest'), {
          email
        }).subscribe(result => {
          resolve(result);
        }, (error) => {
          reject(error);
        });
      });
    });
  }

  async findConnection(token: string) {
    const api = await firstValueFrom(this.trainingService.load());
    return firstValueFrom(this.httpClient.get(api.getRoute('cfi-instructor-findConnection').replace('{uuid}', token)));
  }

  async acceptConnection(token: string) {
    const api = await firstValueFrom(this.trainingService.load());
    return firstValueFrom(this.httpClient.put(api.getRoute('cfi-instructor-acceptConnection').replace('{uuid}', token), {}));
  }

  async declineConnection(token: string) {
    const api = await firstValueFrom(this.trainingService.load());
    return firstValueFrom(this.httpClient.put(api.getRoute('cfi-instructor-declineConnection').replace('{uuid}', token), {}));
  }

}
