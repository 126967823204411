<nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary"
     [ngClass]="{'bg-cfi': authContextService.isValidCFIUser(),'bg-primary':!authContextService.isValidCFIUser()}">
  <div class="d-flex flex-nowrap align-items-center">
    <app-brand-container></app-brand-container>
    <div *ngIf="authContextService.isValidCFIUser()" class="tagline d-none d-md-block">Instructor Portal</div>
  </div>
  <div class="d-flex justify-content-end">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
            [ngClass]="{'open': !collapsed}" (click)="toggleCollapsed()">
      <i class="fa fa-ellipsis-v"></i>
    </button>
    <app-user-info class="d-block d-lg-none" *ngIf="authContextService.user" [user]="authContextService.user">
    </app-user-info>
  </div>

  <div class="navbar-collapse collapse show" [ngClass]="{'in': !collapsed}" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
    </ul>
    <ul class="navbar-nav float-lg-right">
      <li class="nav-item">
        <a class="nav-link" routerLink="/cfi" *ngIf="!!config && config['user.mode'] === 'PAID'" routerLinkActive="active">CFI Portal</a>
      </li>
      <li class="nav-item d-lg-none d-xl-none">
        <a class="nav-link" routerLink="/home/news" routerLinkActive="active">Sporty's News</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/home/courses" *ngIf="!!config && config['user.mode'] === 'PAID'" routerLinkActive="active">My Courses</a>
      </li>
      <li class="nav-item"  *ngIf="!!config && config['user.mode'] === 'PAID'">
        <a class="nav-link" routerLink="/home/learn" routerLinkActive="active">All Courses</a>
      </li>
      <li class="nav-item" *ngIf="!!config && config['user.mode'] === 'PAID'">
        <a class="nav-link" routerLink="/home/learn-to-fly" routerLinkActive="active">Learn To Fly</a>
      </li>
      <li class="nav-item courier-inbox">
        <courier-toast></courier-toast>
        <courier-inbox></courier-inbox>
      </li>
      <app-user-info class="d-none d-lg-block" *ngIf="authContextService.user" [user]="authContextService.user">
      </app-user-info>

      <li class="nav-item" *ngIf="!authContextService.user">
        <a class="nav-link btn btn-white" routerLink="/login">Log In</a>
      </li>
    </ul>
  </div>
</nav>

<router-outlet></router-outlet>
