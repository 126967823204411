import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { scaleLinear } from 'd3-scale';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {

  @Input()
  public correct: number;

  @Input()
  public attempts: number;

  background: string;

  constructor(private e: ElementRef) {
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    //scope.range = _.range(1, 5);
    const range = [50, 75, 100];
    const low = range[0];
    const high = range[2];

    const colorOptions = ['#d7191c', '#efef5d', '#61b203'];
    const colorScale = scaleLinear(range, colorOptions);

    //    console.log('progress bar', this.correct, this.attempts);

    this.background = colorScale(100 * (this.correct / this.attempts));

  }

}
