import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'underscore';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: '[app-figure-modal]',
  templateUrl: './figure-modal.component.html',
  styleUrls: ['./figure-modal.component.scss']
})
export class FigureModalComponent implements OnInit, AfterViewInit {
  question: any;
  type: string;
  code: string;
  url: string;
  html: SafeHtml;
  imageTemplate: string;
  course: any;
  imageIndex: number;

  constructor(
    public bsModalRef: BsModalRef,
    private element: ElementRef,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    this.html = this.sanitizer.bypassSecurityTrustHtml(this.question.parsed_questionText);

    this.imageTemplate = 'https://dl.videos.sportys.com/onlinecourse/images/figures/catalog-2h/${type}-${code}_2h.jpg';
    if ((typeof this.question.image_template !== 'undefined' && this.question.image_template !== null) || (typeof this.question.imageTemplate !== 'undefined' && this.question.imageTemplate !== null)) {
      this.imageTemplate = this.question.image_template || this.question.imageTemplate;
    }

    console.log('FigureModalComponent:imageTemplate', this.type, this.code);
    this.imageIndex = 0;
    this.url = this.imageTemplate.replace('${type}', this.type).replace('${code}', this.code);

  }

  ngAfterViewInit() {
    const links = this.element.nativeElement.querySelectorAll('a.legend, a.figure');
    let index = 0;
    _.forEach(links, (link) => {
      console.log('FigureModalComponent:link', link);
      this.handleClick(link, index);

      if (link.attributes['data-figure'] && this.code === link.attributes['data-figure'].value) {
        this.imageIndex = index;
      } else if (link.attributes['data-legend'] && this.code === link.attributes['data-legend'].value) {
        this.imageIndex = index;
      }
      index++;
    });

  }

  private handleClick<V>(link: any, index: number) {
    link.addEventListener('click', (event) => {
      console.log('FigureModalComponent:link:click', event);
      event.preventDefault();
      event.stopPropagation();

      this.imageIndex = index;
      if (event.target.attributes['data-figure']) {
        this.showFigure(event.target.attributes['data-figure'].value);
      } else if (event.target.attributes['data-legend']) {
        this.showLegend(event.target.attributes['data-legend'].value);
      }
    });
  }

  showFigure(figure) {
    this.url = this.imageTemplate.replace('${type}', 'figure').replace('${code}', figure);
  }

  showLegend(legend) {
    this.url = this.imageTemplate.replace('${type}', 'legend').replace('${code}', legend);
  }
}
