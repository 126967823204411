import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {VolumeSummary} from '../domain/volume-summary';

@Injectable()
export class CourseSharedService {
  public closeCourseSidenavReplaySubject = new ReplaySubject<boolean>();
  public nextVideoSectionNotificationSubject = new ReplaySubject<any>();
  public optionalVideoTrainingNotificationSubject = new ReplaySubject<string>();
  accordionExpandState: { [key: string]: boolean } = {
    'videoTraining': true
  };

  constructor() {
  }

  notifyCloseCourseSidenav(value: boolean) {
    this.closeCourseSidenavReplaySubject.next(value);
  }

  notifyNextVideoSectionChanged(firstVideo: any) {
    this.nextVideoSectionNotificationSubject.next(firstVideo);
  }

  notifyOptionalVideoTrainingChanged(optionalVideoTrainingGuid: string) {
    this.optionalVideoTrainingNotificationSubject.next(optionalVideoTrainingGuid);
  }

  findUnwatchedVideoInVolume = (volume: VolumeSummary, uuids: string[]): string => {
    let unwatchedVideo =
      volume.videoUuids &&
      volume.videoUuids
        .find((v) => {
          return uuids.indexOf(v) < 0;
        });

    if (
      !unwatchedVideo &&
      volume.videoSegmentUuids &&
      volume.videoSegmentUuids.length > 0
    ) {
      unwatchedVideo = volume.videoSegmentUuids
        .find((vs) => {
          return uuids.indexOf(vs) < 0;
        });
    }

    return unwatchedVideo;
  };

  isVolumeOptional(volume): boolean {
    return (volume.requiredVideos + volume.requiredDocuments + volume.requiredVideoSegments + volume.requiredInteractiveLessons + volume.requiredQuizzes) == 0;
  }

  private hasAtLeastOneRequiredProperty(array: any[]) {
    if (!array || array?.length === 0) {
      return false;
    }

    return array.some((item) => item.required);
  }
}
