import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {NgForm} from '@angular/forms';
import * as _ from 'underscore';
import {transition, trigger} from '@angular/animations';
import {useSlideFadeOutAnimation} from '../../ngx-animations/animations';
import {TestsService} from '../../course/services/tests.service';

@Component({
  selector: 'app-video-quiz',
  templateUrl: './video-quiz.component.html',
  styleUrls: ['./video-quiz.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('myTriggerName', [
      transition(':leave', useSlideFadeOutAnimation())
    ])]
})
export class VideoQuizComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  video;

  @Input()
  user;

  @Input()
  course;

  @Output()
  onPlayExplanation: EventEmitter<any> = new EventEmitter<any>();


  currentQuestion;
  questions: Array<any>;
  //grade: number;
  //questionHistory: Array<any> = [];
  purchased = false;
  correctAnswers = 0;

  constructor(private testsService: TestsService) {
    console.log('VideoQuizComponent:constructor');
  }

  ngOnInit() {
    console.log('VideoQuizComponent:ngOnInit');
    this.questions = this.video.videoQuestions;

    this.testsService.modifyQuestions(_.map(this.questions, (question) => {
      return question.question;
    }));

    _.each(this.questions, (question: any) => {
      if (question.question['questionVideos']) {
        question.question['questionVideo'] = _.find(question.question['questionVideos'], (questionVideo) => {
          return questionVideo?.video.id === this.video.id;
        });
      }
    });
    this.currentQuestion = this.video.videoQuestions[0];
    this.grade();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('VideoQuizComponent:ngOnChanges');
  }

  ngOnDestroy() {
    console.log('VideoQuizComponent:ngOnDestroy');
  }

  prev() {
    if (this.video.videoQuestions.indexOf(this.currentQuestion) > 0) {
      this.currentQuestion = this.video.videoQuestions[this.video.videoQuestions.indexOf(this.currentQuestion) - 1];
    }
  }

  next() {
    if (this.video.videoQuestions.indexOf(this.currentQuestion) < this.video.videoQuestions.length - 1) {
      this.currentQuestion = this.video.videoQuestions[this.video.videoQuestions.indexOf(this.currentQuestion) + 1];
    }
  }

  hasPrev() {
    return this.video.videoQuestions.indexOf(this.currentQuestion) > 0;
  }

  hasNext() {
    return this.video.videoQuestions.indexOf(this.currentQuestion) < this.video.videoQuestions.length - 1;
  }


  onSubmit(f: NgForm) {
    const correct = _.values(this.video.videoQuestions).filter(question => {
      return question.selected === _.find(question.question.answers, answer => answer['correct'])['id'];
    }).length;
    //this.grade = correct / this.video.videoQuestions.length;
  }

  arrayOf(n) {
    return Array(n);
  }

  correctAnswer(question) {
    return _.findWhere(question.question.answers, {correct: true});
  }

  isGraded() {
    return false;
  }

  isLearning() {
    return true;
  }

  position(question: any) {
    return this.questions.indexOf(question) + 1;
  }

  /*
  record(q) {
    if (q.correct != null) {
      if (this.questionHistory.indexOf(q) >= 0) {
        this.questionHistory.splice(this.questionHistory.indexOf(q), 1);
      }
      this.questionHistory.unshift(q);
      this.questionHistory.splice(5);
    }
  }
   */

  questionUpdated(question) {
    this.grade();
    //this.record(question);
  }

  grade() {
    _.each(this.questions, (question: any) => {
      const correctAnswer = _.findWhere(question['question']['answers'], {correct: true});

      question['correct'] = question['user_answer'] === correctAnswer['id'];
    });

    this.correctAnswers = _.filter(this.questions, (question) => {
      return question.correct;
    }).length;
  }


  playExplanation(explanation) {
    console.log('VideoQuizComponent:playExplanation', explanation);
    this.onPlayExplanation.emit(explanation);

  }
}
