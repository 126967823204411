import {Component, EventEmitter, Input, OnInit, OnDestroy, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute } from '@angular/router';
import {AuthService} from 'src/app/services/auth.service';
import {identityRevealedValidator} from 'src/app/validators/emailvalidator';
import {PasswordValidator} from 'src/app/validators/password.validator';
import {AnalyticsService} from '../../analytics/analytics.service';
import * as _ from "underscore";
import moment from "moment/moment";

@Component({
  selector: 'app-signup[course]',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit, OnDestroy {
  @Input() showCancelButton = true;
  @Input() submitButtonLabel = 'Create Account';
  @Input() course;

  cfiExpirationDateType = 'text';

  @Input() set showCfiCredentials(value: boolean) {
    this._showCfiCredentials = value;
  }

  get showCfiCredentials(): boolean {
    return this._showCfiCredentials;
  }

  @Output() onCancel = new EventEmitter<boolean>();
  @Output() onCompleted = new EventEmitter<any>();
  @Output() onCancelAndLogin = new EventEmitter<boolean>();

  isLoading = false;
  errorMessage = null;

  form: UntypedFormGroup;

  _showCfiCredentials = false;
  showCancelAndLoginButton = false;
  invitationToken = '';

  public years: number[] = _.range(moment().year(), moment().year() + 3);
  public months: string[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];


  constructor(
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.form = this.initializeFormGroup();
    this.activatedRoute.queryParams
      .subscribe(params => {
        this.invitationToken = params.token;
      });
  }

  ngOnDestroy(): void {
    this.errorMessage = '';
  }

  onSubmit() {
    this.isLoading = true;
    this.showCancelAndLoginButton = false;

    let expirationDate = null;
    if (this.form.value.cfi_expiration_month && this.form.value.cfi_expiration_year) {
      expirationDate = moment().set("month", this.form.value.cfi_expiration_month).set("year", this.form.value.cfi_expiration_year).endOf('month').endOf("day").endOf("day").format('YYYY-MM-DD') as string;
    }

    this.authService.register(this.form.get('firstName').value,
      this.form.get('lastName').value,
      this.form.get('email').value,
      this.form.get('password').value,
      '',
      this.form.get('cfi_number') ? this.form.get('cfi_number').value : null,
      expirationDate)
      .then(value => {
        this.authService.login(this.form.get('email').value, this.form.get('password').value)
          .subscribe(loginResult => {

            this.authService.renew().then(renewedValue => {
              this.isLoading = false;
              if (renewedValue.cfiNumber && !renewedValue.isValidCfi) {
                this.errorMessage = 'We were unable to create your Sporty\'s Flight Instructor account based on the CFI credentials entered. A customer support representative has been notified and will contact you shortly.';
              }

              if (!this.invitationToken || this.invitationToken === '') {
                this.authService.emitNewAccount();
              }
              this.analyticsService.signup(this.course);
              this.onCompleted.emit(renewedValue);

            });
          }, error => {
            console.error(error.error.message);
            this.errorMessage = error.error.message;
            this.isLoading = false;
          });
      }, reason => {
        console.error(reason);
        if (reason.error && reason.error.message) {
          this.errorMessage = reason.error.message;
        } else {
          this.errorMessage = 'We were unable to create your Sporty\'s account. A customer support representative has been notified and will contact you shortly.';
        }
        this.isLoading = false;
        if (reason.status === 412) {
          this.showCancelAndLoginButton = true;
        }
      });
  }

  cancel() {
    this.onCancel.emit(true);
  }

  cancelAndLogin() {
    this.onCancelAndLogin.emit(true);
  }

  /*
  private setCfiCredentialsValidators(enable: boolean): void {
    if (enable) {
      this.form.get('cfi_number').setValidators([Validators.required]);
      this.form.get('cfi_expiration_date').setValidators([DateValidator.isDate, DateValidator.isDateInFuture]);
    }
  }*/

  /*
    public changeExpirationDateValidation(): void {
      const expirationDateValueControl = this.form.get('cfi_expiration_date');

      if (expirationDateValueControl.value !== '') {
        this.form.get('cfi_expiration_date').setValidators([DateValidator.isDate, DateValidator.isDateInFuture]);
      } else {
        this.form.get('cfi_expiration_date').clearValidators();
        this.form.get('cfi_expiration_date').updateValueAndValidity();
      }
    }
  */

  isValidExpirationDate(): boolean {
    if (this.isPopulatedExpirationDate()) {
      const expirationDate = moment()
        .set("month", this.form.value.cfi_expiration_month)
        .set("year", this.form.value.cfi_expiration_year)
        .endOf('month')
        .endOf("day")
      return moment().subtract(3, 'months').isBefore(expirationDate);
    } else {
      return true;
    }
  }

  isPopulatedExpirationDate(): boolean {
    return (this.form.value.cfi_expiration_month && this.form.value.cfi_expiration_year);
  }

  private initializeFormGroup(): UntypedFormGroup {
    const form: UntypedFormGroup = new UntypedFormGroup({});

    form.addControl('firstName', new UntypedFormControl(null, Validators.required));
    form.addControl('lastName', new UntypedFormControl(null, Validators.required));
    form.addControl('email', new UntypedFormControl(null, [Validators.required, Validators.email]));
    form.addControl('confirm_email', new UntypedFormControl(null, [Validators.required, Validators.email]));
    form.addControl('password', new UntypedFormControl(null, [Validators.required, Validators.minLength(8), PasswordValidator.strong]));
    form.setValidators(identityRevealedValidator);

    if (this.showCfiCredentials) {
      form.addControl('cfi_number', new UntypedFormControl(null));
      form.addControl('cfi_expiration_month', new UntypedFormControl(''));
      form.addControl('cfi_expiration_year', new UntypedFormControl(''));
    }

    return form;
  }

}
