<div
    class="row px-4 d-flex justify-content-between align-items-center mt-3 cursor-pointer"
    [class.row-complete]="lesson?.status === 'complete'"
    (click)="toggleCollapsed()"
  >
    <div class="col flex-container justify-content-start align-items-center">
      <app-icon glyph [name]="isExpanded() ? 'minus' : 'plus'"></app-icon>
      <div class="ml-3 text-dark text-size-16 font-weight-bold">{{ lesson?.title }}</div>
    </div>
    <div class="flex-container justify-content-end align-items-center status-wrapper">
      <div class="status complete" *ngIf="lesson?.status === 'complete'; else lessonNotComplete">
        Complete <app-icon glyph name="correct_green"></app-icon>
      </div>
      <ng-template #lessonNotComplete>
        <div class="status in-progress" *ngIf="lesson?.status === 'in-progress'; else lessonNotStarted">
          In progress
        </div>
        <ng-template #lessonNotStarted>
          <div class="status not-started">
            Not started
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
  <div class="row pl-5" [ngClass]="{ collapse: !isExpanded(), 'collapse.show': isExpanded() }">
    <ng-container *ngFor="let video of lesson?.videos">
      <div class="col-md-6 col-12 mt-3">
        <div class="shadow-sm border-white lesson-card-content">
          <div class="d-flex justify-content-between align-items-center w-100 h-100">
            <div class="text-size-14 text-color-gray">{{ video?.name }}</div>
            <app-icon *ngIf="video?.watched" glyph name="correct"></app-icon>
          </div>
        </div>
      </div>
    </ng-container>
    <div
      *ngIf="false"
      class="row col-12 d-flex justify-content-center align-items-center"
    >
      This category doesn't have any lessons.
    </div>
  </div>