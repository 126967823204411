@if (!helpDataService.helpHidden()) {
  <button class="btn btn-round btn-primary main-button" [class.secondary-background]="secondaryBackground()" (click)="onToggle($event)">
    @if (!helpDataService.helpOpened()) {
    <app-icon glyph name="pressure-outlined" class="icon-hangar"/>
    } @else {
    <a class="btn icon-down" title="Toggle drawer">
      <app-icon glyph name="down-white" />
    </a>
    }
  </button>
}

@if (helpDataService.helpOpened()) {
  <div
    [@slideUpDown]="helpDataService.helpOpened()"
    class="help-container"
    (click)="$event.stopPropagation()"
  >
    <div class="header"
         [class.tall]="activeOption() === 'help-center' && showHelpCenterMenuTitle()"
         [class.notes]="activeOption() === 'my-notes'">
      @switch(activeOption()) {
        @case('help-center') {
          @if (showHelpCenterMenuTitle()) {
            <h2 class="name">HI {{ authContext.user?.first_name?.toUpperCase() || '' }}</h2>
            <h2>HOW CAN WE HELP?</h2>
          } @else {
            <app-icon glyph name="back-white" class="back-button" (click)="onHelpCenterTitleBack()"></app-icon>
            <h2>HELP CENTER</h2>
          }
        }
        @case('my-notes') {
          <h2>MY NOTES</h2>
          <a class="btn float-left add-note" (click)="newNote()" title="Add Note">
            <app-icon glyph name="new-note" />
          </a>
        }
        @default {
          <h2>HI</h2>
          <h2>HOW CAN WE HELP?</h2>
        }
      }
    </div>
    <div class="body" #helpBody (scroll)="onScroll($event)">
      @switch(activeOption()) {
        @case('help-center') {
          <app-help-center [course]="course()"
                           [showMenu]="showHelpCenterMenuTitle()"
                           [isScrolled]="isScrolled()"
                           (scrollToTop)="onScrollToTop()"
                           (quickStartGuideOpened)="onClose()"
                           (helpCenterSelected)="onHelpCenterSelected()"></app-help-center>
        }
        @case('my-notes') {
          <app-notes [courseId]="courseId()" class="my-notes" #myNotes></app-notes>
        }
        @default {
          <app-help-center [course]="course()"
                           [showMenu]="showHelpCenterMenuTitle()"
                           [isScrolled]="isScrolled()"
                           (scrollToTop)="onScrollToTop()"
                           (quickStartGuideOpened)="onClose()"
                           (helpCenterSelected)="onHelpCenterSelected()"></app-help-center>
        }
      }
    </div>
    <div class="footer">
      <ul class="actions">
        <li class="action" [class.active]="activeOption() === 'help-center'" (click)="onHelpCenter()">
          @if (activeOption() === 'help-center') {
            <app-icon glyph name="help-center-home-blue"></app-icon>
          } @else {
            <app-icon glyph name="help-center-home"></app-icon>
          }
          Help Center
        </li>
        <li class="action" [class.active]="activeOption() === 'my-notes'" (click)="onMyNotes()">
          @if (activeOption() === 'my-notes') {
            <app-icon glyph name="notes-blue"></app-icon>
          } @else {
            <app-icon glyph name="notes"></app-icon>
          }
          My Notes
        </li>
      </ul>
    </div>
  </div>
}
