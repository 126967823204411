import {Injectable} from '@angular/core';
import {TrainingService} from '../../training.service';
import {HttpClient} from '@angular/common/http';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class UserDocumentService {

  constructor(private trainingService: TrainingService, private httpClient: HttpClient) {
  }

  load(): Promise<any> {

    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.get(api.getRoute('pspdfkitDocumentsUrl')).subscribe(value => {
          resolve(value);
        });
      });
    });
  }

  loadById(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.load().then(documents => {
        const document = _.findWhere(documents, {id: Number.parseInt(id, 10)});
        if (document) {
          resolve(document);
        } else {
          reject();
        }
      }, reason => {
        reject(reason);
      });
    });
  }

  delete(documentId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.delete(api.getRoute('pspdfkitDeleteUrl'), {
          params: {
            id: documentId
          }
        }).subscribe(value => {
          resolve(value);
        });
      });
    });
  }

}
