<div class="request-endorsement responsive-content">
  <div class="responsive-content-header">
  </div>
  <div class="responsive-content-body" *ngIf="course && progress && user && license">
    <div *ngIf="license.course_status !== 'COMPLETED'">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
             *ngIf="user.hasCfiCertificate && !user.isGroundInstructor">
          <!--<i>Scenario 1 (FIRC_ACR={{activeLicense().courseSubType}}: user.has_cfi_certificate && !user.is_ground_instructor</i>-->
          <p>
            Congratulations! You have now completed the Sporty’s FIRC and met the requirements to earn your graduation certificate. Please fill in your information below and press submit. Your
            graduation certificate will be available for immediate download and a copy will be emailed to you.
          </p>

          <div *ngIf="license.courseSubType !== 'FIRC_ACR'">
            <p>
              Once you have received your graduation certificate, you have two options for renewing your unexpired flight instructor certificate. One option would be to contact your local FSDO
              and schedule your flight instructor certificate renewal with them.
            </p>
            <p>
              As an alternative, Sporty's would be happy to assist you with an electronic renewal through our convenient ACR service. If you've already purchased the ACR service, follow the
              instructions contained in your order confirmation from Sporty's. <a href="https://www.sportys.com/pilotshop/acr-processing-service.html?utm_source=onlinecourse&utm_medium=referral"
                                                                                  target="_blank">You may also purchase the ACR renewal service here.</a>
            </p>
            <p>Please note, ACR processing requires uploads of your current CFI and photo identification along with an FAA 8710 application via IACRA.
            </p>
            <p>
              Don't forget that you need to complete either method of renewal before your flight instructor certificate expires and before the graduation certificate expires.
            </p>
          </div>

          <div *ngIf="license.courseSubType === 'FIRC_ACR'">
            <p>
              Once you have received your graduation certificate, you have two options for renewing your unexpired flight instructor certificate. One option would be to contact your local FSDO
              and schedule your flight instructor certificate renewal with them.
            </p>
            <p>
              As an alternative, Sporty's would be happy to assist you with an electronic renewal through our convenient ACR service which you purchased with this eFIRC.
            </p>
            <p>
              Don't forget that you need to complete either method of renewal before your flight instructor certificate expires and before the graduation certificate expires.
            </p>
          </div>
          <p>
            Thank you for joining us. We hope to see you again for your next flight instructor certificate renewal.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" *ngIf="!user.hasCfiCertificate">
          <!--<i>Scenario 2 (FIRC_ACR={{activeLicense().courseSubType}}: !user.has_cfi_certificate</i>-->
          <p>
            Congratulations! You have now completed the Sporty’s FIRC and met the requirements to earn your completion certificate. Please fill in your information below and press submit. Your
            completion certificate will be available for immediate download and a copy will be emailed to you.
          </p>

          <p>
            Thank you for joining us. We hope to see you again the next time you are looking for aviation training.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
             *ngIf="user.hasCfiCertificate && user.isGroundInstructor">
          <p>
            Congratulations! You have now completed the Sporty’s FIRC and met the requirements to earn your graduation certificate. Please fill in your information below and press submit. Your
            graduation certificate will be available for immediate download and copies will be emailed to you.
          </p>

          <div *ngIf="license.courseSubType !== 'FIRC_ACR'">
            <p>
              Once you have received your graduation certificate, you have two options for renewing your unexpired flight instructor certificate. One option would be to contact your local FSDO
              and schedule your flight instructor certificate renewal with them.
            </p>
            <p>
              As an alternative, Sporty's would be happy to assist you with an electronic renewal through our convenient ACR service. If you've already purchased the ACR service, follow the
              instructions contained in your order confirmation from Sporty's. <a href="https://www.sportys.com/pilotshop/acr-processing-service.html?utm_source=onlinecourse&utm_medium=referral"
                                                                                  target="_blank">You may also purchase the ACR renewal service here.</a>
            </p>
            <p>Please note, ACR processing requires uploads of your current CFI and photo identification along with an FAA 8710 application via IACRA.
            </p>
            <p>
              Don't forget that you need to complete either method of renewal before your flight instructor certificate expires and before the graduation certificate expires.
            </p>
          </div>

          <div *ngIf="license.courseSubType === 'FIRC_ACR'">
            <p>
              Once you have received your graduation certificate, you have two options for renewing your unexpired flight instructor certificate. One option would be to contact your local FSDO
              and schedule your flight instructor certificate renewal with them.
            </p>
            <p>
              As an alternative, Sporty's would be happy to assist you with an electronic renewal through our convenient ACR service which you purchased with this eFIRC.
            </p>
            <p>
              Don't forget that you need to complete either method of renewal before your flight instructor certificate expires and before the graduation certificate expires.
            </p>
          </div>

          <p>
            You'll also receive a Ground Instructor Graduation Certificate. This serves as proof of completion of this FIRC which serves as the basis for ground instructor recent experience
            requirements in accordance with 14 CFR part 61, § 61.217(c)
          </p>

          <p>
            Thank you for joining us. We hope to see you again for your next flight instructor certificate renewal.
          </p>
        </div>
      </div>

      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="stylized">
        <div class="form-row">

          <div class="form-group col-md-12">
            <label>Name on Photo ID</label>
            <input formControlName="name" class="form-control form-control-sm form-control form-control-sm-sm" type="text"
                   minlength="4"
                   [attr.disabled]="formDisabled || null" required/>
          </div>

          <div class="form-group col-md-6">
            <label>Street Address</label>
            <input formControlName="address" class="form-control form-control-sm" type="text"
                   [attr.disabled]="formDisabled || null"
                   required/>
          </div>
          <div class="form-group col-md-6">
            <label>City</label><input formControlName="city" class="form-control form-control-sm"
                                      [attr.disabled]="formDisabled || null"
                                      type="text"
                                      required>
          </div>
          <div class="form-group col-md-6">
            <label>State</label>
            <select name="State" formControlName="state" class="form-control form-control-sm"
                    [attr.disabled]="formDisabled || null"
                    required>
              <option value="" selected="selected">Select a State</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
              <option value="--" label="Other Country">Other Country</option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label>Zip</label>
            <input formControlName="zip" class="form-control form-control-sm" type="text"
                   [attr.disabled]="formDisabled || null"
                   required>
          </div>
          <div class="form-group col-md-6">
            <label>Phone</label><input formControlName="phone" class="form-control form-control-sm"
                                       [attr.disabled]="formDisabled || null"
                                       type="text" required>
          </div>
          <!--
          <div class="form-group col-md-6">
            <label>Email <span class="text-danger" *ngIf="form.errors?.mismatchedEmail && (form.touched || form.dirty)">Emails must match</span></label>
            <input formControlName="email" class="form-control form-control-sm"
                   [attr.disabled]="formDisabled || null"
                   type="email" required>
          </div>

          <div class="form-group col-md-6">
            <label>Confirm Email <span class="text-danger"
                                       *ngIf="form.errors?.mismatchedEmail && (form.touched || form.dirty)">Emails must match</span></label>
            <input formControlName="confirmEmail" [attr.disabled]="formDisabled || null"
                   class="form-control form-control-sm"
                   type="email" required>
          </div>
          -->
          <div class="col-12">
            <div class="alert alert-success"  *ngIf="allowDownload && result && result.success" (click)="redirectToAddress()">
              Your certificate is available for download in the Progress section on the left side of this screen.
              <span>Click <a style="cursor: pointer; text-decoration: underline; ">here</a> to proceed to Sporty’s Paperless CFI Renewal Processing</span>
            </div>
            <div class="alert alert-warning" *ngIf="result && !result.success">
              {{result.message}}
            </div>
          </div>
          <div class="col-12 w-100">
            <p>Enter your information and press the Submit button to request your certificate. A copy will be
              emailed to you and it will be available for download in the Progress section of the course menu.</p>
          </div>
          <div class="col-12 w-100">
            <button type="submit" class="btn btn-round btn-primary btn-width-fixed-240"
                    *ngIf="allowSubmit"
                    [attr.disabled]="form.status === 'INVALID' || formDisabled || loading">Submit
            </button>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="license.course_status === 'COMPLETED'">
      <div class="col-xs-12 col-sm-12 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4"
           style="text-align:center;margin-top:100px;">
        <p style="font-size:16px;" *ngIf="user.hasCfiCertificate && user.isGroundInstructor">
          Your Graduation Certificate and Ground Certificate have already been generated and sent to you.
        </p>

        <p style="font-size:16px;" *ngIf="user.hasCfiCertificate && !user.isGroundInstructor">
          Your Graduation Certificate has already been generated and sent to you.
        </p>

        <p style="font-size:16px;" *ngIf="!user.hasCfiCertificate">
          Your Completion Certificate has already been generated and sent to you.
        </p>

        <p>
          You can download a copy from the Progress Reports page.
        </p>

        <p style="margin-top:20px;"><a [routerLink]="['..','licenses']" class="btn btn-success btn-lg">Progress Reports</a></p>

        <div class="well" *ngIf="!user.hasCfiCertificate && license.courseSubType === 'FIRC_ACR'">
          Congratulations on including Sporty's convenient electronic ACR service with your eFIRC purchase. Follow this link to the ACR processing page:<br>
          <a href="https://www.sportys.com/pilotshop/acr-service-upload?utm_source=onlinecourse&utm_medium=referral"
             target="_blank">Sporty's ACR Upload Service</a>
        </div>
        <div class="well" *ngIf="!user.hasCfiCertificate && license.courseSubType !== 'FIRC_ACR'">
          If you would like to purchase Sporty's convenient electronic ACR service to expedite your renewal:<br>
          <a href="https://www.sportys.com/pilotshop/acr-processing-service.html?utm_source=onlinecourse&utm_medium=referral"
             target="_blank">Sporty's Paperless CFI Renewal (ACR Processing)</a>
        </div>
      </div>
    </div>
  </div>
</div>
