import {NgModule} from '@angular/core';
import {RouterModule, Routes,} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {HomeComponent} from './home/home.component';
import {AuthGuard} from './guards/auth-guard';
import {UserResolver} from './course/resolvers/user-resolver';
import {CfiComponent} from './cfi/cfi.component';
import {InstructorResolver} from './course/resolvers/instructor-resolver';
import {SchoolResolver} from './course/resolvers/school-resolver';
import {YeGuard} from './guards/ye-guard';
import {YeAuthGuard} from './guards/ye-auth-guard';
import {LoginResetComponent} from './login/reset/login-reset.component';
import {FeatureDisabledComponent} from './feature-disabled/feature-disabled.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {GlobalQueryParamsResolverService} from './course/resolvers/global-query-params-resolver';
import {ForcePaidGuard} from './guards/force-paid-guard';
import {ForceDemoGuard} from './guards/force-demo-guard';
import {MagicLinkComponent} from './components/magic-link/magic-link.component';

const routes: Routes = [
  {
    path: '',
    resolve: {
      queryParams: GlobalQueryParamsResolverService,
      user: UserResolver
    },
    children: [
      {
        path: '',
        redirectTo: '/home/learn',
        pathMatch: 'full',
      }, {
        path: 'service',
        children:
          [
            {
              path: 'actas',
              pathMatch: 'full',
              component: LoginComponent,
            }
          ]
      },
      {
        ///api/web/link/
        path: 'api/web/link/:code',
        pathMatch: 'full',
        component: MagicLinkComponent
      },
      {
        path: 'maintenance',
        component: MaintenanceComponent,
        pathMatch: 'full'
      },
      {
        path: 'feature-disabled',
        component: FeatureDisabledComponent,
        pathMatch: 'full'
      },
      {
        path: 'login',
        component: LoginComponent,
        resolve: {
          school: SchoolResolver,
        },
        canActivate: [AuthGuard],
      },
      {
        path: 'login/select',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'login/test',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'login/mycourses',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'login/study',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'login/questions',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'login/progress',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'login/categories',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'login/quiz',
        redirectTo: '/login',
        pathMatch: 'full',
      },
      {
        path: 'login/reset',
        pathMatch: 'full',
        component: LoginResetComponent
      },
      {
        path: 'login/:org',
        component: LoginComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'course',
        canActivateChild: [YeAuthGuard],
        canActivate: [ForceDemoGuard],
        loadChildren: () =>
          import('./course/course.module').then((m) => m.CourseModule),
      },
      {
        path: 'demo/course',
        canActivateChild: [YeAuthGuard],
        canActivate: [ForcePaidGuard],
        loadChildren: () =>
          import('./course/course.module').then((m) => m.CourseModule),
      },
      {
        path: 'home',
        component: HomeComponent,
        loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'cfi',
        component: CfiComponent,
        canActivate: [YeGuard],
        resolve: {
          user: UserResolver,
          instructor: InstructorResolver,
        },
        loadChildren: () => import('./cfi/cfi.module').then((m) => m.CfiModule),
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      paramsInheritanceStrategy: 'always',
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})

export class AppRoutingModule {
}
