import {NgModule} from '@angular/core';
import {TimePipe} from './time.pipe';
import {TagFilterPipe} from './tag-filter.pipe';
import {QuestionFilterPipe} from './question-filter.pipe';
import {HoursMinutesPipe} from './hours-minutes.pipe';
import {DurationPipe} from "./duration.pipe";
import {MathRoundPipe} from './math-round.pipe';
import { CapitalizeFirstLetterPipe } from './first-letter-uppercase.pipe';


@NgModule({
  declarations: [
    TimePipe,
    TagFilterPipe,
    MathRoundPipe,
    CapitalizeFirstLetterPipe,
    QuestionFilterPipe,
    HoursMinutesPipe,
    DurationPipe
  ],
  exports: [TimePipe, TagFilterPipe, QuestionFilterPipe, HoursMinutesPipe, MathRoundPipe, DurationPipe, CapitalizeFirstLetterPipe],
  imports: []
})
export class PipeModule {
}
