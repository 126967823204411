<div class="modal-header w-100 p-0">
  <div
    class="w-100 plate plate-dark_blue swoosh swoosh-cfi-home px-3 py-4 border-bottom-left-radius-0 border-bottom-right-radius-0 border-top-left-radius-modal border-top-right-radius-modal">
    <div class="d-flex justify-content-start align-items-center w-100 plate-header">
      <img class="w-100 modal-header-image d-none d-md-block"
        src="https://dl.videos.sportys.com/onlinecourse/images/sportys-logo-block-airplane.svg" />
      <div class="tagline text-size-18 ml-2">{{courseGroup.name}}</div>
      <div class="d-flex align-items-start pt-0 text-size-14 ml-5 pl-5 mr-5 cursor-pointer"
        (click)="bsModalRef.hide();">
        <span aria-hidden="true" class="text-white modal-close-btn text-size-20">×</span>
      </div>
    </div>
  </div>
</div>
<div class="modal-body">
  <div class="mt-3 px-3">
    <p>
      Select a pilot track from the options below.
      You can change your course track selection at any time from within the course.
    </p>
  </div>
  <div class="course-list">
    <div class="course-list_course" *ngFor="let course of courseGroup.courses"
      (click)="selectCourseForCourseGroup(course)">
      <div class="course-list_image">
        <img src="{{course.thumbnailUrl}}">
      </div>
      <div class="course-list_name">
        <span>{{course.longName}}</span>
      </div>
    </div>
  </div>
</div>
