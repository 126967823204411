@if (config) {
  <nav class="navbar navbar-expand-lg fixed-top navbar-dark bg-primary"
       style="flex:0 1 auto;"
       [ngClass]="{'bg-cfi': authContextService.isValidCFIUser(),'bg-primary':!authContextService.isValidCFIUser()}">
    <div class="d-flex justify-content-start align-items-center">
      <a class="navbar-brand nd-block navbar-toggler left-hamburger-menu"
         [class.d-flex]="isFarPage() && isFarSmallScreen()"
         [class.d-lg-none]="!isFarPage()"
         [class.no-toggler]="globalStateService.isSidenavHidden()"
         (click)="toggleSidenav()">
        <span class="navbar-toggler-icon"></span>
      </a>
      <div class="hide-on-small-width-screen">
        <app-brand-container></app-brand-container>
      </div>
      @if (authContextService.isValidCFIUser() && !isFarPage()) {
        <div class="tagline d-md-block d-none">Instructor Portal</div>
      }
    </div>
    <div class="d-flex">
      <div class="d-flex align-items-center justify-content-end">
        <button class="navbar-toggler "
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                [ngClass]="{'open': !isNavbarCollapsed()}"
                (click)="toggleNavbar()">
          <i class="fa fa-ellipsis-v"></i>
        </button>
        <app-user-info class="d-block d-lg-none"
                       [user]="user"
                       [course]="course"></app-user-info>
      </div>
    </div>
    <div class="navbar-collapse collapse show"
         [ngClass]="{'in': !isNavbarCollapsed()}"
         id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
      </ul>
      <ul class="navbar-nav float-lg-right">
        @if (courseId === 'FIRC_2023') {
          <li class="nav-item">
            <a class="nav-link"
               routerLink="/cfi"
               routerLinkActive="active">CFI Portal</a>
          </li>
        }
        @if ((!!config && config['user.mode'] === 'PAID') ) {
          <li class="nav-item">
            <a class="nav-link"
               [routerLink]="['/home','courses']"
               routerLinkActive="active">My Courses</a>
          </li>
        }
        @if (!!config && config['user.mode'] === 'PAID') {
          <li class="nav-item">
            <a class="nav-link"
               [routerLink]="['/home','learn']"
               routerLinkActive="active">All Courses</a>
          </li>
        }
        @if (!!config && config['user.mode'] === 'PAID') {
          <li class="nav-item">
            <a class="nav-link"
               [routerLink]="['/home','learn-to-fly']"
               routerLinkActive="active">Learn To Fly</a>
          </li>
        }
        @if (!authContextService.user) {
          <li class="nav-item">
            <div class="switch primary-switch">
              <div class="custom-control custom-switch">
                <input type="checkbox"
                       class="custom-control-input"
                       id="customSwitches2"
                       (change)="changeTheme()"
                       [ngModel]="!isThemeLightSelected" (ngModelChange)="isThemeLightSelected = $event">
                @if (!isThemeLightSelected) {
                  <label class="custom-control-label text-white"
                         for="customSwitches2">Dark</label>
                }
                @if (isThemeLightSelected) {
                  <label class="custom-control-label text-white"
                         for="customSwitches2">Light</label>
                }
              </div>
              <span class="lever"></span>
            </div>
          </li>
        }
        <li class="nav-item  d-md-none">
          <a class="nav-link"
             [routerLink]="['/course', courseId, 'volumes']"
             routerLinkActive="active">Training</a>
        </li>
        @if (course?.enableTestPrep) {
          <li class="nav-item  d-md-none">
            <a class="nav-link"
               [routerLink]="['/course', courseId, 'testprep']"
               routerLinkActive="active">Test Prep</a>
          </li>
        }
        @if (course?.enableFlightManeuvers) {
          <li class="nav-item  d-md-none">
            <a class="nav-link"
               [routerLink]="['/course', courseId, 'maneuvers']"
               routerLinkActive="active">Maneuvers</a>
          </li>
        }
        <li class="nav-item  d-md-none">
          <a class="nav-link"
             [routerLink]="['/course', courseId, 'resources']"
             routerLinkActive="active">Resources</a>
        </li>
        @if (course?.enableFaaHandbooks) {
          <li class="nav-item  d-md-none">
            <a class="nav-link"
               [routerLink]="['/course', courseId, 'faalibrary']"
               routerLinkActive="active">FAA
                                         Handbooks</a>
          </li>
        }
        @if (course?.enableFAR) {
          <li class="nav-item  d-md-none">
            <a class="nav-link"
               [routerLink]="['/course', course?.webAppId, 'far']"
               routerLinkActive="active">FARs</a>
          </li>
        }
        @if (course?.enableCheckridePrep) {
          <li class="nav-item  d-md-none">
            <a class="nav-link"
               [routerLink]="['/course', courseId, 'checkride']"
               routerLinkActive="active">Checkride
                                         Prep</a>
          </li>
        }
        <li class="nav-item courier-inbox">
          <courier-inbox></courier-inbox>
        </li>
        <li class="d-none d-lg-block">
          @if (authContextService.user) {
            <app-user-info [user]="user"
                           [course]="course"/>
          }
        </li>

        @if (!authContextService.user) {
          <li class="nav-item">
            <a class="nav-link btn btn-white"
               (click)="login()">Log In</a>
          </li>
        }
      </ul>
    </div>
  </nav>
}

<div class="course-body">
  <div class="d-flex flex-row flex-nowrap align-items-stretch">
    <div class="sidenav-background"
         [ngClass]="{'sidenav-open': isSidenavOpen() && !isFarPage()}"
         [class.no-sidenav-background]="globalStateService.isSidenavHidden()"
         (click)="closeSidenav()">
    </div>
    @if (!globalStateService.isSidenavHidden() && !isFarPage()) {
      <div class="sidenav"
           id="courseSidenav"
           [class.sidenav-open]="isSidenavOpen()"
           [class.mobile]="isSmallScreen()"
           [ngClass]="{'sidenav_collapsed': !isSidenavOpen(), 'sticky-header-sidenav': showStickySidenavHeader}"
           #courseSideNav>
        <div class="mt-1 side-menu-minified"
             [class.hidden]="isSidenavOpen() || isSmallScreen()">
          <div class="menu-close justify-content-center ml-2 pt-4"
               (click)="openSidenav()">
            <i class="fa fa-solid fa-angle-double-right"></i>
          </div>
          <div class="sidenav-nav minified-sidebar"
               [ngClass]="{'mt-4 pt-4': course?.enumName !== 'PRIVATE', 'mt-2 pt-2': course?.enumName === 'PRIVATE'}">
            <ul class="nav flex-column">
              @if (course?.enableDashboard) {
                <li class="nav-item"
                    routerLinkActive="active-link"
                    [routerLinkActiveOptions]="{exact: true}"
                    [routerLink]="['/course', course?.webAppId, course?.homePageType.route]">
                  <div class="nav-parent">
                    <a class="nav-link"
                       [ngClass]="{'pt-0': course?.enumName === 'PRIVATE'}"
                       routerLinkActive="active">
                      <app-icon glyph
                                name="home"></app-icon>
                    </a>
                  </div>
                </li>
              }
              @for (volumeGroup of volumeGroups; track volumeGroup.code) {
                <li class="nav-item"
                    routerLinkActive="active-link"
                    [routerLink]="['/course', course?.webAppId, 'volumes', volumeGroup.code]">
                  <div class="nav-parent">
                    <a class="nav-link"
                       [ngClass]="{'pt-0': course?.enumName === 'PRIVATE'}"
                       routerLinkActive="active"
                       [routerLinkActiveOptions]="{exact: true}">
                      <app-icon glyph
                                name="{{volumeGroup.svgIconName}}"></app-icon>
                    </a>
                  </div>
                </li>
              }
              @if (course?.enableTestPrep) {
                <li class="nav-item"
                    routerLinkActive="active-link"
                    [routerLink]="['/course', course?.webAppId, 'testprep']">
                  <div class="nav-parent">
                    <a class="nav-link"
                       [ngClass]="{'pt-0': course?.enumName === 'PRIVATE'}"
                       routerLinkActive="active">
                      <app-icon glyph
                                name="grade"></app-icon>
                    </a>
                  </div>
                </li>
              }
              @if (course?.enableFlightManeuvers) {
                <li class="nav-item"
                    routerLinkActive="active-link"
                    [routerLink]="['/course', course?.webAppId, 'maneuvers']">
                  <div class="nav-parent">
                    <a class="nav-link"
                       [ngClass]="{'pt-0': course?.enumName === 'PRIVATE'}"
                       routerLinkActive="active">
                      <app-icon glyph
                                name="maneuvers"></app-icon>
                    </a>
                  </div>
                </li>
              }
              <li class="nav-item"
                  routerLinkActive="active-link"
                  [routerLink]="['/course', course?.webAppId, 'resources']">
                <div class="nav-parent">
                  <a class="nav-link"
                     [ngClass]="{'pt-0': course?.enumName === 'PRIVATE'}"
                     routerLinkActive="active">
                    <app-icon glyph
                              name="grade"></app-icon>
                  </a>
                </div>
              </li>
              @if (course?.enableFaaHandbooks) {
                <li class="nav-item"
                    routerLinkActive="active-link"
                    [routerLink]="['/course', course?.webAppId, 'faalibrary']">
                  <div class="nav-parent">
                    <a class="nav-link"
                       [ngClass]="{'pt-0': course?.enumName === 'PRIVATE'}"
                       routerLinkActive="active">
                      <app-icon glyph
                                name="test"></app-icon>
                    </a>
                  </div>
                </li>
              }
              @if (course?.enableFlightSequences) {
                <li class="nav-item"
                    routerLinkActive="active-link"
                    [routerLink]="['/course', course?.webAppId, 'flight_sequences']">
                  <div class="nav-parent">
                    <a class="nav-link"
                       [ngClass]="{'pt-0': course?.enumName === 'PRIVATE'}"
                       routerLinkActive="active">
                      <app-icon glyph
                                name="resources"></app-icon>
                    </a>
                  </div>
                </li>
              }
              @if (course?.enumName !== 'PRIVATE' && course?.details?.enableFAR === true) {
                <li class="nav-item"
                    (click)="showFarPage($event);">
                  <div class="nav-parent">
                    <div class="nav-link">
                      <app-icon glyph
                                name="fars"></app-icon>
                    </div>
                  </div>
                </li>
              }
              @if (course?.enableCheckridePrep) {
                <li class="nav-item"
                    routerLinkActive="active-link"
                    [routerLink]="['/course', course?.webAppId, 'checkride']">
                  <div class="nav-parent">
                    <a class="nav-link"
                       [ngClass]="{'pt-0': course?.enumName === 'PRIVATE'}"
                       routerLinkActive="active">
                      <app-icon glyph
                                name="checkride_prep"></app-icon>
                    </a>
                  </div>
                </li>
              }
            </ul>
          </div>
        </div>
        <div class="side-menu"
             [class.hidden]="!isSidenavOpen() && !isSmallScreen()">
          @if (!isCustomSideNav()) {
            <div class="menu-close-wrapper d-none d-md-flex">
              <div class="menu-close justify-content-start ml-3"
                   (click)="closeSidenav()">
                <i class="fa fa-solid fa-angle-double-left row align-items-center"></i>
              </div>
            </div>
          }
          <div class="menu-item">
            <div>
              <router-outlet name="nav-header"></router-outlet>
            </div>
            <router-outlet name="sidenav"></router-outlet>
          </div>
        </div>
      </div>
    }
    <div class="content-body"
         id="contentBody"
         #contentBody
         [class.no-sidenav]="globalStateService.isSidenavHidden() || isFarPage()"
         [class.sidenav-open]="isSidenavOpen()">
      @if (!user && course && course.enableFreeCourseWithSignup) {
        <header class="free-signup d-flex align-items-end justify-content-center">
          <div class="plate-light_blue p-0 mt-2">
            <div class="welcome_plate plate plate-light_blue d-flex">
              <div class="w-100">
                <h1>Welcome to Sporty's Pilot Training Course</h1>
              </div>
              <div class="w-100">
                <p class="mt-2">
                  Create a free Sporty's account to unlock free access to the Get Started Learning to Fly Course.
                </p>
                <p class="mt-2">
                  <button class="btn btn-sm  btn-icon btn-round btn-primary btn-shadow"
                          (click)="signup()">
                    <app-icon glyph
                              name="login_white"></app-icon>
                    Create a FREE Sporty's Account to unlock access
                  </button>
                </p>
              </div>
            </div>
          </div>
        </header>
      }
      <div>
        <router-outlet name="content-header"></router-outlet>
      </div>
      <div id="courseRouterOutlet">
        <router-outlet></router-outlet>
      </div>
      @if (authContextService.user) {
        <app-help class="help" [courseId]="courseId" [course]="course" [secondaryBackground]="isChatDpePage()"></app-help>
      }
    </div>
  </div>
</div>
