import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ColorSchemeService } from 'src/app/services/color-scheme.service';
import { NavLink } from './models/nav-link';

@Component({
  selector: 'app-sidenav-generic',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  @Input() backLink: NavLink;
  @Input() links: NavLink[];

  @Output() sidenavClicked = new EventEmitter();

  constructor(public colorSchemeService: ColorSchemeService) {}

  sidenavLinkClicked(): void {
    this.sidenavClicked.emit();
  }

  public tooltip(link): string {
    if (link.isDisabled) {
      return "This feature is available for current CFIs after creating a free Sporty's Flight Instructor account.";
    }
    return '';
  }
}
