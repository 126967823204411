import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {TrainingService} from '../training.service';

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  constructor(private trainingService: TrainingService, private httpClient: HttpClient) {
  }

  claim(code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('claims-submitAuthCode').replace('{code}', code), {}, {}).subscribe(val => {
          resolve(val);
        }, (error) => {
          reject(error);
        });
      });
    });
  }

  verify(code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.get(api.getRoute('claims-verifyAuthCode').replace('{code}', code), {}).subscribe(val => {
          resolve(val);
        }, (error) => {
          reject(error);
        });
      });
    });
  }

  auth(code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => {
        this.httpClient.post(api.getRoute('loginCodeUrl'), {
          code
        }).subscribe({
          next: (response) => {
            resolve(response);
          }, error: (error) => {
            reject(error);
          }
        });
      });
    });
  }
}
