import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {ModalModule} from 'ngx-bootstrap/modal';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {LoginComponent} from './login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HomeComponent} from './home/home.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {CookieService} from 'ngx-cookie-service';
import {Angulartics2Module} from 'angulartics2';
import {CourseComponent} from './course/course.component';
import {SelectCourseComponent} from './components/select-course/select-course.component';
import {ShareProgressComponent} from './components/share-progress/share-progress.component';
import {OldHelpComponent} from './components/old-help/old-help.component';
import {SessionTimeoutComponent} from './components/session-timeout/session-timeout.component';
import {LoginModalComponent} from './components/login-modal/login-modal.component';
import {SignupModalComponent} from './components/signup-modal/signup-modal.component';
import {FigureModalComponent} from './course/tests/figure-modal/figure-modal.component';
import {GradeModalComponent} from './course/tests/grade-modal/grade-modal.component';
import {ComponentModule} from './components/component.module';
import {AnalysisModalComponent} from './course/tests/analysis-modal/analysis-modal.component';
import {RequestCertificateComponent} from './components/request-certificate/request-certificate.component';
import {RequestEndorsementComponent} from './components/request-endorsement/request-endorsement.component';
import {RequestWingsComponent} from './components/request-wings/request-wings.component';
import {RequestCfiCertificateComponent} from './components/request-cfi-certificate/request-cfi-certificate.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {TabsModule} from 'ngx-bootstrap/tabs';
import {ShareInviteComponent} from './components/share-invite/share-invite.component';

import {AngularFireModule} from '@angular/fire/compat';
import {
  AngularFireAnalyticsModule,
  ScreenTrackingService,
  UserTrackingService
} from '@angular/fire/compat/analytics';
import {environment} from '../environments/environment';
import {PipeModule} from './pipes/pipes.module';
import {TokenInterceptorService} from './interceptors/token-interceptor.service';
import {FarSharedService} from './course/far/far-shared.service';
import {ExamplePdfViewerComponent} from './example-pdf-viewer/example-pdf-viewer.component';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {CourierService} from './services/courier.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NotesModule} from './components/notes/notes.module';
import {QuillModule} from 'ngx-quill';
import {HelpComponent} from './components/help/help.component';
import {SocketIoModule} from 'ngx-socket-io';
import {YeGuard} from './guards/ye-guard';
import {AccessCourseGuard} from './guards/access-course-guard';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {YeAuthGuard} from './guards/ye-auth-guard';
import {LoginResetComponent} from './login/reset/login-reset.component';
import {FeatureInterceptorService} from './interceptors/feature-interceptor.service';
import {FeatureDisabledComponent} from './feature-disabled/feature-disabled.component';
import {TestModeInterceptorService} from './interceptors/test-mode-interceptor.service';
import {MaintenanceModeInterceptorService} from './interceptors/maintenance-mode-interceptor.service';
import {
  GroundLessonComponent
} from './cfi/students/student-course-progress/student-course-ground-lessons/ground-lesson/ground-lesson.component';
import {MarkdownModule} from 'ngx-markdown';
import {ForceDemoGuard} from './guards/force-demo-guard';
import {ForcePaidGuard} from './guards/force-paid-guard';
import {UserGuard} from './guards/user-guard';
import {MagicLinkComponent} from './components/magic-link/magic-link.component';
import {MagicLinkService} from './services/magic-link.service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginResetComponent,
    HomeComponent,
    PageNotFoundComponent,
    CourseComponent,
    SelectCourseComponent,
    ShareProgressComponent,
    OldHelpComponent,
    SessionTimeoutComponent,
    LoginModalComponent,
    SignupModalComponent,
    FigureModalComponent,
    GradeModalComponent,
    AnalysisModalComponent,
    RequestCertificateComponent,
    RequestEndorsementComponent,
    RequestWingsComponent,
    RequestCfiCertificateComponent,
    ShareInviteComponent,
    ExamplePdfViewerComponent,
    MaintenanceComponent,
    FeatureDisabledComponent,
    MagicLinkComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearQueryParams: true
      }
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    ComponentModule,
    //CompileModule,
    TabsModule.forRoot(),
    PipeModule,
    NgxExtendedPdfViewerModule,
    MatSnackBarModule,
    QuillModule.forRoot(),
    NotesModule,
    HelpComponent,
    SocketIoModule,
    GroundLessonComponent,
    // TODO: remove once the FAR component is refactored to be a standalone component. Then use the `providers: [provideMarkdown()]` syntax.
    MarkdownModule.forRoot(),
  ],
  providers: [
    CookieService,
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FeatureInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TestModeInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MaintenanceModeInterceptorService,
      multi: true
    },
    FarSharedService,
    CourierService,
    YeGuard,
    YeAuthGuard,
    ForceDemoGuard,
    ForcePaidGuard,
    AccessCourseGuard,
    MagicLinkService,
    UserGuard
  ],
  bootstrap: [AppComponent],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
