import { Injectable, signal } from '@angular/core';

@Injectable({providedIn: 'root'})
export class HelpDataService {
  helpHidden = signal(false);
  helpOpened = signal(false);
  featureErrorMessage = "";
  featureCode = 0;
  videoName = '';
  fullVideoUrl = '';
}
