<div class="responsive-content">
  <div class="responsive-content-header">
  </div>
  <div class="responsive-content-body" *ngIf="course && progress">
    <div>
      <p>
        Congratulations on completing Sporty's {{course.longName}}!
      </p>
      <div *ngIf="course.webAppId !== 'FLIGHTREVIEW'">
        <p>
          You are now eligible to receive your endorsement to take the FAA knowledge test.
        </p>

        <p>
          The FAA Knowledge Test can be completed at any FAA testing center.
          At the testing center, you will be asked to present verification that you have completed your course of
          training
          and are prepared to take the written test.
          Sporty's will provide a Certificate of Completion to serve as this verification provided you have met two
          requirements:
        </p>

        <ul>
          <li *ngIf="progress.totalRequiredElements > 0">
            You have completed the entire video course of training for your desired track <strong>and</strong>
          </li>
          <li>Completed two practice exams with a minimum score of {{testProgress.minimumScore}}%.</li>
        </ul>

        <p>
          If you have met these minimum requirements, please submit the information below.
          Your certificate will be available for instant download and a copy will be emailed to you.
        </p>

        <div class="alert alert-warning">(Enter your full name exactly as it appears on your photo ID.
          FAA Knowledge Test Centers <b>will not</b> allow you to take the test if the name on the endorsement does not
          match
          the name on your photo ID.)
        </div>
      </div>
      <div *ngIf="course.webAppId === 'FLIGHTREVIEW'">
        <p>
          After completing the video training and receiving a minimum score of {{testProgress.minimumScore}}% on the included quiz, you will be
          eligible to receive an endorsement from Sporty’s Academy stating that you have completed the required one hour
          of ground training for the Flight Review.
        </p>
        <p>
          To satisfy the complete Flight Review requirements, an additional one hour of flight training (minimum) is
          required from a certified flight instructor per FAR 61.56.
        </p>
        <p>
          Note: Acceptance of the Sporty’s endorsement toward your Flight Review is at the discretion of the flight
          instructor conducting the flight training. Sporty’s recommends consulting your flight instructor in advance to
          verify acceptance of this training toward your Flight Review.
        </p>
        <p>
          If you have met these minimum requirements, please submit the information below.
          Your certificate will be available for instant download and a copy will be emailed to you.
        </p>
      </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="stylized">
      <div class="form-row">

        <div class="form-group col-md-6">
          <label>Name on Photo ID</label>
          <input formControlName="name" class="form-control form-control-sm form-control form-control-sm-sm" type="text"
                 minlength="4"
                 [attr.disabled]="formDisabled || null" required/>
        </div>
        <div class="form-group col-md-6">
          <label>Street Address</label>
          <input formControlName="address" class="form-control form-control-sm" type="text"
                 [attr.disabled]="formDisabled || null"
                 required/>
        </div>
        <div class="form-group col-md-6">
          <label>City</label><input formControlName="city" class="form-control form-control-sm"
                                    [attr.disabled]="formDisabled || null"
                                    type="text"
                                    required>
        </div>
        <div class="form-group col-md-6">
          <label>State</label>
          <select name="State" formControlName="state" class="form-control form-control-sm"
                  [attr.disabled]="formDisabled || null"
                  required>
            <option value="" selected="selected">Select a State</option>
            <option value="AL">Alabama</option>
            <option value="AK">Alaska</option>
            <option value="AZ">Arizona</option>
            <option value="AR">Arkansas</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DE">Delaware</option>
            <option value="DC">District Of Columbia</option>
            <option value="FL">Florida</option>
            <option value="GA">Georgia</option>
            <option value="HI">Hawaii</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="IA">Iowa</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="ME">Maine</option>
            <option value="MD">Maryland</option>
            <option value="MA">Massachusetts</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MS">Mississippi</option>
            <option value="MO">Missouri</option>
            <option value="MT">Montana</option>
            <option value="NE">Nebraska</option>
            <option value="NV">Nevada</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NY">New York</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VT">Vermont</option>
            <option value="VA">Virginia</option>
            <option value="WA">Washington</option>
            <option value="WV">West Virginia</option>
            <option value="WI">Wisconsin</option>
            <option value="WY">Wyoming</option>
            <option value="--" label="Other Country">Other Country</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label>Zip</label>
          <input formControlName="zip" class="form-control form-control-sm" type="text"
                 [attr.disabled]="formDisabled || null"
                 required>
        </div>
        <div class="form-group col-md-6">
          <label>Phone</label><input formControlName="phone" class="form-control form-control-sm"
                                     [attr.disabled]="formDisabled || null"
                                     type="text" required>
        </div>
        <div class="form-group col-md-6">
          <label>Email <span class="text-danger" *ngIf="form.errors?.mismatchedEmail && (form.touched || form.dirty)">Emails must match</span></label>
          <input formControlName="email" class="form-control form-control-sm"
                 [attr.disabled]="formDisabled || null"
                 type="email" required>
        </div>

        <div class="form-group col-md-6">
          <label>Confirm Email <span class="text-danger"
                                     *ngIf="form.errors?.mismatchedEmail && (form.touched || form.dirty)">Emails must match</span></label>
          <input formControlName="confirmEmail" [attr.disabled]="formDisabled || null"
                 class="form-control form-control-sm"
                 type="email" required>
        </div>
        <div class="col-12">
          <div class="alert alert-success" *ngIf="allowDownload && result && result.success">
            {{result.message}}
          </div>
          <div class="alert alert-warning" *ngIf="result && !result.success">
            {{result.message}}
          </div>
        </div>
        <div class="col-12 w-100" *ngIf="course.enableTestPrepPracticeTest === true">
          <p>By submitting this information, I certify that I have met the eligibility requirements for the Sporty's
            Completion Certificate having completed the entire video training course and completed at
            least {{course.passingTestsRequired === 1?'one':'two'}} practice
            {{course.passingTestsRequired > 1 ? 'tests' : 'test'}} with a score of at least {{testProgress.minimumScore}} %.</p>
        </div>
        <div class="col-12 w-100 d-flex justify-content-center download-footer">
          <button type="submit" class="btn btn-round btn-primary btn-width-fixed-240"
                  *ngIf="allowSubmit"
                  [attr.disabled]="!form.touched || form.status === 'INVALID' || formDisabled || loading">Submit
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-center download-footer">
        <a (click)="courseService.downloadCertificates(downloadUrl, 'COMPLETION')" class="btn btn-round btn-primary btn-width-fixed-240" *ngIf="allowDownload" download>Download
          Now</a>
      </div>
    </form>
  </div>
</div>

