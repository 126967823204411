<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <p>Your session has timed out.</p>

    <a class="btn btn-white btn-round btn-shadow" (click)="login()">
      <div class="sportys-log-in sportys-log-in-icon"></div>
      Log In
    </a>
  </div>
</div>
