import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QuestionDataService {
  questionText = '';
  fullQuestionUrl = '';

  modifyQuestion(question) {
    const questionText = question['questionText'] || question['question_text'];

    question['legends'] = [];
    question['figures'] = [];

    let split_question = questionText.replace(/^\(Refer.*?\)/, '');

    if (split_question != null) {
      question['first_sentence'] = split_question;
    } else {
      question['first_sentence'] = questionText + '...';
    }

    question['parsed_questionText'] = questionText.replace(/^\(Refer.*?\)/, (str) => {
      let result = '<a href class="figure">' + str + '</a>';
      let first_string = '';

      const nrOfFiguresOrLegends = str
        .match(/(\w\w\w|,) \d+\w?/g)
        .filter((s: string) => s.includes('ure') || s.includes('end')).length;
      let referWithNoParanthesis = str.slice(1, -1);

      if (nrOfFiguresOrLegends === 1) {
        str.replace(/(\w\w\w|,) \d+\w?/g, (sub_str) => {
          let strings = sub_str.split(' ');

          if (first_string === '') {
            first_string = strings[0];
          }

          if (this.isFigure(strings[0], first_string)) {
            question['figures'].push(strings[1]);
            result = this.getResultForOneFigure(strings[1], referWithNoParanthesis);
          } else if (strings[0] === 'end' || first_string === 'end') {
            question['legends'].push(strings[1]);
            result = this.getResultForOneLegend(strings[1], referWithNoParanthesis);
          }
        });
      } else {
        result = str.replace(/(\w\w\w|,) \d+\w?/g, (sub_str) => {
          let strings = sub_str.split(' ');

          if (first_string === '') {
            first_string = strings[0];
          }

          if (this.isFigure(strings[0], first_string)) {
            question['figures'].push(strings[1]);
            return this.getResultForMultipleFigures(strings[0], strings[1]);
          } else if (strings[0] === 'end' || first_string === 'end') {
            question['legends'].push(strings[1]);
            return this.getResultForMultipleLegends(strings[0], strings[1]);
          } else {
            return sub_str;
          }
        });
      }

      return result;
    });

    return question;
  }

  private isFigure(str, firstString) {
    return (
      str !== 'and' &&
      str !== 'eas' &&
      str !== 'rea' &&
      str !== 'ion' &&
      str !== 'end' &&
      firstString !== 'and' &&
      firstString !== 'eas' &&
      firstString !== 'rea' &&
      firstString !== 'ion' &&
      firstString !== 'end'
    );
  }

  private getResultForOneFigure(figure, referWithNoParanthesis) {
    return (
      '(<a href class="figure" data-figure="' +
      figure +
      '" data-type="figure" (click)="showFigure($event, ' +
      figure +
      ')">' +
      referWithNoParanthesis +
      '</a>)'
    );
  }

  private getResultForMultipleFigures(figureTextEnding, figure) {
    return (
      figureTextEnding +
      ' <a href class="figure" data-figure="' +
      figure +
      '" data-type="figure">' +
      figure +
      '</a>'
    );
  }

  private getResultForOneLegend(legend, referWithNoParanthesis) {
    return (
      '(<a href class="legend" data-legend="' +
      legend +
      '" data-type="legend" (click)="showLegend($event, ' +
      legend +
      ')">' +
      referWithNoParanthesis +
      '</a>)'
    );
  }

  private getResultForMultipleLegends(legendTextEnding, legend) {
    return (
      legendTextEnding +
      ' <a href class="legend" data-legend="' +
      legend +
      '" data-type="legend">' +
      legend +
      '</a>'
    );
  }
}
