import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { firstValueFrom, Observable, switchMap } from 'rxjs';
import { TrainingService } from '../training.service';
import { QuestionMarked } from '../course/tests/testprep/questions/question-marked.model';
import { FarCategoryFilter } from '../course/enums/far-category-filter.enum';

@Injectable({
  providedIn: 'root'
})
export class QuestionDetailsService {
  http = inject(HttpClient);
  trainingService = inject(TrainingService);

  getQuestion(questionUuid: string): Observable<any> {
    return this.trainingService
      .load()
      .pipe(
        switchMap((api) =>
          this.http.get<any>(
            `${api.getRoute('getQuestionByUuid').replace('{questionUuid}', questionUuid)}`
          )
        )
      );
  }

  markQuestion(markQuestions: QuestionMarked[]): Observable<any> {
    return this.trainingService.load().pipe(
      switchMap((api) =>
        this.http.put<any>(
          `${api.getRoute('markQuestionUrl')}`,
          markQuestions
        )
      )
    );
  }

  unmarkQuestions(webAppId: string): Promise<any> {
    let params = new HttpParams().append('webAppId', webAppId);
    return new Promise((resolve, reject) => {
      this.trainingService.load().subscribe(api => this.http.post<any>(
        `${api.getRoute('unmarkQuestionsUrl')}`, {},
        {
          params: params
        }
      ).subscribe(() => {
        resolve(null);
      })
      )
    });
  }

  getDPEQuestions(selectedTag: string) {
    return this.trainingService.load().pipe(
      switchMap((api) => {
        return this.http.get<any>(
          `${api.getRoute('getDPEQuestionsUrl').replace('{pilotType}', selectedTag)}`
        );
      })
    );
  }

}


