<div>
  <div *ngIf="correct===undefined" [innerHTML]="html" class="question-text"></div>
  <div *ngIf="correct!==undefined" class="question-text d-flex justify-content-start align-items-start text-size-18">
    <app-icon *ngIf="correct" glyph name="correct_green" class="mr-1"></app-icon>
    <app-icon *ngIf="!correct" glyph name="incorrect_red" class="mr-1"></app-icon>
    <div [innerHTML]="html"></div>
  </div>
  <div *ngIf="images.length>0" class="row col-12 d-flex flex-row flex-wrap question-images " [ngClass]="imagesClass">
    <div *ngFor="let image of images">
      <button class="btn btn-sm btn-round btn-white btn-shadow " (click)="show($event, image)">View Figure
        {{image.code}}</button>
    </div>
  </div>
</div>