import { UntypedFormControl } from '@angular/forms';

export interface ValidationResult {
    [key: string]: boolean;
}

export class PasswordValidator {

    public static strong(control: UntypedFormControl): ValidationResult {
        const hasNumber = /\d/.test(control.value);
        const hasUpper = /[A-Z]/.test(control.value);

        const valid = hasNumber && hasUpper;
        if (!valid) {
            // return what´s not valid
            return { strong: true };
        }
        return null;
    }
}