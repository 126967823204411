import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthService } from '../../services/auth.service';
import { ProgressService } from '../../progress.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoginModalService } from '../../login-modal.service';
import { AnalyticsService } from '../../analytics/analytics.service';
import { identityRevealedValidator } from '../../validators/emailvalidator';

@Component({
  selector: 'app-signup-modal',
  templateUrl: './signup-modal.component.html',
  styleUrls: ['./signup-modal.component.scss']
})
export class SignupModalComponent implements OnInit, OnDestroy {
  message: string;
  working: boolean;
  course: any;
  loginSubscription: Subscription;
  passwordRE = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
  onComplete: () => void;
  allowClose = true;

  form = new UntypedFormGroup({
    firstName: new UntypedFormControl(null, Validators.required),
    lastName: new UntypedFormControl(null, Validators.required),
    email: new UntypedFormControl(null, Validators.required),
    confirm_email: new UntypedFormControl(null, Validators.required),
    password: new UntypedFormControl(null, Validators.required)
  }, { validators: identityRevealedValidator });

  password = true;

  constructor(
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    private progressService: ProgressService,
    private modalService: BsModalService,
    private loginModalService: LoginModalService,
    private analyticsService: AnalyticsService
  ) {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }

  login() {
    this.bsModalRef.hide();
    this.loginModalService.showLogin(this.course, { allowClose: this.allowClose, onComplete: this.onComplete });
  }

  onSubmit() {
    const password = this.form.get('password').value;
    if (!this.passwordRE.test(password) || password.length <= 8) {
      this.message = 'Password must contain one upper case letter and one number.  Minimum length of 8 characters';
      return;
    }
    console.log('SignupModal.onSubmit');
    this.working = true;
    this.authService.register(this.form.get('firstName').value,
      this.form.get('lastName').value,
      this.form.get('email').value,
      password,
      this.course).then(value => {
        console.log('SignupModal.onSubmit:registered');
        this.loginSubscription = this.authService.login(this.form.get('email').value, this.form.get('password').value, this.course)
          .subscribe(loginResult => {
            console.log('SignupModal.onSubmit:login complete');
            this.authService.renew().then(renewedValue => {
              console.log('SignupModal.onSubmit:login renewed');
              this.analyticsService.signup(this.course);
              this.progressService.refreshProgress(this.course.webAppId);
              this.bsModalRef.content.user = renewedValue;
              this.bsModalRef.hide();
              if (this.onComplete) {
                this.onComplete();
              }
            });
          }, error => {
            console.error(error.error.message);
            this.message = error.error.message;
            this.working = false;
          });
      }
        , reason => {
          if (reason.error && reason.error.message) {
            this.message = reason.error.message;
          } else {
            this.message = 'Failed to sign up.';
          }
          this.working = false;
        });

  }
}
