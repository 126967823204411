import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { LoginModalComponent } from './components/login-modal/login-modal.component';

@Injectable({
  providedIn: 'root'
})
export class LoginModalService {

  constructor(private modalService: BsModalService) {
  }

  showLogin(course, config: { allowClose: boolean, onComplete: () => void }) {

    this.modalService.onHide.pipe(take(1)).subscribe(() => {

      const initialState = {
        title: 'Modal with component',
        course,
        courseGroup: course.courseGroup
      };

      this.modalService.show(LoginModalComponent, { ignoreBackdropClick: !config.allowClose, initialState: { ...initialState, ...config }, class: 'modal-lg rounded-modal' });
    });
  }
}
