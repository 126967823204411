<div class="d-flex d-flex-row align-items-center justify-content-start pb-1">
  <div class="text-color-gray mr-3">New to Sporty's?</div>
  <button type="button" class="btn mr-1" (click)="onShowRegisterForm()">Create a free account</button>
</div>
<form [formGroup]="form" (ngSubmit)="onSubmit()" (keypress)="onKeyPress($event)" class="stylized cfi-login-form">
  <div class="row mt-2">

    <div class="form-group col-12 col-md-6">
      <input type="text" class="form-control" formControlName="username" placeholder="Email Address" required>
      <ng-container
        *ngIf="form.get('username').invalid && (form.get('username').dirty || form.get('username').touched)">
        <div class="text-danger" *ngIf="form.get('username').errors?.required">
          Email is required.
        </div>
        <div class="text-danger" *ngIf="form.get('username').errors?.email">
          Email is invalid.
        </div>
      </ng-container>
    </div>
    <div class="form-group col-12 col-md-6">
      <input type="password" class="form-control" formControlName="password" id="password" placeholder="Password"
        required>
      <ng-container
        *ngIf="form.get('password').invalid && (form.get('password').dirty || form.get('password').touched)">
        <div class="text-danger" *ngIf="form.get('password').errors?.required">
          Password is required.
        </div>
      </ng-container>
    </div>
    <div class="text-danger" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
    <div class="row d-flex w-100">
      <div class="row d-flex d-flex-row align-items-start justify-content-start pb-1 pl-4 w-100">
        <button id="createAccount" [disabled]="form.invalid || isLoading" type="submit"
          class="col-1 btn btn-sm btn-primary btn-round btn-shadow ml-4"
          [ngClass]="{'btn-loading': isLoading === true}">Login
        </button>
        <div class="col-10 p-0 d-flex justify-content-end align-items-start">
          <div class="accordion_container w-100 pt-2 pl-3 ml-4">
            <input type="checkbox" id="accordion-1" class="accordion-control">
            <label for="accordion-1" class="accordion-label text-size-14" data-label-closed="" data-label-open="">Need
              help?</label>
            <div class="accordion-content w-100">
              <div class="row">
                <a class="external-link text-size-14" (click)="resetPassword($event)">- Forgot Password</a>
              </div>
              <div class="row">
                <a class="external-link text-size-14" (click)="magicLink($event)">- Send me a link to login</a>
              </div>
            </div>
          </div>
        </div>
        <div class="text-size-12 px-5">
          By logging in, you agree to the <a class="external-link" target="_blank" href="https://www.sportys.com/terms-of-use">Terms of Use</a> and <a
          class="external-link" target="_blank" href="https://www.sportys.com/privacy/">Privacy Policy</a>
        </div>
      </div>
    </div>
  </div>
</form>
