<div class="modal-header">
  <button type="button" class="close" aria-label="Close" (click)="bsModalRef.hide()">
    <app-icon glyph name="incorrect"></app-icon>
  </button>
</div>
<div class="modal-body">
  <div class="question-text" [innerHTML]="html"></div>
  <div>
    <a
      class="btn btn-primary"
      [routerLink]="['/home', 'figure', code]"
      [queryParams]="{ questionId: question.uuid }"
      target="_blank"
      (click)="bsModalRef.hide()"
      >Enlarge Figure in New Tab</a
    >
  </div>
  <div [ngStyle]="{ 'background-image': 'url(' + url + ')' }" class="figure"></div>
</div>
