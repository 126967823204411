import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { SignupModalComponent } from './components/signup-modal/signup-modal.component';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ResetModalComponent } from './components/reset-modal/reset-modal.component';

@Injectable({
  providedIn: 'root'
})
export class SignupModalService {

  constructor(private modalService: BsModalService) {
  }

  showSignup(course) {
    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      const initialState = {
        title: 'Modal with component',
        course,
        courseGroup: course?.courseGroup,
        class: 'second'
      };


      //this.signupModal =
      this.modalService.show(SignupModalComponent, { initialState });

      this.modalService.onHide.pipe(take(1)).subscribe(value => {
        console.log('SignupModalComponent:' + value);
        //if (this.signupModal.content.user) {
        //  this.bsModalRef.hide();
        //}
      });
    });
  }

  showSignupModal(course, config: { allowClose: boolean, onComplete: () => void }) {

    const initialState = {
      title: 'Modal with component',
      course,
      courseGroup: course.courseGroup
    };

    this.modalService.show(SignupModalComponent, {
      ignoreBackdropClick: !config.allowClose,
      initialState: {
        ...initialState,
        ...config
      },
      class: 'modal-lg rounded-modal'
    });

  }

  showReset(course, config: { allowClose: boolean, onComplete: () => any }) {
    this.modalService.onHide.pipe(take(1)).subscribe(() => {
      const initialState = {
        course
      };

      this.modalService.show(ResetModalComponent, { ignoreBackdropClick: !config.allowClose, initialState: { ...initialState, ...config }, class: 'modal-lg rounded-modal' });

      this.modalService.onHide.pipe(take(1)).subscribe(value => {
        console.log('ResetModalComponent:' + value);
      });
    });
  }
}
