import { Component, Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ProgressService } from '../../progress.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {AuthContextService} from '../../services/auth-context.service';

@Directive()
export abstract class ModalViewComponent implements OnInit, OnDestroy {
  user: any;
  purchased: boolean;
  course: any;
  modalRef: BsModalRef;

  constructor(
    protected route: ActivatedRoute,
    protected elementRef: ElementRef,
    protected router: Router,
    protected authContextService: AuthContextService,
    protected progressService: ProgressService,
    protected modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.user = this.authContextService.user;
    this.course = !!this.course ? this.course : this.route.snapshot.data.course;
    this.purchased = this.authContextService.user && this.authContextService.user.coursePermissions[this.course.webAppId];

    this.init();

  }

  ngOnDestroy() {
    this.destroy();
  }

  abstract init();

  abstract destroy();
}
