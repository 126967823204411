import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TrainingService } from '../../training.service';
import * as _ from 'underscore';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(
    private httpClient: HttpClient,
    private trainingService: TrainingService
  ) {
  }

  async load(courseId: string): Promise<any> {
    const api = await this.trainingService.loadByCourse(courseId);
    return new Promise((resolve, reject) => {
      this.httpClient.get(api.getRoute('resourcesUrl')).subscribe(value => {
        _.each(value, (resource) => {
          (resource['references'] as []).sort((a, b) => {
            return a['displayOrder'] - b['displayOrder'];
          });
        });
        resolve(value);
      }, error => {
        reject(error);
      });
    });
  }
}
