// this.purchased = this.authContextService.user && this.authContextService.user.coursePermissions && this.authContextService.user.coursePermissions[this.course.webAppId];
// if purchased is false redirect the user over to the demo route

import {Injectable} from '@angular/core';
import {AuthContextService} from '../services/auth-context.service';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {TrainingService} from '../training.service';
import {AuthService} from '../services/auth.service';

@Injectable()
export class ForcePaidGuard implements CanActivate {
  constructor(private authContextService: AuthContextService,
              private router: Router
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    console.log('ForcePaidGuard.canActivate', state.url);
    const courseId = state.url.substring(state.url.indexOf('course/') + 7, state.url.indexOf('/', state.url.indexOf('course/') + 7));
    console.log('ForcePaidGuard.canActivate', courseId);

    if (this.authContextService.user && this.authContextService.user.coursePermissions && this.authContextService.user.coursePermissions[courseId]) {
      this.router.navigateByUrl(state.url.substring(state.url.indexOf('demo') + 4));
      return false;
    } else {
      return true;
    }
  }
}
