import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AuthService} from '../../services/auth.service';
import {ProgressService} from '../../progress.service';
import * as moment from 'moment';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit, OnDestroy {
  message: string;
  warningMessage: string;
  yesLabel: string;
  yesLabelClass: string;
  noLabel: string;
  noLabelClass: string;
  primaryButton = 'yes';
  response = false;
  title = null;
  showDoughnutChart = false;
  doughnutChartCompletnessPercentage = 100;

  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService
  ) {

  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  yes() {
    console.log('ConfirmationModalComponent.yes');

    this.response = true;
    this.bsModalRef.hide();

  }

  no() {
    console.log('ConfirmationModalComponent.no');

    this.response = false;
    this.bsModalRef.hide();

  }
}
